import { Button, Form, Space, message } from 'antd';
import { useMemo, useState } from 'react';
import EulithHelmetConsumer from '../EulithHelmetConsumer';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useEditFundMutation, useLazyGetUserFundsQuery } from '../../features/funds/fundsService';
import type { FundFormValues, FundsFormSectionProps } from '../../features/funds/fundsTypes';
import { H1, Text, antdFormConfig, fundFormConfig } from '../../styles/shared';
import { buildFieldForType } from '../../utils/funds';
import EulithCard from '../EulithCard';
import BugsnagManager from '../../BugsnagManager';

const FundsFormOverview: React.FC<FundsFormSectionProps> = ({
  editing,
  navigateTo,
  section,
  returnLatestCurrentStage,
  initialValues = {},
  setData
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState<string[]>([]);
  const [editFund] = useEditFundMutation();
  const [getUserFunds] = useLazyGetUserFundsQuery();

  const isLoading = loading || !!loadingFile?.length;

  const fundId = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('id') || '';
  }, []);

  async function handleForwardNavigation(values: FundFormValues) {
    setLoading(true);
    const valuesToSend: FundFormValues = {
      title: values.title,
      id: fundId,
      completedStage: returnLatestCurrentStage(section.path)
    };
    try {
      const response = await editFund(valuesToSend).unwrap();
      setTimeout(async () => {
        if (editing) {
          message.success('Your changes have been saved!');
        }
        try {
          await getUserFunds().unwrap();
        } catch (error: any) {
          BugsnagManager.notify(error, {
            context: 'Unable to fetch latest user funds'
          });
          console.warn('Failed to fetch latest user funds', error);
        }
        const id = response.id;
        setLoading(false);
        setData(response);
        navigate(`/funds/${editing ? 'edit' : 'create'}/${navigateTo}?id=${id}`, {
          replace: true
        });
      }, 750);
    } catch (error: any) {
      BugsnagManager.notify(error, {
        context: 'Unable to save draft'
      });
      setLoading(false);
      message.error('Unable to save your draft. Please try again later.');
      console.warn(error);
    }
  }

  async function resetCompletedStage() {
    const valuesToSend: FundFormValues = {
      id: fundId,
      completedStage: null
    };
    try {
      const response = await editFund(valuesToSend).unwrap();
      console.log('Reset completed stage');
      setData(response);
    } catch (error: any) {
      BugsnagManager.notify(error, {
        context: 'Unable to reset fund form completed stage'
      });
      console.warn('Unable to reset completed stage', error);
    }
  }

  function handleSubmit() {
    form.submit();
  }

  function handleSetFileLoading(id: string, fileIsLoading: boolean) {
    if (fileIsLoading) {
      setLoadingFile((prevFiles: string[]) => {
        if (
          prevFiles.find((fileId) => {
            return fileId === id;
          })
        ) {
          return [...prevFiles];
        } else {
          return [...prevFiles, id];
        }
      });
    } else {
      setLoadingFile((prevFiles) => {
        return [...prevFiles].filter((fileId) => fileId !== id);
      });
    }
  }

  return (
    <>
      <EulithHelmetConsumer>{section.title}</EulithHelmetConsumer>
      <EulithCard
        fullHeight
        bodyStyle={{ height: 'calc(100% - 80px)' }}
        style={{ height: '100%' }}
        extra={
          <Space>
            <Button
              loading={isLoading}
              type={editing ? 'default' : 'text'}
              onClick={() => navigate('/funds/manage')}
            >
              Exit
            </Button>
            <Button loading={isLoading} type="primary" htmlType="submit" onClick={handleSubmit}>
              Next
            </Button>
          </Space>
        }
      >
        <FundsOverviewFormContainer>
          <H1 style={{ textAlign: 'center' }}>{section.title}</H1>
          <Text style={{ textAlign: 'center' }}>{section.subtitle}</Text>
          <Form
            form={form}
            requiredMark={antdFormConfig.requiredMark}
            scrollToFirstError
            wrapperCol={{ span: 24 }}
            initialValues={initialValues}
            style={fundFormConfig.formStyle}
            onFinish={handleForwardNavigation}
            size={antdFormConfig.size}
          >
            {section.fields.map((field) => {
              return buildFieldForType({
                form,
                field,
                fundId,
                setData,
                setLoading,
                setLoadingFile: handleSetFileLoading,
                onRemoveFile: resetCompletedStage
              });
            })}
          </Form>
        </FundsOverviewFormContainer>
      </EulithCard>
    </>
  );
};

const FundsOverviewFormContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
`;

export default FundsFormOverview;
