import styled from 'styled-components';

const StepsContainer = styled.div`
  overflow: auto;
  scrollbar-color: white;
  padding: 0 20px 10px 20px;
  flex-grow: 1;
`;

export default StepsContainer;
