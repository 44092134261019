import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store';
import { endpointHost } from '../../api/common';
import {
  ActivateUserResponse,
  GetNewTotpRequest,
  GetNewTotpResponse,
  LoginLegacyRequest,
  LoginRequest,
  LoginResponse,
  NewActivateUserRequest,
  NewTokenRequest,
  ResetPasswordRequest
} from './authTypes';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: endpointHost,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.accessToken;
      if (token) {
        if (!headers.has('Authorization') && token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
      }
      if (!headers.has('Content-Type')) {
        headers.set('Content-Type', 'application/json');
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (payload) => ({
        url: '/v0/user/login',
        method: 'POST',
        body: payload
      })
    }),
    useLoginLegacyMutation: builder.mutation<LoginResponse, LoginLegacyRequest>({
      query: (payload) => ({
        url: '/v0/user/login',
        method: 'POST',
        body: payload
      })
    }),
    reauthorizeUser: builder.mutation<ActivateUserResponse, NewActivateUserRequest>({
      query: (payload) => ({
        url: '/v0/user/activate-v2',
        method: 'POST',
        body: payload
      })
    }),
    generateNewToken: builder.mutation<LoginResponse, NewTokenRequest>({
      query: (payload) => ({
        url: '/v0/token/new',
        method: 'POST',
        body: payload
      })
    }),
    generateNewTotp: builder.mutation<GetNewTotpResponse, GetNewTotpRequest>({
      query: (payload) => ({
        url: '/v0/token/new-totp',
        method: 'GET',
        params: payload
      })
    }),
    resetPassword: builder.mutation<void, ResetPasswordRequest>({
      query: (payload) => ({
        url: '/v0/user/update_password',
        method: 'POST',
        body: payload
      })
    })
  })
});

export const {
  useLoginMutation,
  useUseLoginLegacyMutationMutation,
  useGenerateNewTokenMutation,
  useResetPasswordMutation,
  useReauthorizeUserMutation,
  useGenerateNewTotpMutation
} = authApi;
