import styled from 'styled-components';
import eulithLogo from '../../assets/images/eulith-logo.png';
import metamaskLogo from '../../assets/images/metamask-logo.png';

function MetaMaskHero() {
  return (
    <StyledHeaderImageContainer>
      <img src={eulithLogo} style={{ width: 60, height: 60 }} />
      <StyledSlash>/</StyledSlash>
      <img src={metamaskLogo} style={{ width: 60, height: 60 }} />
    </StyledHeaderImageContainer>
  );
}

const StyledHeaderImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  user-select: none;
`;

const StyledSlash = styled.div`
  font-size: 50px;
  opacity: 0.75;
  font-weight: 100;
  margin: 0 20px;
`;

export default MetaMaskHero;
