import { Form, Input, Modal, message, notification } from 'antd';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { antdFormConfig } from '../../styles/shared';
import { useCreateWhitelistMutation } from '../../features/whitelist/whitelistService';
import * as Eulith from 'eulith-web3js';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../hooks/redux';
import { selectSelectedWhitelistContract } from '../../features/order/orderSlice';
import BugsnagManager from '../../BugsnagManager';
import AddWhitelistAddressForm from './components/AddWhitelistAddressForm';

interface Props {
  onDiscard: () => void;
  onFinish: (results: number) => void;
  optInWhitelists: Eulith.WhitelistsV2.OptInList[];
}

interface WhitelistFormValues {
  displayName: string;
  description?: string;
  addresses: Eulith.WhitelistsV2.AddressOnChain[];
  sortedSublists: Eulith.WhitelistsV2.OptInList[];
}

const CreateWhitelistDraft = forwardRef(
  ({ onDiscard, onFinish, optInWhitelists = [] }: Props, ref) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [createWhitelist] = useCreateWhitelistMutation();
    const selectedContract = useAppSelector(selectSelectedWhitelistContract);
    const location = useLocation();
    const initialWhitelist = location.state?.fromWhitelist as Eulith.WhitelistsV2.Whitelist;
    const tradingKeyAddress = selectedContract?.tradingKeyAddress || '';

    useEffect(() => {
      form.setFieldsValue({ optInWhitelists });
    }, [form, optInWhitelists]);

    useImperativeHandle(ref, () => ({
      discard: () => {
        const values = form.getFieldValue('addresses');
        if (values.length) {
          Modal.confirm({
            title: 'Are you sure?',
            content: 'You will lose any unsaved progress if you continue.',
            maskClosable: true,
            cancelText: 'Cancel',
            okText: 'Discard',
            okButtonProps: {
              danger: true
            },
            onOk: () => {
              onDiscard();
              form.resetFields();
            }
          });
        } else {
          onDiscard();
          form.resetFields();
        }
      },
      save: () => {
        const addresses = form.getFieldValue('addresses') as Eulith.WhitelistsV2.AddressOnChain[];
        if (addresses.length) {
          form.submit();
        } else {
          message.error('Please provide at least one address before submitting.');
        }
      }
    }));

    const handleFinish = async (values: WhitelistFormValues) => {
      if (tradingKeyAddress) {
        try {
          setLoading(true);
          const addresses = form.getFieldValue('addresses') as Eulith.WhitelistsV2.AddressOnChain[];
          const sublists = form.getFieldValue('sortedSublists') as Eulith.WhitelistsV2.OptInList[];
          const results = await createWhitelist({
            displayName: values.displayName,
            addresses,
            description: values.description || '',
            sublists: (sublists || []).map((list) => list.listId)
          }).unwrap();
          message.success('Created draft whitelist!');
          setLoading(false);
          onFinish(results);
        } catch (error: any) {
          BugsnagManager.notify(error, {
            context: 'Unable to create whitelist draft',
            metadata: {
              values
            }
          });
          console.warn(error);
          notification.open({
            message: 'Unable to create whitelist draft',
            description: error?.message || '',
            placement: 'top',
            type: 'error',
            duration: null
          });
          setLoading(false);
        }
      } else {
        message.error('Please connect your wallet first');
      }
    };

    return (
      <Form
        form={form}
        requiredMark={antdFormConfig.requiredMark}
        scrollToFirstError
        disabled={loading}
        initialValues={{
          displayName: initialWhitelist?.displayName
            ? `${initialWhitelist.displayName} (2)`
            : 'Untitled Whitelist',
          description: initialWhitelist
            ? `Created on ${new Date().toDateString()} and copied from ${
                initialWhitelist.displayName
              }.`
            : `Created on ${new Date().toDateString()}.`,
          sortedSublists: initialWhitelist?.sortedSublists || [],
          addresses: initialWhitelist?.sortedAddresses || []
        }}
        onFinish={handleFinish}
        size={antdFormConfig.size}
        wrapperCol={{ span: 24 }}
        labelCol={antdFormConfig.labelCol}
      >
        <Form.Item
          label="Display Name"
          name="displayName"
          rules={[
            {
              required: true,
              message: 'Please enter a name.'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: 'Please enter a description.'
            }
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldValue }) => {
            const addresses = getFieldValue('addresses') as Eulith.WhitelistsV2.AddressOnChain[];
            const sortedSublists = getFieldValue(
              'sortedSublists'
            ) as Eulith.WhitelistsV2.OptInList[];
            function addOptInList(optInList: Eulith.WhitelistsV2.OptInList) {
              setFieldValue('sortedSublists', [...sortedSublists, optInList]);
            }
            function removeOptInList(optInListToRemove: Eulith.WhitelistsV2.OptInList) {
              const updatedSublists = sortedSublists.filter((existingOptInList) => {
                return existingOptInList.listId !== optInListToRemove.listId;
              });
              setFieldValue('sortedSublists', updatedSublists);
            }
            function addAddress(address: Eulith.WhitelistsV2.AddressOnChain) {
              setFieldValue('addresses', [...addresses, address]);
            }

            function editAddress(
              oldAddress: Eulith.WhitelistsV2.AddressOnChain,
              newAddress: Eulith.WhitelistsV2.AddressOnChain
            ) {
              const updatedAddresses = addresses.map((existingAddress) => {
                if (oldAddress.address === existingAddress.address) {
                  return { ...newAddress };
                } else {
                  return existingAddress;
                }
              });
              setFieldValue('addresses', updatedAddresses);
            }

            function deleteAddress(addressToDelete: Eulith.WhitelistsV2.AddressOnChain) {
              const updatedAddresses = addresses.filter((existingAddress) => {
                return existingAddress.address !== addressToDelete.address;
              });
              setFieldValue('addresses', updatedAddresses);
            }
            return (
              <AddWhitelistAddressForm
                data={addresses}
                selectedOptInLists={sortedSublists || []}
                optInLists={optInWhitelists || []}
                addOptInList={addOptInList}
                removeOptInList={removeOptInList}
                addAddress={addAddress}
                editAddress={editAddress}
                deleteAddress={deleteAddress}
              />
            );
          }}
        </Form.Item>
      </Form>
    );
  }
);

CreateWhitelistDraft.displayName = 'CreateWhitelistDraft';

export default CreateWhitelistDraft;
