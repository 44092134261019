import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  WalletConnectSessionProposal,
  WalletConnectSessionRequest,
  WalletMetadata,
  WalletState
} from './walletTypes';
import type { PayloadAction } from '@reduxjs/toolkit';
import { PairingTypes, SessionTypes } from '@walletconnect/types';
import { JsonRpcResult } from '@json-rpc-tools/utils';
import { MetaMaskWallet } from './metamask';
import eulithSingleton from '../eulith/EulithSingleton';
import { DecoratedContract } from '../eulith/eulithTypes';

export const initialState: WalletState = {
  initializing: true,
  initialized: false,
  tabNotificationMessage: '',
  address: null,
  chainId: null,
  wallet: null,
  failedToConnectToWallet: false,
  proposal: null,
  sessions: [],
  pairings: [],
  transactions: []
};

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWalletInitializing: (state, action: PayloadAction<boolean>) => {
      state.initializing = action.payload;
    },
    setWalletInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
    },
    setTabNotificationMessage: (state, action: PayloadAction<string>) => {
      state.tabNotificationMessage = action.payload;
    },
    setAddress: (state, action: PayloadAction<string | null>) => {
      state.address = action.payload || null;
    },
    setChainId: (state, action: PayloadAction<number | null>) => {
      state.chainId = action.payload ?? null;
    },
    setWalletInstance: (state, action: PayloadAction<WalletMetadata | null>) => {
      if (action.payload) {
        if (action.payload.type === 'MetaMask') {
          eulithSingleton.wallet = new MetaMaskWallet(action.payload.address);
        }
      }
      state.wallet = action.payload;
    },
    setWalletDidFailToConnect: (state, action: PayloadAction<boolean>) => {
      state.failedToConnectToWallet = action.payload;
    },
    setWalletProposal: (state, action: PayloadAction<WalletConnectSessionProposal | null>) => {
      state.proposal = action.payload;
    },
    setWalletSessions: (state, action: PayloadAction<SessionTypes.Struct[]>) => {
      state.sessions = action.payload;
    },
    setWalletPairings: (state, action: PayloadAction<PairingTypes.Struct[]>) => {
      state.pairings = action.payload;
    },
    resetTransactionHistory: (state) => {
      state.transactions = initialState.transactions;
    },
    addWalletTransaction: (
      state,
      action: PayloadAction<{
        request: WalletConnectSessionRequest;
        session?: SessionTypes.Struct;
        contract: DecoratedContract;
      }>
    ) => {
      state.transactions = [
        {
          ...action.payload.request,
          timestamp: new Date().toISOString(),
          session: action.payload.session ? { ...action.payload.session } : undefined,
          contract: action.payload.contract
        },
        ...(state.transactions || [])
      ];
    },
    markWalletTransactionError: (
      state,
      action: PayloadAction<{
        request: WalletConnectSessionRequest;
        reason: string;
        rejected: boolean;
      }>
    ) => {
      state.transactions = state.transactions.map((transaction) => {
        if (transaction.id === action.payload.request.id) {
          return {
            ...transaction,
            error: true,
            success: false,
            rejected: action.payload.rejected,
            result: action.payload.reason
          };
        } else {
          return transaction;
        }
      });
    },
    markWalletTransactionSuccess: (
      state,
      action: PayloadAction<{ request: WalletConnectSessionRequest; response: JsonRpcResult }>
    ) => {
      state.transactions = state.transactions.map((transaction) => {
        if (transaction.id === action.payload.request.id) {
          return {
            ...transaction,
            error: false,
            success: true,
            result: action.payload.response.result
          };
        } else {
          return transaction;
        }
      });
    }
  }
});

export const {
  setWalletInitializing,
  setWalletInitialized,
  setTabNotificationMessage,
  setAddress,
  setChainId,
  setWalletInstance,
  setWalletDidFailToConnect,
  setWalletProposal,
  setWalletSessions,
  setWalletPairings,
  addWalletTransaction,
  resetTransactionHistory,
  markWalletTransactionError,
  markWalletTransactionSuccess
} = walletSlice.actions;

export const selectWalletInitializing = (state: RootState) => state.wallet.initializing;
export const selectWalletInitialized = (state: RootState) => state.wallet.initialized;
export const selectTabNotificationMessage = (state: RootState) =>
  state.wallet.tabNotificationMessage;
export const selectAddress = (state: RootState) => state.wallet.address;
export const selectChainId = (state: RootState) => state.wallet.chainId;
export const selectWalletInstance = (state: RootState) => state.wallet.wallet;
export const selectWalletDidFailToConnect = (state: RootState) =>
  state.wallet.failedToConnectToWallet;
export const selectWalletProposal = (state: RootState) => state.wallet.proposal;
export const selectWalletSessions = (state: RootState) => state.wallet.sessions;
export const selectWalletPairings = (state: RootState) => state.wallet.pairings;
export const selectWalletTransactions = (state: RootState) => state.wallet.transactions;

export default walletSlice.reducer;
