import * as Eulith from 'eulith-web3js';
import { formatJsonRpcError, formatJsonRpcResult, JsonRpcResponse } from '@json-rpc-tools/utils';
import { getSdkError } from '@walletconnect/utils';
import eulithSingleton from '../eulith/EulithSingleton';
import { SUPPORTED_CHAIN_TYPES, SUPPORTED_CHAINS } from './constants';
import { Wallet, WalletConnectSessionRequest } from './walletTypes';
import { showArmorPolicyFailure, showError } from './error';
import { DecoratedContract } from '../eulith/eulithTypes';
import * as ArmorTypes from './armorTypes';
import { chainIdToNetworkLabel } from '../../utils/networks';
import BugsnagManager from '../../BugsnagManager';

export async function signAndSendTransaction(
  selectedContract: DecoratedContract,
  request: WalletConnectSessionRequest
): Promise<JsonRpcResponse> {
  const chainParts = request.params.chainId.split(':');
  const chainType = chainParts[0];
  const chainId = Number(chainParts[1]);
  const baseProvider = eulithSingleton.provider;

  const wallet = eulithSingleton.wallet;

  if (!SUPPORTED_CHAIN_TYPES.has(chainType) || !SUPPORTED_CHAINS.has(chainId)) {
    showError(
      `Received a request for an unsupported network: ${chainIdToNetworkLabel(
        Number(request.params.chainId || -1)
      )}.`,
      null,
      request.id
    );
    return formatJsonRpcError(request.id, getSdkError('UNSUPPORTED_CHAINS').message);
  }

  if (!wallet) {
    showError(
      'Received a request, but could not respond because the custodial wallet is not connected.',
      null,
      request.id
    );
    return formatJsonRpcError(request.id, 'Custodial wallet is not connected.');
  }

  if (!baseProvider) {
    showError(
      'Received a request, but could not respond because Eulith provider is not initialized.',
      null,
      request.id
    );
    return formatJsonRpcError(request.id, 'Eulith provider is not initialized.');
  }

  const armorContract = selectedContract;

  const currentChainId = await wallet.chainId();
  if (currentChainId !== chainId) {
    showError(
      `Received a request, but could not respond because of a mismatch between current network (${chainIdToNetworkLabel(
        currentChainId
      )}) and requested network (${chainIdToNetworkLabel(chainId)}).`,
      null,
      request.id
    );
    return formatJsonRpcError(
      request.id,
      `Mismatch between current network (${chainIdToNetworkLabel(
        currentChainId
      )}) and requested network (${chainIdToNetworkLabel(chainId)}).`
    );
  }

  const originalTx = request.params.request.params[0];

  const randomArray = new BigUint64Array(1);
  window.crypto.getRandomValues(randomArray);
  const atomicTxId = randomArray[0].toString();

  const tradingKeyAddress = armorContract.tradingKeyAddress;
  const provider = baseProvider.clone();
  provider.setAtomicTxParams(
    selectedContract.tradingKeyAddress,
    atomicTxId,
    armorContract.safeAddress
  );
  const ourTx = {
    from: tradingKeyAddress,
    to: originalTx.to,
    value: originalTx.value,
    data: !originalTx.data || originalTx.data === '0x' ? undefined : originalTx.data
  };

  // TODO(drew/ian): use Eulith client bindings instead of directly making provider calls
  try {
    await provider.request({
      method: 'eth_sendTransaction',
      params: [ourTx]
    });
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'signAndSendTransaction: Eulith server returned an error response',
      metadata: {
        ourTx,
        selectedContract,
        armorContract,
        originalTx,
        currentChainId,
        chainParts,
        chainId,
        chainType
      }
    });
    showError(
      'Transaction cancelled because the Eulith server returned an error response.',
      {
        error,
        method: 'eth_sendTransaction'
      },
      request.id
    );
    return formatJsonRpcError(request.id, 'Eulith server failed to create atomic commit.');
  }

  if (armorContract.hasAce) {
    return await commitForAce(request.id, provider, wallet, tradingKeyAddress);
  } else {
    return await commitNoAce(request.id, provider, wallet);
  }
}

async function commitNoAce(
  requestId: number,
  provider: Eulith.Provider,
  wallet: Wallet
): Promise<JsonRpcResponse> {
  let atomicTx;
  try {
    atomicTx = await provider.request({ method: 'eulith_commit', params: [] });
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'commitNoAce/eulith_commit: Eulith server returned an error response',
      metadata: {
        requestId,
        atomicTx
      }
    });
    const errorMessage =
      'Transaction cancelled because the Eulith server returned an error response.';

    const context = {
      error,
      method: 'eulith_commit'
    };

    const data = error?.data;
    if (ArmorTypes.isPolicyFailure(data)) {
      showArmorPolicyFailure({
        msg: 'Transaction cancelled because it failed the DeFi Armor policy.',
        response: error?.data,
        context,
        key: requestId
      });
      return formatJsonRpcError(
        requestId,
        'Transaction denied by Armor policy. Please switch to the Eulith Wallet tab for details.'
      );
    } else {
      showError(errorMessage, context);
      return formatJsonRpcError(requestId, 'Eulith server failed to create atomic commit.');
    }
  }

  let txHash;
  try {
    txHash = await wallet.ethRpcRequest('eth_sendTransaction', [atomicTx]);
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'commitNoAce/eth_sendTransaction: Failed to send atomic transaction to the network',
      metadata: {
        atomicTx,
        requestId
      }
    });
    const msg = 'Failed to send atomic transaction to the network.';
    showError(msg, {
      error,
      method: 'eth_sendTransaction'
    });
    return formatJsonRpcError(requestId, msg);
  }

  return formatJsonRpcResult(requestId, txHash);
}

async function commitForAce(
  requestId: number,
  provider: Eulith.Provider,
  wallet: Wallet,
  tradingKeyAddress: string
): Promise<JsonRpcResponse> {
  let aceImmediateTx;
  try {
    aceImmediateTx = await provider.request({ method: 'eulith_commit_for_ace', params: [] });
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'commitForAce/eulith_commit_for_ace: Eulith server returned an error response',
      metadata: {
        tradingKeyAddress,
        requestId
      }
    });
    const errorMessage =
      'Transaction cancelled because the Eulith server returned an error response.';

    const context = {
      error,
      method: 'eulith_commit_for_ace'
    };

    const data = error?.data;
    if (ArmorTypes.isPolicyFailure(data)) {
      showArmorPolicyFailure({
        msg: 'Transaction cancelled because it failed the DeFi Armor policy.',
        response: error?.data,
        context,
        key: requestId
      });
      return formatJsonRpcError(
        requestId,
        'Transaction denied by Armor policy. Please switch to the Eulith Wallet tab for details.'
      );
    } else {
      showError(errorMessage, context);
      return formatJsonRpcError(requestId, 'Eulith server failed to create atomic commit.');
    }
  }

  const typedData = Eulith.AtomicTx.getAceImmediateTxTypedData(aceImmediateTx);
  ledgerBugWorkaround(typedData);
  const sig = await wallet.signTypedData(typedData);

  let txHash;
  try {
    txHash = await provider.request({
      method: 'eulith_send_ace_transaction',
      params: [
        {
          signature: sig,
          immediate_tx: aceImmediateTx,
          authorized_address: tradingKeyAddress
        }
      ]
    });
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'commitForAce/eulith_send_ace_transaction: Eulith server returned an error response',
      metadata: {
        tradingKeyAddress,
        requestId,
        signature: sig,
        immediate_tx: aceImmediateTx,
        authorized_address: tradingKeyAddress
      }
    });
    showError(
      'Transaction cancelled because the Eulith server returned an error response.',
      {
        error,
        method: 'eulith_send_ace_transaction'
      },
      requestId
    );
    return formatJsonRpcError(requestId, 'Eulith server failed to send transaction to ACE.');
  }

  return formatJsonRpcResult(requestId, txHash);
}

function ledgerBugWorkaround(typedData: any) {
  // Ledger Live requires integers encoded as hex strings to be padded to an even number of digits.

  const nonce = typedData?.message?.nonce;
  if (isHexString(nonce)) {
    typedData.message.nonce = padHexString(nonce);
  }

  const maxPriorityFeePerGas = typedData?.message?.maxPriorityFeePerGas;
  if (isHexString(maxPriorityFeePerGas)) {
    typedData.message.maxPriorityFeePerGas = padHexString(maxPriorityFeePerGas);
  }

  const maxFeePerGas = typedData?.message?.maxFeePerGas;
  if (isHexString(maxFeePerGas)) {
    typedData.message.maxFeePerGas = padHexString(maxFeePerGas);
  }

  const gasLimit = typedData?.message?.gasLimit;
  if (isHexString(gasLimit)) {
    typedData.message.gasLimit = padHexString(gasLimit);
  }
}

function isHexString(x: any): boolean {
  return !!x && typeof x === 'string' && x.startsWith('0x');
}

function padHexString(x: string): string {
  return x.length % 2 === 1 ? '0x0' + x.slice(2) : x;
}
