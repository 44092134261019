import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import usePageView from '../../hooks/usePageView';
import FundsCreateEditPage from '../../pages/FundsCreateEditPage';
import FundsListPage from '../../pages/FundsListPage';
import FundsManagePage from '../../pages/FundsManagePage';

const FundsLayout: React.FC = () => {
  usePageView();
  const location = useLocation();
  const paths = location.pathname.split('/').filter((x) => x);

  return (
    <SwitchTransition>
      <CSSTransition key={`fund_layout_${paths[1]}`} classNames="fade" timeout={300} unmountOnExit>
        <Routes location={location}>
          <Route path="list" element={<FundsListPage />} />
          <Route path="manage" element={<FundsManagePage />} />
          <Route path="edit/*" element={<FundsCreateEditPage editing />} />
          <Route path="create/*" element={<FundsCreateEditPage />} />
        </Routes>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default FundsLayout;
