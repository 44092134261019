import * as Eulith from 'eulith-web3js';
import {
  BaseQueryFn,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery
} from '@reduxjs/toolkit/query/react';
import eulithSingleton from '../eulith/EulithSingleton';
import {
  AppendWhitelistDraftRequest,
  CreateClientWhitelistRequest,
  EditClientWhitelistRequest,
  ArchiveClientWhitelistRequest,
  RemoveWhitelistFromSafeRequest,
  PublishWhitelistRequest,
  SubmitWhitelistSignatureRequest,
  StartWhitelistActivationRequest,
  DeleteClientWhitelistDraftRequest
} from './whitelistTypes';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { createSignerForSafeWhitelist } from '../../utils/signing';
import BugsnagManager from '../../BugsnagManager';

const getOptInWhitelistsQuery: BaseQueryFn<
  void,
  Eulith.WhitelistsV2.OptInList[],
  FetchBaseQueryError
> = async () => {
  try {
    if (eulithSingleton.provider) {
      const result = await Eulith.WhitelistsV2.getOptIn(eulithSingleton.provider);
      return { data: result };
    } else {
      return {
        error: {
          error: 'Eulith singleton has not been set in getOptInWhitelistsQuery'
        } as FetchBaseQueryError
      };
    }
  } catch (error) {
    console.warn('Unable to get all primitive whitelists', error);
    return { error: error as FetchBaseQueryError };
  }
};

const getWhitelistsQuery: BaseQueryFn<
  void,
  Eulith.WhitelistsV2.Whitelist[],
  FetchBaseQueryError
> = async () => {
  try {
    if (eulithSingleton.provider) {
      const result = await Eulith.WhitelistsV2.getAll(eulithSingleton.provider);
      return { data: result };
    } else {
      return {
        error: {
          error: 'Eulith singleton has not been set in getWhitelistsQuery'
        } as FetchBaseQueryError
      };
    }
  } catch (error) {
    console.warn('Unable to get all whitelists', error);
    return { error: error as FetchBaseQueryError };
  }
};

const getPendingActivationsQuery: BaseQueryFn<
  void,
  Eulith.WhitelistsV2.GetPendingActivationsResponse,
  FetchBaseQueryError
> = async () => {
  try {
    if (eulithSingleton.provider) {
      const result = await Eulith.WhitelistsV2.getPendingActivations(eulithSingleton.provider);
      return { data: result };
    } else {
      return {
        error: {
          error: 'Eulith singleton has not been set in getPendingActivationsQuery'
        } as FetchBaseQueryError
      };
    }
  } catch (error) {
    console.warn('Unable to get pending activations', error);
    return { error: error as FetchBaseQueryError };
  }
};

const createWhitelistMutation: BaseQueryFn<
  CreateClientWhitelistRequest,
  number,
  FetchBaseQueryError
> = async ({ displayName, description, addresses, sublists = [] }) => {
  try {
    if (eulithSingleton.provider) {
      const result = await Eulith.WhitelistsV2.createDraftWithSublists(
        eulithSingleton.provider,
        displayName,
        addresses,
        sublists,
        description
      );
      return { data: result };
    } else {
      return {
        error: {
          error: 'Eulith singleton has not been set in createWhitelistMutation'
        } as FetchBaseQueryError
      };
    }
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'Unable to create whitelist in query',
      metadata: {
        displayName,
        addresses
      }
    });
    console.warn('Unable to create whitelist', error);
    return { error: error as FetchBaseQueryError };
  }
};

const removeWhitelistFromSafeMutation: BaseQueryFn<
  RemoveWhitelistFromSafeRequest,
  null,
  FetchBaseQueryError
> = async ({ authAddress, safeAddress, chainId }) => {
  try {
    if (eulithSingleton.provider) {
      await Eulith.WhitelistsV2.removeFromSafe(
        eulithSingleton.provider,
        authAddress,
        safeAddress,
        chainId
      );
      return { data: null };
    } else {
      return {
        error: {
          error: 'Eulith singleton has not been set in removeWhitelistFromSafeMutation'
        } as FetchBaseQueryError
      };
    }
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'Unable to remove whitelist from safe',
      metadata: {
        authAddress,
        safeAddress,
        chainId
      }
    });
    console.warn('Unable to remove whitelist from safe', error);
    return { error: error as FetchBaseQueryError };
  }
};

const editWhitelistMutation: BaseQueryFn<
  EditClientWhitelistRequest,
  Eulith.WhitelistsV2.Whitelist,
  FetchBaseQueryError
> = async ({ listId, displayName, description, notesUpdates }) => {
  try {
    if (eulithSingleton.provider) {
      const result = await Eulith.WhitelistsV2.updateMetadata(
        eulithSingleton.provider,
        listId,
        displayName || '',
        description || '',
        notesUpdates
      );
      return { data: result };
    } else {
      return {
        error: {
          error: 'Eulith singleton has not been set in editWhitelistMutation'
        } as FetchBaseQueryError
      };
    }
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'Unable to edit whitelist',
      metadata: {
        displayName,
        description
      }
    });
    console.warn('Unable to edit whitelist', error);
    return { error: error as FetchBaseQueryError };
  }
};

const archiveWhitelistMutation: BaseQueryFn<
  ArchiveClientWhitelistRequest,
  Eulith.WhitelistsV2.Whitelist,
  FetchBaseQueryError
> = async ({ listId }) => {
  try {
    if (eulithSingleton.provider) {
      const result = await Eulith.WhitelistsV2.archive(eulithSingleton.provider, listId);
      return { data: result };
    } else {
      return {
        error: {
          error: 'Eulith singleton has not been set in archiveWhitelistMutation'
        } as FetchBaseQueryError
      };
    }
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'Unable to archive whitelist',
      metadata: {
        listId
      }
    });
    console.warn('Unable to archive whitelist', error);
    return { error: error as FetchBaseQueryError };
  }
};

const unarchiveWhitelistMutation: BaseQueryFn<
  ArchiveClientWhitelistRequest,
  Eulith.WhitelistsV2.Whitelist,
  FetchBaseQueryError
> = async ({ listId }) => {
  try {
    if (eulithSingleton.provider) {
      const result = await Eulith.WhitelistsV2.unarchive(eulithSingleton.provider, listId);
      return { data: result };
    } else {
      return {
        error: {
          error: 'Eulith singleton has not been set in unarchiveWhitelistMutation'
        } as FetchBaseQueryError
      };
    }
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'Unable to unarchive whitelist',
      metadata: {
        listId
      }
    });
    console.warn('Unable to unarchive whitelist', error);
    return { error: error as FetchBaseQueryError };
  }
};

const publishWhitelistMutation: BaseQueryFn<
  PublishWhitelistRequest,
  Eulith.WhitelistsV2.Whitelist,
  FetchBaseQueryError
> = async ({ listId }) => {
  try {
    if (eulithSingleton.provider) {
      const result = await Eulith.WhitelistsV2.publish(eulithSingleton.provider, listId);
      return { data: result };
    } else {
      return {
        error: {
          error: 'Eulith singleton has not been set in publishWhitelistMutation'
        } as FetchBaseQueryError
      };
    }
  } catch (error) {
    console.warn('Unable to publish whitelist', error);
    return { error: error as FetchBaseQueryError };
  }
};

const startWhitelistActivationMutation: BaseQueryFn<
  StartWhitelistActivationRequest,
  Eulith.WhitelistsV2.StartActivateResponse,
  FetchBaseQueryError
> = async ({ listId, tradingKeyAddress, safeAddress, chainId }) => {
  try {
    if (eulithSingleton.provider) {
      const result = await Eulith.WhitelistsV2.startActivation(
        eulithSingleton.provider,
        listId,
        tradingKeyAddress,
        safeAddress,
        chainId
      );
      return { data: result };
    } else {
      return {
        error: {
          error: 'Eulith singleton has not been set in submitWhitelistSignatureMutation'
        } as FetchBaseQueryError
      };
    }
  } catch (error) {
    console.warn('Unable to submit whitelist signature', error);
    return { error: error as FetchBaseQueryError };
  }
};

const appendWhitelistDraftMutation: BaseQueryFn<
  AppendWhitelistDraftRequest,
  Eulith.WhitelistsV2.Whitelist,
  FetchBaseQueryError
> = async ({ addresses, listId, sublists = [] }) => {
  try {
    if (eulithSingleton.provider) {
      const result = await Eulith.WhitelistsV2.appendToDraftWithSublists(
        eulithSingleton.provider,
        listId,
        addresses,
        sublists
      );
      return { data: result };
    } else {
      return {
        error: {
          error: 'Eulith singleton has not been set in submitWhitelistSignatureMutation'
        } as FetchBaseQueryError
      };
    }
  } catch (error) {
    console.warn('Unable to submit whitelist signature', error);
    return { error: error as FetchBaseQueryError };
  }
};

const deleteWhitelistAddressMutation: BaseQueryFn<
  AppendWhitelistDraftRequest,
  Eulith.WhitelistsV2.Whitelist,
  FetchBaseQueryError
> = async ({ addresses, listId, sublists = [] }) => {
  try {
    if (eulithSingleton.provider) {
      const result = await Eulith.WhitelistsV2.deleteAddresses(
        eulithSingleton.provider,
        listId,
        addresses,
        sublists
      );
      return { data: result };
    } else {
      return {
        error: {
          error: 'Eulith singleton has not been set in useDeleteWhitelistAddressMutation'
        } as FetchBaseQueryError
      };
    }
  } catch (error) {
    console.warn('Unable to delete whitelist address(es)', error);
    return { error: error as FetchBaseQueryError };
  }
};

const submitWhitelistSignatureMutation: BaseQueryFn<
  SubmitWhitelistSignatureRequest,
  Eulith.WhitelistsV2.SubmitActivateSignatureResponse,
  FetchBaseQueryError
> = async ({ address, request }) => {
  try {
    if (eulithSingleton.provider) {
      const signer = createSignerForSafeWhitelist(address);
      const result = await Eulith.WhitelistsV2.submitSignature(
        eulithSingleton.provider,
        signer,
        request
      );
      return { data: result };
    } else {
      return {
        error: {
          error: 'Eulith singleton has not been set in submitWhitelistSignatureMutation'
        } as FetchBaseQueryError
      };
    }
  } catch (error) {
    console.warn('Unable to submit whitelist signature', error);
    return { error: error as FetchBaseQueryError };
  }
};

const deleteWhitelistDraftMutation: BaseQueryFn<
  DeleteClientWhitelistDraftRequest,
  Eulith.WhitelistsV2.Whitelist,
  FetchBaseQueryError
> = async ({ listId }) => {
  try {
    if (eulithSingleton.provider) {
      const result = await Eulith.WhitelistsV2.deleteDraft(eulithSingleton.provider, listId);
      return { data: result };
    } else {
      return {
        error: {
          error: 'Eulith singleton has not been set in deleteWhitelistDraftMutation'
        } as FetchBaseQueryError
      };
    }
  } catch (error) {
    console.warn('Unable to delete whitelist draft', error);
    return { error: error as FetchBaseQueryError };
  }
};

export const whitelistsApi = createApi({
  reducerPath: 'whitelistsApi',
  tagTypes: ['Whitelist', 'Contract'],
  baseQuery: fetchBaseQuery({}),
  endpoints: (builder) => ({
    getPendingActivations: builder.query<Eulith.WhitelistsV2.GetPendingActivationsResponse, void>({
      queryFn: getPendingActivationsQuery,
      providesTags: ['Whitelist']
    }),
    getWhitelists: builder.query<Eulith.WhitelistsV2.Whitelist[], void>({
      queryFn: getWhitelistsQuery,
      providesTags: ['Whitelist']
    }),
    getOptInWhitelists: builder.query<Eulith.WhitelistsV2.OptInList[], void>({
      queryFn: getOptInWhitelistsQuery,
      providesTags: ['Whitelist']
    }),
    createWhitelist: builder.mutation<number, CreateClientWhitelistRequest>({
      queryFn: createWhitelistMutation,
      invalidatesTags: ['Whitelist']
    }),
    editWhitelist: builder.mutation<Eulith.WhitelistsV2.Whitelist, EditClientWhitelistRequest>({
      queryFn: editWhitelistMutation,
      invalidatesTags: ['Whitelist', 'Contract']
    }),
    removeWhitelistFromSafe: builder.mutation<null, RemoveWhitelistFromSafeRequest>({
      queryFn: removeWhitelistFromSafeMutation,
      invalidatesTags: ['Whitelist', 'Contract']
    }),
    archiveWhitelist: builder.mutation<
      Eulith.WhitelistsV2.Whitelist,
      ArchiveClientWhitelistRequest
    >({
      queryFn: archiveWhitelistMutation,
      invalidatesTags: ['Whitelist', 'Contract']
    }),
    unarchiveWhitelist: builder.mutation<
      Eulith.WhitelistsV2.Whitelist,
      ArchiveClientWhitelistRequest
    >({
      queryFn: unarchiveWhitelistMutation,
      invalidatesTags: ['Whitelist', 'Contract']
    }),
    publishWhitelist: builder.mutation<Eulith.WhitelistsV2.Whitelist, PublishWhitelistRequest>({
      queryFn: publishWhitelistMutation,
      invalidatesTags: ['Whitelist']
    }),
    submitWhitelistSignature: builder.mutation<
      Eulith.WhitelistsV2.SubmitActivateSignatureResponse,
      SubmitWhitelistSignatureRequest
    >({
      queryFn: submitWhitelistSignatureMutation,
      invalidatesTags: ['Whitelist', 'Contract']
    }),
    startWhitelistActivation: builder.mutation<
      Eulith.WhitelistsV2.StartActivateResponse,
      StartWhitelistActivationRequest
    >({
      queryFn: startWhitelistActivationMutation,
      invalidatesTags: ['Whitelist', 'Contract']
    }),
    deleteWhitelistDraft: builder.mutation<
      Eulith.WhitelistsV2.Whitelist,
      DeleteClientWhitelistDraftRequest
    >({
      queryFn: deleteWhitelistDraftMutation,
      invalidatesTags: ['Whitelist']
    }),
    appendWhitelistDraft: builder.mutation<
      Eulith.WhitelistsV2.Whitelist,
      AppendWhitelistDraftRequest
    >({
      queryFn: appendWhitelistDraftMutation,
      invalidatesTags: ['Whitelist']
    }),
    deleteWhitelistAddress: builder.mutation<
      Eulith.WhitelistsV2.Whitelist,
      AppendWhitelistDraftRequest
    >({
      queryFn: deleteWhitelistAddressMutation,
      invalidatesTags: ['Whitelist']
    })
  })
});

export const {
  useCreateWhitelistMutation,
  useDeleteWhitelistDraftMutation,
  useGetPendingActivationsQuery,
  useLazyGetPendingActivationsQuery,
  useGetWhitelistsQuery,
  useGetOptInWhitelistsQuery,
  useLazyGetOptInWhitelistsQuery,
  useLazyGetWhitelistsQuery,
  usePublishWhitelistMutation,
  useSubmitWhitelistSignatureMutation,
  useStartWhitelistActivationMutation,
  useAppendWhitelistDraftMutation,
  useDeleteWhitelistAddressMutation,
  useEditWhitelistMutation,
  useArchiveWhitelistMutation,
  useUnarchiveWhitelistMutation,
  useRemoveWhitelistFromSafeMutation
} = whitelistsApi;

export const selectWhitelistsData = createSelector(
  (state: RootState) => {
    if (state.eulith.initialized) {
      const getWhitelistsResponse = whitelistsApi.endpoints.getWhitelists.select()(state);
      return getWhitelistsResponse.data || [];
    } else {
      return [];
    }
  },
  (result) => result
);

export const selectOptInWhitelistsData = createSelector(
  (state: RootState) => {
    if (state.eulith.initialized) {
      const getOptInWhitelistsResponse = whitelistsApi.endpoints.getOptInWhitelists.select()(state);
      return getOptInWhitelistsResponse.data || [];
    } else {
      return [];
    }
  },
  (result) => result
);

export const selectPendingWhitelistActivationsData = createSelector(
  (state: RootState) => {
    if (state.eulith.initialized) {
      const getPendingActivationsResponse =
        whitelistsApi.endpoints.getPendingActivations.select()(state);
      return getPendingActivationsResponse.data?.activations || [];
    } else {
      return [];
    }
  },
  (result) => result
);
