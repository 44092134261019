import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendContactInquiry } from '../../utils/slack';
import { PageContainer, antdFormConfig } from '../../styles/shared';
import { Button, Form, Input } from 'antd';
import FormRow from '../FormRow';
import EulithCard from '../EulithCard';
import BugsnagManager from '../../BugsnagManager';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  message?: string;
}

const ContactUsForm: React.FC = () => {
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (data: FormValues) => {
      const { firstName, lastName, email, message } = data;
      setSubmitting(true);
      try {
        await sendContactInquiry({ firstName, lastName, email, message });
        setSubmitting(false);
        navigate('/thank-you');
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Failed to send contact inquiry in contact us form'
        });
        console.log(error);
        setSubmitting(false);
        navigate('/thank-you');
      }
    },
    [navigate]
  );

  return (
    <PageContainer>
      <EulithCard title="Contact Us">
        <Form
          onFinish={onSubmit}
          style={{ width: '100%' }}
          layout="vertical"
          requiredMark={antdFormConfig.requiredMark}
          scrollToFirstError
          size={antdFormConfig.size}
        >
          <FormRow id="contact_us_form_row">
            <Form.Item
              label="First name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: 'Please enter your first name.'
                }
              ]}
            >
              <Input placeholder="First name" disabled={submitting} />
            </Form.Item>
            <Form.Item
              label="Last name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: 'Please enter your last name.'
                }
              ]}
            >
              <Input placeholder="Last name" disabled={submitting} />
            </Form.Item>
          </FormRow>
          <Form.Item
            label="How can we help?"
            name="message"
            rules={[
              {
                required: true,
                message: 'Please enter a message.'
              }
            ]}
          >
            <Input.TextArea placeholder="Enter your message here..." disabled={submitting} />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please enter your email.'
              }
            ]}
          >
            <Input placeholder="Email" type="email" disabled={submitting} />
          </Form.Item>
          <Button type="primary" htmlType="submit" disabled={submitting}>
            Send Message
          </Button>
        </Form>
      </EulithCard>
    </PageContainer>
  );
};

export default ContactUsForm;
