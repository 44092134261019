import React from 'react';
import EulithHelmetConsumer from '../components/EulithHelmetConsumer';
import SignUpForm from '../components/CreateUser/SignUpForm';

const SignUpPage: React.FC = () => {
  return (
    <>
      <EulithHelmetConsumer>Sign Up</EulithHelmetConsumer>
      <SignUpForm />
    </>
  );
};

export default SignUpPage;
