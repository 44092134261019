import { CopyOutlined } from '@ant-design/icons';
import { useSetIsWalletModalOpen } from '@thirdweb-dev/react';
import { Button, Col, Row, Space, message } from 'antd';
import styled from 'styled-components';
import { selectSelectedTradingContract } from '../../features/order/orderSlice';
import { useAppSelector } from '../../hooks/redux';
import { colors } from '../../styles/shared';
import MiddleEllipses from '../MiddleEllipses';
import { selectPingData } from '../../features/eulith/eulithService';
import MonoSpaceSpan from '../MonoSpaceSans';

function TradingMismatchedWalletAlert() {
  const setIsWalletModalOpen = useSetIsWalletModalOpen();
  const selectedTradingContract = useAppSelector(selectSelectedTradingContract);
  const pingData = useAppSelector(selectPingData);
  const selectedContractAce = pingData[selectedTradingContract?.tradingKeyAddress || ''];

  function connectToCorrectWallet() {
    setIsWalletModalOpen(true);
  }

  function copyAddress() {
    if (selectedContractAce?.ui_address) {
      navigator.clipboard.writeText(selectedContractAce.ui_address);
      message.success('Copied to clipboard!');
    }
  }

  return (
    <StyledAlert>
      <Row align="middle" justify="center" style={{ maxWidth: 800, width: '100%' }}>
        <Col xs={24} lg={12} style={{ padding: '10px 0' }}>
          <Row align="middle" justify="center">
            <strong style={{ textAlign: 'center' }}>
              {selectedContractAce?.ui_address
                ? 'Please connect the correct wallet address:'
                : 'This safe/trading key address combination does not have an associated wallet address.'}
            </strong>
          </Row>
        </Col>
        {selectedContractAce?.ui_address ? (
          <Col xs={24} lg={12} style={{ padding: '10px 0' }}>
            <Row align="middle" justify="center">
              <Space.Compact block style={{ width: '100%', justifyContent: 'center' }}>
                <Button ghost type="default" style={{ maxWidth: 200, pointerEvents: 'none' }}>
                  <MiddleEllipses>
                    <MonoSpaceSpan>{selectedContractAce.ui_address}</MonoSpaceSpan>
                  </MiddleEllipses>
                </Button>
                <Button onClick={copyAddress} ghost>
                  <CopyOutlined style={{ color: colors.black }} />
                </Button>
                <Button
                  onClick={connectToCorrectWallet}
                  ghost
                  style={{ color: '#000', backgroundColor: 'rgba(255,255,255,1)' }}
                >
                  <strong>Connect</strong>
                </Button>
              </Space.Compact>
            </Row>
          </Col>
        ) : null}
      </Row>
    </StyledAlert>
  );
}
const StyledAlert = styled.div`
  margin-bottom: 10px;
  background-color ${colors.error};
  margin-top: -20px;
  margin-left: -20px;
  padding: 10px 20px;
  width: calc(100% + 40px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export default TradingMismatchedWalletAlert;
