import React, { useState } from 'react';
import { Button, Checkbox, Form, Input, Modal, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import * as Eulith from 'eulith-web3js';
import WhitelistAddressListItem from './components/WhitelistAddressListItem';
import { H3, addressValidationConfig, antdFormConfig } from '../../../../styles/shared';
import { networkOptions } from '../../../../utils/networks';

interface AddWhitelistAddressProps {
  data: Eulith.WhitelistsV2.AddressOnChain[];
  optInLists?: Eulith.WhitelistsV2.OptInList[];
  selectedOptInLists?: Eulith.WhitelistsV2.OptInList[];
  addOptInList?: (optInList: Eulith.WhitelistsV2.OptInList) => void;
  removeOptInList?: (optInList: Eulith.WhitelistsV2.OptInList) => void;
  addAddress: (values: Eulith.WhitelistsV2.AddressOnChain) => void;
  editAddress?: (
    oldAddress: Eulith.WhitelistsV2.AddressOnChain,
    newAddress: Eulith.WhitelistsV2.AddressOnChain
  ) => void;
  deleteAddress?: (values: Eulith.WhitelistsV2.AddressOnChain) => void;
  loading?: boolean;
}

interface ContextObject {
  editing: boolean;
  previousValue?: Eulith.WhitelistsV2.AddressOnChain;
}

const AddWhitelistAddressForm: React.FC<AddWhitelistAddressProps> = ({
  data = [],
  addOptInList,
  removeOptInList,
  addAddress,
  deleteAddress,
  editAddress,
  optInLists = [],
  selectedOptInLists = []
}) => {
  const [modalForm] = Form.useForm();
  const [context, setContext] = useState<ContextObject | null>(null);
  const buttonText = context?.editing ? 'Edit Address' : 'Add Address';
  const editable = !!editAddress;

  function onCancel() {
    setContext(null);
    setTimeout(modalForm.resetFields, 500);
  }

  function editLocalAddress(values: Eulith.WhitelistsV2.AddressOnChain) {
    setContext({
      editing: true,
      previousValue: values
    });
    modalForm.setFieldsValue(values);
  }

  function handleFinish() {
    modalForm
      .validateFields()
      .then(async (values) => {
        const existingAddressForWhitelist = data.find(
          (datum) => datum.address?.toLowerCase() === values?.address?.toLowerCase()
        );
        if (!context?.editing && existingAddressForWhitelist) {
          modalForm.setFields([
            {
              name: 'address',
              errors: ['This address already exists in this whitelist.']
            }
          ]);
        } else {
          if (context?.editing && context?.previousValue && editable) {
            if (
              context?.previousValue?.address !== values?.address &&
              existingAddressForWhitelist
            ) {
              modalForm.setFields([
                {
                  name: 'address',
                  errors: ['This address already exists in this whitelist.']
                }
              ]);
            } else {
              editAddress(context?.previousValue, values);
              setContext(null);
              setTimeout(modalForm.resetFields, 500);
            }
          } else {
            addAddress(values);
            setContext(null);
            setTimeout(modalForm.resetFields, 500);
          }
        }
      })
      .catch(console.warn);
  }

  function handleOptInChange(e: any) {
    const optInList = optInLists.find((list) => list.listId === e.target.value);
    if (optInList) {
      if (e.target.checked) {
        addOptInList?.(optInList);
      } else {
        removeOptInList?.(optInList);
      }
    }
  }

  return (
    <div>
      {editable
        ? data.map((datum) => {
            return (
              <WhitelistAddressListItem
                key={`whitelist_address_${datum.address}`}
                data={datum}
                editAddress={editLocalAddress}
                deleteAddress={deleteAddress}
              />
            );
          })
        : null}
      {optInLists?.length ? (
        <div style={{ marginBottom: 15 }}>
          <div style={{ marginBottom: 15 }}>
            <label title="Included Predefined Whitelist(s)">
              <H3>Included Predefined Whitelist(s)</H3>
            </label>
          </div>
          {optInLists.map((optInList) => {
            return (
              <Checkbox
                key={optInList.listId}
                value={optInList.listId}
                name={optInList.name}
                checked={selectedOptInLists?.some((list) => list.listId === optInList.listId)}
                onChange={handleOptInChange}
              >
                {optInList.name}
              </Checkbox>
            );
          })}
        </div>
      ) : null}
      <Button
        type="dashed"
        icon={<PlusOutlined />}
        style={{ marginBottom: 20 }}
        onClick={() => {
          setContext({
            editing: false
          });
        }}
      >
        Add Address
      </Button>
      <Modal
        open={!!context}
        title={buttonText}
        okText={context?.editing ? 'Save' : 'Add'}
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={handleFinish}
        okButtonProps={{
          htmlType: 'submit'
        }}
      >
        <Form
          form={modalForm}
          layout="vertical"
          name="Addresses"
          requiredMark={antdFormConfig.requiredMark}
          scrollToFirstError
          onFinish={handleFinish}
          size={antdFormConfig.size}
        >
          <Form.Item
            name="address"
            label="Address"
            labelCol={antdFormConfig.labelCol}
            style={{ marginBottom: 35 }}
            rules={[
              {
                required: true,
                message: 'Please provide a valid address.'
              },
              addressValidationConfig
            ]}
          >
            <Input style={{ fontFamily: 'monospace' }} placeholder="Add address..." />
          </Form.Item>
          <Form.Item
            name="chainId"
            label="Network"
            labelCol={antdFormConfig.labelCol}
            rules={[
              {
                required: true,
                message: 'Please provide a network.'
              }
            ]}
          >
            <Select options={networkOptions} placeholder="Add address network..." />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddWhitelistAddressForm;
