import { CloseOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import styled from 'styled-components';

interface Props {
  onClick: (value: any) => void;
  tooltip?: string;
  size?: number;
  style?: React.CSSProperties;
}

export default function CloseButton({ onClick, tooltip, size = 10, style }: Props) {
  return (
    <Tooltip title={tooltip}>
      <StyledCloseButton onClick={onClick} size={size} style={style}>
        <CloseOutlined style={{ fontSize: size + 2 }} />
      </StyledCloseButton>
    </Tooltip>
  );
}

const StyledCloseButton = styled.div<{ size: number }>`
  width: ${({ size }) => `${size * 2 + 1}px`};
  height: ${({ size }) => `${size * 2}px`};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${({ size }) => `${size}px`};
  transition: all 0.3s;
  &:hover {
    color: #dc4446;
    cursor: pointer;
    background-color: #dc444622;
  }
`;
