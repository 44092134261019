import { Collapse, notification } from 'antd';
import styled from 'styled-components';
import JsonHighlighter from '../../components/JSONHighlighter';
import { chainIdToScannerUrl } from '../../utils/networks';
import { store } from '../../store';
import * as ArmorTypes from './armorTypes';

export function showError(msg: string, context?: any, key?: number, title?: string) {
  const description = (
    <div>
      <div>{msg}</div>
      <br />
      <StyledDate>({new Date().toLocaleString()})</StyledDate>
    </div>
  );

  showErrorBase(title || msg, description, context, key);
}

export function showArmorPolicyFailure({
  msg,
  response,
  context,
  key
}: {
  msg: string;
  response: ArmorTypes.PolicyFailure;
  context?: any;
  key?: number;
}) {
  const description = generateArmorPolicyErrorBody(response);
  showErrorBase(msg, description, context, key);
}

function showErrorBase(msg: string, description: React.ReactNode, context?: any, key?: number) {
  if (context !== undefined) {
    console.group('eulith-wallet error');
    console.error('eulith-wallet: error:', msg);
    console.error('eulith-wallet: error context:', context);
    console.groupEnd();
  } else {
    console.error('eulith-wallet: error:', msg);
  }

  notification.open({
    key,
    message: msg || 'Error',
    placement: 'top',
    type: 'error',
    duration: null,
    description,
    className: 'wide-notification'
  });
}

function generateArmorPolicyErrorBody(response: ArmorTypes.PolicyFailure) {
  // TODO: ideally we would not grab directly from redux global state
  const chainId = store.getState().wallet.chainId;

  function messageForDeniedCall(reason: ArmorTypes.DenialReason) {
    if (typeof reason === 'string') {
      return <>{reason}</>;
    } else if ('function' in reason) {
      return messageForDeniedCallRule(reason);
    } else if (reason.type === 'EthDestination' && 'destination' in reason) {
      return messageForDeniedCallEthDestination(reason);
    } else if (reason.type === 'Revert' && 'reason' in reason) {
      return messageForRevert(reason);
    } else {
      return messageForUnknownDenial();
    }
  }

  function messageForDeniedCallEthDestination(reason: ArmorTypes.EthDestinationDenialReason) {
    const scannerUrl =
      chainId && chainIdToScannerUrl[chainId]
        ? `${chainIdToScannerUrl[chainId].url}/address/${reason.destination}`
        : '';
    const selectedContract = store.getState().order.selectedWalletContract;
    return (
      <div>
        <div>
          ETH transfer to{' '}
          {
            <a
              href={scannerUrl}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              {reason.destination}
            </a>
          }{' '}
          disallowed. If this transfer is legitimate, you need to add the address to{' '}
          <a
            href={
              selectedContract
                ? `/armor/accounts/${selectedContract.contractAddress}`
                : '/armor/accounts'
            }
            onClick={(e) => e.stopPropagation()}
          >
            your whitelist
          </a>
          .
        </div>
      </div>
    );
  }

  function messageForDeniedCallRule(
    reason: ArmorTypes.RuleDenialReason | ArmorTypes.LegacyRuleDenialReason
  ) {
    if (
      reason.protocol === 'ERC-20' &&
      (reason.function === 'transfer' || reason.function === 'transferFrom')
    ) {
      const selectedContract = store.getState().order.selectedWalletContract;
      const paramToFind =
        reason.function === 'transfer' ? " (first param ('to'))" : " (second param ('_to'))";
      const toParam = (
        (reason.examined_addresses || []).find((address: string) => {
          return address.includes(paramToFind);
        }) || ''
      ).replace(paramToFind, '');
      const scannerUrl =
        chainId && chainIdToScannerUrl[chainId] && toParam
          ? `${chainIdToScannerUrl[chainId].url}/address/${toParam}`
          : '';
      return (
        <div>
          <div>
            ERC-20 transfer to{' '}
            {toParam ? (
              <a
                href={scannerUrl}
                target="_blank"
                rel="noreferrer"
                onClick={(e) => e.stopPropagation()}
              >
                {toParam}
              </a>
            ) : (
              'address'
            )}{' '}
            disallowed. If this transfer is legitimate, you need to add the address to{' '}
            <a
              href={
                selectedContract
                  ? `/armor/accounts/${selectedContract.contractAddress}`
                  : '/armor/accounts'
              }
            >
              your whitelist
            </a>
            .
          </div>
        </div>
      );
    } else {
      // TODO: handle empty protocol
      return (
        <div>
          <div>
            {reason.comment} ({reason.protocol}).
          </div>
          <div>Examined addresses: {reason.examined_addresses}</div>
        </div>
      );
    }
  }

  function messageForRevert(reason: ArmorTypes.RevertDenialReason) {
    return (
      <div>
        {reason.reason ? (
          <div>
            The transaction reverted with message: <code>{reason.reason}</code>
          </div>
        ) : (
          <div>The transaction reverted.</div>
        )}
      </div>
    );
  }

  function messageForUnknownDenial() {
    // If someone contacts us about this, it means we added a new member to the `DenialReason` enum in the backend and
    // forgot to update the frontend.
    return (
      <div>
        <div>
          The transaction was denied for an unspecified reason. Details below. If you see this
          message, please contact the Eulith team and we can help clarify.
        </div>
      </div>
    );
  }

  function messageForComplianceDenial(complianceDenial: ArmorTypes.AddressComplianceResult) {
    return `Address ${complianceDenial.address} was flagged as a compliance risk${
      complianceDenial.risk_explanation ? ` (${complianceDenial.risk_explanation}).` : '.'
    }`;
  }

  function generateCollapsibleErrors(response: ArmorTypes.PolicyFailure) {
    const deniedCallMessages = response.denied_calls.map((deniedCall, i) => {
      return {
        key: `transaction_failure_${i}`,
        label: (
          <div>
            <div>{messageForDeniedCall(deniedCall.reason)}</div>
            <StyledDetailsButton>Details</StyledDetailsButton>
          </div>
        ),
        children: <JsonHighlighter json={deniedCall} />
      };
    });

    const complianceMessages = response.compliance_denials.map((complianceDenial, i) => {
      return {
        key: `transaction_failure_${i}`,
        label: (
          <div>
            <div>{messageForComplianceDenial(complianceDenial)}</div>
            <StyledDetailsButton>Details</StyledDetailsButton>
          </div>
        ),
        children: <JsonHighlighter json={complianceDenial} />
      };
    });

    return deniedCallMessages.concat(complianceMessages);
  }

  return (
    <div>
      <StyledCollapse ghost items={generateCollapsibleErrors(response)} />
      <StyledDate>({new Date().toLocaleString()})</StyledDate>
    </div>
  );
}

const StyledCollapse = styled(Collapse)`
  margin-left: -16px;
  max-height: 70vh;
  overflow: auto;
  margin-bottom: 15px;
  & .ant-collapse-item .ant-collapse-header {
    pointer-events: none !important;
  }
  & .ant-collapse-item .ant-collapse-header .ant-collapse-content .ant-collapse-content-box {
    padding-top: 0 !important;
  }
  & .ant-collapse-item .ant-collapse-header .ant-collapse-expand-icon {
    align-self: flex-end;
    pointer-events: all;
  }
`;

const StyledDate = styled.div`
  font-style: italic !important;
`;

const StyledDetailsButton = styled.div`
  font-weight: bold;
  margin-top: 5px;
  pointer-events: all;
  cursor: pointer;
`;
