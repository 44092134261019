import {
  DownOutlined,
  ExclamationCircleFilled,
  ExportOutlined,
  PlusOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Popover,
  Row,
  Select,
  Space,
  Tag,
  message
} from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import EulithHelmetConsumer from '../components/EulithHelmetConsumer';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import EulithCard from '../components/EulithCard';
import EulithTable from '../components/EulithTable';
import {
  selectContractsData,
  selectPingData,
  useEditContractMutation,
  useLazyGetContractsQuery
} from '../features/eulith/eulithService';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { antdFormConfig, colors } from '../styles/shared';
import { chainIdToTokenSymbol } from '../utils/tokens';
import EmptyState from '../components/EmptyState/EmptyState';
import { AccountTableContract, DecoratedContract } from '../features/eulith/eulithTypes';
import { calculateChainIconUrl, getAllChains } from '../utils/networks';
import { ARMOR_TABS } from '../components/Layouts/shared';
import BugsnagManager from '../BugsnagManager';
import eulithSingleton from '../features/eulith/EulithSingleton';
import {
  useGetWhitelistsQuery,
  useRemoveWhitelistFromSafeMutation
} from '../features/whitelist/whitelistService';
import { setSelectedWhitelistContract } from '../features/order/orderSlice';
import * as Eulith from 'eulith-web3js';
import TradingAccountRow from '../components/TradingAccountRow';
import { useConnectionStatus } from '@thirdweb-dev/react';

const ALLOW_NAVIGATION = false;

const TradingAccountsPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const contracts = useAppSelector(selectContractsData);
  const pingData = useAppSelector(selectPingData);
  const { data: whitelists } = useGetWhitelistsQuery();
  const [editForm] = Form.useForm();
  const [editingContract, setEditingContract] = useState<DecoratedContract | null>(null);
  const [editContract, { isLoading }] = useEditContractMutation();
  const [removeWhitelistFromSafe] = useRemoveWhitelistFromSafeMutation();
  const [getContracts] = useLazyGetContractsQuery();
  const connectionStatus = useConnectionStatus();

  const navigateToWhitelists = useCallback(
    (account: DecoratedContract) => {
      navigate(`/armor/accounts/${Eulith.Web3.utils.toChecksumAddress(account.contractAddress)}`);
    },
    [navigate]
  );

  const dataSource: AccountTableContract[] = contracts
    .filter((x) => x)
    .sort(function (a, b) {
      const textA = a.name?.toUpperCase();
      const textB = b.name?.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    })
    .map((contract: DecoratedContract, index: number) => {
      const contractPingData = pingData[contract.tradingKeyAddress?.toLowerCase?.() || ''];
      return {
        key: contract.contractAddress,
        safeAddress: contract.safeAddress,
        deployedContractId: contract.deployedContractId,
        name: contract.name || `Untitled Account ${index + 1}`,
        description: contract.description,
        tradingKeyAddress: contract.tradingKeyAddress,
        contractAddress: contract.contractAddress,
        hasAce: contract.hasAce,
        isEnabled: contract.isEnabled,
        chainId: contract.chainId,
        tradingKeyAddressBalance: contract.isEnabled
          ? `${contract.tradingKeyAddressBalance || '0'} ${
              chainIdToTokenSymbol[contract.chainId] || ''
            }`
          : '',
        balance: contract.isEnabled ? (contract.balance || 0).toFixed(2) : 0,
        wallet: contractPingData?.ui_address || '',
        aceStatus: contract.isEnabled ? !!contractPingData : null,
        whitelistStatus: contract.isEnabled ? (contract.whitelist ? 'Active' : 'Inactive') : null,
        whitelistId: contract.whitelistId
      };
    });

  const handleEditContract = useCallback(
    (contractAddress: string) => {
      const contract = contracts.find((contract) => {
        return contract.contractAddress === contractAddress;
      });
      if (contract) {
        editForm.setFieldsValue({
          name: contract.name,
          description: contract.description
        });
        setEditingContract(contract);
      }
    },
    [contracts, editForm]
  );

  const handleRemoveWhitelistFromSafe = useCallback(
    async (record: DecoratedContract) => {
      if (!eulithSingleton.provider) {
        message.warning(
          'Unable to remove whitelist from account because Eulith services have not been initialized.'
        );
      } else if (!record) {
        message.warning('You must select a whitelist before removing it from this account.');
      } else {
        try {
          await removeWhitelistFromSafe({
            authAddress: record.tradingKeyAddress,
            safeAddress: record.safeAddress,
            chainId: record.chainId
          }).unwrap();
          await getContracts().unwrap();
          message.success('Whitelist successfully removed from account!');
        } catch (error: any) {
          message.error(error?.message || 'An error occurred when removing this whitelist.');
        }
      }
    },
    [removeWhitelistFromSafe, getContracts]
  );

  const navigateToCreateWhitelistDraft = useCallback(() => {
    navigate('/armor/whitelists/create');
  }, [navigate]);

  const onSelectWhitelistForAssignment = useCallback(
    (whitelistId: number, contract: DecoratedContract) => {
      dispatch(setSelectedWhitelistContract(contract));
      navigate(`/armor/whitelists/${whitelistId}/edit`);
    },
    [navigate, dispatch]
  );

  const renderWhitelistOptions = useMemo(() => {
    if (whitelists) {
      return whitelists.map((whitelist) => {
        return {
          label: whitelist.displayName,
          value: whitelist.listId
        };
      });
    } else {
      return [];
    }
  }, [whitelists]);

  const columns = useMemo(
    () => [
      {
        title: 'Account Name',
        dataIndex: 'name',
        render: (text: string, account: any) => {
          return (
            <StyledAccountNameContainer>
              <div style={{ minWidth: 100 }}>{text}</div>
              {account?.isEnabled ? null : (
                <Popover
                  content={
                    <div>
                      <div>This account is not yet enabled.</div>
                      <div>Click to finish deployment.</div>
                    </div>
                  }
                >
                  <ExclamationCircleFilled
                    style={{ fontSize: 14, marginLeft: 5, color: '#F39292' }}
                  />
                </Popover>
              )}
            </StyledAccountNameContainer>
          );
        }
      },
      {
        title: 'Enabled',
        dataIndex: 'isEnabled',
        render: (isEnabled: boolean) => {
          if (isEnabled) {
            return <StyledTag color="#89E592">Yes</StyledTag>;
          } else {
            return <StyledTag color="#F39292">No</StyledTag>;
          }
        }
      },
      {
        title: 'Whitelist',
        dataIndex: 'whitelistStatus',
        render: (status: string, account: any) => {
          let tag = null;
          const whitelist = whitelists?.find((x) => x.listId === account.whitelistId);
          switch (status) {
            case 'Active':
              tag = (
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 'view_whitelist',
                        onClick: (e) => {
                          e.domEvent.stopPropagation();
                          navigate(`/armor/whitelists/${whitelist?.listId}/edit`);
                        },
                        label: 'View'
                      },
                      {
                        key: 'remove_whitelist',
                        danger: true,
                        onClick: (e) => {
                          e.domEvent.stopPropagation();
                        },
                        label: (
                          <Popconfirm
                            title="Remove from account?"
                            //TODO: eventually we will have the ability to reassociate a whitelist with an account
                            description="You will need to create a new whitelist for this account."
                            okText="Remove"
                            okButtonProps={{
                              danger: true,
                              ghost: true
                            }}
                            onCancel={(e) => {
                              e?.stopPropagation?.();
                            }}
                            onConfirm={(e) => {
                              e?.preventDefault?.();
                              e?.stopPropagation?.();
                              handleRemoveWhitelistFromSafe(account as DecoratedContract);
                            }}
                          >
                            <a onClick={(e: any) => e?.stopPropagation?.()}>Remove</a>
                          </Popconfirm>
                        )
                      }
                    ]
                  }}
                >
                  <div onClick={(e) => e.preventDefault()} className="cursor-pointer">
                    <Space>
                      {whitelist?.displayName || 'Untitled Whitelist'}
                      <DownOutlined style={{ color: '#fff' }} />
                    </Space>
                  </div>
                </Dropdown>
              );
              break;
            case 'In-Progress':
              tag = <StyledTag color="#D8E589">In Progress</StyledTag>;
              break;
            case 'Inactive':
              tag = whitelists?.length ? (
                <Select
                  style={{ width: 150 }}
                  popupMatchSelectWidth={false}
                  placeholder="Attach Whitelist"
                  options={renderWhitelistOptions}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Button
                        type="text"
                        icon={<PlusOutlined />}
                        onClick={navigateToCreateWhitelistDraft}
                      >
                        Create New
                      </Button>
                    </>
                  )}
                  onChange={(whitelistId: number) =>
                    onSelectWhitelistForAssignment(whitelistId, account)
                  }
                />
              ) : (
                'Unassigned'
              );
              break;
            default:
              tag = '-';
          }
          return (
            <Space
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                minWidth: 150
              }}
            >
              {tag}
              {account?.is_enabled ? (
                <DetailButton
                  shape="round"
                  size="small"
                  style={{ backgroundColor: '#3b3f48' }}
                  onClick={() => {
                    if (ALLOW_NAVIGATION) {
                      navigateToWhitelists(account);
                    }
                  }}
                >
                  See Detail
                </DetailButton>
              ) : null}
            </Space>
          );
        }
      },
      {
        title: 'ACE',
        dataIndex: 'aceStatus',
        render: (status: boolean | null, account: any) => {
          if (!account.hasAce) {
            return '-';
          }
          if (status) {
            return <StyledTag color="#89E592">Connected</StyledTag>;
          } else {
            return <StyledTag color="#F39292">Disconnected</StyledTag>;
          }
        }
      },
      {
        title: 'Network',
        dataIndex: 'chainId',
        render: (chainId: number, record: any) => {
          let logo = null;
          let chainWords: any = '?';
          const chain = getAllChains().find((chain) => chain.chainId === chainId);
          if (!chain) {
            console.warn('Could not find a chain for record', record);
          }
          if (chain) {
            logo = calculateChainIconUrl(chain.icon?.url || '', chainId);
            chainWords = chain.name
              .split(' ')
              .filter((x) => x)
              .map((word) => {
                return (
                  <div
                    key={`split_network_name_${record.contractAddress}_${word}`}
                    style={{ fontSize: 13 }}
                  >
                    {word}
                  </div>
                );
              });
          }
          return (
            <Row align="middle" style={{ minWidth: 150 }}>
              {logo ? (
                <Image src={logo} width={30} height={30} preview={false} />
              ) : (
                <QuestionCircleOutlined style={{ fontSize: 30, color: 'rgba(255,255,255,0.3)' }} />
              )}
              <div style={{ marginLeft: 10 }}>{chainWords || chainId}</div>
            </Row>
          );
        }
      },
      {
        title: 'Balance',
        width: '10%',
        dataIndex: 'safeAddress',
        render: (text: string, record: any) =>
          record?.isEnabled && text ? (
            <a
              style={{ color: colors.white }}
              onClick={(e) => {
                e.stopPropagation();
                window.open(`https://debank.com/profile/${text}`, '_blank');
              }}
            >
              <div style={{ minWidth: 100 }}>
                {`$${record?.balance || '0.00'}`}
                <ExportOutlined
                  style={{ marginLeft: 10, fontSize: 14, cursor: 'pointer', color: colors.grey1 }}
                />
              </div>
            </a>
          ) : (
            '-'
          )
      }
    ],
    [
      navigateToWhitelists,
      handleRemoveWhitelistFromSafe,
      navigate,
      whitelists,
      renderWhitelistOptions,
      onSelectWhitelistForAssignment,
      navigateToCreateWhitelistDraft
    ]
  );

  const onRow = useCallback(
    (record: any) => {
      return {
        className: 'eulith-table-row',
        onClick: () => {
          if (!record?.isEnabled) {
            navigate(
              `${ARMOR_TABS.ADD_OWNERS}?id=${Eulith.Web3.utils.toChecksumAddress(
                record.contractAddress
              )}`
            );
          }
        }
      };
    },
    [navigate]
  );

  const handleContractEdit = useCallback(
    (contract: DecoratedContract) => {
      if (editingContract) {
        editContract({
          deployedContractId: editingContract.deployedContractId,
          name: contract.name,
          description: contract.description
        })
          .then(() => {
            editForm.resetFields();
            setEditingContract(null);
            message.success('Account successfully edited!');
          })
          .catch((error: any) => {
            BugsnagManager.notify(error, {
              context: 'Unable to edit account in trading accounts page',
              metadata: {
                contract,
                editingContract
              }
            });
            message.error(error?.message || 'Unable to edit account.');
          });
      }
    },
    [editContract, editingContract, editForm]
  );

  return useMemo(
    () => (
      <>
        <EulithHelmetConsumer>Trading Accounts</EulithHelmetConsumer>
        <EulithCard
          title="Trading Accounts"
          fullHeight
          bodyStyle={{ height: 'calc(100% - 80px)', padding: 10, overflow: 'auto' }}
          style={{ height: '100%', overflow: 'hidden' }}
        >
          {connectionStatus === 'connected' ? (
            dataSource?.length ? (
              <EulithTable
                scroll={{ x: 600 }}
                dataSource={dataSource}
                columns={columns}
                onRow={onRow}
                expandable={{
                  expandRowByClick: true,
                  expandedRowRender: (
                    record: any,
                    index: number,
                    indent: number,
                    expanded: boolean
                  ) => {
                    return (
                      <TradingAccountRow
                        data={record}
                        expanded={expanded}
                        handleEditContract={handleEditContract}
                      />
                    );
                  },
                  rowExpandable: (record: any) => record.isEnabled
                }}
              />
            ) : (
              <EmptyState description="No accounts found" />
            )
          ) : (
            <Alert
              type="warning"
              banner
              message="Please connect a wallet first."
              style={{ maxWidth: 250, margin: '25px auto' }}
            />
          )}
        </EulithCard>
        <Modal
          title="Edit Account Details"
          open={!!editingContract}
          onCancel={() => setEditingContract(null)}
          onOk={() => editForm.submit()}
          okButtonProps={{
            loading: isLoading
          }}
          cancelButtonProps={{
            loading: isLoading
          }}
        >
          <Form
            form={editForm}
            layout="vertical"
            initialValues={{
              name: editingContract?.name,
              description: editingContract?.description
            }}
            requiredMark={antdFormConfig.requiredMark}
            scrollToFirstError
            size={antdFormConfig.size}
            onFinish={handleContractEdit}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please provide a name.' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: 'Please provide a description.' }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </>
    ),
    [
      columns,
      dataSource,
      onRow,
      editForm,
      editingContract,
      isLoading,
      handleEditContract,
      handleContractEdit,
      connectionStatus
    ]
  );
};

const StyledTag = styled(Tag)`
  color: ${colors.blue2} !important;
  font-weight: 600;
`;

const DetailButton = styled(Button)`
  margin-left: 4px;
`;

const StyledAccountNameContainer = styled.div`
  min-width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default TradingAccountsPage;
