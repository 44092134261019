import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Text, antdFormConfig } from '../../styles/shared';
import { Button, Form, Input, Modal, message } from 'antd';
import * as Eulith from 'eulith-web3js';
import EulithCard from '../EulithCard';
import { useReauthorizeUserMutation } from '../../features/auth/authService';
import { LockOutlined } from '@ant-design/icons';
import { QRCodeSVG } from 'qrcode.react';
import { setAccessToken } from '../../features/auth/authSlice';
import { useAppDispatch } from '../../hooks/redux';
import { NewActivateUserRequest } from '../../features/auth/authTypes';
import BugsnagManager from '../../BugsnagManager';

interface LocationState {
  sub: string;
  salt: number;
  activation_hash: string;
  totp_url: string;
}

interface CodePayload {
  code: string;
}

const ReauthorizeForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [reauthorizeUser, { isLoading }] = useReauthorizeUserMutation();
  const locationState = location.state as LocationState;
  const [form] = Form.useForm();

  useEffect(() => {
    if (
      !locationState?.sub ||
      !locationState?.activation_hash ||
      !locationState?.salt ||
      !locationState?.totp_url
    ) {
      message.error(
        'Unable to authenticate due to a malformed payload. Please contact us if this issue persists.'
      );
      navigate('/login');
    }
  }, [
    locationState?.activation_hash,
    locationState?.salt,
    locationState?.sub,
    locationState?.totp_url,
    navigate
  ]);

  const onSubmit = useCallback(
    async (payload: CodePayload) => {
      try {
        const { code } = payload;
        Modal.destroyAll();
        const username = locationState.sub;
        const password = form.getFieldValue('password');
        const generateKeyPairResponse = await Eulith.Auth.generateKeyPair(username, password);
        const signHashResponse = generateKeyPairResponse.sign_hash(locationState.activation_hash);
        const requestData = {
          user: username,
          salt: `${locationState.salt}`,
          activation_hash: locationState.activation_hash,
          totp_code: code,
          pub_key: generateKeyPairResponse.public_key,
          activation_hash_signature: signHashResponse
        } as NewActivateUserRequest;
        const response = await reauthorizeUser(requestData).unwrap();
        if (response?.token) {
          dispatch(setAccessToken(response.token));
          message.success('Thank you for updating your information!');
          const urlParams = new URLSearchParams(window.location.search);
          const redirect = urlParams.get('redirect') || '';
          if (redirect) {
            window.location.replace(redirect);
          } else {
            navigate('/home');
          }
        } else {
          navigate('/login' + window.location.search);
          Modal.info({
            title: "You're all set!",
            content:
              "Moving forward, you'll use the same authenticator app along with your new password to securely log in."
          });
        }
        setLoading(false);
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Unable to update login information in reauthorize form'
        });
        setLoading(false);
        console.log(error);
        message.error(
          'We were unable to update your login information. Please reach out to us if you continue to have difficulties.'
        );
      }
    },
    [
      dispatch,
      form,
      locationState?.activation_hash,
      locationState?.salt,
      locationState?.sub,
      navigate,
      reauthorizeUser
    ]
  );

  function handleNext() {
    setLoading(true);
    Modal.info({
      title: 'Scan QR code to continue in an authenticator app of choice.',
      icon: <LockOutlined />,
      closeIcon: <div />,
      footer: null,
      closable: false,
      maskClosable: false,
      content: (
        <div style={{ paddingRight: 30, paddingTop: 20 }}>
          <QRCodeSVG
            style={{ width: '100%', height: '100%' }}
            includeMargin={false}
            value={locationState?.totp_url}
          />
          <Form onFinish={onSubmit} style={{ marginTop: 20 }}>
            <Form.Item
              name="code"
              labelCol={antdFormConfig.labelCol}
              rules={[
                {
                  required: true,
                  message: 'Please enter a code.'
                }
              ]}
            >
              <Input
                size="large"
                placeholder="Enter code from authenticator app"
                ref={(input) => input && input.focus()}
              />
            </Form.Item>
            <Button
              htmlType="submit"
              block
              size="large"
              type="primary"
              style={{ width: '100%' }}
              loading={isLoading}
            >
              Submit
            </Button>
          </Form>
        </div>
      )
    });
  }

  return (
    <Container>
      <EulithCard title="Update Your Information" extra={<LockOutlined />}>
        <Text>
          Eulith is migrating to a secure{' '}
          <a href="https://www.twilio.com/blog/what-does-otp-mean" target="_blank" rel="noreferrer">
            one-time password
          </a>{' '}
          authentication system, and we&apos;ll need you to update your login information just this
          once. Moving forward, you will use an authenticator app of your choice to log in.
        </Text>
        <Text style={{ margin: '10px 0' }}>
          <strong>Note: </strong> It&apos;s strongly encouraged to use a different password from
          your previous password.
        </Text>
        <Form
          form={form}
          autoComplete="off"
          initialValues={{
            sub: locationState?.sub || ''
          }}
          onFinish={handleNext}
          requiredMark="optional"
          scrollToFirstError
          wrapperCol={{ span: 24 }}
          size={antdFormConfig.size}
        >
          <Form.Item
            labelCol={antdFormConfig.labelCol}
            name="sub"
            label="Username"
            rules={[
              {
                required: true,
                message: 'Please enter your username.'
              }
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            labelCol={antdFormConfig.labelCol}
            name="password"
            label="New Password"
            rules={[
              {
                required: true,
                message: 'Please enter a new password'
              }
            ]}
          >
            <Input.Password
              autoComplete="off"
              placeholder="Please enter a new password"
              disabled={isLoading || loading}
            />
          </Form.Item>
          <Button
            htmlType="submit"
            block
            size="large"
            type="primary"
            style={{ width: '100%' }}
            disabled={isLoading || loading}
            loading={isLoading || loading}
          >
            {isLoading || loading ? null : 'Next'}
          </Button>
        </Form>
      </EulithCard>
    </Container>
  );
};

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 160px;
`;

export default ReauthorizeForm;
