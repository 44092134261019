import styled from 'styled-components';

interface FlexProps {
  /****** Container Props ********/
  flexDirection?: 'row' | 'column';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'initial'
    | 'inherit';
  flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
  alignItems?:
    | 'stretch'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'baseline'
    | 'initial'
    | 'inherit';
  /****** Child Props ********/
  flexGrow?: number;
  flexShrink?: number;
  flexBasis?: number;
  flex?: string;
  /****** Common Layout Props ********/
  padding?: string;
  margin?: string;
  width?: string;
  height?: string;
  maxWidth?: string;
}

const Flex = styled.div<FlexProps>`
  ${({
    flexDirection,
    justifyContent,
    flexWrap,
    alignItems,
    flexGrow,
    flexShrink,
    flexBasis,
    flex,
    /****** Common Layout Props ********/
    padding,
    margin,
    width,
    height,
    maxWidth
  }) => `
    display: flex;
    justify-content: ${justifyContent || 'flex-start'};
    flex-direction: ${flexDirection || 'row'};
    flex-grow: ${flexGrow || 0};
    flex-basis: ${flexBasis || 'auto'};
    flex-shrink: ${flexShrink || 1};
    flex-wrap: ${flexWrap || 'nowrap'};
    flex: ${flex || '0 1 auto'};
    align-items: ${alignItems || 'stretch'};
    margin: ${margin || '0'};
    padding: ${padding || '0'};
    width: ${width || 'auto'};
    height: ${height || 'auto'};
    max-width: ${maxWidth || 'none'};
    
    `}
`;

export default Flex;
