import { LoadingOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import { ConnectWallet, useConnectionStatus } from '@thirdweb-dev/react';
import { Button, Col, Result, Row, Spin } from 'antd';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import styled from 'styled-components';
import {
  selectEulithClientInitialized,
  selectEulithClientInitializing
} from '../../features/eulith/eulithSlice';
import { useAppSelector } from '../../hooks/redux';
import { useIsMismatched } from '../../hooks/useMismatch';
import usePageView from '../../hooks/usePageView';
import TradingHomePage from '../../pages/TradingHomePage';
import { Text } from '../../styles/shared';
import ContractStatus from '../ContractStatus';
import EulithCard from '../EulithCard';
import TradingMismatchedWalletAlert from '../TradingMismatchedWalletAlert';
import { selectHasTradingAccess } from '../../features/auth/authSlice';
import EmptyState from '../EmptyState/EmptyState';
import { selectContractsData } from '../../features/eulith/eulithService';
import { ARMOR_TABS } from './shared';

const TradingLayout: React.FC = () => {
  usePageView();
  const navigate = useNavigate();
  const eulithClientInitializing = useAppSelector(selectEulithClientInitializing);
  const eulithClientInitialized = useAppSelector(selectEulithClientInitialized);
  const connectionStatus = useConnectionStatus();
  const location = useLocation();
  const hasTradingAccess = useAppSelector(selectHasTradingAccess);
  const isMismatched = useIsMismatched();
  const contracts = useAppSelector(selectContractsData);

  function navigateToTokenGeneration() {
    navigate('/tokens');
  }

  function navigateToDeployArmor() {
    navigate(ARMOR_TABS.OVERVIEW);
  }

  useEffect(() => {
    if (!hasTradingAccess) {
      navigate('/home');
    }
  }, [hasTradingAccess, navigate]);

  if (!hasTradingAccess) {
    return null;
  }

  if (!contracts?.length) {
    return (
      <Row
        gutter={[16, 16]}
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <EmptyState
          image={<SafetyCertificateOutlined style={{ fontSize: 50, color: '#FFFFFF40' }} />}
          imageStyle={{ height: 60 }}
          description="You must deploy an Armor instance before using the Eulith trading platform."
        >
          <Button type="primary" size="large" onClick={navigateToDeployArmor}>
            Deploy Armor
          </Button>
        </EmptyState>
      </Row>
    );
  }

  if (connectionStatus !== 'connected') {
    return (
      <div style={{ height: '100%', position: 'relative' }}>
        <Row
          gutter={[16, 16]}
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Text>
            {connectionStatus !== 'disconnected' ? 'Initializing...' : 'Please connect a wallet'}
          </Text>
          {connectionStatus !== 'disconnected' ? (
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          ) : (
            <ConnectWallet theme="dark" />
          )}
        </Row>
      </div>
    );
  } else if (eulithClientInitializing) {
    return (
      <div style={{ height: '100%', position: 'relative' }}>
        <Row
          gutter={[16, 16]}
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Text>Loading...</Text>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </Row>
      </div>
    );
  } else if (!eulithClientInitialized) {
    return (
      <div style={{ height: '100%', position: 'relative' }}>
        <Row
          gutter={[16, 16]}
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <EulithCard>
            <Result
              status="warning"
              title="Please generate an access token first."
              subTitle={
                <Button type="primary" onClick={navigateToTokenGeneration}>
                  Generate Token
                </Button>
              }
            />
          </EulithCard>
        </Row>
      </div>
    );
  }

  const paths = location.pathname.split('/').filter((x) => x);

  return (
    <StyledContainer>
      {isMismatched ? <TradingMismatchedWalletAlert /> : null}
      <StyledRow wrap={false} gutter={[10, 10]}>
        <Col flex="auto" style={{ paddingBottom: 20 }}>
          <ContractStatus contractType="trading" />
          <SwitchTransition>
            <CSSTransition
              key={`trading_layout_${paths[1]}`}
              classNames="fade"
              timeout={300}
              unmountOnExit
            >
              <Routes location={location}>
                <Route path="/" element={<TradingHomePage />} />
              </Routes>
            </CSSTransition>
          </SwitchTransition>
        </Col>
      </StyledRow>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledRow = styled(Row)`
  flex: 1;
`;

export default TradingLayout;
