import * as Eulith from 'eulith-web3js';
import eulithSingleton from '../features/eulith/EulithSingleton';
import { EIP712Domain } from '@thirdweb-dev/sdk/dist/declarations/src/evm/common/sign';

// TODO: there is a decent amount of duplicated code across all of these signature generator methods.
// these should be abstracted out into a comment function that accepts a data type to be signed,
// and returns a ECDSASignature

async function signSafeWhitelistData(
  a: Eulith.Signing.TypedData
): Promise<Eulith.Signing.ECDSASignature> {
  const sdk = eulithSingleton.sdk;
  if (sdk) {
    const response = await sdk.wallet.signTypedData(
      a.domain as EIP712Domain,
      {
        ActivateHashInput: a.types.ActivateHashInput,
        AddressOnChain: a.types.AddressOnChain,
        Sublist: a.types.Sublist
      },
      a.message
    );
    const { /* payload */ signature } = response;
    return new Eulith.Signing.ECDSASignature({
      rsv: signature
    });
  } else {
    throw new Error('SDK is not initialized');
  }
}

async function signSafeTransactionData(
  a: Eulith.Signing.TypedData
): Promise<Eulith.Signing.ECDSASignature> {
  const sdk = eulithSingleton.sdk;
  if (sdk) {
    const response = await sdk.wallet.signTypedData(
      a.domain as EIP712Domain,
      {
        SafeTx: a.types.SafeTx
      },
      a.message
    );
    const { /* payload */ signature } = response;
    return new Eulith.Signing.ECDSASignature({
      rsv: signature
    });
  } else {
    throw new Error('SDK is not initialized');
  }
}

async function signAceTransactionData(
  a: Eulith.Signing.TypedData
): Promise<Eulith.Signing.ECDSASignature> {
  const sdk = eulithSingleton.sdk;
  if (sdk) {
    const response = await sdk.wallet.signTypedData(
      a.domain as EIP712Domain,
      {
        AceImmediateTx: a.types.AceImmediateTx
      },
      a.message
    );
    const { /* payload */ signature } = response;
    return new Eulith.Signing.ECDSASignature({
      rsv: signature
    });
  } else {
    throw new Error('SDK is not initialized');
  }
}

export function createSignerForSafeWhitelist(authorizedAddress: string) {
  return new Eulith.Signing.SigningService({
    typedDataSigner: {
      address: Eulith.Web3.utils.toChecksumAddress(authorizedAddress),
      signTypedData: signSafeWhitelistData
    }
  });
}

export function createSignerForAceImmediateTransaction(authorizedAddress: string) {
  return new Eulith.Signing.SigningService({
    typedDataSigner: {
      address: Eulith.Web3.utils.toChecksumAddress(authorizedAddress),
      signTypedData: signAceTransactionData
    }
  });
}

export function createSignerForSafeTransaction(authorizedAddress: string) {
  return new Eulith.Signing.SigningService({
    typedDataSigner: {
      address: Eulith.Web3.utils.toChecksumAddress(authorizedAddress),
      signTypedData: signSafeTransactionData
    }
  });
}

export function createTransactionSigner(authorizedAddress: string) {
  const signer = eulithSingleton.signer;

  //@ts-ignore
  async function sendTransaction(transactionConfig: TransactionConfig) {
    return signer?.sendTransaction(transactionConfig);
  }

  return new Eulith.Signing.SigningService({
    provider: eulithSingleton.provider as Eulith.Provider | Eulith.Web3 | undefined,
    transactionSigningSender: {
      address: authorizedAddress,
      //@ts-ignore
      sendTransaction
    }
  });
}
