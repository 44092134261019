import { useRef } from 'react';
import { Button, DatePicker, FormInstance } from 'antd';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { DatePickerType } from 'antd/es/date-picker';
import type { FundFormValues } from '../../features/funds/fundsTypes';

interface FundFormDatePickerProps {
  form: FormInstance;
  name: string;
  setData: (values: FundFormValues) => void;
  disabledDate?: (date: any) => boolean;
  placeholder: string;
  value?: DatePickerType | null;
}

const FundFormDatePicker: React.FC<FundFormDatePickerProps> = ({
  form,
  disabledDate,
  placeholder,
  setData,
  name,
  value,
  ...props
}) => {
  const datePickerRef = useRef<any>(null);

  function setDate(value: any) {
    setData({ [name]: value });
    form.setFieldValue(name, value);
  }

  function setLaunchDateOngoing() {
    setDate(null);
    if (datePickerRef.current) {
      datePickerRef.current.blur();
    }
  }

  const formattedValue = value ? dayjs(value as any) : null;

  return (
    <DatePicker
      ref={datePickerRef}
      format="MMM, YYYY"
      picker="month"
      value={formattedValue}
      disabledDate={disabledDate}
      placeholder={placeholder}
      renderExtraFooter={() => {
        return (
          <DatePickerFooterContainer>
            <Button type="primary" size="small" onClick={setLaunchDateOngoing}>
              {placeholder}
            </Button>
          </DatePickerFooterContainer>
        );
      }}
      {...props}
    />
  );
};

const DatePickerFooterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
`;

export default FundFormDatePicker;
