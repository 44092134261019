import { SwapOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Input, Row, Select, Space } from 'antd';
import * as Eulith from 'eulith-web3js';
import { useEffect } from 'react';
import styled from 'styled-components';
import { selectOrderForm } from '../../features/order/orderSlice';
import { useAppSelector } from '../../hooks/redux';
import { useIsMismatched } from '../../hooks/useMismatch';
import { colors } from '../../styles/shared';
import { tokens } from '../../utils/tokens';
import EulithCard from '../EulithCard';

const ICON_SIZE = {
  width: 16,
  height: 16,
  marginRight: 10
};

const ROUTERS = Object.values(Eulith.Swaps.Provider || {});

interface Props {
  handleFieldChange: (fields: any) => void;
  fetchQuote: () => void;
  form: FormInstance;
  loadingQuote: boolean;
}

const OrderForm: React.FC<Props> = ({ handleFieldChange, fetchQuote, form, loadingQuote }) => {
  const formData = useAppSelector(selectOrderForm);
  const isMismatched = useIsMismatched();

  useEffect(() => {
    form.setFieldValue('marketTo', formData.marketTo);
    form.setFieldValue('marketFrom', formData.marketFrom);
  }, [form, formData.marketFrom, formData.marketTo]);

  function renderTokenSwap() {
    return (
      <Col flex="1" style={{ minWidth: 300 }}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Form.Item noStyle shouldUpdate style={{ flex: 1 }}>
            {({ getFieldValue, setFieldValue }) => {
              const marketFrom = getFieldValue('marketFrom');
              const marketType = getFieldValue('marketType');
              const buttonCopy = marketType === 'sell-buy' ? 'SELL' : 'BUY';
              return (
                <Form.Item
                  label="Market"
                  name="marketFrom"
                  style={{ flex: 1 }}
                  rules={[{ required: true, message: 'Please select a token.' }]}
                >
                  <Space.Compact block>
                    <BuySellContainer type={buttonCopy}>{buttonCopy}</BuySellContainer>
                    <Select
                      onChange={(value) => {
                        handleFieldChange({ marketFrom: value });
                        setFieldValue('marketFrom', value);
                      }}
                      popupMatchSelectWidth={false}
                      value={marketFrom}
                      showSearch
                      placeholder="Select Token"
                    >
                      <Select.OptGroup
                        label={buttonCopy === 'BUY' ? 'Select Buy Token' : 'Select Sell Token'}
                      >
                        {tokens.map(([token, Icon]) => (
                          <Select.Option key={token} value={token}>
                            <StyledSelectOption>
                              <Icon style={ICON_SIZE} />
                              {token}
                            </StyledSelectOption>
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                    </Select>
                  </Space.Compact>
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => {
              const marketType = getFieldValue('marketType');
              return (
                <Form.Item label="&nbsp;">
                  <Button
                    type="text"
                    icon={<SwapOutlined style={{ color: '#595D6D' }} />}
                    onClick={() => {
                      const nextPayload = {
                        marketType: marketType === 'sell-buy' ? 'buy-sell' : 'sell-buy'
                      };
                      handleFieldChange(nextPayload);
                      setFieldsValue(nextPayload);
                    }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item noStyle shouldUpdate style={{ flex: 1 }}>
            {({ getFieldValue, setFieldValue }) => {
              const marketTo = getFieldValue('marketTo');
              const marketType = getFieldValue('marketType');
              const buttonCopy = marketType === 'sell-buy' ? 'BUY' : 'SELL';
              return (
                <Form.Item
                  label="&nbsp;"
                  name="marketTo"
                  style={{ flex: 1 }}
                  rules={[{ required: true, message: 'Please select a token.' }]}
                >
                  <Space.Compact block>
                    <BuySellContainer type={buttonCopy}>{buttonCopy}</BuySellContainer>
                    <Select
                      onChange={(value) => {
                        handleFieldChange({ marketTo: value });
                        setFieldValue('marketTo', value);
                      }}
                      popupMatchSelectWidth={false}
                      value={marketTo}
                      showSearch
                      placeholder="Select Token"
                    >
                      <Select.OptGroup
                        label={buttonCopy === 'BUY' ? 'Select Buy Token' : 'Select Sell Token'}
                      >
                        {tokens.map(([token, Icon]) => (
                          <Select.Option key={token} value={token}>
                            <StyledSelectOption>
                              <Icon style={ICON_SIZE} />
                              {token}
                            </StyledSelectOption>
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                    </Select>
                  </Space.Compact>
                </Form.Item>
              );
            }}
          </Form.Item>
        </div>
      </Col>
    );
  }

  return (
    <EulithCard title="Trade" isDisabled={isMismatched} style={{ marginBottom: 10 }}>
      <StyledForm
        layout="vertical"
        name="basic"
        form={form}
        disabled={loadingQuote}
        wrapperCol={{ span: 24 }}
        requiredMark={false}
        initialValues={formData}
        onFinish={fetchQuote}
        autoComplete="off"
        onValuesChange={handleFieldChange}
      >
        <Row gutter={[10, 10]} style={{ width: '100%' }}>
          <Col flex="auto" style={{ maxWidth: 300 }}>
            <Form.Item
              label="Parent Order"
              name="parentOrder"
              rules={[{ required: true, message: 'Please add a parent order.' }]}
            >
              <Input contentEditable={false} value="Immediate Swap" disabled />
            </Form.Item>
          </Col>
          {renderTokenSwap()}
          <Col xs={24} md={8}>
            <Form.Item
              label="In (Qty)"
              name="amount"
              rules={[{ required: true, message: 'Please an input quantity.' }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldValue }) => {
                const value = getFieldValue('maximumSlippage');
                return (
                  <>
                    <Form.Item
                      label="Maximum Slippage"
                      name="maximumSlippage"
                      rules={[{ required: true, message: 'Please enter a maximum slippage.' }]}
                    >
                      <Input
                        type="number"
                        placeholder="eg. 1234"
                        suffix="%"
                        value={value}
                        onChange={(event) => setFieldValue('maximumSlippage', event.target.value)}
                      />
                    </Form.Item>
                    <div style={{ marginTop: -12, display: 'flex', gap: 8 }}>
                      <SlippageBtn
                        size="small"
                        onClick={() => {
                          handleFieldChange({ maximumSlippage: '.01' });
                          setFieldValue('maximumSlippage', '.01');
                        }}
                      >
                        .01%
                      </SlippageBtn>
                      <SlippageBtn
                        size="small"
                        onClick={() => {
                          handleFieldChange({ maximumSlippage: '1' });
                          setFieldValue('maximumSlippage', '1');
                        }}
                      >
                        1%
                      </SlippageBtn>
                      <SlippageBtn
                        size="small"
                        onClick={() => {
                          handleFieldChange({ maximumSlippage: '2' });
                          setFieldValue('maximumSlippage', '2');
                        }}
                      >
                        2%
                      </SlippageBtn>
                    </div>
                    <div
                      style={{ textAlign: 'center', fontSize: 10, fontWeight: 700, marginTop: 4 }}
                    >
                      Recommended
                    </div>
                  </>
                );
              }}
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item label="Router" name="router">
              <Select popupMatchSelectWidth={false} showSearch placeholder="Select Router">
                {ROUTERS.map((item: string) => (
                  <Select.Option key={item}>
                    <StyledSelectOption>{item}</StyledSelectOption>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Button type="primary" htmlType="submit" style={{ display: 'none' }}>
          Submit
        </Button>
      </StyledForm>
    </EulithCard>
  );
};

const StyledForm = styled(Form)`
  label {
    color: #6cbbc2 !important;
    font-weight: 700;
    margin-bottom: 5px !important;
    font-size: 11px !important;
  }

  .ant-input {
    background: none;
  }
`;

const BuySellContainer = styled.span<{ type: 'BUY' | 'SELL' }>`
  font-size: 10px;
  padding: 0 6px;
  color: ${colors.blue2};
  font-weight: 600;
  display: flex;
  user-select: none;
  align-items: center;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  ${({ type }) => {
    if (type === 'SELL') {
      return `background-color: ${colors.error};`;
    } else {
      return `background-color: ${colors.success};`;
    }
  }}
`;

const SlippageBtn = styled(Button)`
  font-size: 9px !important;
  font-weight: 700;
  background-color: #3b3f48;
  flex: 1;
  height: 16px !important;
  box-shadow: none;
`;

const StyledSelectOption = styled.div`
  display: flex;
  align-items: center;
  gap: 6;
`;

export default OrderForm;
