import {
  Button,
  Form,
  FormInstance,
  Image,
  Upload,
  Modal,
  message,
  Row,
  Popconfirm,
  Space
} from 'antd';
import { antdFormConfig } from '../../styles/shared';
import { useCallback, useMemo, useState } from 'react';
import {
  DeleteOutlined,
  FilePdfOutlined,
  LoadingOutlined,
  UploadOutlined
} from '@ant-design/icons';
import ImageCrop from 'antd-img-crop';
import { ShowUploadListInterface, UploadListType } from 'antd/es/upload/interface';
import { capitalize } from 'lodash';
import type { FundFormFile, FundFormValues } from '../../features/funds/fundsTypes';
import {
  useCreateUploadMutation,
  useDeleteUploadMutation,
  useEditUploadMutation
} from '../../features/funds/fundsService';
import BugsnagManager from '../../BugsnagManager';

const formStyles = { maxWidth: 500 };

interface FundFormUploaderProps {
  name: string;
  required?: boolean;
  fundId: string;
  label: string;
  allowCrop?: boolean;
  help?: string;
  extra?: string;
  form?: FormInstance;
  maxCount?: number;
  accept?: string;
  listType?: UploadListType;
  allowHideFromListing?: boolean;
  onRemove?: (id: string) => void;
  setData: (payload: FundFormValues) => void;
  setLoading?: (loading: boolean) => void;
  setLoadingFile?: (id: string, loading: boolean) => void;
}

interface OnChange {
  file: FundFormFile;
  fileList: FundFormFile[];
}

const FundFormUploader: React.FC<FundFormUploaderProps> = ({
  form,
  fundId,
  maxCount,
  name,
  accept,
  extra,
  allowCrop = false,
  required = false,
  label,
  help,
  allowHideFromListing = true,
  listType = 'picture',
  onRemove,
  setData,
  setLoadingFile
}) => {
  const [deleteUpload] = useDeleteUploadMutation();
  const [createUpload] = useCreateUploadMutation();
  const [editUpload] = useEditUploadMutation();

  const initialValues = useMemo(() => {
    return form?.getFieldValue?.(name) || [];
  }, [form, name]);

  const [fileList, setFileList] = useState<Array<FundFormFile>>(initialValues || []);
  const [preview, setPreview] = useState<FundFormFile | null>(null);

  const transformedFileList = fileList.map(
    (file) => {
      const stableId = `${!file.uid || file.uid?.includes?.('__AUTO__') ? file.id : file.uid}`;
      if (file.response) {
        return {
          ...file,
          ...file.response,
          uid: stableId
        };
      } else if (!file.uid) {
        return {
          ...file,
          status: 'done',
          response: file,
          FundId: fundId,
          uid: stableId
        };
      } else {
        return {
          ...file,
          uid: stableId
        };
      }
    },
    [fileList]
  );

  function closePreviewModal() {
    setPreview(null);
  }

  const showUploadListOptions: ShowUploadListInterface = {
    showRemoveIcon: false,
    showPreviewIcon: false,
    showDownloadIcon: false
  };

  function normFile(e: any) {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  }

  const rules = useMemo(() => {
    return required
      ? [
          {
            required: true,
            validator(/* _, value */) {
              if (transformedFileList.length) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(`Please upload a ${label.toLowerCase()}.`));
            }
          }
        ]
      : [];
  }, [transformedFileList.length, label, required]);

  const customRequest = useCallback(
    async (options: any) => {
      const id = options.file.uid;
      setLoadingFile?.(id, true);
      form?.validateFields?.();
      try {
        const formData = new FormData();
        formData.append('file', options.file);
        formData.append('fieldName', name);
        formData.append('fundId', fundId);
        formData.append('label', label);
        const response = await createUpload(formData).unwrap();
        const newData = [...(initialValues || []), response];
        setData({ [name]: newData });
        form?.setFieldValue?.(name, newData);
        setLoadingFile?.(id, false);
        form?.validateFields?.();
        options?.onSuccess?.(response, response);
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Unable to upload file in fund form uploader'
        });
        console.log(error);
        message.error(
          'Unable to upload file. Please try again later or contact us if this issue persists.'
        );
        options?.onError?.(error);
        setLoadingFile?.(id, false);
        console.warn(error);
      }
    },
    [setLoadingFile, form, name, fundId, label, createUpload, initialValues, setData]
  );

  const handleRemove = useCallback(
    async (f: FundFormFile) => {
      const id = f.response?.id || f.id;
      if (id) {
        try {
          await deleteUpload(id).unwrap();
          const newData = transformedFileList.filter((file) => {
            return file.id !== id && file.uid !== id;
          });
          form?.setFieldValue?.(name, newData);
          form?.validateFields?.();
          setFileList(newData);
          setData({ [name]: newData });
          onRemove?.(id);
          message.success(`${label} deleted!`);
        } catch (error: any) {
          BugsnagManager.notify(error, {
            context: 'Unable to delete file in fund form uploader'
          });
          console.warn(error);
          message.error(`Unable to delete ${label.toLowerCase()}.`);
        }
      } else {
        const newData = transformedFileList.filter((file) => {
          return file.id !== id && file.uid !== id;
        });
        setData({ [name]: newData });
        form?.setFieldValue?.(name, newData);
        form?.validateFields?.();
      }
    },
    [deleteUpload, transformedFileList, form, name, setData, onRemove, label]
  );

  const iconRenderer = useCallback((file: FundFormFile) => {
    const type = file.type;
    if (file.url) {
      if (type?.includes?.('pdf')) {
        return <FilePdfOutlined />;
      }
      return <Image width={200} height={200} src={file.url} />;
    } else if (file.status === 'uploading') {
      return <LoadingOutlined />;
    } else if (type?.includes?.('pdf')) {
      return <FilePdfOutlined />;
    } else {
      return null;
    }
  }, []);

  const toggleUploadVisibility = useCallback(
    async (file: FundFormFile | undefined) => {
      if (file) {
        const newStatus = !file.hide;
        setFileList((prevList) => {
          return [...prevList].map((f) => {
            if (f.uid === file.uid || f.id === file.id) {
              return {
                ...f,
                hide: newStatus
              };
            } else {
              return f;
            }
          });
        });
        // TODO: this needs better type definitions (name and uid were originally not here)
        const valuesToSend: FundFormFile = {
          id: file.id || file.response?.id,
          name: file.name,
          uid: file.uid,
          hide: newStatus
        };
        try {
          await editUpload(valuesToSend).unwrap();
          message.success(
            `${capitalize(label)} visibility set to ${newStatus ? 'private' : 'public'}!`
          );
        } catch (error: any) {
          BugsnagManager.notify(error, {
            context: 'Unable to change file visibility in fund form uploader'
          });
          console.warn(error);
          message.error('Unable to change file visibility. Please try again later.');
        }
      }
    },
    [editUpload, label]
  );

  const itemRenderer = useCallback(
    (node: React.ReactElement, file: FundFormFile) => {
      const stateFile = fileList.find((f) => {
        return f.id === file.id || f.uid === file.uid;
      });
      if (file.status === 'error') {
        return null;
      }
      return (
        <Row style={{ width: '100%' }}>
          <Space style={{ width: '100%' }}>
            {node}
            {stateFile?.id || stateFile?.response?.id ? (
              <Space size="small" direction="vertical" style={{ marginTop: 10 }}>
                <Popconfirm
                  title="Delete this file?"
                  description="This file will be permanently deleted from your fund."
                  okText="Delete"
                  cancelText="Cancel"
                  okButtonProps={{
                    danger: true
                  }}
                  onConfirm={(e) => {
                    e?.preventDefault?.();
                    handleRemove(stateFile);
                  }}
                >
                  <Button type="text" size="small" danger>
                    <DeleteOutlined />
                    Delete
                  </Button>
                </Popconfirm>
                {allowHideFromListing ? (
                  <Popconfirm
                    title={stateFile?.hide ? 'Make this file public?' : 'Make this file private?'}
                    description={
                      stateFile?.hide
                        ? "This file will become available on your fund's public page."
                        : "This file will become hidden on your fund's public page."
                    }
                    okText={stateFile?.hide ? 'Make Public' : 'Make Private'}
                    cancelText="Cancel"
                    onConfirm={(e) => {
                      e?.preventDefault?.();
                      toggleUploadVisibility(stateFile);
                    }}
                  >
                    <Button type="text" size="small">
                      {stateFile?.hide ? 'Make Public' : 'Make Private'}
                    </Button>
                  </Popconfirm>
                ) : null}
              </Space>
            ) : null}
          </Space>
        </Row>
      );
    },
    [allowHideFromListing, fileList, handleRemove, toggleUploadVisibility]
  );

  const onPreview = useCallback((file: FundFormFile) => {
    if (file.type?.includes('pdf')) {
      window.open(file.url || file.response?.url, '_blank');
    } else {
      setPreview(file);
    }
  }, []);

  const onChange = useCallback(({ fileList }: OnChange) => {
    setFileList(
      fileList.filter((file) => {
        return file.status !== 'error';
      })
    );
  }, []);

  const upload = (
    <Upload
      style={{ width: '100%' }}
      accept={accept}
      multiple={maxCount !== 1}
      maxCount={maxCount}
      onPreview={onPreview}
      onChange={onChange}
      onDownload={() => null}
      fileList={transformedFileList.filter((file) => {
        return file.status !== 'error';
      })}
      iconRender={iconRenderer}
      itemRender={itemRenderer}
      customRequest={customRequest}
      showUploadList={showUploadListOptions}
      listType={listType}
    >
      {transformedFileList.length === maxCount ? null : (
        <Button icon={<UploadOutlined />}>Upload</Button>
      )}
    </Upload>
  );

  return (
    <>
      <Form.Item
        labelCol={antdFormConfig.labelCol}
        name={name}
        label={label}
        rules={rules}
        help={help}
        extra={extra}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        style={formStyles}
      >
        {allowCrop ? <ImageCrop>{upload}</ImageCrop> : upload}
      </Form.Item>
      <Modal open={!!preview} maskClosable onCancel={closePreviewModal} footer={null}>
        <Row align="middle" justify="center">
          <Image src={preview?.url} preview={false} />
        </Row>
      </Modal>
    </>
  );
};

export default FundFormUploader;
