import { FormProps } from 'antd';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const transitonTime = 0.115;

export const padding = 42;
export const margin = 16;
export const marginTop = 160;

export const sizes = {
  default: 16,
  small: 12,
  large: 24,
  xLarge: 36
};

export const fontWeights = {
  ultraLight: 100,
  light: 200,
  default: 300,
  medium: 400,
  semiBold: 500,
  bold: 600,
  black: 800
};

export const colors = {
  grey1: '#a7a7a7',
  grey2: '#c7c7c7',
  grey3: '#f7f7f7',
  grey4: '#7E8189',
  info: '#0275d8',
  infoLight: '#44a7fd',
  error: '#F39292',
  text: '#344767',
  success: '#89E592',

  teal: '#4E9C9F',

  /* new colors   */
  black: '#000000',
  background: '#121212',
  blue: '#0085FF',
  blue2: '#181A1F',
  blue3: '#080B10',
  blueTrans: '#b5daff21',
  greenTrans: 'rgba(0, 255, 25, 0.24)',
  red: '#FF004D',
  redTrans: 'rgba(255, 0, 77, 0.24)',
  white: '#FFFFFF',
  whiteTrans: 'rgba(255, 255, 255, 0.05)',
  placeholder: 'rgba(255, 255, 255, 0.38)',

  grey: '#B3B3B3',
  darkGrey: '#1C1C1C',
  darkGrey2: '#303030',
  lightGrey: 'rgba(217, 217, 217, 0.32)',
  terminalGrey: '#262626',
  borderGrey: 'rgba(255, 255, 255, 0.24)',

  primary: '#60B2B6'
};

export const fundFormConfig = {
  cardStyle: { height: '100%', overflow: 'hidden' },
  bodyStyle: {
    height: 'calc(100% - 80px)',
    padding: 0,
    overflow: 'auto',
    scrollbarColor: 'light'
  },
  formStyle: { marginTop: 20, paddingBottom: 0, width: '100%' }
};

export const antdFormConfig: FormProps = {
  labelCol: { span: 24 },
  requiredMark: true,
  size: 'large'
};

export const addressValidationConfig = {
  pattern: /^(0x)?[0-9a-fA-F]{40}$/,
  message: 'Please enter a valid address.'
};

export const device = {
  tablet: '(min-width: 800px)',
  tabletLarge: '(min-width: 970px)',
  laptop: '(min-width: 1240px)',
  desktop: '(min-width: 1400px)',
  desktopLarge: '(min-width: 1600px)'
};

export const transitionCss = css`
  transition: all ${transitonTime}s linear;
  * {
    transition: all ${transitonTime}s linear;
  }
`;

export const opacityTransitionCss = css`
  ${transitionCss}
  :hover {
    opacity: 0.7;
  }
`;

export const backgroundFixedCSS = css`
  background-attachment: scroll;
  @media ${device.tabletLarge} {
    background-attachment: fixed;
  }
`;

export const BackgroundFixed = styled.div`
  ${backgroundFixedCSS};
`;

export const baseCss = css`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const OldSubmitButton = styled.input`
  font-size: 16px;
  border-radius: 9px;
  background-color: ${colors.info};
  border: 0;
  color: white;
  margin-top: 25px;
  padding: 0px 0px;
  height: 40px;
  font-weight: bold;
  box-shadow: 0px 0px 3px 1px ${colors.grey2};
`;

interface CustomButtonProps extends React.HTMLProps<HTMLInputElement> {
  dark?: boolean;
  fullWidth?: boolean;
}

export const buttonCss = `
border: 1px solid #0085ff;
border-radius: 10px;
color: white;
padding: 20px 12px;
font-weight: ${fontWeights.black};
font-size: ${sizes.default}px;
text-align: center;
cursor: pointer;
${transitionCss};
display: block;
:hover {
  opacity: 0.7;
}

`;

export const Button = styled.button<CustomButtonProps>`
  ${buttonCss}
  background: ${({ dark }) => (dark ? colors.darkGrey2 : colors.blue)};

  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

  ${({ disabled }) =>
    disabled &&
    `
  background-color: ${colors.grey1};
  color: ${colors.grey2};
  cursor: not-allowed;
  border: none;


`}
`;

export const StyledInternalLink = styled(Link)`
  ${transitionCss};
  text-decoration: none;
  cursor: pointer;
  color: ${colors.blue};

  :hover {
    opacity: 0.8;
  }
`;

export const StyledLink = styled.a`
  ${transitionCss};
  text-decoration: none;
  cursor: pointer;

  :hover {
    opacity: 0.8;
    color: ${colors.blue};
  }
`;

export const PageContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 160px;
  height: 100%;
`;

export const FadedOverlay = styled.div`
  position: fixed;
  z-index: 3;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 77%);
`;

export const Sticky = styled.div`
  position: sticky;
  top: 0;
  padding: ${margin}px;
  text-align: center;
  color: white;
  align-self: flex-start;
  width: 100%;
  flex: 0 0 auto;
  backdrop-filter: blur(4px);
  display: block;
  z-index: 1;
`;

/* Text */

export const H1 = styled.h1`
  padding: 0;
  margin: 0;
  font-weight: ${fontWeights.black};
  font-size: ${sizes.xLarge}px;
  color: ${colors.white};
  line-height: 43px;
`;

export const H2 = styled.h2`
  padding: 0;
  margin: 0;
  font-weight: ${fontWeights.bold};
  font-size: ${sizes.large}px;
  line-height: 32px;
  color: ${colors.white};
`;

export const H3 = styled.h3`
  padding: 0;
  margin: 0;
  font-weight: ${fontWeights.semiBold};
  font-size: ${sizes.default}px;
  line-height: 32px;
  color: ${colors.white};
`;

export const Text = styled.p`
  font-weight: ${fontWeights.default};
  font-size: ${sizes.default}px;
  color: ${colors.white};
  margin: 0;
`;

export const StyledLi = styled.li`
  font-weight: bold;
  font-size: ${sizes.default}px;
  color: ${colors.white};
  margin-bottom: 15px;
`;

export const StyledItalic = styled.p`
  font-style: italic;
  opacity: 0.7;
`;
