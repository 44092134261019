import { ArrowLeftOutlined } from '@ant-design/icons';
import { colors } from '../../styles/shared';
import { useNavigate } from 'react-router-dom';

interface Props {
  to?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  backTitle?: string | null;
}

function FundFormBackButton({ to, backTitle = 'BACK', disabled, style = {} }: Props) {
  const navigate = useNavigate();
  function goBack() {
    if (to) {
      navigate(to);
    } else {
      navigate(-1);
    }
  }
  return (
    <div
      onClick={goBack}
      style={{
        opacity: disabled ? 0.5 : 1,
        pointerEvents: disabled ? 'none' : 'auto',
        color: colors.borderGrey,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        fontSize: 14,
        ...style
      }}
    >
      <ArrowLeftOutlined style={{ marginRight: 10, fontSize: 20 }} />
      {backTitle}
    </div>
  );
}

export default FundFormBackButton;
