import { Input, InputProps } from 'antd';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

function PhoneInput(props: InputProps) {
  const formatPhoneNumber = (value: any) => {
    if (!value) {
      return value;
    }

    const phoneNumber = parsePhoneNumberFromString(value || '', 'US');
    return phoneNumber ? phoneNumber.formatNational() : value;
  };

  const handlePhoneNumberChange = (e: any) => {
    const { value } = e.target;
    const formattedValue = formatPhoneNumber(value);
    e.target.value = formattedValue;
    props.onChange?.(e);
  };

  return (
    <Input {...props} value={formatPhoneNumber(props.value)} onChange={handlePhoneNumberChange} />
  );
}

export default PhoneInput;
