import { Tokens } from 'eulith-web3js';
import { ReactComponent as IconUSDT } from 'cryptocurrency-icons/svg/color/usdt.svg';
import { ReactComponent as IconBNB } from 'cryptocurrency-icons/svg/color/bnb.svg';
import { ReactComponent as IconUSDC } from 'cryptocurrency-icons/svg/color/usdc.svg';
// import { ReactComponent as IconBUSD } from 'cryptocurrency-icons/svg/color/busd.svg';
import { ReactComponent as IconMATIC } from 'cryptocurrency-icons/svg/color/matic.svg';
// import { ReactComponent as IconSTETH } from 'cryptocurrency-icons/svg/color/steth.svg';
// import { ReactComponent as IconWETH } from 'cryptocurrency-icons/svg/color/weth.svg';
// import { ReactComponent as IconLDO } from 'cryptocurrency-icons/svg/color/ldo.svg';
import { ReactComponent as IconCRV } from 'cryptocurrency-icons/svg/color/crv.svg';
// import { ReactComponent as IconCVX } from 'cryptocurrency-icons/svg/color/cvx.svg';
import { ReactComponent as IconBAL } from 'cryptocurrency-icons/svg/color/bal.svg';
// import { ReactComponent as IconBADGER } from 'cryptocurrency-icons/svg/color/badger.svg';
import { ReactComponent as IconGeneric } from 'cryptocurrency-icons/svg/color/generic.svg';

const { Symbols } = Tokens;

export const tokens: [string, React.FC<any>][] = [
  [Symbols.USDT, IconUSDT],
  [Symbols.BNB, IconBNB],
  [Symbols.USDC, IconUSDC],
  [Symbols.BUSD, IconGeneric],
  [Symbols.MATIC, IconMATIC],
  [Symbols.STETH, IconGeneric],
  [Symbols.WETH, IconGeneric],
  [Symbols.LDO, IconGeneric],
  [Symbols.CRV, IconCRV],
  [Symbols.CVX, IconGeneric],
  [Symbols.BAL, IconBAL],
  [Symbols.BADGER, IconGeneric],
  [Symbols.WBTC, IconGeneric]
  // [Symbols.ONEINCH,],
  // [Symbols.UNI,],
  // [Symbols.LINK,],
  // [Symbols.APE,],
  // [Symbols.GMT,],
  // [Symbols.LUSD,],
  // [Symbols.FRAX,],
  // [Symbols.CBETH,],
  // [Symbols.GALA,],
  // [Symbols.HEX,],
  // [Symbols.RPL,],
  // [Symbols.DYDX,],
  // [Symbols.BONE,],
  // [Symbols.LOOKS,],
  // [Symbols.AGEUR,],
  // [Symbols.OSQTH,],
  // [Symbols.WSTETH,],
  // [Symbols.ALBT,]
];

interface ChainIdMap {
  [key: number]: string;
}

export const chainIdToTokenSymbol: ChainIdMap = {
  1: 'ETH', // Ethereum Mainnet
  3: 'ETH', // Ropsten Testnet
  4: 'ETH', // Rinkeby Testnet
  5: 'ETH', // Goerli Testnet
  42: 'ETH', // Kovan Testnet
  56: 'BNB', // Binance Smart Chain
  100: 'xDAI', // xDai Chain
  137: 'MATIC', // Polygon (previously Matic Network)
  30: 'ETC', // Ethereum Classic Mainnet
  31: 'ETC', // Ethereum Classic Testnet (Morden)
  61: 'ETC', // Ethereum Classic Mainnet (After separation)
  62: 'ETC', // Ethereum Classic Testnet
  250: 'FTM', // Fantom Opera Mainnet
  97: 'BNB', // Binance Smart Chain Testnet
  128: 'HT', // Huobi Chain Mainnet
  256: 'HT', // Huobi Chain Testnet
  43114: 'AVAX', // Avalanche C-Chain Mainnet
  43113: 'AVAX', // Avalanche C-Chain Testnet
  1666600000: 'ONE', // Harmony Mainnet
  1666700000: 'ONE', // Harmony Testnet
  820: 'CLO' // Callisto Mainnet
};
