import { Helmet } from 'react-helmet-async';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  selectTabNotificationMessage,
  setTabNotificationMessage
} from '../../features/wallet/walletSlice';
import { useCallback, useEffect, useMemo } from 'react';

function EulithHelmetConsumer({ children }: { children: React.ReactNode }) {
  const dispatch = useAppDispatch();
  const tabNotificationMessage = useAppSelector(selectTabNotificationMessage);
  const childMessage = useMemo(() => `Eulith • ${children}`, [children]);

  const handleFocus = useCallback(() => {
    dispatch(setTabNotificationMessage(''));
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('focus', handleFocus);
    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, [handleFocus]);

  useEffect(() => {
    let worker: any;
    // if our browser supports workers, we're going to set up a worker
    // that adds the ability to update the title of the page when the window
    // is not active. more information on web workers:
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Using_web_workers
    if (window.Worker) {
      // initialize a worker in a singleton fashion (let worker: any) is only initialized
      // during the use effect once)
      if (!worker) {
        worker = new Worker('/titleWorker.js');
      }
      if (document.hasFocus()) {
        // instruct the worker to stop updating the title
        // (by clearing the timeout that posts the "update" message)
        worker.postMessage('stop');
        // revert the title back to the active's page chosen title
        document.title = childMessage;
      } else {
        // if we don't have focus, instruct the worker to start an interval
        // timer that continuously executes the "onmessage" function below
        worker.postMessage('start');
        worker.onmessage = function (e: any) {
          if (document.hasFocus()) {
            document.title = childMessage;
          } else {
            if (e.data === 'update') {
              // if the worker sends us an "update" message, we're going to check
              // if there is a tabNotificationMessage set in redux and if so,
              // temporarily set the title to that message
              if (tabNotificationMessage) {
                document.title =
                  document.title === childMessage ? tabNotificationMessage : childMessage;
              } else {
                document.title = childMessage;
              }
            }
          }
        };
      }
    }
    return () => {
      if (worker) {
        worker?.terminate?.();
      }
    };
  }, [childMessage, tabNotificationMessage]);

  return <Helmet>{childMessage}</Helmet>;
}

export default EulithHelmetConsumer;
