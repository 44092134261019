import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { persistor, store } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import AppProvider from './providers/AppProvider';
import './styles/index.css';
import './styles/antd-overrides.css';
import { Button, Result } from 'antd';
import { Text } from './styles/shared';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const isProductionEnvironment = window.location.host === 'eulithclient';
Bugsnag.start({
  apiKey: isProductionEnvironment
    ? 'aefc80d096af6e657e8e6c472c733d43'
    : '135fcda5c83fe5914a5523ee2a971e18',
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production', 'staging'],
  releaseStage:
    process.env.NODE_ENV === 'production'
      ? isProductionEnvironment
        ? 'production'
        : 'staging'
      : 'development'
});

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) || React.Fragment;

const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

function Fallback({
  error,
  // info, // stack trade, diagnostic information, etc
  clearError
}: {
  error: Error;
  info: React.ErrorInfo;
  clearError: () => void;
}) {
  return (
    <Result
      status="warning"
      title={<Text style={{ fontSize: 24 }}>Something went wrong.</Text>}
      subTitle={
        <Text style={{ fontSize: 14 }}>
          {error?.message || 'If this problem persists, please contact us.'}
        </Text>
      }
      extra={[
        <Button type="primary" key="console" onClick={clearError}>
          Try Again
        </Button>,
        <Button key="contact" onClick={() => window.location.replace('/')}>
          Go Home
        </Button>
      ]}
    />
  );
}

root.render(
  <ErrorBoundary FallbackComponent={Fallback}>
    <Provider store={store}>
      <PersistGate loading={<div />} persistor={persistor}>
        <AppProvider>
          <App />
        </AppProvider>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
