import styled from 'styled-components';
import { colors } from '../../styles/shared';

const LeftColumnContainer = styled.div<{ theme: 'dark' | 'light' }>`
  height: 100%;
  border: 1px solid #303030;
  box-shadow: none;
  transition: all 0.2s;
  background-color: ${colors.background};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  :hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  }
  ${({ theme }) => {
    if (theme === 'light') {
      return `background-color: ${colors.white}`;
    } else {
      return `background-color: ${colors.blue2}`;
    }
  }}
`;

export default LeftColumnContainer;
