import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store';
import { ContactFundRequest, FundFormValues, ManagerType, FundFormFile } from './fundsTypes';
import { createSelector } from '@reduxjs/toolkit';

export const fundsApi = createApi({
  reducerPath: 'fundsApi',
  baseQuery: fetchBaseQuery({
    baseUrl:
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3001/funds'
        : 'https://profile.eulithclient.com/funds',
    prepareHeaders: (headers, { getState, endpoint }) => {
      const token = (getState() as RootState).auth.accessToken;
      if (token) {
        if (!headers.has('Authorization') && token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
      }
      if (!headers.has('Content-Type') && endpoint !== 'createUpload') {
        headers.set('Content-Type', 'application/json');
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getUserFunds: builder.query<FundFormValues[], void>({
      query: () => '/me'
    }),
    getFunds: builder.query<FundFormValues[], void>({
      query: () => '/'
    }),
    getFund: builder.query<FundFormValues, string>({
      query: (fundId) => ({
        url: `/${fundId}`,
        method: 'GET'
      })
    }),
    contactFund: builder.query<void, ContactFundRequest>({
      query: (payload) => ({
        url: `${payload.id}/contact`,
        method: 'POST',
        body: payload
      })
    }),
    createFund: builder.mutation<FundFormValues, FundFormValues>({
      query: (payload) => ({
        url: '/',
        method: 'POST',
        body: payload
      })
    }),
    editFund: builder.mutation<FundFormValues, FundFormValues>({
      query: (payload) => ({
        url: `${payload.id}`,
        method: 'PATCH',
        body: payload
      })
    }),
    deleteFund: builder.mutation<FundFormValues, FundFormValues>({
      query: (payload) => ({
        url: `${payload.id}`,
        method: 'DELETE',
        body: payload
      })
    }),
    deleteManager: builder.mutation<ManagerType, ManagerType>({
      query: (payload) => ({
        url: `/managers/${payload.id}`,
        method: 'DELETE'
      })
    }),
    editManager: builder.mutation<ManagerType, ManagerType>({
      query: (payload) => ({
        url: `/managers/${payload.id}`,
        method: 'PATCH',
        body: payload
      })
    }),
    createManager: builder.mutation<ManagerType, ManagerType>({
      query: (payload) => ({
        url: '/managers',
        method: 'POST',
        body: payload
      })
    }),
    deleteUpload: builder.mutation<FundFormFile, FundFormFile>({
      query: (id) => ({
        url: `/uploads/${id}`,
        method: 'DELETE'
      })
    }),
    // TODO: payload should be defined as FormData type
    createUpload: builder.mutation<FundFormFile, any>({
      query: (payload) => ({
        url: '/uploads',
        method: 'POST',
        body: payload
      })
    }),
    editUpload: builder.mutation<FundFormFile, FundFormFile>({
      query: (payload) => ({
        url: `/uploads/${payload.id}`,
        method: 'PATCH',
        body: payload
      })
    })
  })
});

export const {
  useGetUserFundsQuery,
  useLazyGetFundQuery,
  useContactFundQuery,
  useLazyContactFundQuery,
  useLazyGetUserFundsQuery,
  useEditFundMutation,
  useDeleteFundMutation,
  useCreateFundMutation,
  useDeleteManagerMutation,
  useDeleteUploadMutation,
  useCreateUploadMutation,
  useEditUploadMutation,
  useEditManagerMutation,
  useCreateManagerMutation,
  useGetFundsQuery,
  useLazyGetFundsQuery
} = fundsApi;

const selectUserFundsResults = fundsApi.endpoints.getUserFunds.select();
const selectFundsResults = fundsApi.endpoints.getFunds.select();

export const selectUserFundsData = createSelector(selectUserFundsResults, (result) => result.data);
export const selectAllFundsData = createSelector(selectFundsResults, (result) => result.data);
