import { useCallback, useState } from 'react';
import styled from 'styled-components';
import {
  H1,
  H2,
  Text,
  buttonCss,
  colors,
  fontWeights,
  margin,
  padding,
  sizes
} from '../../styles/shared';
import Flex from '../Flex';
import { ProviderStatus } from '../../features/status/statusTypes';

interface Props {
  providers: ProviderStatus[];
  loading: boolean;
}

const StatusSection: React.FC<Props> = ({ providers, loading }) => {
  const [seeProviders, setSeeProviders] = useState(false);
  const getStatus = useCallback(() => {
    const isGood = providers.filter(({ status }) => {
      return status;
    });

    return isGood.length >= 3;
  }, [providers]);

  return (
    <Container style={{ textAlign: 'center' }}>
      <H1 style={{ marginBottom: margin }}>API Status</H1>

      <Flex justifyContent="center" flex="1 1 auto" style={{ marginBottom: 24 }}>
        <H1 style={{ fontSize: sizes.xLarge * 3, marginTop: padding }}>
          {loading ? <Green>OK</Green> : getStatus() ? <Green>OK </Green> : <Red>DOWN</Red>}
        </H1>
      </Flex>

      <StatusContainer
        flexDirection="column"
        flexWrap="wrap"
        alignItems="flex-start"
        style={{ padding }}
      >
        <SeeButton onClick={() => setSeeProviders(!seeProviders)}>
          {!seeProviders ? 'See Providers' : 'Close'}
        </SeeButton>
        {seeProviders && (
          <>
            <Text>Providers</Text>
            <Flex flexWrap="wrap" flexDirection="row" style={{ gap: 18 }}>
              {loading
                ? 'Loading'
                : providers.map(({ provider_id, provider_environment, status }, i) => {
                    return (
                      <StatusItem key={i}>
                        <ItemTitle>
                          {provider_id} ETH {provider_environment}
                        </ItemTitle>
                        <Flex alignItems="center" justifyContent="flex-start">
                          <span>{status ? <Green>OK </Green> : <Red>DOWN</Red>}</span>
                        </Flex>
                      </StatusItem>
                    );
                  })}
            </Flex>
          </>
        )}
      </StatusContainer>
    </Container>
  );
};

const StatusContainer = styled(Flex)`
  margin-top: 60px;
  gap: 16px;
`;

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 160px;
`;

const StatusItem = styled.div`
  position: relative;
  width: auto;
  border-radius: 4px;
  padding: 12px 24px;
  font-size: ${sizes.default}px;
  background-color: rgb(255 255 255 / 9%);
  box-shadow: rgba(255, 255, 255, 0.05) 0px 6px 16px;
  color: white;
  border-radius: 4px;
  font-weight: ${fontWeights.medium};
`;

const ItemTitle = styled.div`
  margin-bottom: 4px;
  font-size: ${sizes.large}px;
  font-weight: ${fontWeights.semiBold};
`;

const Green = styled.span`
  color: #00d23c;
  margin-left: 4px;
  font-weight: ${fontWeights.bold};
`;

const Red = styled.span`
  color: ${colors.red};
  margin-left: 4px;

  font-weight: ${fontWeights.bold};
`;

const SeeButton = styled(H2)`
  ${buttonCss}
  font-size: ${sizes.large};
  cursor: pointer;
  color: ${colors.blue};

  width: 100%;
  padding: 12px;
  background: #ffffff05;
  border: none;
  border-radius: 8px;
`;

export default StatusSection;
