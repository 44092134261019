import { Button, ConfigProvider, Dropdown, Row, Space, Table, Tag } from 'antd';
import { SessionTypes } from '@walletconnect/types';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  resetTransactionHistory,
  selectWalletSessions,
  selectWalletTransactions
} from '../../features/wallet/walletSlice';
import CloseButton from '../CloseButton';
import EulithCard from '../EulithCard';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import EmptyState from '../EmptyState/EmptyState';
import { H3 } from '../../styles/shared';
import styled from 'styled-components';
import { useEulithWallet } from '../../features/wallet/useEulithWallet';
import { DateTime } from 'luxon';
import { chainIdToScannerUrl } from '../../utils/networks';
import MiddleEllipses from '../MiddleEllipses';
import { ColumnsType } from 'antd/es/table';
import { WalletConnectSessionRequestExtra } from '../../features/wallet/walletTypes';
import { useMemo } from 'react';
import { selectSelectedWalletContract } from '../../features/order/orderSlice';

const SUGGESTED_REMEDY_MESSAGE = 'Please switch to the Eulith Wallet tab for details.';

interface Props {
  session: SessionTypes.Struct;
  onPressForget: () => void;
}

function SessionInfo({ session, onPressForget }: Props) {
  function handleError(e: any) {
    e.currentTarget.src = '/dapp.png';
  }
  return (
    <StyledEulithCard style={{ height: 100 }} bodyStyle={{ height: '100%' }}>
      <CloseButton
        key="close"
        tooltip="Disconnect Client"
        onClick={onPressForget}
        style={{
          position: 'absolute',
          top: 5,
          right: 5
        }}
      />
      <Row align="middle" justify="center" style={{ height: '100%' }}>
        {session?.peer?.metadata?.icons?.length ? (
          <img
            onError={handleError}
            src={session.peer.metadata.icons[0]}
            style={{ width: 30, height: 30, marginRight: 10, borderRadius: 4 }}
          />
        ) : null}
        <div>
          {session?.peer?.metadata?.name || session?.peer?.metadata?.description || 'Unknown'}
        </div>
      </Row>
    </StyledEulithCard>
  );
}

export default function SessionsList({
  onPressForget,
  setAddModalVisible
}: {
  onPressForget: any;
  setAddModalVisible: any;
}) {
  const walletSessions = useAppSelector(selectWalletSessions);
  const { disconnectAllWalletClients } = useEulithWallet();
  const transactions = useAppSelector(selectWalletTransactions);
  const selectedWalletContract = useAppSelector(selectSelectedWalletContract);
  const dispatch = useAppDispatch();

  const transactionHistoryForSelectedWalletContract = useMemo(() => {
    return transactions.filter((transaction: WalletConnectSessionRequestExtra) => {
      if (transaction.contract && selectedWalletContract) {
        return (
          transaction.contract.contractAddress.toLowerCase() ===
          selectedWalletContract.contractAddress.toLowerCase()
        );
      } else {
        return transaction;
      }
    });
  }, [transactions, selectedWalletContract]);

  const columns: ColumnsType<any> = [
    {
      title: 'Status',
      dataIndex: 'id',
      width: 75,
      render: (id: any, transaction: any) => {
        if (transaction.success) {
          return <Tag color="green">SUCCESS</Tag>;
        } else if (transaction.rejected) {
          return <Tag color="orange">REJECTED</Tag>;
        } else if (transaction.error) {
          return <Tag color="red">FAILED</Tag>;
        } else {
          return <Tag>PENDING</Tag>;
        }
      }
    },
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      width: 100,
      render: (text: string) => {
        return text ? DateTime.fromISO(text).toLocaleString(DateTime.DATETIME_SHORT) : '';
      }
    },
    {
      title: 'Client',
      dataIndex: 'topic',
      filters: walletSessions.map((session: SessionTypes.Struct) => {
        return {
          text: session.peer.metadata.name,
          value: session.peer.metadata.name
        };
      }),
      onFilter: (value: any, session: SessionTypes.Struct) => {
        return !!session?.peer?.metadata?.name?.includes?.(value);
      },
      render: (value: any, transaction: any) => {
        const session = transaction?.session;
        if (session) {
          return (
            <Row justify="start" align="middle">
              {session?.peer?.metadata?.icons?.length ? (
                <img
                  src={session.peer.metadata.icons[0]}
                  style={{ width: 30, height: 30, marginRight: 10, borderRadius: 4 }}
                />
              ) : null}
              <div>
                {session?.peer?.metadata?.name || session?.peer?.metadata?.description || 'Unknown'}
              </div>
            </Row>
          );
        } else {
          return 'Disconnected';
        }
      }
    },
    {
      title: 'Result',
      dataIndex: 'result',
      render: (text: string, transaction: any) => {
        const chainId = transaction.params?.chainId?.split?.(':')?.[1] || 1;
        const scannerUrl = chainIdToScannerUrl[chainId]?.url;
        return text ? (
          transaction.success ? (
            <div>
              <span>Transaction submitted: </span>
              <span style={{ display: 'inline-block', maxWidth: 200 }}>
                {scannerUrl ? (
                  <a href={`${scannerUrl}/tx/${text}`} target="_blank" rel="noreferrer">
                    <MiddleEllipses>
                      <span>{text}</span>
                    </MiddleEllipses>
                  </a>
                ) : (
                  <MiddleEllipses>
                    <span>{text}</span>
                  </MiddleEllipses>
                )}
              </span>
            </div>
          ) : transaction.rejected ? null : (
            text.replace(SUGGESTED_REMEDY_MESSAGE, '')
          )
        ) : (
          '-'
        );
      }
    }
  ];

  function onAdd() {
    setAddModalVisible(true);
  }

  function clearTransactionHistory() {
    dispatch(resetTransactionHistory());
  }

  if (walletSessions?.length) {
    return (
      <div>
        <Row align="middle" justify="space-between">
          <H3>Connected Clients</H3>
          <Dropdown
            menu={{
              items: walletSessions?.length
                ? [
                    {
                      key: 'disconnect_all',
                      onClick: disconnectAllWalletClients,
                      danger: true,
                      label: 'Disconnect All'
                    },
                    {
                      key: 'clear_transactions',
                      onClick: clearTransactionHistory,
                      danger: true,
                      label: 'Clear Transaction History'
                    }
                  ]
                : []
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <H3>Actions</H3>
                <DownOutlined style={{ color: '#fff' }} />
              </Space>
            </a>
          </Dropdown>
        </Row>
        <Space wrap style={{ margin: '10px 0' }}>
          {walletSessions.map((session: SessionTypes.Struct) => {
            return (
              <SessionInfo
                key={session.topic}
                session={session}
                onPressForget={() => onPressForget(session.topic)}
              />
            );
          })}
          <EulithCard style={{ height: 100, width: 100 }} bodyStyle={{ height: '100%' }}>
            <Row align="middle" justify="center" style={{ height: '100%' }}>
              <Button
                onClick={onAdd}
                shape="circle"
                style={{ backgroundColor: 'transparent' }}
                icon={<PlusOutlined style={{ marginTop: 3 }} />}
              />
            </Row>
          </EulithCard>
        </Space>
        {walletSessions?.length ? (
          <div>
            <H3 style={{ paddingBottom: 10 }}>Transaction History</H3>
            <ConfigProvider renderEmpty={() => <EmptyState description="No transactions yet" />}>
              <Table
                rowKey={(record) => `${record.id}_${record.timestamp}`}
                columns={columns}
                dataSource={transactionHistoryForSelectedWalletContract}
                scroll={{
                  x: 600
                }}
              />
            </ConfigProvider>
          </div>
        ) : null}
      </div>
    );
  } else {
    return (
      <EmptyState description="No connected clients. Connect a DApp to get started.">
        <Button type="primary" onClick={onAdd}>
          Connect Client
        </Button>
      </EmptyState>
    );
  }
}

const StyledEulithCard = styled(EulithCard)`
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.3s ease;

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.02);
  }
`;
