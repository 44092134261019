import { SignClientTypes } from '@walletconnect/types';
import { PairingTypes, SessionTypes } from '@walletconnect/types';
import { DecoratedContract } from '../eulith/eulithTypes';

export type SupportedWallets = 'MetaMask' | 'WalletConnect';

export interface WalletMetadata {
  type: SupportedWallets | null;
  address: string;
  name: string;
}
export interface WalletState {
  initializing: boolean;
  initialized: boolean;
  tabNotificationMessage: string;
  address: string | null;
  chainId: number | null;
  wallet: WalletMetadata | null;
  failedToConnectToWallet: boolean;
  proposal: WalletConnectSessionProposal | null;
  sessions: SessionTypes.Struct[];
  pairings: PairingTypes.Struct[];
  transactions: WalletConnectSessionRequestExtra[];
}

export abstract class Wallet {
  abstract getName(): string;
  abstract getAddress(): string;
  abstract chainId(): Promise<number>;
  abstract signTypedData(typedData: any): Promise<string>;
  abstract ethRpcRequest(method: string, params: any[]): Promise<any>;
  abstract disconnect(): Promise<void>;
}

export type WalletConnectSessionProposal = SignClientTypes.EventArguments['session_proposal'];
export type WalletConnectSessionRequest = SignClientTypes.EventArguments['session_request'];
export type WalletConnectSessionUpdate = SignClientTypes.EventArguments['session_update'];
export type WalletConnectSessionDelete = SignClientTypes.EventArguments['session_delete'];
export type WalletConnectSessionRequestExtra = WalletConnectSessionRequest & {
  timestamp: string;
  session?: SessionTypes.Struct;
  contract: DecoratedContract;
};
