import { Table } from 'antd';
import styled from 'styled-components';
import { colors } from '../../styles/shared';

export default styled(Table)`
  .ant-table {
    background: ${colors.blue2};
    font-size: 12px;
  }

  thead {
    pointer-events: none !important;
  }

  .ant-table-cell {
    padding: 0;
    margin: 0;
    font-weight: 500;
    font-size: 16px;
  }

  .ant-table-cell {
    &.ant-table-cell-fix-left {
      background: ${colors.blue2} !important;
    }
  }

  th,
  td {
    border: none !important;
  }

  .ant-table-row-expand-icon-cell {
    // pointer-events: none !important;
    background: ${colors.blue2} !important;
  }

  .ant-table-row-expand-icon:hover {
    color: #6cbbc2 !important;
    border: 1px solid #6cbbc2 !important;
  }

  .ant-table-row-expand-icon {
    color: #fff !important;
    background-color: #141414 !important;
    border: 1px solid #303030 !important;
  }
  .ant-table-row-expand-icon:focus {
    color: #fff !important;
    background-color: #141414 !important;
    border: 1px solid #303030 !important;
  }
  .ant-table-row-expand-icon:active {
    color: #fff !important;
    background-color: #141414 !important;
    border: 1px solid #303030 !important;
  }

  th {
    color: #6cbbc2 !important;
    background: ${colors.blue2} !important;
    &.ant-table-cell {
      padding-bottom: 10px !important;
    }
  }
  th::before {
    display: none;
  }

  tr td {
    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  tr:not(.ant-table-placeholder):hover > td {
    background: #272930 !important;
  }

  td:first-child {
    font-weight: 600;
  }
`;
