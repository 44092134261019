import { useAddress } from '@thirdweb-dev/react';
import { Button, Col, Row, message } from 'antd';
import styled from 'styled-components';
import eulithSingleton from '../../features/eulith/EulithSingleton';
import {
  useExecuteOrderWithoutAceMutation,
  useExecuteOrderForAceMutation
} from '../../features/order/orderService';
import { createTransactionSigner } from '../../utils/signing';
import {
  selectOrderForm,
  selectQuote,
  selectSelectedTradingContract
} from '../../features/order/orderSlice';
import { useAppSelector } from '../../hooks/redux';
import { useIsMismatched } from '../../hooks/useMismatch';
import { colors } from '../../styles/shared';
import { shortenString } from '../../utils/data';
import EmptyState from '../EmptyState/EmptyState';
import EulithCard from '../EulithCard';
import { showArmorPolicyFailure } from '../../features/wallet/error';
import * as ArmorTypes from '../../features/wallet/armorTypes';
import BugsnagManager from '../../BugsnagManager';

const Item: React.FC<{
  label: string;
  value?: string;
  bold?: boolean;
  hideLabel?: boolean;
  style?: React.CSSProperties;
  negative?: boolean;
  positive?: boolean;
}> = ({ label, value, bold, style, negative, positive, hideLabel }) => {
  return (
    <ItemContainer style={style}>
      <span
        style={{
          color: hideLabel ? 'transparent' : bold ? '#6cbbc2' : 'white',
          fontWeight: bold ? 600 : 400
        }}
      >
        {label}
      </span>
      {value ? <DottedLine /> : null}
      <span style={{ color: negative ? colors.error : positive ? colors.success : colors.white }}>
        {value}
      </span>
    </ItemContainer>
  );
};

interface Props {
  loadingQuote: boolean;
  onFinish: (hash: string) => void;
}

const OrderSummary: React.FC<Props> = ({ loadingQuote, onFinish }) => {
  const address = useAddress();
  const quote = useAppSelector(selectQuote);
  const order = useAppSelector(selectOrderForm);
  const [executeOrderWithoutAce, { isLoading: loadingNoAceOrder }] =
    useExecuteOrderWithoutAceMutation();
  const [executeOrderForAce, { isLoading: loadingAceOrder }] = useExecuteOrderForAceMutation();
  const selectedTradingContract = useAppSelector(selectSelectedTradingContract);
  const isMismatched = useIsMismatched();

  async function handleExecuteOrder() {
    if (address) {
      try {
        const provider = eulithSingleton.provider;
        if (!provider) {
          message.error('Eulith instance not yet initialized.');
          return;
        }
        if (selectedTradingContract?.hasAce) {
          const results = await executeOrderForAce().unwrap();
          onFinish(results);
        } else {
          const atomicTx = await executeOrderWithoutAce().unwrap();
          const signer = createTransactionSigner(address);
          const results = await provider.signAndSendTransaction(atomicTx, signer);
          onFinish(results);
        }
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Unable to execute order',
          metadata: {
            address,
            selectedTradingContract
          }
        });
        const response = error?.data;
        if (ArmorTypes.isPolicyFailure(response)) {
          const errorMessage = 'Transaction cancelled because it failed the DeFi Armor policy.';
          showArmorPolicyFailure({
            msg: errorMessage,
            response,
            context: {
              error,
              method: 'eulith_commit_for_ace'
            }
          });
        } else {
          message.error(error?.message || 'Unable to execute order. Please try again later.');
        }
      }
    } else {
      message.error('Please select a contract first');
    }
  }

  function renderQuote() {
    if (quote) {
      return (
        <>
          <Row gutter={40}>
            {/* <Col xs={24} md={12}>
            <Item label="Fees" bold style={{ marginBottom: 5 }} />
            <Item label="Gas Cost" value="0.00" />
            <Item label="Protocol Cost" value="0.00" />
            <Item label="Eulith Cost" value="0.00" />
            <br />
            <Item label="Security Risk" bold value="4%" />
            <Item label="Execution Risk" bold value="4%" />
          </Col> */}
            <Col xs={24} md={24 /* used to be 12 */}>
              {/* <Item label="Net Change" bold value="-[Sell Qty]" negative />
            <Item label="Net Change" hideLabel bold value="[Buy Qty]" positive />
          <br /> */}
              <Item
                label="Price"
                bold
                value={`${parseFloat(quote.price).toFixed(5)} ${order.marketFrom}`}
              />
              {/* <Item label="Avg. Price" bold value="$1,952.00" /> */}
              <br />
              <Item label="Total In" bold value={`${order.amount}`} />
              <Item
                label="Total Out"
                bold
                value={`${(order.amount / parseFloat(quote.price)).toFixed(5)}`}
              />
            </Col>
          </Row>
          <br />
          <Row justify="center">
            <Button
              type="primary"
              loading={loadingAceOrder || loadingNoAceOrder || loadingQuote}
              disabled={loadingAceOrder || loadingNoAceOrder || loadingQuote}
              style={{ fontWeight: 600, width: 280, height: 40, marginTop: 8 }}
              onClick={handleExecuteOrder}
            >
              Execute Order
            </Button>
          </Row>
        </>
      );
    } else {
      return <EmptyState description="No quotes yet" />;
    }
  }

  return (
    <>
      <EulithCard
        isDisabled={isMismatched}
        title="Order Summary"
        extra={
          <StyledToContract>
            <span style={{ color: '#6CBBC2', fontWeight: 600, marginRight: 10 }}>To Contract:</span>{' '}
            {selectedTradingContract
              ? shortenString(selectedTradingContract.contractAddress, 30)
              : ''}
          </StyledToContract>
        }
      >
        {renderQuote()}
      </EulithCard>
    </>
  );
};

const StyledToContract = styled.div`
  background-color: rgba(255, 255, 255, 0.05);
  padding: 5px 20px;
  border-radius: 100px;
`;

const DottedLine = styled.span`
  height: 1px;
  border-bottom: 1px dashed #3e424d;
  flex: 0.9;
  transform: translateY(-6px);
`;

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
  white-space: nowrap;
`;

export default OrderSummary;
