import React, { useState } from 'react';
import { Button, Form, FormInstance, Modal, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { buildFieldForType } from '../../utils/funds';
import { antdFormConfig } from '../../styles/shared';
import ManagerListItem from '../ManagerListItem';
import type {
  FundFormSectionField,
  FundFormValues,
  ManagerType
} from '../../features/funds/fundsTypes';
import {
  useCreateManagerMutation,
  useDeleteManagerMutation,
  useEditManagerMutation
} from '../../features/funds/fundsService';
import BugsnagManager from '../../BugsnagManager';

interface FundFormManagersProps {
  form: FormInstance;
  setData: (values: FundFormValues) => void;
  field: FundFormSectionField;
  fundId: string;
  data: ManagerType[];
}

const FundFormManagers: React.FC<FundFormManagersProps> = ({
  form,
  field,
  setData,
  fundId,
  data = []
}) => {
  const [modalForm] = Form.useForm();
  const [context, setContext] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [deleteManager] = useDeleteManagerMutation();
  const [editManager] = useEditManagerMutation();
  const [createManager] = useCreateManagerMutation();

  const buttonText = context === 'editing' ? 'Edit Manager' : field.placeholder;

  function handleEditManager(manager: ManagerType) {
    modalForm.setFieldsValue(manager);
    setContext('editing');
  }

  function onCancel() {
    setContext(null);
    modalForm.resetFields();
    setLoading(false);
  }

  async function handleDeleteManager(manager: ManagerType) {
    setLoading(true);
    try {
      await deleteManager(manager).unwrap();
      const managers = form.getFieldValue(field.name) || [];
      const newManagers = [...managers].filter((m: ManagerType) => {
        return manager.id !== m.id;
      });
      form.setFieldValue(field.name, newManagers);
      setData({
        [field.name]: newManagers
      });
      message.success('Manager deleted!');
      setLoading(false);
    } catch (error: any) {
      BugsnagManager.notify(error, {
        context: 'Unable to delete manager in fund form managers'
      });
      setLoading(false);
      console.warn(error);
      message.error('Unable to delete manager');
    }
  }

  function handleFinish() {
    setLoading(true);
    modalForm
      .validateFields()
      .then(async (values) => {
        const id = modalForm.getFieldValue('id');
        if (context === 'editing') {
          try {
            const valuesToSend: ManagerType = {
              fundId,
              id,
              ...values
            };
            const response = await editManager(valuesToSend).unwrap();
            const managers = form.getFieldValue(field.name) || [];
            const newManagers = [...managers].map((manager) => {
              if (id === manager.id) {
                return response;
              } else {
                return manager;
              }
            });
            form.setFieldValue(field.name, newManagers);
            setData({
              [field.name]: newManagers
            });
            setData({ [field.name]: newManagers });
            setContext(null);
            modalForm.resetFields();
            message.success('Manager edited!');
            setLoading(false);
          } catch (error: any) {
            BugsnagManager.notify(error, {
              context: 'Unable to edit manager in fund form managers'
            });
            console.warn(error);
            message.error('Unable to edit manager');
            setLoading(false);
          }
        } else {
          try {
            const valuesToSend: ManagerType = {
              fundId,
              ...values
            };
            const response = await createManager(valuesToSend).unwrap();
            const newManager = response;
            const existingData = form.getFieldValue(field.name) || [];
            const newData = [...existingData, newManager];
            setData({ [field.name]: newData });
            form.setFieldValue(field.name, newData);
            setContext(null);
            message.success('Manager created!');
            modalForm.resetFields();
            setLoading(false);
          } catch (error: any) {
            BugsnagManager.notify(error, {
              context: 'Unable to create manager in fund form managers'
            });
            message.error('Unable to create manager. Please try again later.');
            console.log(error);
            setLoading(false);
          }
        }
      })
      .catch((error: any) => {
        setLoading(false);
        console.warn(error);
      });
  }

  return (
    <>
      {data.map((datum) => {
        return (
          <ManagerListItem
            key={`manager_${datum.id}`}
            data={datum}
            editManager={handleEditManager}
            deleteManager={handleDeleteManager}
          />
        );
      })}
      <Button
        type="dashed"
        icon={<PlusOutlined />}
        onClick={() => {
          setContext('creating');
        }}
      >
        {field.placeholder}
      </Button>
      <Modal
        open={!!context}
        title={buttonText}
        okText={context === 'editing' ? 'Save' : 'Create'}
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={handleFinish}
        okButtonProps={{
          htmlType: 'submit',
          loading: loading
        }}
        cancelButtonProps={{
          loading: loading
        }}
      >
        <Form
          form={modalForm}
          layout="vertical"
          name={field.name}
          requiredMark={antdFormConfig.requiredMark}
          scrollToFirstError
          onFinish={handleFinish}
          size={antdFormConfig.size}
        >
          {field.fields?.map((f) => {
            return buildFieldForType({
              form: modalForm,
              field: f,
              setData,
              fundId,
              setLoading
            });
          })}
        </Form>
      </Modal>
    </>
  );
};

export default FundFormManagers;
