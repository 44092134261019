import ReactGA from 'react-ga4';
import { RouterProvider } from 'react-router-dom';
import router from './router/Router';
import { selectAccessToken, selectUser } from './features/auth/authSlice';
import React, { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './hooks/redux';
import { isDevelopmentEnv } from './api/common';
import {
  setAddress,
  setChainId,
  setWalletInitialized,
  setWalletInitializing,
  setWalletPairings,
  setWalletProposal,
  setWalletSessions
} from './features/wallet/walletSlice';
import {
  selectSelectedTradingContract,
  selectSelectedWalletContract,
  selectSelectedWhitelistContract
} from './features/order/orderSlice';
import { createClient, getPairings, getSessions } from './features/wallet/walletconnect';
import { useEulithWallet } from './features/wallet/useEulithWallet';
import { isTokenExpired } from './features/auth/authUtils';
import {
  useAddress,
  useChainId,
  useConnectionStatus,
  useSDK,
  useSigner
} from '@thirdweb-dev/react';
import { usePrevious } from './hooks/usePrevious';
import {
  selectContractsData,
  useLazyGetContractsQuery,
  usePingAceQuery
} from './features/eulith/eulithService';
import { useLazyGetTokensQuery } from './features/tokens/tokensService';
import { message } from 'antd';
import eulithInstance from './features/eulith/EulithSingleton';
import {
  selectEulithClientInitialized,
  setEulithClientInitialized,
  setEulithClientInitializing
} from './features/eulith/eulithSlice';
import eulithSingleton from './features/eulith/EulithSingleton';
import { batch } from 'react-redux';
import BugsnagManager from './BugsnagManager';
import Bugsnag from '@bugsnag/js';
import { useLazyGetWhitelistsQuery } from './features/whitelist/whitelistService';

ReactGA.initialize(process.env.REACT_APP_GA_ID ?? '', {
  testMode: isDevelopmentEnv
});

const App: React.FC = () => {
  const user = useAppSelector(selectUser);
  const accessToken = useAppSelector(selectAccessToken);
  const eulithClientInitialized = useAppSelector(selectEulithClientInitialized);
  const contractData = useAppSelector(selectContractsData);
  const dispatch = useAppDispatch();
  const chainId = useChainId();
  const address = useAddress();
  const [getContracts] = useLazyGetContractsQuery();
  const [getWhitelists] = useLazyGetWhitelistsQuery();
  const [getTokens] = useLazyGetTokensQuery();
  const selectedTradingContract = useAppSelector(selectSelectedTradingContract);
  const selectedWalletContract = useAppSelector(selectSelectedWalletContract);
  const selectedWhitelistContract = useAppSelector(selectSelectedWhitelistContract);
  const sdk = useSDK();
  const signer = useSigner();
  const { registerSessionHandlers, registerSessionRequestHandler, connectToSession } =
    useEulithWallet();
  const connectionStatus = useConnectionStatus();
  const previousConnectionStatus = usePrevious(connectionStatus);

  eulithSingleton.setAccessToken(accessToken);

  usePingAceQuery(
    (contractData || []).map((contract) => {
      return {
        tradingKeyAddress: contract.tradingKeyAddress?.toLowerCase?.() || '',
        hasAce: contract.hasAce
      };
    }),
    {
      pollingInterval: 10000
    }
  );

  useEffect(() => {
    if (
      eulithClientInitialized &&
      connectionStatus === 'connected' &&
      previousConnectionStatus !== 'connected'
    ) {
      getContracts()
        .unwrap()
        .catch((error: any) => {
          console.warn(error);
          BugsnagManager.notify(error, {
            context: 'Unable to getContracts in App.tsx useEffect'
          });
        });
    }
  }, [connectionStatus, previousConnectionStatus, eulithClientInitialized, getContracts]);

  useEffect(() => {
    dispatch(setAddress(address || null));
    dispatch(setChainId(chainId || null));
    if (address) {
      Bugsnag.addMetadata('global_address', { address });
    } else {
      Bugsnag.clearMetadata('global_address');
    }
    if (chainId) {
      Bugsnag.addMetadata('global_network', { chainId });
    } else {
      Bugsnag.clearMetadata('global_network');
    }
  }, [address, chainId, dispatch]);

  const setupWallet = useCallback(async () => {
    try {
      batch(() => {
        dispatch(setWalletInitialized(false));
        dispatch(setWalletInitializing(true));
      });
      const wcClient = await createClient();
      registerSessionHandlers(wcClient);
      const promises = wcClient.session.getAll().map(async (session) => {
        return await connectToSession(session, wcClient);
      });
      await Promise.all(promises);
      eulithSingleton.setWalletClients(wcClient);
      registerSessionRequestHandler(wcClient);
      batch(() => {
        dispatch(setWalletSessions(getSessions(wcClient)));
        dispatch(setWalletPairings(getPairings(wcClient)));
        dispatch(setWalletProposal(null));
        dispatch(setWalletInitialized(true));
        dispatch(setWalletInitializing(false));
      });
      console.log('Initialized Eulith wallet ✅');
    } catch (error: any) {
      BugsnagManager.notify(error, {
        context: 'Unable to initialize Eulith Wallet in App.tsx useEffect'
      });
      batch(() => {
        dispatch(setWalletProposal(null));
        dispatch(setWalletInitialized(false));
        dispatch(setWalletInitializing(false));
      });
      console.warn('Unable to initialize Eulith Wallet', error);
      message.error(
        'Unable to initialize Eulith Wallet. Please contact us if this issue persists.'
      );
    }
  }, [dispatch, connectToSession, registerSessionHandlers, registerSessionRequestHandler]);

  const setup = useCallback(async () => {
    try {
      if (chainId && accessToken) {
        batch(() => {
          dispatch(setEulithClientInitialized(false));
          dispatch(setEulithClientInitializing(true));
        });
        const provider = eulithSingleton.createProviderFromChainId(chainId, accessToken);
        eulithInstance.setProvider(provider);
        Bugsnag.addMetadata('provider', provider);
        batch(() => {
          dispatch(setEulithClientInitialized(true));
          dispatch(setEulithClientInitializing(false));
        });
        console.log('Initialized Eulith services ✅');
      }
    } catch (error: any) {
      BugsnagManager.notify(error, {
        context: 'Unable to initialize Eulith services in App.tsx useEffect'
      });
      batch(() => {
        dispatch(setEulithClientInitialized(false));
        dispatch(setEulithClientInitializing(false));
      });
      console.warn(error);
      message.error(
        error?.message ||
          'Unable to initialize Eulith Services. Please contact us if this issue persists.'
      );
    }
  }, [accessToken, chainId, dispatch]);

  useEffect(() => {
    eulithInstance.setSDK(sdk);
    eulithInstance.setSigner(signer);
  }, [sdk, signer]);

  useEffect(() => {
    if (user) {
      BugsnagManager.setUser(user?.sub || '');
      // console.log('Setting GA user id to', user.sub);
      ReactGA.set({
        user_id: user.sub,
        ...user
      });
    } else {
      BugsnagManager.setUser('');
      // console.log('Setting GA user id to null');
      ReactGA.set({
        user_id: null
      });
    }
  }, [user]);

  useEffect(() => {
    if (eulithClientInitialized) {
      getContracts().unwrap().catch(console.warn);
      getWhitelists().unwrap().catch(console.warn);
    }
    if (selectedTradingContract?.safeAddress && chainId) {
      getTokens({ chainId, address: selectedTradingContract.safeAddress })
        .unwrap()
        .catch(console.warn);
    }
  }, [
    eulithClientInitialized,
    selectedTradingContract?.safeAddress,
    chainId,
    getContracts,
    getWhitelists,
    getTokens,
    selectedTradingContract?.contractAddress
  ]);

  useEffect(() => {
    if (selectedTradingContract) {
      Bugsnag.addMetadata('selected_trading_contract', selectedTradingContract);
    } else {
      Bugsnag.clearMetadata('selected_trading_contract');
    }
    if (selectedWalletContract) {
      Bugsnag.addMetadata('selected_wallet_contract', selectedWalletContract);
    } else {
      Bugsnag.clearMetadata('selected_wallet_contract');
    }
    if (selectedWhitelistContract) {
      Bugsnag.addMetadata('selected_whitelist_contract', selectedWhitelistContract);
    } else {
      Bugsnag.clearMetadata('selected_whitelist_contract');
    }
  }, [selectedTradingContract, selectedWalletContract, selectedWhitelistContract]);

  const walletContractIsSelected = !!selectedWalletContract;
  const walletConnected = !!chainId;
  const isAuthenticated = !!accessToken && !isTokenExpired(accessToken || '');

  useEffect(() => {
    if (isAuthenticated && chainId) {
      setup();
    }
  }, [isAuthenticated, chainId, setup]);

  useEffect(() => {
    if (isAuthenticated && walletConnected && walletContractIsSelected) {
      setupWallet();
    }
  }, [isAuthenticated, walletConnected, walletContractIsSelected, setupWallet]);

  return <RouterProvider router={router} />;
};

export default App;
