import React, { useRef, useState } from 'react';
import EulithHelmetConsumer from '../components/EulithHelmetConsumer';
import { Button, Alert, Col, Progress, Row, Space, Tooltip, message, Tag } from 'antd';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import { H1, H2, H3, Text, colors } from '../styles/shared';
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CopyOutlined,
  ExportOutlined,
  SafetyCertificateOutlined
} from '@ant-design/icons';
import { Header } from 'antd/es/layout/layout';
import logo from '../assets/images/logo_solid_white.png';
import { Link, useNavigate } from 'react-router-dom';
import ReactConfetti from 'react-confetti';
import outboundSvg from '../assets/images/outbound.svg';
import { shuffle } from 'lodash';
import MiddleEllipses from '../components/MiddleEllipses';
import 'swiper/css';

interface TransactionStep {
  [key: string]: any;
  from: string;
  to: string;
  value: string;
  data: string;
}

interface Steps {
  title: string;
  description: string;
  tx: TransactionStep;
  selectedResponse: null | boolean;
  answerHasBeenSubmitted: boolean;
  incorrectComment: string;
  correctComment: string;
  etherscan: string;
  legitimate: boolean;
}

const GAME_DATA = [
  {
    tx: {
      from: '0x00a329c0648769a73afac7f9381e08fb43dbea72',
      to: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      value: '0x0',
      data: '0xa9059cbb000000000000000000000000792ec27874e1f614e757a1ae49d00ef5b2c73959000000000000000000000000000000000000000000000000000009184e72a000'
    },
    legitimate: false,
    answerHasBeenSubmitted: false,
    description: 'Please approve or deny this transaction as if you were prompted by your wallet.',
    title: 'Is This Legitimate?',
    incorrectComment:
      'You transferred $10M to the attacker involved in the AstridFinance hack. See <a href="https://twitter.com/Phalcon_xyz/status/1718455634155409489">this tweet</a> for more information. This account has been laundering the stolen funds through Tornado Cash.',
    correctComment:
      'If you had proceeded with this transaction, you would have transferred $10M to the attacker involved in the AstridFinance hack. See <a href="https://twitter.com/Phalcon_xyz/status/1718455634155409489">this tweet</a> for more information. This account has been laundering the stolen funds through Tornado Cash.',
    selectedResponse: null,
    etherscan: 'https://etherscan.io/address/0x792ec27874e1f614e757a1ae49d00ef5b2c73959'
  },
  {
    tx: {
      from: '0x00a329c0648769a73afac7f9381e08fb43dbea72',
      to: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      value: '0x0',
      data: '0xa9059cbb000000000000000000000000d90e2f925da726b50c4ed8d0fb90ad053324f31b000000000000000000000000000000000000000000000000000009184e72a000'
    },
    legitimate: false,
    answerHasBeenSubmitted: false,
    description: 'Please approve or deny this transaction as if you were prompted by your wallet.',
    title: 'Is This Legitimate?',
    incorrectComment: 'You deposited $10M into Tornado Cash. This is an OFAC Sanctioned operation.',
    correctComment: 'You deposited $10M into Tornado Cash. This is an OFAC Sanctioned operation.',
    selectedResponse: null,
    etherscan: 'https://etherscan.io/address/0xd90e2f925da726b50c4ed8d0fb90ad053324f31b'
  },
  {
    tx: {
      from: '0x00a329c0648769a73afac7f9381e08fb43dbea72',
      to: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      value: '0x0',
      data: '0xa9059cbb0000000000000000000000002faf487a4414fe77e2327f0bf4ae2a264a776ad2000000000000000000000000000000000000000000000000000009184e72a000'
    },
    legitimate: false,
    answerHasBeenSubmitted: false,
    description: 'Please approve or deny this transaction as if you were prompted by your wallet.',
    title: 'Is This Legitimate?',
    correctComment:
      'If you had proceeded with this transaction, you would have sent $10M to the FTX Exchange account on ETH Mainnet',
    incorrectComment: 'You sent $10M to the FTX Exchange account on ETH Mainnet',
    selectedResponse: null,
    etherscan: 'https://etherscan.io/address/0x2faf487a4414fe77e2327f0bf4ae2a264a776ad2'
  },
  {
    tx: {
      from: '0x00a329c0648769a73afac7f9381e08fb43dbea72',
      to: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      value: '0x0',
      data: '0xa9059cbb0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000009184e72a000'
    },
    legitimate: false,
    answerHasBeenSubmitted: false,
    description: 'Please approve or deny this transaction as if you were prompted by your wallet.',
    title: 'Is This Legitimate?',
    correctComment:
      'If you had proceeded with this transaction, you would have burned $10M. This can never be recovered and your assets no longer exist.',
    incorrectComment:
      'You burned $10M. This can never be recovered and your assets no longer exist.',
    selectedResponse: null,
    etherscan: 'https://etherscan.io/address/0x0000000000000000000000000000000000000000'
  },
  {
    tx: {
      from: '0x00a329c0648769a73afac7f9381e08fb43dbea72',
      to: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      value: '0x0',
      data: '0xa9059cbb0000000000000000000000005f259d0b76665c337c6104145894f4d1d2758b8c000000000000000000000000000000000000000000000000000009184e72a000'
    },
    legitimate: false,
    answerHasBeenSubmitted: false,
    description: 'Please approve or deny this transaction as if you were prompted by your wallet.',
    title: 'Is This Legitimate?',
    correctComment:
      'If you had proceeded with this transaction, you would have sent $10M to the Euler Finance hacker.',
    incorrectComment: 'You sent $10M to the Euler Finance hacker.',
    selectedResponse: null,
    etherscan: 'https://etherscan.io/address/0x5f259d0b76665c337c6104145894f4d1d2758b8c'
  },
  {
    tx: {
      from: '0x00a329c0648769a73afac7f9381e08fb43dbea72',
      to: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      value: '0x0',
      data: '0xa9059cbb00000000000000000000000000a329c0648769a73afac7f9381e08fb43dbea72000000000000000000000000000000000000000000000000000009184e72a000'
    },
    legitimate: true,
    answerHasBeenSubmitted: false,
    description: 'Please approve or deny this transaction as if you were prompted by your wallet.',
    title: 'Is This Legitimate?',
    correctComment: 'You sent $10M to yourself. Weird, but not harmful.',
    incorrectComment: 'You were trying to send $10M to yourself. Weird, but not harmful.',
    selectedResponse: null,
    etherscan: 'https://etherscan.io/address/0x00a329c0648769a73afac7f9381e08fb43dbea72'
  },
  {
    tx: {
      from: '0x00a329c0648769a73afac7f9381e08fb43dbea72',
      to: '0x88e6A0c2dDD26FEEb64F039a2c41296FcB3f5640',
      value: '0x0',
      data: '0x128acb08000000000000000000000000792ec27874e1f614e757a1ae49d00ef5b2c739590000000000000000000000000000000000000000000000000000000000000001000000000000000000000000000000000000000000000000000009184e72a000000000000000000000000000000000000000000000000000000009184e72a00000000000000000000000000000000000000000000000000000000000000000a00000000000000000000000000000000000000000000000000000000000000000'
    },
    legitimate: false,
    answerHasBeenSubmitted: false,
    description: 'Please approve or deny this transaction as if you were prompted by your wallet.',
    title: 'Is This Legitimate?',
    correctComment:
      'If you had proceeded with this transaction, you would have executed a Uniswap tx that transferred $10M to the attacker involved in the AstridFinance hack. See <a href="https://twitter.com/Phalcon_xyz/status/1718455634155409489" target="_blank">this tweet</a> for more information. This account has been laundering the stolen funds through Tornado Cash.',
    incorrectComment:
      'You executed a Uniswap tx that transferred $10M to the attacker involved in the AstridFinance hack. See <a href="https://twitter.com/Phalcon_xyz/status/1718455634155409489" target="_blank">this tweet</a> for more information. This account has been laundering the stolen funds through Tornado Cash.',
    selectedResponse: null,
    etherscan: 'https://etherscan.io/address/0x792ec27874e1f614e757a1ae49d00ef5b2c73959'
  },
  {
    tx: {
      from: '0x00a329c0648769a73afac7f9381e08fb43dbea72',
      to: '0x88e6A0c2dDD26FEEb64F039a2c41296FcB3f5640',
      value: '0x0',
      data: '0x128acb08000000000000000000000000d90e2f925da726b50c4ed8d0fb90ad053324f31b0000000000000000000000000000000000000000000000000000000000000001000000000000000000000000000000000000000000000000000009184e72a000000000000000000000000000000000000000000000000000000009184e72a00000000000000000000000000000000000000000000000000000000000000000a00000000000000000000000000000000000000000000000000000000000000000'
    },
    legitimate: false,
    answerHasBeenSubmitted: false,
    description: 'Please approve or deny this transaction as if you were prompted by your wallet.',
    title: 'Is This Legitimate?',
    correctComment:
      'You executed a Uniswap tx that deposited $10M into Tornado Cash. This is an OFAC Sanctioned operation.',
    incorrectComment:
      'You executed a Uniswap tx that deposited $10M into Tornado Cash. This is an OFAC Sanctioned operation.',
    selectedResponse: null,
    etherscan: 'https://etherscan.io/address/0xd90e2f925da726b50c4ed8d0fb90ad053324f31b'
  },
  {
    tx: {
      from: '0x00a329c0648769a73afac7f9381e08fb43dbea72',
      to: '0x88e6A0c2dDD26FEEb64F039a2c41296FcB3f5640',
      value: '0x0',
      data: '0x128acb080000000000000000000000002faf487a4414fe77e2327f0bf4ae2a264a776ad20000000000000000000000000000000000000000000000000000000000000001000000000000000000000000000000000000000000000000000009184e72a000000000000000000000000000000000000000000000000000000009184e72a00000000000000000000000000000000000000000000000000000000000000000a00000000000000000000000000000000000000000000000000000000000000000'
    },
    legitimate: false,
    answerHasBeenSubmitted: false,
    description: 'Please approve or deny this transaction as if you were prompted by your wallet.',
    title: 'Is This Legitimate?',
    incorrectComment:
      'You executed a Uniswap tx that sent $10M to the FTX Exchange account on ETH Mainnet',
    correctComment:
      'If you had proceeded with this transaction, you would have executed a Uniswap tx that sent $10M to the FTX Exchange account on ETH Mainnet',
    selectedResponse: null,
    etherscan: 'https://etherscan.io/address/0x2faf487a4414fe77e2327f0bf4ae2a264a776ad2'
  },
  {
    tx: {
      from: '0x00a329c0648769a73afac7f9381e08fb43dbea72',
      to: '0x88e6A0c2dDD26FEEb64F039a2c41296FcB3f5640',
      value: '0x0',
      data: '0x128acb0800000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000001000000000000000000000000000000000000000000000000000009184e72a000000000000000000000000000000000000000000000000000000009184e72a00000000000000000000000000000000000000000000000000000000000000000a00000000000000000000000000000000000000000000000000000000000000000'
    },
    legitimate: false,
    answerHasBeenSubmitted: false,
    description: 'Please approve or deny this transaction as if you were prompted by your wallet.',
    title: 'Is This Legitimate?',
    incorrectComment:
      'You executed a Uniswap tx that burned $10M. They can never be recovered; they no longer exist.',
    correctComment:
      'If you had proceeded with this transaction, you would have executed a Uniswap tx that burned $10M. Your assets can never be recovered; they no longer exist.',
    selectedResponse: null,
    etherscan: 'https://etherscan.io/address/0x0000000000000000000000000000000000000000'
  },
  {
    tx: {
      from: '0x00a329c0648769a73afac7f9381e08fb43dbea72',
      to: '0x88e6A0c2dDD26FEEb64F039a2c41296FcB3f5640',
      value: '0x0',
      data: '0x128acb080000000000000000000000005f259d0b76665c337c6104145894f4d1d2758b8c0000000000000000000000000000000000000000000000000000000000000001000000000000000000000000000000000000000000000000000009184e72a000000000000000000000000000000000000000000000000000000009184e72a00000000000000000000000000000000000000000000000000000000000000000a00000000000000000000000000000000000000000000000000000000000000000'
    },
    legitimate: false,
    answerHasBeenSubmitted: false,
    description: 'Please approve or deny this transaction as if you were prompted by your wallet.',
    title: 'Is This Legitimate?',
    correctComment:
      'If you had proceeded with this transaction, you would have executed a Uniswap tx that sent $10M to the Euler Finance hacker.',
    incorrectComment: 'You executed a Uniswap tx that sent $10M to the Euler Finance hacker.',
    selectedResponse: null,
    etherscan: 'https://etherscan.io/address/0x5f259d0b76665c337c6104145894f4d1d2758b8c'
  },
  {
    tx: {
      from: '0x00a329c0648769a73afac7f9381e08fb43dbea72',
      to: '0x88e6A0c2dDD26FEEb64F039a2c41296FcB3f5640',
      value: '0x0',
      data: '0x128acb0800000000000000000000000000a329c0648769a73afac7f9381e08fb43dbea720000000000000000000000000000000000000000000000000000000000000001000000000000000000000000000000000000000000000000000009184e72a000000000000000000000000000000000000000000000000000000009184e72a00000000000000000000000000000000000000000000000000000000000000000a00000000000000000000000000000000000000000000000000000000000000000'
    },
    legitimate: true,
    answerHasBeenSubmitted: false,
    description: 'Please approve or deny this transaction as if you were prompted by your wallet.',
    title: 'Is This Legitimate?',
    incorrectComment: 'You successfully swapped $10M on Uniswap. Yay!',
    correctComment: 'You successfully swapped $10M on Uniswap. Yay!',
    selectedResponse: null,
    etherscan: 'https://etherscan.io/address/0x00a329c0648769a73afac7f9381e08fb43dbea72'
  }
];

const buttons = [
  {
    title: 'Approve Transaction',
    value: true
  },
  {
    title: 'Deny Transaction',
    value: false
  }
];

const QuizPage: React.FC = () => {
  const swiperRef = useRef<null | SwiperClass>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [steps, setSteps] = useState<Steps[]>(shuffle(GAME_DATA).slice(0, 7));
  const [showConfetti, setShowConfetti] = useState(false);
  const [quizStarted, setQuizStarted] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const navigate = useNavigate();

  function setStepResponse(value: boolean) {
    setSteps((prevSteps) => {
      prevSteps[activeIndex].selectedResponse = value;
      return [...prevSteps];
    });
  }

  function advanceToNextSlide() {
    if (activeIndex === steps.length - 1) {
      setShowConfetti(false);
      setQuizStarted(false);
      setShowResults(true);
    } else {
      swiperRef.current?.slideNext?.();
    }
  }

  function checkAnswer() {
    const el = document.getElementById('quiz-scroll-container');
    if (el) {
      el.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
    const activeQuestion = steps[activeIndex];
    if (activeQuestion.legitimate === activeQuestion.selectedResponse) {
      setShowConfetti(true);
    }
    setSteps((prevSteps) => {
      prevSteps[activeIndex].answerHasBeenSubmitted = true;
      return [...prevSteps];
    });
  }

  function renderJsonForStep() {
    const keys = steps[activeIndex].tx;
    return Object.keys(keys).map((key, keyIndex) => {
      const value = steps[activeIndex].tx[key];
      const canViewOnEtherscan = ['to', 'from'].includes(key);
      const isLastKey = keyIndex === Object.keys(keys).length - 1;
      return (
        <div key={`json_data_${key}`} style={{ marginLeft: 60 }}>
          <span className="json-container-property-key">{`"${key}"`}</span>
          <span>: </span>
          {canViewOnEtherscan ? (
            <Tooltip
              trigger="hover"
              title={
                <a
                  style={{ color: '#fff' }}
                  href={`https://etherscan.io/tx/${value}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span style={{ textDecoration: 'underline' }}>View on Etherscan</span>{' '}
                  <ExportOutlined />
                </a>
              }
            >
              <span>
                <a
                  className="json-container-property-link"
                  target="_blank"
                  href={`https://etherscan.io/tx/${value}`}
                  rel="noreferrer"
                >{`"${value}"`}</a>
              </span>
            </Tooltip>
          ) : (
            <span className="json-container-property-value">{`"${value}"`}</span>
          )}
          {isLastKey ? null : <span>,</span>}
        </div>
      );
    });
  }

  function copyValue(value: string) {
    navigator.clipboard.writeText(value || '');
    message.success('Copied to clipboard!');
  }

  function calculatePercentageCorrect() {
    let numberOfCorrectAnswers = 0;
    const numberOfQuestions = steps.length;
    steps.forEach((step) => {
      if (step.legitimate === step.selectedResponse) {
        numberOfCorrectAnswers++;
      }
    });
    const percentage = +((numberOfCorrectAnswers / numberOfQuestions) * 100).toFixed(0);
    return {
      percent: percentage,
      message: (
        <div>
          If this quiz didn&apos;t go well, you may need DeFi Armor to keep you safe on-chain.
          Don&apos;t worry, you don&apos;t need to change your trading workflow or custodian. DeFi
          Armor plugs right into your current setup and automatically inspects every transaction
          before you sign away your assets. <Link to="/contact">Contact us</Link> today to get
          started.
        </div>
      ),
      color: percentage < 100 ? 'red' : 'green'
    };
  }

  function renderTransactionAddress(label: string, value: string) {
    return (
      <div style={{ marginBottom: 15 }}>
        <Row align="middle" style={{ marginBottom: 5 }}>
          <H3 style={{ fontFamily: 'monospace' }}>{label}</H3>
          <Button
            onClick={() => copyValue(value)}
            shape="circle"
            size="small"
            style={{ marginLeft: 10 }}
          >
            <CopyOutlined style={{ color: colors.grey4 }} />
          </Button>
        </Row>
        <div>
          <Row
            style={{
              width: '95%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              flexWrap: 'nowrap'
            }}
          >
            <StyledProfilePhoto src={require('../assets/images/blank-profile-picture.png')} />
            <MiddleEllipses>
              <span style={{ color: '#fff', paddingLeft: 10, fontFamily: 'monospace' }}>
                {value.toLowerCase()}
              </span>
            </MiddleEllipses>
          </Row>
        </div>
      </div>
    );
  }

  function renderTransaction(hasPadding: boolean) {
    const from = steps[activeIndex].tx.from;
    const to = steps[activeIndex].tx.to;
    return steps[activeIndex] ? (
      <div
        style={{
          padding: hasPadding ? 20 : 0,
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <div style={{ height: '100%', overflow: 'auto' }}>
          <Row align="middle">
            <H2>Send</H2>
            <img src={outboundSvg} style={{ marginLeft: 5 }} />
          </Row>
          <Row style={{ margin: '10px 0' }} align="middle">
            <Text style={{ fontFamily: 'monospace' }}>Status</Text>
            {steps[activeIndex].answerHasBeenSubmitted ? (
              steps[activeIndex].selectedResponse ? (
                <Tag style={{ marginLeft: 10 }} color="green">
                  SENT
                </Tag>
              ) : (
                <Tag style={{ marginLeft: 10 }} color="red">
                  DENIED
                </Tag>
              )
            ) : (
              <Tag style={{ marginLeft: 10 }} color="orange">
                PENDING
              </Tag>
            )}
          </Row>
          {renderTransactionAddress('From', from)}
          {renderTransactionAddress('To', to)}
          <div style={{ marginBottom: 15 }}>
            <Text style={{ marginBottom: 5, fontFamily: 'monospace' }}>Value</Text>
            <StyledDataContainer>{steps[activeIndex].tx.value}</StyledDataContainer>
          </div>
          <div style={{ marginBottom: 15 }}>
            <Text style={{ marginBottom: 5, fontFamily: 'monospace' }}>Data</Text>
            <StyledDataContainer>{steps[activeIndex].tx.data}</StyledDataContainer>
          </div>
          <StyledNoticeContainer>
            Note: This is an entirely fictional wallet with hardcoded data and is not connected to
            your accounts whatsoever.
          </StyledNoticeContainer>
        </div>
      </div>
    ) : null;
  }

  const activeIndexHasResponse = steps[activeIndex].selectedResponse !== null;
  const activeIndexHasBeenSubmitted = steps[activeIndex].answerHasBeenSubmitted;
  const activeIndexWasCorrect =
    activeIndexHasBeenSubmitted &&
    steps[activeIndex].legitimate === steps[activeIndex].selectedResponse;

  return (
    <>
      <EulithHelmetConsumer>Quiz</EulithHelmetConsumer>
      <Header
        style={{
          zIndex: 1,
          width: '100%',
          display: 'flex',
          position: 'absolute',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: 'transparent'
        }}
      >
        <Link to="/">
          <Logo src={logo} style={{ zIndex: 10 }} />
        </Link>
        <Button onClick={() => navigate('/contact')} type="primary" style={{ zIndex: 10 }}>
          Sign Up
        </Button>
      </Header>
      <StyledContainer>
        <StyledInnerContainer>
          <StyledRow gutter={20}>
            {showConfetti ? (
              <ReactConfetti
                recycle={false}
                gravity={0.3}
                onConfettiComplete={() => {
                  setShowConfetti(false);
                }}
              />
            ) : null}
            {quizStarted ? (
              <>
                <StyledLeftCol sm={24} md={12}>
                  <StyledProgressContainer>
                    <H3 style={{ color: colors.primary, fontWeight: 900 }}>{`${activeIndex + 1}/${
                      steps.length
                    }`}</H3>
                    <Progress
                      showInfo={false}
                      percent={((activeIndex + 1) / steps.length) * 100}
                      strokeColor={colors.primary}
                    />
                  </StyledProgressContainer>
                  <StyledQuizContainer>
                    <Swiper
                      allowTouchMove={false}
                      onSlideChange={(swiper: SwiperClass) => setActiveIndex(swiper.activeIndex)}
                      onSwiper={(swiper: SwiperClass) => (swiperRef.current = swiper)}
                      style={{ height: '100%' }}
                    >
                      {steps.map((step, index) => {
                        return (
                          <SwiperSlide
                            key={`${step.correctComment}_${index}`}
                            style={{ height: '100%' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%'
                              }}
                            >
                              <div
                                id="quiz-scroll-container"
                                style={{ overflow: 'auto', paddingBottom: 20 }}
                              >
                                <Space
                                  size="large"
                                  direction="vertical"
                                  style={{
                                    padding: '0 7px',
                                    marginBottom: 20,
                                    width: '100%'
                                  }}
                                >
                                  <H1
                                    style={{
                                      color: activeIndexHasBeenSubmitted
                                        ? activeIndexWasCorrect
                                          ? colors.success
                                          : colors.error
                                        : colors.white
                                    }}
                                  >
                                    {activeIndexHasBeenSubmitted
                                      ? activeIndexWasCorrect
                                        ? 'Correct!'
                                        : 'Incorrect'
                                      : step.title}
                                  </H1>
                                  <Text
                                    style={{
                                      width: '100%',
                                      fontSize: 18,
                                      paddingRight: 5,
                                      userSelect: 'none'
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: step.answerHasBeenSubmitted
                                        ? activeIndexWasCorrect
                                          ? step.correctComment
                                          : step.incorrectComment
                                        : step.description
                                    }}
                                  />
                                  {step.answerHasBeenSubmitted &&
                                  !step.legitimate &&
                                  step.selectedResponse ? (
                                    <Alert
                                      type="success"
                                      showIcon
                                      style={{ marginRight: 5 }}
                                      message="Eulith Protects Against This"
                                      description="Eulith protects against this by refusing to co-sign transactions that take an unauthorized action on your behalf. We protect you from interacting with unsafe addresses at any layer of a complex transaction."
                                    />
                                  ) : null}
                                </Space>
                                <Row>
                                  <Col xs={24} md={0}>
                                    {renderTransaction(false)}
                                  </Col>
                                </Row>
                              </div>
                              {step.answerHasBeenSubmitted ? null : (
                                <Space direction="vertical" style={{ marginTop: 20 }}>
                                  {buttons.map((button, buttonIndex) => {
                                    const selected = steps[index].selectedResponse === button.value;
                                    return (
                                      <StyledResponseButton
                                        key={`button_${buttonIndex}`}
                                        onClick={() => setStepResponse(button.value)}
                                        selected={selected}
                                        style={{
                                          opacity: steps[index].answerHasBeenSubmitted ? 0.3 : 1,
                                          pointerEvents: steps[index].answerHasBeenSubmitted
                                            ? 'none'
                                            : 'auto'
                                        }}
                                      >
                                        {selected ? <CheckCircleFilled /> : <CheckCircleOutlined />}
                                        {button.title.toUpperCase()}
                                      </StyledResponseButton>
                                    );
                                  })}
                                </Space>
                              )}
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </StyledQuizContainer>
                  <StyledButtonContainer>
                    <Button
                      size="large"
                      block
                      onClick={activeIndexHasBeenSubmitted ? advanceToNextSlide : checkAnswer}
                      type="primary"
                      disabled={!activeIndexHasResponse}
                    >
                      {activeIndexHasBeenSubmitted ? 'Next' : 'Check Answer'}
                    </Button>
                  </StyledButtonContainer>
                </StyledLeftCol>
                <StyledRightContainer xs={0} md={12}>
                  {renderTransaction(true)}
                </StyledRightContainer>
              </>
            ) : showResults ? (
              <StyledStartQuizContainer>
                <Space size="large" direction="vertical" style={{ maxWidth: 550 }}>
                  <H1>Results</H1>
                  <Text>{calculatePercentageCorrect().message}</Text>
                  <Progress
                    strokeColor={calculatePercentageCorrect().color}
                    percent={calculatePercentageCorrect().percent}
                  />
                  <Button
                    onClick={() => navigate('/contact')}
                    type="primary"
                    block
                    style={{ zIndex: 10 }}
                  >
                    Contact Us
                  </Button>
                </Space>
              </StyledStartQuizContainer>
            ) : (
              <StyledStartQuizContainer>
                <Space size="large" direction="vertical" style={{ maxWidth: 550 }}>
                  <SafetyCertificateOutlined
                    style={{ marginBottom: 15, color: '#fff', fontSize: 100 }}
                  />
                  <H1>Think You&apos;re Safe On-Chain?</H1>
                  <Text>
                    The currently accepted standard for on-chain transaction security is
                    &quot;manual inspection.&quot; In other words, custodians rely primarily on your
                    eyeballs to inspect and approve transactions. We believe this is a woefully
                    inadequate practice. Most people are bad at parsing and interpreting machine
                    code.
                  </Text>
                  <Text>
                    Think you&apos;re different? Take this quiz to see how many dangerous
                    transactions you can catch
                  </Text>
                  <Button block type="primary" size="large" onClick={() => setQuizStarted(true)}>
                    Start Quiz
                  </Button>
                </Space>
              </StyledStartQuizContainer>
            )}
          </StyledRow>
        </StyledInnerContainer>
      </StyledContainer>
    </>
  );
};

const StyledProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  user-select: none;
`;

const StyledStartQuizContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
`;

const StyledRow = styled(Row)`
  height: 100%;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
`;

const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 4% 20px 4%;
  background: linear-gradient(
    to bottom,
    #1c2029 0%,
    #1d1f20 25%,
    #1d1f20 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
`;

const StyledInnerContainer = styled.div`
  max-width: 1024px;
  max-height: 700px;
  width: 100%;
  padding: 4%;
  border-radius: 1.625rem;
  background-color: rgb(24, 26, 27);
  box-shadow: rgba(0, 0, 0, 0.03) 0px 2px 12px, rgba(0, 0, 0, 0.06) 0px 20px 70px,
    rgba(0, 0, 0, 0.02) 0px 2px 4px;
`;

const StyledLeftCol = styled(Col)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledQuizContainer = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const StyledRightContainer = styled(Col)`
  width: 100%;
  height: calc(100% + 21%);
  transform: translate(12%, -9%);
  padding: 0 !important;
  border-radius: 1.625rem;
  background-color: rgb(31, 34, 35);
  box-shadow: rgba(0, 0, 0, 0.03) 0px 2px 12px, rgba(0, 0, 0, 0.06) 0px 20px 70px,
    rgba(0, 0, 0, 0.02) 0px 2px 4px;
`;

interface ResponseButtonProps {
  selected: boolean;
}

const StyledResponseButton = styled(Button)<ResponseButtonProps>`
  color: ${({ selected }) => (selected ? colors.white : colors.white)};
  background: ${({ selected }) => (selected ? colors.primary : 'transparent')};
  background-color: ${({ selected }) => (selected ? colors.primary : 'transparent')};
  transition: all 0.2;
  width: 100%;
  height: 50px;
  text-align: left;
  font-weight: 600;
  letter-spacing: 1px;
  border-color: ${({ selected }) => (selected ? colors.primary : colors.white)};
  &:hover {
    cursor: pointer;
    border-color: ${({ selected }) => (selected ? colors.white : colors.primary)} !important;
    color: ${({ selected }) => (selected ? colors.white : colors.primary)} !important;
  }
`;

const StyledDataContainer = styled.div`
  font-family: monospace;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  white-space: pre-wrap;
  color: #abb2bf;
  border-radius: 4px;
  font-family: monospace;
  word-wrap: break-word;
  word-break: break-all;
  overflow: auto;
`;

const Logo = styled.img`
  height: 32px;
  vertical-align: middle;
  object-fit: contain;
  user-select: none;
`;

const StyledProfilePhoto = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 100%;
  vertical-align: middle;
  object-fit: contain;
  user-select: none;
`;

const StyledNoticeContainer = styled.div`
  width: 100%;
  border-bottom-left-radius: 1.625rem;
  border-bottom-right-radius: 1.625rem;
  color: #fff;
  font-style: italic;
`;

export default QuizPage;
