import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { FundsPreviewLayout, LoggedInLayout } from '../components/Layouts';
import LoggedOutLayout from '../components/Layouts/LoggedOutLayout';
import ActivateUserPage from '../pages/ActivateUserPage';
import ContactUsPage from '../pages/ContactUsPage';
import FundsPreviewPage from '../pages/FundsPreviewPage';
import LoginPage from '../pages/LoginPage';
import SignUpPage from '../pages/SignUpPage';
import ReauthPage from '../pages/ReauthPage';
import ThankYouPage from '../pages/ThankYouPage';
import QuizPage from '../pages/QuizPage';
import BugsnagPerformance from '@bugsnag/browser-performance';
import { ReactRouterRoutingProvider } from '@bugsnag/react-router-performance';

const routes = createRoutesFromElements(
  <Route path="/">
    <Route element={<LoggedOutLayout />}>
      <Route index path="/login" element={<LoginPage />} />
      <Route path="/" element={<LoginPage />} />
      <Route path="/user/activate" element={<ActivateUserPage />} />
      <Route path="/reauth" element={<ReauthPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/metamask-signup" element={<SignUpPage />} />
      <Route path="/contact-us" element={<ContactUsPage />} />
      <Route path="/thank-you" element={<ThankYouPage />} />
    </Route>
    <Route element={<FundsPreviewLayout />}>
      <Route path="/funds/public/:id" element={<FundsPreviewPage />} />
    </Route>
    <Route path="/quiz" element={<QuizPage />} />
    <Route path="*" element={<LoggedInLayout />} />
  </Route>
);

const isProductionEnvironment = window.location.host === 'eulithclient';
BugsnagPerformance.start({
  apiKey: isProductionEnvironment
    ? 'aefc80d096af6e657e8e6c472c733d43'
    : '135fcda5c83fe5914a5523ee2a971e18',
  routingProvider: new ReactRouterRoutingProvider(routes, ''),
  enabledReleaseStages: ['production', 'staging'],
  releaseStage:
    process.env.NODE_ENV === 'production'
      ? isProductionEnvironment
        ? 'production'
        : 'staging'
      : 'development'
});

// NOTE: if adding a new Layout element, make sure usePageView is called within
const router = createBrowserRouter(routes);

export default router;
