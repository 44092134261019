import styled from 'styled-components';
import {
  AccountTableContract,
  GetBalanceForContractResponse
} from '../../features/eulith/eulithTypes';
import {
  Button,
  Col,
  Collapse,
  ConfigProvider,
  Image,
  Popover,
  Row,
  Statistic,
  message
} from 'antd';
import { H2, colors } from '../../styles/shared';
import {
  CopyOutlined,
  EditOutlined,
  ExportOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
  SafetyCertificateOutlined
} from '@ant-design/icons';
import MonoSpaceSpan from '../MonoSpaceSans';
import { useCallback, useEffect, useState } from 'react';
import * as Eulith from 'eulith-web3js';
import eulithSingleton from '../../features/eulith/EulithSingleton';
import ArmorOwnerListItem from '../Layouts/ArmorDeployLayout/components/ArmorOwnerListItem';
import { ArmorOwner } from '../../features/order/orderTypes';
import { chainIdToCurrencySymbol, chainIdToNetworkShortName } from '../../utils/networks';
import BugsnagManager from '../../BugsnagManager';
import { useAppSelector } from '../../hooks/redux';
import { selectAccessToken } from '../../features/auth/authSlice';
import EulithTable from '../EulithTable';
import { ColumnsType } from 'antd/es/table';
import { getTokenListForContract } from '../../features/eulith/eulithService';
import { decimalFormatter, dollarFormatter } from '../../utils/data';
import EmptyState from '../EmptyState/EmptyState';

const { Panel } = Collapse;

function AddressElement({
  label,
  address,
  chainId,
  allowCopy = false
}: {
  label: string;
  address: string;
  chainId: number;
  allowCopy?: boolean;
}) {
  function copyAddress() {
    if (address) {
      navigator.clipboard.writeText(address);
      message.success('Copied to clipboard!');
    }
  }
  function openAddress(address: string) {
    window.open(address, '_blank');
  }
  return (
    <>
      <Statistic
        title={
          <StyledLabel>
            {label}
            {allowCopy ? (
              <ExportOutlined
                onClick={() => {
                  openAddress(
                    `https://app.safe.global/home?safe=${chainIdToNetworkShortName[chainId]}:${address}`
                  );
                }}
                style={{ marginLeft: 10, fontSize: 14, cursor: 'pointer', color: colors.grey3 }}
              />
            ) : null}
          </StyledLabel>
        }
        valueStyle={{ display: 'none' }}
      />
      <StyledAddressContainer>
        <MonoSpaceSpan>{address || '-'}</MonoSpaceSpan>
        {address ? (
          <StyledCopyButton
            onClick={copyAddress}
            shape="circle"
            type="text"
            style={{ backgroundColor: 'transparent', color: colors.placeholder, marginLeft: 5 }}
          >
            <CopyOutlined style={{ color: colors.grey3 }} />
          </StyledCopyButton>
        ) : null}
      </StyledAddressContainer>
    </>
  );
}

function TradingAccountRow({
  data,
  expanded,
  handleEditContract
}: {
  data: AccountTableContract;
  expanded: boolean;
  handleEditContract: (contractAddress: string) => void;
}) {
  const [owners, setOwners] = useState<ArmorOwner[]>([]);
  const [tokens, setTokens] = useState<GetBalanceForContractResponse[]>([]);
  const [loadingTokens, setLoadingTokens] = useState(true);
  const [threshold, setThreshold] = useState(1);
  const [loading, setLoading] = useState(true);
  const accessToken = useAppSelector(selectAccessToken);

  const fetchOwners = useCallback(async () => {
    if (accessToken && data.safeAddress) {
      try {
        setLoading(true);
        const provider = eulithSingleton.createProviderFromChainId(data.chainId, accessToken);
        const agent = await Eulith.OnChainAgents.getArmorAgent({
          provider,
          tradingKeyAddress: data.tradingKeyAddress,
          armorContractAddress: data.contractAddress
        });
        const safe = new Eulith.Contracts.Safe(provider, data.safeAddress);
        const ownerAddresses = (await safe.getOwners()) || [];
        const thresholdValue = await safe.getThreshold();
        const authorizedOwnerSignatures =
          (await agent.getAuthorizingOwnerSignatures(provider, data.tradingKeyAddress)) || [];
        const normalizedOwnerAddresses = ownerAddresses.map((owner) => {
          return Eulith.Web3.utils.toChecksumAddress(owner);
        });
        const normalizedAuthorizedOwnerSignatures: string[] = authorizedOwnerSignatures.map(
          (owner) => {
            // TODO: this type is not correct - is typed as owner_address
            //@ts-ignore
            return Eulith.Web3.utils.toChecksumAddress(owner.ownerAddress);
          }
        );
        const authorizedOwnersMap: any = {};
        const unauthorizedOwnersMap: any = {};

        normalizedAuthorizedOwnerSignatures.forEach((authorizedOwner) => {
          authorizedOwnersMap[authorizedOwner] = {
            address: authorizedOwner,
            authorized: true
          };
        });

        normalizedOwnerAddresses.forEach((unauthorizedOwner) => {
          unauthorizedOwnersMap[unauthorizedOwner] = {
            address: unauthorizedOwner,
            authorized: false
          };
        });

        for (const address in authorizedOwnersMap) {
          const ownerIsOnChain = !!unauthorizedOwnersMap[address];
          if (ownerIsOnChain) {
            unauthorizedOwnersMap[address].authorized = true;
          }
        }
        setOwners(Object.values(unauthorizedOwnersMap) as ArmorOwner[]);
        setThreshold(thresholdValue);
        setLoading(false);
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Unable to fetch account owners'
        });
        console.warn(error);
        message.error('Unable to fetch account owners');
        setLoading(false);
      }
    }
  }, [accessToken, data.chainId, data.contractAddress, data.safeAddress, data.tradingKeyAddress]);

  const fetchTokens = useCallback(async () => {
    setLoadingTokens(true);
    try {
      getTokenListForContract(data.safeAddress, data.chainId)
        .then((response) => {
          console.log(response);
          setTokens(response as GetBalanceForContractResponse[]);
        })
        .catch((error) => {
          message.error('Unable to fetch token list for this contract.');
          console.warn(error);
        })
        .finally(() => {
          setLoadingTokens(false);
        });
    } catch (error: any) {
      setLoadingTokens(false);
      BugsnagManager.notify(error, {
        context: 'Error in fetch tokens for account',
        metadata: {
          safeAddress: data.safeAddress,
          chainId: data.chainId
        }
      });
      console.warn('Error in fetch tokens for account', error);
    }
  }, [data.safeAddress, data.chainId]);

  useEffect(() => {
    if (expanded) {
      fetchOwners();
      fetchTokens();
    }
  }, [expanded, fetchOwners, fetchTokens]);

  function renderOwners() {
    return (
      <div style={{ maxWidth: 450 }}>
        {owners.map((owner) => {
          return (
            <ArmorOwnerListItem
              key={`armor_detail_owner_list_${data.contractAddress}_${owner.address}`}
              data={owner}
              allowAddressCopy
            />
          );
        })}
      </div>
    );
  }

  const columns: ColumnsType<any> = [
    {
      title: 'Token',
      dataIndex: 'name',
      render: (name: string, record: GetBalanceForContractResponse) => {
        const logo = record.logo_url;
        const displayName = record.optimized_symbol || record.display_symbol || record.symbol;
        return (
          <Row align="middle" style={{ minWidth: 150 }}>
            {logo ? (
              <Image src={logo} width={30} height={30} preview={false} />
            ) : (
              <QuestionCircleOutlined style={{ fontSize: 30, color: 'rgba(255,255,255,0.3)' }} />
            )}
            <div style={{ marginLeft: 10 }}>{displayName}</div>
          </Row>
        );
      }
    },
    {
      title: 'Price',
      dataIndex: 'price',
      render: (price: number) => {
        return dollarFormatter(price);
      }
    },
    {
      title: 'USD Value',
      dataIndex: 'amount',
      render: (amount: number, record: GetBalanceForContractResponse) => {
        return dollarFormatter(amount * record.price);
      }
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (amount: number) => {
        return amount.toFixed(5);
      }
    }
  ];

  return (
    <StyledContainer>
      <Row gutter={16}>
        <Col sm={24} md={10}>
          <div style={{ marginBottom: 20 }}>
            <H2 style={{ fontSize: 20 }}>
              <span>
                <SafetyCertificateOutlined style={{ marginRight: 10 }} />
              </span>
              DeFi Armor Safe
            </H2>
          </div>
          <Statistic
            title={
              <StyledLabel
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleEditContract(data.contractAddress);
                }}
              >
                Name <EditOutlined style={{ color: colors.grey3 }} />
              </StyledLabel>
            }
            value={data.name || 'Not Provided'}
            valueStyle={{ fontSize: 14 }}
            style={{ marginBottom: 20 }}
          />
          <Statistic
            title={
              <StyledLabel
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleEditContract(data.contractAddress);
                }}
              >
                Description <EditOutlined style={{ color: colors.grey3 }} />
              </StyledLabel>
            }
            value={data.description || 'Not Provided'}
            valueStyle={{ fontSize: 14 }}
            style={{ marginBottom: 20 }}
          />
          <AddressElement
            label="Safe Address"
            address={data.safeAddress}
            chainId={data.chainId}
            allowCopy
          />
          <AddressElement
            label="Trading Key"
            address={data.tradingKeyAddress}
            chainId={data.chainId}
          />
          <Collapse size="small" style={{ marginBottom: 20, maxWidth: 450 }}>
            <Panel key="more details" header="More Details">
              <AddressElement
                label="Armor Address"
                address={data.contractAddress}
                chainId={data.chainId}
              />
              <AddressElement label="Wallet Address" address={data.wallet} chainId={data.chainId} />
            </Panel>
          </Collapse>
          <Statistic
            title={
              <StyledLabel>
                <div
                  style={{
                    pointerEvents: 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <div>Gas Balance</div>
                  <div>
                    <Popover
                      placement="topRight"
                      content="This is the balance of the trading key for this account, which pays for gas."
                    >
                      <InfoCircleOutlined
                        style={{ cursor: 'help', color: colors.grey1, marginLeft: 10 }}
                      />
                    </Popover>
                  </div>
                </div>
              </StyledLabel>
            }
            value={
              data.tradingKeyAddressBalance
                ? `${parseFloat(data.tradingKeyAddressBalance).toFixed(5)} ${
                    chainIdToCurrencySymbol[data.chainId]
                  }`
                : '-'
            }
            valueStyle={{ fontSize: 14 }}
            style={{ marginBottom: 20 }}
          />
          {loading ? null : (
            <>
              <Statistic
                title={<StyledLabel>Owners</StyledLabel>}
                value={`A threshold of ${threshold} out of ${owners.length} owner(s) is required to approve administrative functions.`}
                valueStyle={{ fontSize: 14, marginBottom: 10 }}
              />
              {renderOwners()}
            </>
          )}
        </Col>
        <Col sm={24} md={14} className="col-with-border">
          <ConfigProvider renderEmpty={() => <EmptyState description="No tokens found" />}>
            <EulithTable
              rowKey="id"
              rowClassName="antd-table-row-no-border-radius"
              loading={loadingTokens}
              dataSource={tokens}
              columns={columns}
            />
          </ConfigProvider>
        </Col>
      </Row>
    </StyledContainer>
  );
}

export default TradingAccountRow;

const StyledContainer = styled.div`
  padding: 20px;
`;

const StyledCopyButton = styled(Button)`
  > * {
    > * {
      transition: all 0.4s ease;
    }
  }
  :hover {
    > * {
      > * {
        color: ${colors.primary};
      }
    }
  }
`;

const StyledLabel = styled.div`
  font-size: 16px !important;
`;

const StyledAddressContainer = styled.div`
  margin-bottom: 20px;
  font-size: 14px;
`;
