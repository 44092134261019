// TODO(ian): move these type definitions to the client library

export interface PolicyFailure {
  denied_calls: DeniedCall[];
  compliance_denials: AddressComplianceResult[];
  traces: string[];
}

export function isPolicyFailure(obj: any): obj is PolicyFailure {
  return (
    typeof obj === 'object' &&
    'denied_calls' in obj &&
    'compliance_denials' in obj &&
    'traces' in obj
  );
}

export interface DeniedCall {
  rule_id: number;
  reason: DenialReason;
  call: SimulatedCall;
}

export type DenialReason =
  | EthDestinationDenialReason
  | RuleDenialReason
  | RevertDenialReason
  | UnknownDenialReason
  | LegacyDenialReason;

export interface EthDestinationDenialReason {
  type: 'EthDestination';
  destination: string;
}

export interface RuleDenialReason {
  type: 'Rule';
  function: string;
  protocol: string;
  failing_rule_index: number;
  matched_rule: RuleExplanation[];
  examined_addresses: string[];
  comment: string | null;
}

export interface RevertDenialReason {
  type: 'Revert';
  reason: string;
}

export interface UnknownDenialReason {
  type: Exclude<string, 'EthDestination' | 'Revert' | 'Rule'>;
}

// legacy types can be removed when new server is released
export type LegacyDenialReason = string | LegacyRuleDenialReason;

export interface LegacyRuleDenialReason {
  function: string;
  protocol: string;
  failing_rule_index: number;
  matched_rule: RuleExplanation[];
  examined_addresses: string[];
  comment: string | null;
}

export interface RuleExplanation {
  text: string;
  conditions: string[];
}

export interface SimulatedCall {
  tx: { from: string | null; data: string; to: string; value: string };
  call_type: string;
}

export interface AddressComplianceResult {
  address: string;
  // floating-point percentage, 0.0-1.0
  risk_score: number;
  risk_explanation: string | null;
}
