import { createSlice } from '@reduxjs/toolkit';
import { decodeUserToken, isTokenExpired } from './authUtils';
import eulithSingleton from '../eulith/EulithSingleton';
import type { RootState } from '../../store';
import type { AuthState } from './authTypes';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: AuthState = {
  accessToken: null,
  accessTokenExp: null,
  user: null,
  network: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => {
      localStorage.clear();
      return initialState;
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
      state.user = decodeUserToken(state.accessToken);
      eulithSingleton.setAccessToken(action.payload);
    },
    setAccessTokenExp: (state, action: PayloadAction<number>) => {
      state.accessTokenExp = action.payload;
    },
    setNetwork: (state, action: PayloadAction<string>) => {
      state.network = action.payload;
    }
  }
});

export const { logout, setAccessToken, setAccessTokenExp } = authSlice.actions;

export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const selectAccessTokenExp = (state: RootState) => state.auth.accessTokenExp;
export const selectUser = (state: RootState) => state.auth.user;

export const selectAuthenticated = (state: RootState) => {
  const token = selectAccessToken(state);
  if (token) {
    return !isTokenExpired(token);
  } else {
    return false;
  }
};

export const selectIsAdmin = (state: RootState) => {
  const user = selectUser(state);
  if (user) {
    return user.privileges?.includes?.('Admin');
  } else {
    return false;
  }
};

export const selectCanCreateFunds = (state: RootState) => {
  return selectIsAdmin(state) || state.auth.user?.privileges?.includes?.('CreateFundProfile');
};

export const selectCanViewAllFunds = (state: RootState) => {
  return selectIsAdmin(state) || state.auth.user?.privileges?.includes?.('AllocatorView');
};

export const selectCanGenerateTokens = (state: RootState) => {
  return (
    selectIsAdmin(state) ||
    state.auth.user?.privileges?.includes?.('ProgrammaticTrading') ||
    state.auth.user?.privileges?.includes?.('ArmorWallet')
  );
};

export const selectHasTradingAccess = (state: RootState) => {
  return selectIsAdmin(state) || state.auth.user?.privileges?.includes?.('FrontendTrading');
};

export const selectHasArmorAccess = (state: RootState) => {
  return (
    selectIsAdmin(state) ||
    state.auth.user?.privileges?.includes?.('ArmorWallet') ||
    state.auth.user?.privileges?.includes?.('FrontendTrading') ||
    state.auth.user?.privileges?.includes?.('ProgrammaticTrading')
  );
};

export const selectHasDocsAccess = (state: RootState) => {
  return (
    selectIsAdmin(state) ||
    state.auth.user?.privileges?.includes?.('ArmorWallet') ||
    state.auth.user?.privileges?.includes?.('FrontendTrading') ||
    state.auth.user?.privileges?.includes?.('ProgrammaticTrading')
  );
};

export const selectHasWalletAccess = (state: RootState) => {
  return selectIsAdmin(state) || state.auth.user?.privileges?.includes?.('ArmorWallet');
};

export const selectHasMetamaskSnapAccess = (state: RootState) => {
  return selectIsAdmin(state) || state.auth.user?.privileges?.includes?.('MetaMaskSnap');
};

export default authSlice.reducer;
