import { getSdkError } from '@walletconnect/utils';
import { ProposalTypes, SessionTypes } from '@walletconnect/types';
import eulithSingleton from '../eulith/EulithSingleton';
import { CHAIN_ID_REGEXP, SUPPORTED_CHAINS } from './constants';
import { signAndSendTransaction } from './ethrpc';
import * as metamask from './metamask';
import {
  SupportedWallets,
  Wallet,
  WalletConnectSessionProposal,
  WalletConnectSessionRequest,
  WalletConnectSessionUpdate
} from './walletTypes';
import {
  WalletConnectWallet,
  fillNamespace,
  getPairings,
  getSessions,
  isWalletSession,
  pairDappViaUri
} from './walletconnect';
import { formatJsonRpcError } from '@json-rpc-tools/utils';
import { showError } from './error';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  addWalletTransaction,
  markWalletTransactionError,
  markWalletTransactionSuccess,
  selectWalletInitialized,
  selectWalletProposal,
  setTabNotificationMessage,
  setWalletDidFailToConnect,
  setWalletInstance,
  setWalletPairings,
  setWalletProposal,
  setWalletSessions
} from './walletSlice';
import { useCallback } from 'react';
import { notification } from 'antd';
import { selectSelectedWalletContract } from '../order/orderSlice';
import { shortenString } from '../../utils/data';
import { SignClient } from '@walletconnect/sign-client/dist/types/client';
import { store } from '../../store';
import { chainIdToNetworkLabel } from '../../utils/networks';
import BugsnagManager from '../../BugsnagManager';

export const useEulithWallet = () => {
  const dispatch = useAppDispatch();
  const proposal = useAppSelector(selectWalletProposal);
  const selectedWalletContract = useAppSelector(selectSelectedWalletContract);
  const walletIsInitialized = useAppSelector(selectWalletInitialized);

  async function connectToMetaMask() {
    dispatch(setWalletDidFailToConnect(false));
    const wallet = await metamask.connect();
    if (wallet) {
      await onWalletConnected(wallet, 'MetaMask');
    } else {
      dispatch(setWalletDidFailToConnect(true));
      throw new Error('Failed to connect to MetaMask');
    }
  }

  const onWalletConnected = useCallback(
    async (wallet: Wallet, type: SupportedWallets) => {
      const currentChainId = await wallet.chainId();
      const provider = eulithSingleton.provider;
      if (!SUPPORTED_CHAINS.has(currentChainId)) {
        showError(
          `Custodial wallet is connected to an unsupported network: ${chainIdToNetworkLabel(
            currentChainId
          )}`,
          null,
          undefined,
          'Unable to connect to wallet.'
        );
        return;
      }

      if (!provider) {
        showError(
          'Received a wallet connection request, but could not respond because Eulith provider is not initialized.',
          null,
          undefined,
          'Unable to handle dApp request.'
        );
        return;
      }

      dispatch(setWalletDidFailToConnect(false));
      dispatch(
        setWalletInstance({
          type,
          address: wallet.getAddress(),
          name: wallet.getName()
        })
      );
      dispatch(setWalletProposal(null));
    },
    [dispatch]
  );

  async function approveDappConnection() {
    if (!proposal) {
      return;
    }

    const walletClients = eulithSingleton.walletClients;

    if (!walletClients) {
      return;
    }

    if (!walletIsInitialized) {
      showError(
        'Rejected DApp connection because the wallet is not yet initialized.',
        null,
        undefined,
        'Unable to connect to dApp.'
      );
      await walletClients.reject({
        id: proposal.id,
        reason: getSdkError('USER_REJECTED')
      });
      dispatch(setWalletProposal(null));
      return;
    }

    const safeAddress = selectedWalletContract?.safeAddress || '';
    const namespaces: SessionTypes.Namespaces = {};
    for (const [key, namespace] of Object.entries(proposal.params.requiredNamespaces)) {
      const approved = fillNamespace(
        namespaces,
        key,
        namespace as ProposalTypes.RequiredNamespace,
        safeAddress
      );
      if (!approved) {
        await walletClients.reject({
          id: proposal.id,
          reason: getSdkError('UNSUPPORTED_CHAINS')
        });

        showError(
          'Rejected DApp connection proposal because of unsupported chains',
          null,
          undefined,
          'Unable to connect to dApp.'
        );
        dispatch(setWalletProposal(null));
        return;
      }
    }

    for (const [key, namespace] of Object.entries(proposal.params.optionalNamespaces)) {
      fillNamespace(namespaces, key, namespace as ProposalTypes.RequiredNamespace, safeAddress);
    }

    await walletClients.approve({
      id: proposal.id,
      // https://github.com/orgs/WalletConnect/discussions/3598
      relayProtocol: proposal.params.relays[0].protocol,
      namespaces
    });

    dispatch(setWalletProposal(null));
    dispatch(setWalletSessions(getSessions(walletClients)));
    dispatch(setWalletPairings(getPairings(walletClients)));
  }

  async function rejectDappConnection() {
    if (!proposal) {
      return;
    }

    const walletClients = eulithSingleton.walletClients;

    if (!walletClients) {
      return;
    }

    await walletClients.reject({
      id: proposal.id,
      reason: getSdkError('USER_REJECTED_METHODS')
    });

    dispatch(setWalletProposal(null));
  }

  async function connectDapp(uri: string) {
    const walletClients = eulithSingleton.walletClients;

    if (!walletClients) {
      showError(
        'Eulith wallet clients have not been initialized yet.',
        null,
        undefined,
        'Unable to connect to dApp.'
      );
      return;
    }
    const client = walletClients;
    await pairDappViaUri(client, uri);
  }

  const refreshState = useCallback(() => {
    const walletClients = eulithSingleton.walletClients;
    if (walletClients) {
      dispatch(setWalletSessions(getSessions(walletClients)));
      dispatch(setWalletPairings(getPairings(walletClients)));
    } else {
      console.error(
        'Unable to refresh state: no wallet clients detected (this should never happen!)'
      );
    }
  }, [dispatch]);

  const handleSessionDelete = useCallback(
    async (/* event: WalletConnectSessionDelete */) => {
      console.log('eulith-wallet: session_delete received');
      refreshState();
      // TODO(ian): use event.topic to conditionally delete wallet
      // TODO(ian): display a different message to user depending on if DApp or wallet disconnecting
      //            (requires access to current value of states)
    },
    [refreshState]
  );

  const handleSessionProposal = useCallback(
    async (event: WalletConnectSessionProposal) => {
      console.log('eulith-wallet: session_proposal received');
      const contract = store.getState().order?.selectedWalletContract;
      if (contract) {
        const supportedChain =
          event.params.requiredNamespaces?.eip155?.chains?.find?.((chain) => {
            return CHAIN_ID_REGEXP.test(chain);
          }) || '';
        const chainId = supportedChain.split(':')[1];
        if (chainId === `${contract.chainId}`) {
          dispatch(setWalletProposal(event));
        } else {
          showError(
            `dApp proposal request received from ${
              chainId ? chainIdToNetworkLabel(parseInt(chainId)) : 'an unknown network'
            }, but current network is ${chainIdToNetworkLabel(contract.chainId)}`,
            null,
            undefined,
            'Unable to connect to dApp.'
          );
        }
      } else {
        showError(
          'dApp requested a request but you have not yet selected an Armor contract (this should never happen!)',
          null,
          undefined,
          'Unable to connect to dApp.'
        );
      }
    },
    [dispatch]
  );

  const registerSessionHandlers = useCallback(
    (client: SignClient) => {
      client.removeAllListeners('session_proposal');
      client.removeAllListeners('session_update');
      client.removeAllListeners('session_delete');
      client.removeAllListeners('session_expire');
      client.removeAllListeners('session_ping');
      client.removeAllListeners('session_event');

      client.on('session_proposal', handleSessionProposal);

      client.on('session_update', (event: WalletConnectSessionUpdate) => {
        console.log('eulith-wallet: session_update received');
        client.update(event as any);
        refreshState();
      });

      client.on('session_delete', handleSessionDelete);

      client.on('session_expire', async () => {
        console.log('eulith-wallet: session_expire received');
      });

      client.on('session_ping', async () => {
        console.log('eulith-wallet: session_ping received');
      });

      client.on('session_event', async () => {
        console.log('eulith-wallet: session_event received');
      });
      console.log('Registered Eulith Wallet session handlers ✅');
    },
    [handleSessionDelete, handleSessionProposal, refreshState]
  );

  const handleSessionRequest = useCallback(
    async (request: WalletConnectSessionRequest) => {
      const contract = store.getState().order?.selectedWalletContract;
      const method = request.params.request.method;
      console.log(`eulith-wallet: session_request received (${method})`, request);
      const walletClients = eulithSingleton.walletClients;
      if (!contract) {
        showError(
          'dApp requested a request but you have not yet selected an Armor contract (this should never happen!)',
          null,
          undefined,
          'Unable to handle dApp request.'
        );
        return;
      }

      if (!walletClients) {
        showError(
          `dApp requested '${method}' but Eulith Wallet has no clients (this should never happen!)`,
          null,
          undefined,
          'Unable to handle dApp request.'
        );
        return;
      }

      if (method !== 'eth_sendTransaction') {
        showError(
          `dApp requested '${method}' but Eulith Wallet only supports 'eth_sendTransaction'`,
          null,
          undefined,
          'Unable to handle dApp request.'
        );

        await walletClients.respond({
          topic: request.topic,
          response: formatJsonRpcError(request.id, 'only eth_sendTransaction method is supported')
        });
        return;
      }

      let dappChainId = `${request.params.chainId}`;
      if (CHAIN_ID_REGEXP.test(dappChainId)) {
        dappChainId = dappChainId.split(':')[1];
      }

      if (dappChainId !== `${contract.chainId}`) {
        showError(
          `dApp request received from ${
            dappChainId ? chainIdToNetworkLabel(parseInt(dappChainId)) : `network id ${dappChainId}`
          }, but current network is ${
            contract.chainId
              ? chainIdToNetworkLabel(contract.chainId)
              : `network id ${contract.chainId}`
          }`,
          null,
          undefined,
          'Unable to handle dApp request.'
        );

        await walletClients.respond({
          topic: request.topic,
          response: formatJsonRpcError(request.id, 'client chain id mismatch')
        });
        return;
      }

      const key = request.id;

      let response: any;
      try {
        if (contract) {
          const walletSessions = getSessions(walletClients);
          const session = walletSessions.find((session) => session.topic === request.topic);
          dispatch(addWalletTransaction({ request, session, contract }));
          dispatch(setTabNotificationMessage('Transaction request pending'));
          notification.open({
            key,
            message: 'Transaction request pending',
            placement: 'top',
            type: 'info',
            duration: null,
            className: 'wide-notification',
            description: request?.params?.request?.params ? (
              <ul>
                {request.params.request.params.map((param: any, i: number) => {
                  return (
                    <li key={`transaction_param_${i}`}>
                      <strong>{shortenString(param.from, 30)}</strong> to{' '}
                      <strong>{shortenString(param.to, 30)}</strong>
                    </li>
                  );
                })}
              </ul>
            ) : undefined
          });
          response = await signAndSendTransaction(contract, request);
          if (response?.error) {
            dispatch(
              markWalletTransactionError({
                request,
                reason:
                  response?.error?.message ||
                  'Eulith received a DApp request but encountered an error after',
                rejected: false
              })
            );
          } else {
            dispatch(markWalletTransactionSuccess({ request, response }));
            notification.open({
              key,
              message: 'DApp Request Success!',
              placement: 'top',
              type: 'success',
              duration: 2,
              className: 'wide-notification'
            });
          }
        } else {
          dispatch(setTabNotificationMessage(''));
          const msg = 'You have not yet selected a contract in Eulith.';
          showError(msg, null, undefined, 'Unable to handle dApp request');
          response = formatJsonRpcError(request.id, msg);
        }
      } catch (error: any) {
        if (error.code === 4001) {
          const msg = 'User rejected DApp request.';
          dispatch(
            markWalletTransactionError({ request, reason: error?.message || msg, rejected: true })
          );
          showError(msg, error, undefined, 'Unable to handle dApp request');
          response = formatJsonRpcError(request.id, error?.message || msg);
        } else {
          BugsnagManager.notify(error, {
            context: 'Eulith received a DApp request but encountered an error after',
            metadata: {
              method
            }
          });
          const msg =
            error?.message || 'Eulith received a DApp request but encountered an error after.';
          dispatch(markWalletTransactionError({ request, reason: msg, rejected: false }));
          showError(msg, error, undefined, 'Unable to handle dApp request');
          response = formatJsonRpcError(request.id, msg);
        }
        notification.destroy(key);
      }

      await walletClients.respond({
        topic: request.topic,
        response
      });
    },
    [dispatch]
  );

  const registerSessionRequestHandler = useCallback(
    (client: SignClient) => {
      client.removeAllListeners('session_request');
      client.on('session_request', handleSessionRequest);
      console.log('Registered Eulith Wallet session request handler ✅');
    },
    [handleSessionRequest]
  );

  async function disconnectWallet() {
    const wallet = eulithSingleton.wallet;
    const walletClients = eulithSingleton.walletClients;

    if (wallet) {
      console.warn('No wallet instance found for disconnect');
      await wallet.disconnect();
    }
    dispatch(setWalletInstance(null));
    if (walletClients) {
      dispatch(setWalletSessions(getSessions(walletClients)));
      dispatch(setWalletPairings(getPairings(walletClients)));
    }
    refreshState();
  }

  async function disconnectSession(topic: string) {
    const walletClients = eulithSingleton.walletClients;

    if (walletClients) {
      await walletClients.disconnect({
        topic,
        reason: getSdkError('USER_DISCONNECTED')
      });
    }
    refreshState();
  }

  async function disconnectAllSessions() {
    const walletClients = eulithSingleton.walletClients;

    if (walletClients) {
      const promises = getSessions(walletClients).map(async (session) => {
        return await disconnectSession(session.topic);
      });
      await Promise.all(promises);
    }
  }

  async function disconnectAllWalletClients() {
    await disconnectAllSessions();
    window.localStorage.removeItem('wc@2:client:0.3//proposal');
    window.localStorage.removeItem('wc@2:client:0.3//subscription');
    window.localStorage.removeItem('wc@2:client:0.3//keychain');
    window.localStorage.removeItem('wc@2:client:0.3//messages');
    window.localStorage.removeItem('wc@2:client:0.3//history');
    window.localStorage.removeItem('wc@2:client:0.3//session');
    window.localStorage.removeItem('wc@2:client:0.3//expirer');
    window.localStorage.removeItem('wc@2:client:0.3//pairing');
    window.localStorage.removeItem('wc@2:client:0.3//request');
    window.location.reload();
  }

  async function forgetPairing(topic: string) {
    const walletClients = eulithSingleton.walletClients;

    if (walletClients) {
      await walletClients.core.pairing.disconnect({ topic });
    }
    refreshState();
  }

  const connectToSession = useCallback(
    async (session: SessionTypes.Struct, wcClient: SignClient) => {
      if (isWalletSession(session)) {
        const wallet = new WalletConnectWallet(session, wcClient);
        return await onWalletConnected(wallet, 'WalletConnect');
      } else {
        console.warn('Session is not a wallet session', session);
        return Promise.resolve(null);
      }
    },
    [onWalletConnected]
  );

  return {
    connectToMetaMask,
    onWalletConnected,
    approveDappConnection,
    rejectDappConnection,
    connectDapp,
    registerSessionHandlers,
    registerSessionRequestHandler,
    disconnectWallet,
    disconnectSession,
    disconnectAllSessions,
    forgetPairing,
    disconnectAllWalletClients,
    connectToSession
  };
};
