import EulithHelmetConsumer from '../components/EulithHelmetConsumer';
import { H1, PageContainer, Text, margin } from '../styles/shared';

const NOT_FOUND_POEMS = [
  `You're lost; 404
The page you're looking for
Is nowhere to be found
You may as well give up
And turn around

There's nothing here for you
No matter how long you look
So go back to where you came from
And stop wasting your time`,

  `You're lost, but that's okay
We'll help you find your way
Just follow the path we've laid
And you'll be back home someday`,

  `You're lost 404
In a world of numbers and screens
You're a number, a statistic
A cog in the machine

You're lost in the system
A victim of technology
You're just another number
In the digital age`,

  `You're lost 404
You were looking for a way out
But instead you found a dead end
You followed the path of least resistance
But it led you astray
You were looking for a way out
But you only found yourself
Lost in a labyrinth of your own making
A victim of your own choices
You were looking for a way out
But you only found yourself
Falling further down the rabbit hole
Into a world of madness
A world of chaos
A world of despair
This is your 404
This is your dead end
This is your way out`
];

function getRandomNotFoundPoem(): string {
  return NOT_FOUND_POEMS[Math.floor(Math.random() * NOT_FOUND_POEMS.length)];
}

const NotFoundPage: React.FC = () => {
  return (
    <PageContainer>
      <EulithHelmetConsumer>404</EulithHelmetConsumer>
      <H1 style={{ fontSize: 72, marginBottom: margin * 2, fontWeight: 300, color: '#fff' }}>
        404 Page
      </H1>
      <Text
        style={{
          whiteSpace: 'pre-wrap'
        }}
      >
        {getRandomNotFoundPoem()}
      </Text>
    </PageContainer>
  );
};

export default NotFoundPage;
