import { Button, Form, Space, message } from 'antd';
import { useMemo, useRef, useState } from 'react';
import EulithHelmetConsumer from '../EulithHelmetConsumer';
import { useNavigate } from 'react-router-dom';
import { useEditFundMutation } from '../../features/funds/fundsService';
import type { FundFormValues, FundsFormSectionProps } from '../../features/funds/fundsTypes';
import { H1, Text, antdFormConfig, colors } from '../../styles/shared';
import { buildFieldForType } from '../../utils/funds';
import EulithCard from '../EulithCard';
import FundFormBackButton from './FundFormBackButton';
import FundsSectionContainer from '../FundsSectionContainer';
import BugsnagManager from '../../BugsnagManager';

const FundsFormSection: React.FC<FundsFormSectionProps> = ({
  editing,
  initialValues = {},
  setData,
  navigateBack,
  returnLatestCurrentStage,
  navigateTo,
  section,
  progressCount
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState<string[]>([]);
  const shouldLeaveAfterSubmit = useRef(false);
  const [editFund] = useEditFundMutation();

  const fundId = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('id') || '';
  }, []);

  const isLoading = loading || !!loadingFile?.length;

  function handleSubmit() {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  async function handleForwardNavigation(values: FundFormValues) {
    if (!initialValues?.managers?.[0]) {
      message.error('Please add at least one manager.');
      setLoading(false);
      return;
    }
    setLoading(true);
    const id = fundId || values.id;
    const valuesToSend = {
      ...values,
      id,
      completedStage: returnLatestCurrentStage(section.path)
    };
    try {
      const response = await editFund(valuesToSend).unwrap();
      setTimeout(() => {
        if (editing) {
          message.success('Your changes have been saved!');
        }
        setLoading(false);
        setData(response);
        if (shouldLeaveAfterSubmit.current) {
          navigate('/funds/manage');
        } else {
          navigate(`/funds/${editing ? 'edit' : 'create'}/${navigateTo}?id=${id}`);
        }
      }, 750);
    } catch (error: any) {
      BugsnagManager.notify(error, {
        context: 'Unable to save draft in fund form section'
      });
      setLoading(false);
      message.error('Unable to save your draft. Please try again later.');
      console.warn(error);
    }
  }

  function handleSaveAndExit() {
    shouldLeaveAfterSubmit.current = true;
    handleSubmit();
  }

  function handleSetFileLoading(id: string, fileIsLoading: boolean) {
    if (fileIsLoading) {
      setLoadingFile((prevFiles: string[]) => {
        if (
          prevFiles.find((fileId) => {
            return fileId === id;
          })
        ) {
          return [...prevFiles];
        } else {
          return [...prevFiles, id];
        }
      });
    } else {
      setLoadingFile((prevFiles) => {
        return [...prevFiles].filter((fileId) => fileId !== id);
      });
    }
  }

  return (
    <>
      <EulithHelmetConsumer>{section.title}</EulithHelmetConsumer>
      <EulithCard
        fullHeight
        bodyStyle={{ height: 'calc(100% - 80px)', padding: 0, overflow: 'auto' }}
        style={{ height: '100%', overflow: 'hidden' }}
        title={
          <FundFormBackButton
            disabled={isLoading}
            to={`/funds/${editing ? 'edit' : 'create'}/${navigateBack}?id=${
              fundId || initialValues.id
            }`}
          />
        }
        extra={
          <Space>
            <Button
              loading={isLoading}
              type={editing ? 'default' : 'text'}
              onClick={handleSaveAndExit}
            >
              Save and Exit
            </Button>
            <Button loading={isLoading} type="primary" htmlType="submit" onClick={handleSubmit}>
              Next
            </Button>
          </Space>
        }
      >
        <FundsSectionContainer>
          <Text style={{ color: colors.borderGrey, marginBottom: 10 }}>{progressCount}</Text>
          <H1>{section.title}</H1>
          <Text>{section.subtitle}</Text>
          <Form
            form={form}
            requiredMark={antdFormConfig.requiredMark}
            scrollToFirstError
            initialValues={initialValues}
            style={{ marginTop: 20, paddingBottom: 10 }}
            onFinish={handleForwardNavigation}
            size={antdFormConfig.size}
          >
            {section.fields.map((field) => {
              return buildFieldForType({
                form,
                field,
                fundId,
                setData,
                setLoading,
                setLoadingFile: handleSetFileLoading
              });
            })}
          </Form>
        </FundsSectionContainer>
      </EulithCard>
    </>
  );
};

export default FundsFormSection;
