import React from 'react';
import EulithHelmetConsumer from '../components/EulithHelmetConsumer';
import GenerateTokenForm from '../components/Tokens/GenerateTokenForm';

const ApiPage: React.FC = () => {
  return (
    <>
      <EulithHelmetConsumer>Generate API Tokens</EulithHelmetConsumer>
      <GenerateTokenForm />
    </>
  );
};

export default ApiPage;
