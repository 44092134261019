import { ConnectWallet, useConnectionStatus } from '@thirdweb-dev/react';
import { Header } from 'antd/es/layout/layout';
import React from 'react';
import { Link, useMatch } from 'react-router-dom';
import styled from 'styled-components';
import images from '../../assets';
import { selectAuthenticated } from '../../features/auth/authSlice';
import { useAppSelector } from '../../hooks/redux';
import { colors } from '../../styles/shared';
import Flex from '../Flex/Flex';
import { Button } from 'antd';

const LoggedInHeader: React.FC = () => {
  const connectionStatus = useConnectionStatus();
  const isFundPreview = useMatch('/funds/public/:id');
  const authenticated = useAppSelector(selectAuthenticated);

  const isDarkMode = !isFundPreview;

  function handleHowToClick() {
    window.open('/resources/docs/how-to/', '_blank');
  }

  return (
    <Header
      style={{
        zIndex: 1,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0 3px 8px rgba(0,0,0,0.15)',
        backgroundColor: isDarkMode ? colors.blue2 : colors.white
      }}
    >
      <Link to="/home">
        <Logo src={isDarkMode ? images.logo : images.logoBlack} style={{ zIndex: 10 }} />
      </Link>
      {authenticated && !isFundPreview ? (
        <Flex style={{ marginLeft: 'auto' }} alignItems="center" flexDirection="row">
          <Button
            size="large"
            type="primary"
            onClick={handleHowToClick}
            style={{
              height: 47,
              color: '#181A1F',
              fontWeight: 'bold',
              border: '1px solid #89E592',
              backgroundColor: '#89E592'
            }}
          >
            How To Guide
          </Button>
          <ConnectWallet
            style={{ height: 47, marginLeft: 16 }}
            theme={isDarkMode ? 'dark' : 'light'}
            className={connectionStatus === 'disconnected' ? 'connectBtn' : ''}
          />
        </Flex>
      ) : null}
    </Header>
  );
};

const Logo = styled.img`
  height: 32px;
  vertical-align: middle;
  object-fit: contain;
`;

export default LoggedInHeader;
