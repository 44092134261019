import { WalletConnectButton } from './WalletConnectButton';
import { Col, Row, Space } from 'antd';
import { Text, colors } from '../../styles/shared';
import { useAppSelector } from '../../hooks/redux';
import { selectWalletDidFailToConnect } from '../../features/wallet/walletSlice';
import { SafetyCertificateOutlined } from '@ant-design/icons';
import { SupportedWallets } from '../../features/wallet/walletTypes';

interface ClickableWallets {
  title: SupportedWallets;
  onClick: any;
}

export default function WalletInfoOrConnect({
  onClickMetaMask,
  onClickWalletConnect
}: {
  onClickMetaMask: any;
  onClickWalletConnect: any;
}) {
  const walletFailedToConnect = useAppSelector(selectWalletDidFailToConnect);
  const wallets: ClickableWallets[] = [
    {
      title: 'MetaMask',
      onClick: onClickMetaMask
    },
    {
      title: 'WalletConnect',
      onClick: onClickWalletConnect
    }
  ];
  return (
    <Space
      direction="vertical"
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Row
        gutter={[20, 20]}
        align="middle"
        style={{
          maxWidth: 600,
          height: '100%'
        }}
      >
        <Col span={10}>
          {wallets.map((wallet) => {
            return (
              <Col span={24} key={`connect_wallet_${wallet.title}`}>
                <WalletConnectButton onClick={wallet.onClick} title={wallet.title} />
              </Col>
            );
          })}
        </Col>
        <Col
          span={14}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          <div>
            <SafetyCertificateOutlined style={{ fontSize: 65, color: colors.placeholder }} />
            <Text style={{ fontWeight: 'bold', marginBottom: 15, marginTop: 30 }}>
              Use Eulith DeFi Armor on any DApp that supports Wallet Connect.
            </Text>
            <Text>Please connect a custodial wallet to begin.</Text>
            {walletFailedToConnect ? <Text>Failed to connect to wallet.</Text> : null}
          </div>
        </Col>
      </Row>
    </Space>
  );
}
