import * as Eulith from 'eulith-web3js';
import {
  BaseQueryFn,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery
} from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store';
import { createSelector } from '@reduxjs/toolkit';
import eulithSingleton from './EulithSingleton';
import { message } from 'antd';
import {
  PingAceResponse,
  DecoratedContract,
  PingAceRequest,
  PingAceResponseMap,
  EditContractRequest,
  GetBalanceForContractResponse
} from './eulithTypes';
import { formatEther } from 'ethers/lib/utils';
import { BigNumberish } from 'ethers';
import BugsnagManager from '../../BugsnagManager';
import axios from 'axios';
import { chainIdToNetworkShortName } from '../../utils/networks';

export async function getBalanceForContract(safeAddress: string, chainId: number) {
  return new Promise(async (resolve) => {
    try {
      const url = `https://pro-openapi.debank.com/v1/user/chain_balance?id=${safeAddress}&chain_id=${chainIdToNetworkShortName[chainId]}`;
      const response = await axios.get(url, {
        headers: {
          Accept: 'application/json',
          AccessKey: '2ac63a3e2e144b05278fc48bf246d74cb9a0ca6f'
        }
      });
      resolve(response?.data?.usd_value || 0);
    } catch (error) {
      console.error('Error fetching wallet balance:', error);
      resolve(0);
    }
  });
}

export async function getTokenListForContract(safeAddress: string, chainId: number) {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `https://pro-openapi.debank.com/v1/user/token_list?id=${safeAddress}&chain_id=${chainIdToNetworkShortName[chainId]}`;
      const response = await axios.get(url, {
        headers: {
          Accept: 'application/json',
          AccessKey: '2ac63a3e2e144b05278fc48bf246d74cb9a0ca6f'
        }
      });
      resolve((response?.data || []) as GetBalanceForContractResponse);
    } catch (error) {
      console.error('Error fetching token list for contract:', error);
      reject(error);
    }
  });
}

export async function getGasBalanceForContract(
  tradingKeyAddress: string,
  chainId: number,
  accessToken: string
) {
  return new Promise((resolve, reject) => {
    const provider = eulithSingleton.createProviderFromChainId(chainId, accessToken);
    let web3: Eulith.Web3 | null = new Eulith.Web3({ provider });
    if (web3?.eth) {
      web3.eth
        .getBalance(tradingKeyAddress)
        .then((response) => {
          web3 = null;
          resolve(response);
        })
        .catch((error) => {
          console.warn(error);
          BugsnagManager.notify(error, {
            context: 'Unable to get contract balance',
            metadata: {
              tradingKeyAddress,
              chainId
            }
          });
          reject(null);
        });
    } else {
      reject(null);
    }
  });
}

const UNABLE_TO_PING_ACE_MESSAGE_KEY = 'unable_to_ping_ace';

const EMPTY_ARRAY: any = [];
const EMPTY_MAP: any = {};

export const editContractQuery: BaseQueryFn<
  EditContractRequest,
  (Eulith.OnChainAgents.IAgent | Eulith.OnChainAgents.IArmorAgent)[],
  FetchBaseQueryError
> = async ({ deployedContractId, name, description }) => {
  try {
    if (eulithSingleton.provider) {
      const results = await Eulith.OnChainAgents.updateContractNameAndDescription({
        provider: eulithSingleton.provider,
        deployedContractId,
        name,
        description
      });
      return { data: results };
    } else {
      return {
        error: {
          error: 'Eulith singleton has not been set in editContractQuery'
        } as FetchBaseQueryError
      };
    }
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'Unable to edit contract',
      metadata: {
        deployedContractId,
        name,
        description
      }
    });
    console.warn('Unable to edit account', error);
    return { error: error as FetchBaseQueryError };
  }
};

export const getContractsQuery: BaseQueryFn<
  void,
  DecoratedContract[],
  FetchBaseQueryError
> = async () => {
  try {
    if (eulithSingleton.provider && eulithSingleton.accessToken) {
      const results = await Eulith.OnChainAgents.getAll(eulithSingleton.provider);
      const armorContracts = results.filter(
        (contract) => contract.type === Eulith.OnChainAgents.Type.Armor
      );
      const getBalancesAndWhitelistsPromise = armorContracts.map(async (contract) => {
        return Promise.all([
          getGasBalanceForContract(
            contract.tradingKeyAddress,
            contract.chainId,
            eulithSingleton.accessToken || ''
          ),
          //@ts-ignore
          getBalanceForContract(contract.safeAddress, contract.chainId),
          getWhitelistForContract(
            //@ts-ignore because eulithSingleton.provider is null-checked above
            eulithSingleton.provider,
            contract
          )
        ]);
      });
      const balancesAndWhitelists = (await Promise.all(getBalancesAndWhitelistsPromise)) || [];
      return {
        data: armorContracts.map(
          (
            contract: Eulith.OnChainAgents.IArmorAgent | Eulith.OnChainAgents.IAgent,
            index: number
          ) => {
            const [gasBalance, balance, whitelist] = balancesAndWhitelists[index];
            return {
              ...(contract as Eulith.OnChainAgents.IArmorAgent | Eulith.OnChainAgents.IAgent),
              tradingKeyAddress: contract.tradingKeyAddress?.toLowerCase() || '',
              authorizedAddress: contract.tradingKeyAddress?.toLowerCase() || '',
              contractAddress: contract.contractAddress?.toLowerCase() || '',
              tradingKeyAddressBalance: formatEther(gasBalance as BigNumberish),
              balance,
              whitelist
            };
          }
        ) as DecoratedContract[]
      };
    } else {
      return {
        error: {
          error: 'Eulith singleton has not been set in getContractsQuery'
        } as FetchBaseQueryError
      };
    }
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'Unable to fetch contracts in query'
    });
    console.warn('Unable to fetch contracts', error);
    return { error: error as FetchBaseQueryError };
  }
};

async function getWhitelistForContract(
  provider: Eulith.Provider,
  contract: Eulith.OnChainAgents.IAgent | Eulith.OnChainAgents.IArmorAgent
): Promise<Eulith.WhitelistsV2.Whitelist | null> {
  if ('whitelistId' in contract && contract.whitelistId) {
    return await Eulith.WhitelistsV2.getById(provider, contract.whitelistId);
  } else {
    return null;
  }
}

const pingAceQuery: BaseQueryFn<PingAceRequest[], PingAceResponseMap, FetchBaseQueryError> = async (
  contracts
) => {
  const filteredContracts =
    contracts?.filter?.((contract) => contract.tradingKeyAddress && contract.hasAce) || [];
  try {
    if (filteredContracts?.length) {
      if (eulithSingleton.web3 && filteredContracts?.length) {
        const promises = filteredContracts
          .map(async (contract) => {
            if (eulithSingleton.web3) {
              return await eulithSingleton.web3
                .eulithPingAce(contract.tradingKeyAddress)
                .catch((error) => {
                  if (!error?.message?.includes?.('32600')) {
                    console.warn(error);
                    BugsnagManager.notify(error, {
                      context: 'Unable to ping ace'
                    });
                  }
                });
            } else {
              return null;
            }
          })
          .filter((x) => x);
        const result = (await Promise.all(promises)) as PingAceResponse[];
        const filteredResults = result.filter((x) => x);
        const pingAceMap: PingAceResponseMap = {};
        filteredResults.forEach((result) => {
          pingAceMap[result.auth_address.toLowerCase()] = result;
        });
        message.destroy(UNABLE_TO_PING_ACE_MESSAGE_KEY);
        return { data: pingAceMap };
      } else {
        return {
          error: {
            error: 'Eulith web3 singleton has not been set in pingAceQuery'
          } as FetchBaseQueryError
        };
      }
    } else {
      return {
        error: {
          error: 'Trading key address has not been provided to pingAceQuery'
        } as FetchBaseQueryError
      };
    }
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'Unable to ping ace in query'
    });
    message.open({
      key: UNABLE_TO_PING_ACE_MESSAGE_KEY,
      type: 'error',
      content: 'Unable to connect to ACE.',
      duration: 0
    });
    console.warn('Unable to ping ace', error);
    return { error: error as FetchBaseQueryError };
  }
};

export const eulithApi = createApi({
  reducerPath: 'eulithApi',
  tagTypes: ['Contract'],
  baseQuery: fetchBaseQuery({}),
  endpoints: (builder) => ({
    getContracts: builder.query<DecoratedContract[], void>({
      queryFn: getContractsQuery,
      providesTags: ['Contract']
    }),
    editContract: builder.mutation<
      (Eulith.OnChainAgents.IAgent | Eulith.OnChainAgents.IArmorAgent)[],
      EditContractRequest
    >({
      queryFn: editContractQuery,
      invalidatesTags: ['Contract']
    }),
    pingAce: builder.query<PingAceResponseMap, PingAceRequest[]>({
      queryFn: pingAceQuery,
      providesTags: ['Contract']
    })
  })
});

export const {
  useGetContractsQuery,
  useLazyGetContractsQuery,
  usePingAceQuery,
  useLazyPingAceQuery,
  useEditContractMutation
} = eulithApi;

export const selectContractsData = createSelector(
  (state: RootState) => {
    if (state.eulith.initialized) {
      const getContractsResponse = eulithApi.endpoints.getContracts.select()(state);
      return (getContractsResponse.data || []) as DecoratedContract[];
    } else {
      return EMPTY_ARRAY as DecoratedContract[];
    }
  },
  (result) => result
);

export const selectPingData = createSelector(
  (state: RootState) => {
    if (state.eulith.initialized) {
      const contracts = selectContractsData(state);
      const pings = eulithApi.endpoints.pingAce.select(
        contracts?.map?.((contract) => {
          return {
            hasAce: contract.hasAce,
            tradingKeyAddress: contract.tradingKeyAddress?.toLowerCase?.() || ''
          };
        }) || EMPTY_ARRAY
      )(state);
      return pings?.data || EMPTY_MAP;
    } else {
      return EMPTY_MAP;
    }
  },
  (result) => result
);
