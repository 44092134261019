import styled from 'styled-components';
import { padding } from '../../styles/shared';

export const ARMOR_TABS = {
  OVERVIEW: '/armor/deploy/overview',
  CONFIGURE: '/armor/deploy/configure',
  ADD_OWNERS: '/armor/deploy/owners',
  AUTHORIZE_OWNERS: '/armor/deploy/authorize',
  SUCCESS: '/armor/deploy/success'
};

export const StyledContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const Main = styled.div`
  position: relative;
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  padding: 0 ${padding}px;
`;
