import styled from 'styled-components';

const StepsFooterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 20px 15px 20px;
`;

export default StepsFooterContainer;
