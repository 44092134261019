import React from 'react';
import EulithHelmetConsumer from '../components/EulithHelmetConsumer';
import Flex from '../components/Flex';
import { Result } from 'antd';

const LoginPage: React.FC = () => {
  return (
    <>
      <EulithHelmetConsumer>Thank You</EulithHelmetConsumer>
      <Flex flex="1 1 auto" flexDirection="column" justifyContent="center" alignItems="center">
        <Result
          status="success"
          title="Thank You!"
          subTitle="A representative from Eulith will reach out to you soon."
        />
      </Flex>
    </>
  );
};

export default LoginPage;
