export const slackHookUrl =
  'https://hooks.slack.com/services/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';

export const sendInquiry = async ({
  firstName = '',
  lastName = '',
  username = '',
  email = '',
  isMetaMaskSnapSignUp = false
}) => {
  const data = {
    text: 'New Inquiry',
    blocks: [
      {
        type: 'header',
        text: {
          type: 'plain_text',
          text: 'Inquiry',
          emoji: false
        }
      },
      {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: `*Name:*\n${firstName} ${lastName}`
          },
          {
            type: 'mrkdwn',
            text: `*Desired Username:*\n${username}`
          },
          {
            type: 'mrkdwn',
            text: `*Email:*\n${email}`
          },
          {
            type: 'mrkdwn',
            text: `*From MetaMask Snaps:*\n${isMetaMaskSnapSignUp ? 'true' : 'false'}`
          }
        ]
      }
    ]
  };

  const raw = JSON.stringify(data);

  const requestOptions = {
    method: 'POST',
    body: raw
  };

  const res = await fetch(slackHookUrl, requestOptions);

  const text = await res.text();
  return text;
};

export const sendContactInquiry = async ({
  firstName = '',
  lastName = '',
  message = '',
  email = ''
}) => {
  const data = {
    text: 'New Inquiry',
    blocks: [
      {
        type: 'header',
        text: {
          type: 'plain_text',
          text: 'Inquiry',
          emoji: false
        }
      },
      {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: `*Name:*\n${firstName} ${lastName}`
          },
          {
            type: 'mrkdwn',
            text: `*Message:*\n${message}`
          },
          {
            type: 'mrkdwn',
            text: `*Email:*\n${email}`
          }
        ]
      }
    ]
  };

  const raw = JSON.stringify(data);

  const requestOptions = {
    method: 'POST',
    body: raw
  };

  const res = await fetch(slackHookUrl, requestOptions);

  const text = await res.text();
  return text;
};
