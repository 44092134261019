import React, { useEffect, useRef } from 'react';
import hljs from 'highlight.js/lib/core';
import json from 'highlight.js/lib/languages/json';
import 'highlight.js/styles/default.css';
import styled from 'styled-components';

// Then register the languages you need
hljs.registerLanguage('json', json);

interface Props {
  json: any;
}

const JsonHighlighter = ({ json }: Props) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref && ref.current) {
      hljs.highlightBlock(ref.current);
    }
  }, [json]);

  const jsonString = JSON.stringify(json, null, 4);

  return (
    <StyledPre>
      <code ref={ref} className="json">
        {jsonString}
      </code>
    </StyledPre>
  );
};

const StyledPre = styled.pre`
  margin: 0 !important;
  padding-top: 0 !important;
  padding-left: 0 !important;
  width: calc(80vw - 110px) !important;
  overflow-x: auto !important;
  border-radius: 5px;
`;

export default JsonHighlighter;
