import { ThirdwebSDK } from '@thirdweb-dev/sdk';
import * as Eulith from 'eulith-web3js';
import type { Signer } from 'ethers';
import { createSignerForAceImmediateTransaction } from '../../utils/signing';
import { SignClient } from '@walletconnect/sign-client/dist/types/client';
import { Wallet } from '../wallet/walletTypes';
import { isDevelopmentEnv, localHostServer } from '../../api/common';
import axios from 'axios';

class EulithSingleton {
  public provider: Eulith.Provider | null = null;
  public web3: Eulith.Web3 | null = null;
  public sdk: ThirdwebSDK | undefined = undefined;
  public signer: Signer | undefined = undefined;
  public atomicTx: Eulith.AtomicTx.Transaction | undefined = undefined;
  public wallet: Wallet | null = null;
  public walletClients: SignClient | null = null;
  public accessToken: string | null = null;

  private static instance: EulithSingleton;

  private constructor() {
    // Initialize the class here if needed (e.g., this.provider = new Eulith.Provider();)
  }

  public static getInstance(): EulithSingleton {
    if (!EulithSingleton.instance) {
      EulithSingleton.instance = new EulithSingleton();
    }
    return EulithSingleton.instance;
  }

  public setProvider(newProvider: Eulith.Provider): void {
    this.provider = newProvider;
    this.web3 = new Eulith.Web3({ provider: newProvider });
  }

  public setSDK(sdk: ThirdwebSDK | undefined): void {
    this.sdk = sdk;
  }

  public setSigner(signer: Signer | undefined): void {
    this.signer = signer;
  }

  public createProviderFromChainId(chainId: number, accessToken: string) {
    const network = isDevelopmentEnv
      ? Eulith.Networks.Predefined.get({ chainId }).with({
          eulithURL: localHostServer
        })
      : Eulith.Networks.Predefined.get({ chainId });

    const auth = Eulith.Auth.fromToken(accessToken || '');
    const fetcherInstance = axios.create();

    return new Eulith.Provider({
      network,
      auth,
      fetcherInstance
    });
  }

  public setAtomicTx(
    safeAddress: string,
    connectedWalletAddress: string,
    tradingKeyAddress: string
  ): Eulith.AtomicTx.Transaction | undefined {
    if (!this.provider) {
      console.warn('Could not create atomic tx because provider is not set yet');
      return;
    }
    if (!this.signer) {
      console.warn('Could not create atomic tx because signer is not set yet');
      return;
    }
    if (!connectedWalletAddress) {
      console.warn('Could not create atomic tx because trading key address is not set yet');
      return;
    }
    if (!safeAddress) {
      console.warn('Could not create atomic tx because safe address is not set yet');
      return;
    }
    const signer = createSignerForAceImmediateTransaction(
      Eulith.Web3.utils.toChecksumAddress(connectedWalletAddress)
    );
    this.atomicTx = new Eulith.AtomicTx.Transaction({
      provider: this.provider,
      signer: signer,
      safeAddress,
      tradingKeyAddress
    });
    return this.atomicTx;
  }

  public setWalletClients(clients: SignClient): void {
    this.walletClients = clients;
  }

  public setWallet(wallet: Wallet | null): void {
    this.wallet = wallet;
  }

  public setAccessToken(accessToken: string | null): void {
    this.accessToken = accessToken || null;
  }
}

const eulithInstance = EulithSingleton.getInstance();
export default eulithInstance;
