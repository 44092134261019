import { PairingTypes, ProposalTypes, SessionTypes } from '@walletconnect/types';
import { getSdkError } from '@walletconnect/utils';

import { SUPPORTED_CHAIN_TYPES, SUPPORTED_CHAINS } from './constants';
import Client from '@walletconnect/sign-client';
import { Wallet } from './walletTypes';
import { SignClient } from '@walletconnect/sign-client/dist/types/client';

export class WalletConnectWallet implements Wallet {
  private session: SessionTypes.Struct;
  private client: SignClient;

  constructor(session: SessionTypes.Struct, client: SignClient) {
    this.session = session;
    this.client = client;
  }

  async chainId(): Promise<number> {
    // TODO(ian): retrieve chain ID from wallet
    return 1;
  }

  getAddress(): string {
    const allNamespaceAccounts = Object.values(this.session.namespaces)
      .map((namespace) => namespace.accounts)
      .flat();

    // TODO(ian): handle multiple accounts (or reject this possibility)
    const parts = allNamespaceAccounts[0].split(':');
    return parts[2];
  }

  getName(): string {
    return this.session.peer.metadata.name;
  }

  async signTypedData(typedData: any): Promise<string> {
    return await this.client.request<string>({
      topic: this.session.topic,
      // TODO(ian): make this a parameter
      chainId: 'eip155:1',
      request: {
        method: 'eth_signTypedData_v4',
        // JSON.stringify is required for some wallets to work (e.g., Ledger Live)
        params: [this.getAddress(), JSON.stringify(typedData)]
      }
    });
  }

  async ethRpcRequest(method: string, params: any[]): Promise<any> {
    return await this.client.request({
      topic: this.session.topic,
      // TODO(ian): make this a parameter
      chainId: 'eip155:1',
      request: {
        method,
        params
      }
    });
  }

  async disconnect(): Promise<void> {
    await this.client.disconnect({
      topic: this.session.topic,
      reason: getSdkError('USER_DISCONNECTED')
    });
  }
}

export function fillNamespace(
  fillInto: SessionTypes.Namespaces,
  key: string,
  fillFrom: ProposalTypes.RequiredNamespace,
  safeAddress: string
): boolean {
  if (!SUPPORTED_CHAIN_TYPES.has(key)) {
    console.warn(`skipping unsupported chain type: ${key}`);
    return false;
  }

  let approved = true;
  const accounts: string[] = [];
  const chains: string[] = [];
  for (const chain of fillFrom?.chains || []) {
    const [chainType, chainId] = chain.split(':');

    if (!SUPPORTED_CHAIN_TYPES.has(chainType)) {
      console.warn(`skipping unsupported chain type: ${chainType}`);
      approved = false;
    }

    if (!SUPPORTED_CHAINS.has(Number(chainId))) {
      console.warn(`skipping unsupported chain type: ${key}`);
      approved = false;
    }

    // TODO(ian): handle multiple addresses
    chains.push(chain);
    accounts.push(`${chain}:${safeAddress}`);
  }

  // const methods = requiredNamespaces[key].methods.filter(method => SUPPORTED_METHODS.has(method));
  const methods = fillFrom.methods;
  // const events = requiredNamespaces[key].events.filter(event => SUPPORTED_EVENTS.has(event));
  const events = fillFrom.events;

  if (fillInto[key]) {
    const previous = fillInto[key];
    fillInto[key] = {
      accounts: dedup(previous.accounts.concat(accounts)),
      methods: dedup(previous.methods.concat(methods)),
      events: dedup(previous.events.concat(events)),
      chains: dedup(chains.concat(previous.chains ?? []))
    };
  } else {
    fillInto[key] = {
      accounts,
      methods,
      events,
      chains
    };
  }
  return approved;
}

export function getSessions(client: SignClient): SessionTypes.Struct[] {
  return client.session.getAll();
}

export function getPairings(client: SignClient): PairingTypes.Struct[] {
  return client.core.pairing.getPairings();
}

// distinguishes wallet connection (outgoing) from dapp connection (incoming)
export function isWalletSession(session: SessionTypes.Struct): boolean {
  return session.controller === session.peer.publicKey;
}

export async function createClient(): Promise<SignClient> {
  return await Client.init({
    logger: 'info',
    projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
    relayUrl: process.env.REACT_APP_WALLET_CONNECT_RELAY_URL,
    metadata: {
      name: 'Eulith Wallet',
      description: 'Wallet for the Eulith OEMS',
      url: 'https://eulith.com',
      icons: ['https://avatars.githubusercontent.com/u/98235882']
    }
  });
}

export async function pairDappViaUri(client: SignClient, uri: string) {
  await client.core.pairing.pair({ uri });
}

export interface SessionProposal {
  uri: string | undefined;
  approval: () => Promise<SessionTypes.Struct>;
  standaloneChains: string[];
}

export async function createSessionProposal(client: SignClient): Promise<SessionProposal> {
  const requiredNamespaces = {
    eip155: {
      methods: ['eth_sendTransaction', 'eth_signTypedData_v4'],
      // TODO(ian): how to decide this?
      chains: ['eip155:1'],
      events: []
    }
  };
  const optionalNamespaces = {};

  const { uri, approval } = await client.connect({
    requiredNamespaces,
    optionalNamespaces
  });

  const standaloneChains = Object.values(requiredNamespaces)
    .map((namespace) => namespace.chains)
    .flat() as string[];

  return { uri, approval, standaloneChains };
}

function dedup(items: any[]) {
  return Array.from(new Set(items));
}
