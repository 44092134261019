import React, { useCallback } from 'react';
import ReactGA from 'react-ga4';
import styled from 'styled-components';
import { antdFormConfig, colors } from '../../styles/shared';
import { CopyFilled, LockOutlined } from '@ant-design/icons';
import { Button, message, Form, Input, Space, Select, Modal, Alert } from 'antd';
import { useAppDispatch } from '../../hooks/redux';
import { useGenerateNewTokenMutation } from '../../features/auth/authService';
import EulithCard from '../EulithCard';
import { setAccessToken } from '../../features/auth/authSlice';
import BugsnagManager from '../../BugsnagManager';

const NUM_SECS_IN_DAY = 86400;
interface GenerateTokenValues {
  accessToken: string;
  accessTokenExpiration: number;
}

interface ExpirationOption {
  label: string;
  value: number;
}

interface OTPValues {
  code: string;
}

const expirationOptions: ExpirationOption[] = [
  {
    label: '1 Day',
    value: 1
  },
  {
    label: '2 Days',
    value: 2
  },
  {
    label: '5 Days',
    value: 5
  },
  {
    label: '15 Days',
    value: 15
  },
  {
    label: '30 Days',
    value: 30
  },
  {
    label: '60 Days',
    value: 60
  },
  {
    label: '1 Year',
    value: 365
  }
];

const GenerateTokenForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [generateNewToken, { isLoading }] = useGenerateNewTokenMutation();

  const generateAccessToken = useCallback(
    async (values: GenerateTokenValues & OTPValues) => {
      Modal.destroyAll();
      try {
        ReactGA.event({ category: 'API Token Page Click', action: 'Generate Access' });
        const response = await generateNewToken({
          otp: values.code,
          expire_in_secs: values.accessTokenExpiration * NUM_SECS_IN_DAY
        }).unwrap();
        if (response?.token) {
          form.setFieldValue('accessToken', response.token);
          form.setFieldValue('accessTokenExpiration', '');
          dispatch(setAccessToken(response.token));
          message.success('Generated new API access token!');
        } else {
          message.error(
            'Unable to generate a new access token. Please contact us if this issue persists.'
          );
        }
        form.setFieldValue('accessTokenExpiration', '');
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Unable to generate api access token',
          metadata: {
            values
          }
        });
        console.warn(error);
        message.error('Unable to generate a API access token.');
      }
    },
    [generateNewToken, form, dispatch]
  );

  const requestCode = useCallback(
    (formData: GenerateTokenValues) => {
      Modal.info({
        title: 'Please enter your one-time password.',
        icon: <LockOutlined />,
        closeIcon: <div />,
        footer: null,
        maskClosable: true,
        closable: true,
        content: (
          <div style={{ paddingRight: 30 }}>
            <div style={{ paddingBottom: 15 }}>We just want to make sure it&apos;s still you.</div>
            <Form
              onFinish={(data: OTPValues) => {
                generateAccessToken({
                  ...formData,
                  ...data
                });
              }}
            >
              <Form.Item
                name="code"
                labelCol={antdFormConfig.labelCol}
                rules={[
                  {
                    required: true,
                    message: 'Please enter a code.'
                  }
                ]}
              >
                <Input
                  ref={(input) => input && input.focus()}
                  size="large"
                  placeholder="Enter code from authenticator app"
                />
              </Form.Item>
              <Button
                htmlType="submit"
                block
                size="large"
                type="primary"
                style={{ width: '100%' }}
                loading={isLoading}
                disabled={isLoading}
              >
                Submit
              </Button>
            </Form>
          </div>
        )
      });
    },
    [generateAccessToken, isLoading]
  );

  const copyApiAccessToken = useCallback(() => {
    ReactGA.event({ category: 'API Token Page Click', action: 'Copy Access' });
    const accessToken = form.getFieldValue('accessToken');
    if (accessToken) {
      navigator.clipboard.writeText(accessToken);
      message.success('Access token copied to clipboard!');
    }
  }, [form]);

  return (
    <Container>
      <EulithCard title="Generate API Tokens" style={{ maxWidth: 600, margin: 'auto' }}>
        <Form
          form={form}
          requiredMark={antdFormConfig.requiredMark}
          disabled={isLoading}
          onFinish={requestCode}
          size={antdFormConfig.size}
          wrapperCol={{ span: 24 }}
        >
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const accessToken = getFieldValue('accessToken');
              return (
                <Form.Item
                  name="accessToken"
                  label="Access Token"
                  labelCol={antdFormConfig.labelCol}
                >
                  <Space.Compact style={{ width: '100%' }}>
                    <Input disabled value={accessToken} />
                    {accessToken ? (
                      <Button shape="circle" onClick={copyApiAccessToken}>
                        <CopyFilled style={{ color: colors.blue }} />
                      </Button>
                    ) : null}
                  </Space.Compact>
                  {accessToken ? (
                    <Alert
                      showIcon
                      style={{ marginTop: 10 }}
                      type="warning"
                      message="Your token will only be displayed once, so please copy it before leaving this form."
                    />
                  ) : null}
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item
            name="accessTokenExpiration"
            label="Expiration"
            rules={[{ required: true, message: 'Please select an expiration.' }]}
            labelCol={antdFormConfig.labelCol}
          >
            <Select showSearch placeholder="Select Expiration">
              {expirationOptions.map((option: ExpirationOption) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              return (
                <Button
                  htmlType="submit"
                  type="primary"
                  block
                  size="large"
                  style={{ width: '100%' }}
                  disabled={isLoading || !getFieldValue('accessTokenExpiration')}
                >
                  {isLoading ? 'Loading...' : 'Generate Access Token'}
                </Button>
              );
            }}
          </Form.Item>
        </Form>
      </EulithCard>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export default GenerateTokenForm;
