import { CheckCircleFilled, CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Collapse, Popconfirm, Popover, Space, message } from 'antd';
import { ArmorOwner } from '../../../../../features/order/orderTypes';
import MonoSpaceSpan from '../../../../MonoSpaceSans';
import MiddleEllipses from '../../../../MiddleEllipses';
import { colors } from '../../../../../styles/shared';
import styled from 'styled-components';

const { Panel } = Collapse;

interface ArmorOwnerListItemProps {
  data: ArmorOwner;
  editOwner?: (owner: ArmorOwner) => void;
  deleteOwner?: (owner: ArmorOwner) => void;
  allowAddressCopy?: boolean;
}

function copyAddress(address: string) {
  navigator.clipboard.writeText(address);
  message.success('Copied to clipboard!');
}

function ArmorOwnerListItem({
  data,
  editOwner,
  deleteOwner,
  allowAddressCopy = false
}: ArmorOwnerListItemProps) {
  function stopPropagation(e: any) {
    e?.stopPropagation?.();
  }

  return (
    <Collapse size="small">
      <Panel
        showArrow={false}
        key={`armor_owner_panel_${data.address}`}
        header={
          <MiddleEllipses width="95%">
            {data.address ? <MonoSpaceSpan>{data.address}</MonoSpaceSpan> : 'No address provided'}
          </MiddleEllipses>
        }
        extra={
          <Space>
            {!data.authorized && editOwner ? (
              <EditOutlined
                onClick={(e: any) => {
                  e?.stopPropagation?.();
                  editOwner(data);
                }}
              />
            ) : null}
            {!data.authorized && deleteOwner ? (
              <Popconfirm
                title="Delete this owner?"
                description="They will be removed from this Armor instance."
                onConfirm={(e: any) => {
                  e?.stopPropagation?.();
                  deleteOwner(data);
                }}
                okText="Delete"
                okButtonProps={{
                  danger: true
                }}
              >
                <DeleteOutlined style={{ color: '#DC4546' }} onClick={stopPropagation} />
              </Popconfirm>
            ) : null}
            {data.authorized ? (
              <Popover title="This owner has already authorized.">
                <CheckCircleFilled style={{ color: colors.success }} />
              </Popover>
            ) : null}
          </Space>
        }
        style={{ marginBottom: 20 }}
      >
        <div>
          {data.authorized ? (
            <div style={{ marginBottom: 10 }}>
              <strong>This is a safe owner who has already authorized this account.</strong>
            </div>
          ) : null}
          {/* {data.email ? <a href={`mailto:${data.email}`}>{data.email}</a> : null} */}
          <div>
            <MiddleEllipses>
              {data.address ? <MonoSpaceSpan>{data.address}</MonoSpaceSpan> : 'No address provided'}
              {data.address && allowAddressCopy ? (
                <StyledCopyButton
                  onClick={() => copyAddress(data.address)}
                  shape="circle"
                  type="text"
                  style={{
                    backgroundColor: 'transparent',
                    color: colors.placeholder,
                    marginLeft: 5
                  }}
                >
                  <CopyOutlined style={{ color: colors.grey3 }} />
                </StyledCopyButton>
              ) : null}
            </MiddleEllipses>
          </div>
        </div>
      </Panel>
    </Collapse>
  );
}

const StyledCopyButton = styled(Button)`
  > * {
    > * {
      transition: all 0.4s ease;
    }
  }
  :hover {
    > * {
      > * {
        color: ${colors.primary};
      }
    }
  }
`;

export default ArmorOwnerListItem;
