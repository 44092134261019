import { Empty } from 'antd';
import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

interface EmptyStateProps {
  description: string;
  style?: CSSProperties;
  children?: React.ReactElement;
  image?: ReactNode;
  imageStyle?: React.CSSProperties;
}

function EmptyState({
  description,
  style,
  children,
  image = Empty.PRESENTED_IMAGE_SIMPLE,
  imageStyle
}: EmptyStateProps) {
  return (
    <StyledEmpty description={description} image={image} style={style} imageStyle={imageStyle}>
      {children}
    </StyledEmpty>
  );
}

export default EmptyState;

const StyledEmpty = styled(Empty)<{ theme: 'dark' | 'light' }>`
  > .ant-empty-description {
    color: ${({ theme }) => (theme === 'light' ? '#FFFFFF40' : '#EDEDEF')};
    font-size: 16px;
    padding: 0 10%;
    font-weight: normal;
  }
`;
