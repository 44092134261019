import { Button, Select, Steps, Alert, message } from 'antd';
import { useCallback, useContext, useMemo, useState } from 'react';
import * as Eulith from 'eulith-web3js';
import EulithCard from '../components/EulithCard';
import {
  MetamaskActions,
  MetaMaskContext,
  MetaMaskProvider
} from '../features/snap/MetaMaskContext';
import { connectSnap, eulithSnapId, getSnap } from '../features/snap/utils';
import { useAppSelector } from '../hooks/redux';
import { selectAccessToken } from '../features/auth/authSlice';
import EulithHelmetConsumer from '../components/EulithHelmetConsumer';
import { chainIdToNetworkName } from '../utils/networks';
import { plural } from '../utils/data';
import { isDevelopmentEnv } from '../api/common';
import BugsnagManager from '../BugsnagManager';
import { CheckCircleFilled, CheckCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { H2, H3, Text } from '../styles/shared';
import { selectWhitelistsData } from '../features/whitelist/whitelistService';
import { ConnectWallet, useConnectionStatus } from '@thirdweb-dev/react';

const eulithLogo = require('../assets/images/eulith-logo.png');
const metamaskLogo = require('../assets/images/metamask-logo.png');

export default function MetaMaskPage() {
  return (
    <>
      <EulithHelmetConsumer>MetaMask Snaps</EulithHelmetConsumer>
      <MetaMaskProvider>
        <Inner />
      </MetaMaskProvider>
    </>
  );
}

function Inner() {
  const [state, dispatch] = useContext(MetaMaskContext);
  const connectionStatus = useConnectionStatus();

  function getCurrentStep() {
    if (connectionStatus !== 'connected') {
      return 0;
    } else if (!state.installedSnap) {
      return 1;
    } else {
      return 2;
    }
  }

  const currentStep = getCurrentStep();

  const installSnap = useCallback(async () => {
    try {
      await connectSnap(eulithSnapId());
      const installedSnap = await getSnap();
      dispatch({
        type: MetamaskActions.SetInstalled,
        payload: installedSnap
      });
    } catch (error: any) {
      BugsnagManager.notify(error, {
        context: 'Unable to install snap in install view'
      });
      console.error(error);
      dispatch({ type: MetamaskActions.SetError, payload: error });
    }
  }, [dispatch]);

  const steps = useMemo(() => {
    return [
      {
        title: (
          <StyledStepTitleContainer enabled={connectionStatus !== 'connected'}>
            <StyledImage src={metamaskLogo} />
            Connect Wallet
          </StyledStepTitleContainer>
        ),
        icon: connectionStatus === 'connected' ? <CheckCircleFilled /> : <CheckCircleOutlined />,
        description: (
          <div>
            <div style={{ marginBottom: 20 }}>
              You must connect your MetaMask wallet to continue.
            </div>
            {connectionStatus === 'connected' ? (
              <Alert
                style={{ marginTop: 20, marginBottom: 20, width: 130 }}
                type="success"
                showIcon
                message="Connected!"
              />
            ) : (
              <ConnectWallet style={{ marginBottom: 20 }} theme="dark" />
            )}
          </div>
        )
      },
      {
        title: (
          <StyledStepTitleContainer enabled={currentStep === 1}>
            <StyledImage src={eulithLogo} />
            Install Eulith MetaMask Plugin
          </StyledStepTitleContainer>
        ),
        icon: state.installedSnap ? <CheckCircleFilled /> : <CheckCircleOutlined />,
        description: (
          <div>
            <div style={{ marginBottom: 20 }}>
              Once you have connected your wallet, you can install the Eulith MetaMask Plugin.
            </div>
            {state.installedSnap ? (
              <Alert
                type="info"
                style={{ maxWidth: 450 }}
                showIcon
                description={
                  <span>
                    Snap ID: <code>{eulithSnapId()}</code>
                  </span>
                }
              />
            ) : null}
            {state.error && currentStep === 1 ? (
              <Alert
                type="error"
                showIcon
                style={{ marginTop: 20, marginBottom: 20 }}
                message="An error happened"
                description={state.error.message}
              />
            ) : null}
            {state.installedSnap ? (
              <Alert
                style={{ marginTop: 20, marginBottom: 20, width: 110 }}
                type="success"
                showIcon
                message="Installed!"
              />
            ) : currentStep === 1 && connectionStatus === 'connected' ? (
              <Button style={{ marginBottom: 20 }} type="primary" onClick={installSnap}>
                Install Eulith MetaMask Plugin
              </Button>
            ) : null}
          </div>
        )
      },
      {
        title: (
          <StyledStepTitleContainer enabled={currentStep === 2}>
            <StyledImage src={metamaskLogo} />
            Connect a Eulith Whitelist
          </StyledStepTitleContainer>
        ),
        icon:
          connectionStatus === 'connected' && state.installedSnap ? (
            <CheckCircleFilled />
          ) : (
            <CheckCircleOutlined />
          ),
        description: (
          <div>
            <div style={{ marginBottom: 20 }}>
              Once the Eulith MetaMask plugin is registered with MetaMask, you can choose which
              whitelist you would like to use. If you do not select a whitelist from the list below,
              then transactions will be screened against Eulith&apos;s default global whitelist
              only.
            </div>
            {state.error && currentStep === 2 ? (
              <Alert
                type="error"
                showIcon
                style={{ marginTop: 20 }}
                message="An error happened"
                description={state.error.message}
              />
            ) : null}
            {connectionStatus === 'connected' && state.installedSnap ? <ConnectView /> : null}
          </div>
        )
      }
    ];
  }, [currentStep, state.error, state.installedSnap, connectionStatus, installSnap]);

  return (
    <EulithCard
      title="MetaMask Snaps Integration"
      fullHeight
      bodyStyle={{ height: 'calc(100% - 80px)' }}
      style={{ height: '100%' }}
    >
      <div>
        <StyledHeaderImageContainer>
          <img src={eulithLogo} style={{ width: 60, height: 60 }} />
          <StyledSlash>/</StyledSlash>
          <img src={metamaskLogo} style={{ width: 60, height: 60 }} />
        </StyledHeaderImageContainer>
        <StyledCopyContainer>
          <H2 style={{ marginBottom: 20 }}>Eulith & MetaMask Snaps</H2>
          <Text>
            DeFi Armor is used by professional traders and hedge funds to secure their transaction
            activity on-chain. This MetaMask snap brings industry-leading deep transaction
            inspection straight to your favorite wallet. Learn more about MetaMask Snaps{' '}
            <a href="https://metamask.io/snaps/" target="_blank" rel="noreferrer">
              here
            </a>
            .
          </Text>
        </StyledCopyContainer>
      </div>
      <br />
      <StyledTitle>Getting Started</StyledTitle>
      <Steps
        style={{ paddingBottom: 20 }}
        direction="vertical"
        current={currentStep}
        items={steps}
      />
    </EulithCard>
  );
}

function ConnectView() {
  const [whitelistId, setWhitelistId] = useState<number | null>(null);
  const accessToken = useAppSelector(selectAccessToken);
  const whitelists = useAppSelector(selectWhitelistsData);

  const filteredWhitelists = useMemo(() => {
    const whitelistsWithNetworks = [];
    for (const whitelist of whitelists) {
      if (whitelist.isDraft) {
        continue;
      }

      const networksMap = new Map();
      for (const addressOnChain of whitelist.sortedAddresses) {
        const countBefore = networksMap.get(addressOnChain.chainId) ?? 0;
        networksMap.set(addressOnChain.chainId, countBefore + 1);
      }

      const networks = [];
      for (const [chainId, count] of networksMap.entries()) {
        networks.push({
          name: chainIdToNetworkName[chainId] ?? 'unknown network',
          count
        });
      }

      whitelistsWithNetworks.push({
        ...whitelist,
        networks
      });
      return whitelistsWithNetworks;
    }
  }, [whitelists]);

  async function connectToSnap() {
    await window.ethereum.request({
      method: 'wallet_invokeSnap',
      params: {
        snapId: eulithSnapId(),
        request: {
          method: 'eulith_snapSetAccount',
          params: [
            {
              token: accessToken,
              whitelistId,
              eulithDomain: isDevelopmentEnv ? 'localhost:7777' : 'eulithrpc.com'
            }
          ]
        }
      }
    });
    message.success('Sent to the plugin!');
  }

  return (
    <div>
      {filteredWhitelists ? (
        <Select
          onChange={setWhitelistId}
          value={whitelistId}
          placeholder="Select Whitelist"
          style={{ width: 200 }}
        >
          {filteredWhitelists.map((whitelist: any) => (
            <Select.Option key={whitelist.listId} value={whitelist.listId}>
              {whitelist.displayName} {/* &ndash; {addressCount(whitelist)} */}
            </Select.Option>
          ))}
        </Select>
      ) : (
        <p>No whitelists found.</p>
      )}
      <div>
        <Button
          disabled={!whitelistId}
          type="primary"
          size="large"
          onClick={connectToSnap}
          style={{ marginTop: 20 }}
        >
          Connect Whitelist
        </Button>
      </div>
    </div>
  );
}

const StyledImage = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

const StyledStepTitleContainer = styled.div<{ enabled: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  opacity: ${({ enabled }) => (enabled ? 1 : 0.45)};
`;

const StyledTitle = styled(H3)`
  margin-bottom: 10px;
`;

const StyledHeaderImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  user-select: none;
`;

const StyledSlash = styled.div`
  font-size: 50px;
  opacity: 0.75;
  font-weight: 100;
  margin: 0 20px;
`;

const StyledCopyContainer = styled.div`
  text-align: center;
  max-width: 650px;
  margin: auto;
`;
