import {
  ApartmentOutlined,
  AuditOutlined,
  BankOutlined,
  CalendarOutlined,
  ContactsOutlined,
  DatabaseOutlined,
  DollarOutlined,
  EditOutlined,
  ExceptionOutlined,
  FieldTimeOutlined,
  FileSearchOutlined,
  FilterOutlined,
  FormOutlined,
  FundOutlined,
  GlobalOutlined,
  IdcardOutlined,
  LinkOutlined,
  MailOutlined,
  PhoneOutlined,
  ProfileOutlined,
  PushpinOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  SecurityScanOutlined,
  SnippetsOutlined,
  TagsOutlined,
  TeamOutlined
} from '@ant-design/icons';
import { Checkbox, Form, FormInstance, Input, InputNumber, Radio, Select } from 'antd';
import FormRow from '../components/FormRow';
import FundFormDatePicker from '../components/FundsForm/FundFormDatePicker';
import FundFormManagers from '../components/FundsForm/FundFormManagers';
import FundFormUploader from '../components/FundsForm/FundFormUploader';
import PhoneInput from '../components/PhoneInput';
import URLInput from '../components/URLInput';
import type {
  BuildFieldForTypeProps,
  BuildFundFormConfigProps,
  FundFormSection,
  FundFormSectionField,
  FundFormSectionFieldType,
  FundFormValues,
  ReturnFieldComponentProps
} from '../features/funds/fundsTypes';
import { antdFormConfig } from '../styles/shared';
import { disabledDateAfter, disabledDateBefore, dollarFormatter, dollarParser } from './data';
import { states } from './states';

export const BASE_FUNDS_CREATE_FORM_ROOT = '/funds/create';
export const BASE_FUNDS_EDIT_FORM_ROOT = '/funds/edit';

const DEFAULT_REQUIRED_MESSAGE = 'Please add a response.';

export const buildFundFormConfig = ({ title }: BuildFundFormConfigProps): FundFormSection[] => [
  {
    title: "Let's get started",
    path: 'start',
    showStep: false,
    subtitle: `Here is where you can provide basic information about ${title || 'your fund'}.`,
    icon: <ProfileOutlined />,
    quickDescription: 'Provide basic information',
    fields: [
      {
        type: 'text',
        name: 'title',
        label: 'Name',
        maxLength: 255,
        icon: <EditOutlined />,
        requiredMessage: 'Please provide a name.',
        placeholder: 'Add a name...',
        defaultValue: '',
        formItemStyle: { width: '100%' },
        formItemWrapperCol: { span: 24 },
        required: true,
        size: 'large'
      },
      {
        type: 'upload',
        name: 'logoFile',
        maxCount: 1,
        label: 'Logo',
        accept: 'image/png,image/jpeg,image/jpg',
        requiredMessage: 'Please provide a logo.',
        placeholder: 'Add a logo...',
        defaultValue: '',
        required: true,
        allowHideFromListing: false
      }
    ]
  },
  {
    title: 'Overview',
    path: 'overview',
    showStep: true,
    subtitle: `Here is where you can provide basic information about ${title || 'your fund'}.`,
    icon: <FormOutlined />,
    quickDescription: 'Provide basic information',
    fields: [
      {
        type: 'textarea',
        name: 'summary',
        label: 'Summary',
        icon: <GlobalOutlined />,
        requiredMessage: 'Please add a summary.',
        placeholder: 'Enter a summary...',
        defaultValue: '',
        required: true
      },
      {
        type: 'url',
        name: 'website',
        label: 'Website',
        maxLength: 255,
        icon: <LinkOutlined />,
        requiredMessage: 'Please add a website.',
        placeholder: 'Enter a website...',
        defaultValue: '',
        required: false,
        addonBefore: 'https://'
      },
      {
        type: 'date',
        requiredType: 'date',
        name: 'launchDate',
        label: 'Fund Launch Date',
        icon: <CalendarOutlined />,
        requiredMessage: 'Please enter a launch date.',
        placeholder: 'Ongoing',
        defaultValue: '',
        required: false,
        disabledDate: disabledDateAfter
      },
      {
        type: 'text',
        name: 'managers',
        label: 'Managers',
        icon: <TeamOutlined />,
        requiredMessage: 'Please add at least one manager.',
        placeholder: 'Add a Manager',
        defaultValue: '',
        required: true,
        size: 'large',
        fields: [
          {
            type: 'text',
            name: 'firstName',
            label: 'First Name',
            maxLength: 255,
            requiredMessage: 'Please enter a first name.',
            placeholder: 'Enter a first name...',
            defaultValue: '',
            required: true
          },
          {
            type: 'text',
            name: 'lastName',
            label: 'Last Name',
            maxLength: 255,
            requiredMessage: 'Please enter a last name.',
            placeholder: 'Enter a last name...',
            defaultValue: '',
            required: true
          },
          {
            type: 'text',
            name: 'email',
            label: 'Email',
            maxLength: 255,
            requiredType: 'email',
            requiredMessage: 'Please enter an email.',
            placeholder: 'Enter an email...',
            defaultValue: '',
            required: true
          },
          {
            type: 'text',
            name: 'title',
            label: 'Title',
            maxLength: 255,
            requiredMessage: 'Please enter a title.',
            placeholder: 'Enter a title...',
            defaultValue: '',
            required: true
          },
          {
            type: 'textarea',
            name: 'summary',
            label: 'Summary',
            requiredMessage: 'Please enter a summary.',
            placeholder: 'Enter a summary...',
            defaultValue: '',
            required: false
          }
        ]
      },
      {
        type: 'number',
        name: 'historicalPeakAUM',
        label: 'Historical Peak AUM',
        formatter: dollarFormatter,
        parser: dollarParser,
        requiredType: 'number',
        maxLength: 255,
        icon: <DollarOutlined />,
        requiredMessage: 'Please add a historical peak AUM.',
        placeholder: 'Enter a historical peak AUM...',
        defaultValue: '',
        required: false
      },
      {
        type: 'number',
        name: 'currentAUM',
        label: 'Current AUM',
        formatter: dollarFormatter,
        parser: dollarParser,
        requiredType: 'number',
        maxLength: 255,
        icon: <DollarOutlined />,
        requiredMessage: 'Please enter a current AUM.',
        placeholder: 'Enter a current AUM...',
        defaultValue: '',
        required: false
      },
      {
        type: 'number',
        name: 'targetAUM',
        label: 'Target AUM',
        formatter: dollarFormatter,
        parser: dollarParser,
        requiredType: 'number',
        maxLength: 255,
        icon: <DollarOutlined />,
        requiredMessage: 'Please enter a target AUM.',
        placeholder: 'Enter a target AUM...',
        defaultValue: '',
        required: false
      },
      {
        type: 'date',
        requiredType: 'date',
        name: 'closingDate',
        label: 'Closing Date',
        icon: <CalendarOutlined />,
        requiredMessage: 'Please enter a closing date.',
        placeholder: 'Ongoing',
        defaultValue: '',
        required: false,
        disabledDate: disabledDateBefore
      },
      {
        type: 'select',
        name: 'fundType',
        label: 'Fund Type',
        icon: <FilterOutlined />,
        requiredMessage: 'Please enter a fund type.',
        placeholder: 'Fund type option...',
        defaultValue: '',
        required: false,
        options: getFundTypes()
      },
      {
        type: 'text',
        name: 'fundDomicile',
        label: 'Fund Domicile',
        maxLength: 255,
        icon: <GlobalOutlined />,
        requiredMessage: 'Please enter a fund domicile.',
        placeholder: 'Fund domicile...',
        defaultValue: '',
        required: false
      },
      {
        type: 'number',
        name: 'minimumCommitment',
        label: 'Minimum Commitment',
        formatter: dollarFormatter,
        parser: dollarParser,
        requiredType: 'number',
        maxLength: 255,
        icon: <FieldTimeOutlined />,
        placeholder: 'Minimum commitment...',
        defaultValue: '',
        requiredMessage: 'Please add your minimum commitment.',
        required: false
      },
      {
        type: 'radio',
        name: 'offeredToUSInvestors',
        icon: <QuestionCircleOutlined />,
        label: 'Is your fund offered to US investors?',
        placeholder: '',
        defaultValue: '',
        requiredMessage: 'Please indicate whether your fund is offered to US investors.',
        required: false,
        requiredType: 'boolean',
        options: [
          {
            value: true,
            label: 'Yes'
          },
          {
            value: false,
            label: 'No'
          }
        ]
      },
      {
        type: 'select',
        name: 'fundAttributes',
        mode: 'tags',
        icon: <TagsOutlined />,
        label: 'Fund Attributes',
        placeholder: 'Add fund attributes...',
        defaultValue: '',
        requiredMessage: 'Please add at least one attribute.',
        required: false,
        requiredType: 'array',
        options: getFundAttributes()
      },
      {
        type: 'upload',
        name: 'factsheetFile',
        label: 'Fact Sheet',
        accept: 'application/pdf',
        placeholder: 'Add a fact sheet file...',
        defaultValue: '',
        requiredMessage: 'Please add a face sheet file.',
        required: false
      },
      {
        type: 'upload',
        name: 'fundDeckFile',
        label: 'Fund Deck',
        accept: 'application/pdf',
        placeholder: 'Add a fund deck file...',
        defaultValue: '',
        requiredMessage: 'Please add a fund deck file.',
        required: false
      },
      {
        type: 'upload',
        name: 'supplementalDocumentsFile',
        label: 'Supplemental Documents',
        accept: 'application/pdf',
        placeholder: 'Add supplemental documents...',
        defaultValue: '',
        requiredMessage: 'Please add supplemental documents.',
        required: false,
        extra:
          'Note: These documents will be publicly visible. You should not upload any documents you wish to reserve for later stage discussions.'
      }
    ]
  },
  {
    title: 'Performance Metrics & Comparisons',
    path: 'performance-metrics-comparison',
    showStep: true,
    quickDescription: 'Performance Metrics and Comparisons',
    icon: <FundOutlined />,
    subtitle: 'Here you can add performance metrics and comparisons.',
    fields: [
      {
        type: 'textarea',
        name: 'performanceMetricsAndComparisons',
        label: 'Performance Metrics and Comparisons',
        icon: <FundOutlined />,
        placeholder: 'Add performance notes...',
        required: false,
        requiredMessage: 'Please add notes on performance metrics and comparisons.',
        defaultValue: ''
      }
    ]
  },
  {
    title: 'Risk Analysis',
    path: 'risk-analysis',
    showStep: true,
    quickDescription: 'Risk Analysis Description',
    icon: <SecurityScanOutlined />,
    subtitle: 'Here you can add risk analysis notes.',
    fields: [
      {
        type: 'upload',
        name: 'riskAnalysisFinancialFile',
        label: 'Financial',
        accept: 'application/pdf',
        placeholder: 'Add a financial risk analysis file...',
        required: false,
        requiredMessage: 'Please a financial risk analysis file.',
        defaultValue: ''
      },
      {
        type: 'upload',
        name: 'riskAnalysisOperationalFile',
        label: 'Operational',
        accept: 'application/pdf',
        placeholder: 'Add an operational risk analysis file...',
        required: false,
        requiredMessage: 'Please add an operational risk analysis file.',
        defaultValue: ''
      },
      {
        type: 'upload',
        name: 'riskAnalysisSecurityFile',
        label: 'Security',
        accept: 'application/pdf',
        placeholder: 'Add a security risk analysis file...',
        required: false,
        requiredMessage: 'Please add a security risk analysis file.',
        defaultValue: ''
      }
    ]
  },
  {
    title: 'Investor Terms',
    path: 'investor-terms',
    showStep: true,
    quickDescription: 'Investor Terms Description',
    icon: <ReadOutlined />,
    subtitle: 'Here you can add investor terms.',
    fields: [
      {
        type: 'text',
        name: 'managementFee',
        label: 'Management Fee',
        suffix: '%',
        maxLength: 255,
        icon: <DollarOutlined />,
        placeholder: 'Add a management fee...',
        required: false,
        requiredMessage: 'Please add a management fee.',
        defaultValue: ''
      },
      {
        type: 'text',
        name: 'performanceFee',
        label: 'Performance Fee',
        suffix: '%',
        maxLength: 255,
        icon: <DollarOutlined />,
        placeholder: 'Add a performance fee...',
        required: false,
        requiredMessage: 'Please add a performance fee.',
        defaultValue: ''
      },
      {
        type: 'text',
        name: 'subscriptionsFee',
        label: 'Subscriptions Fee',
        suffix: '%',
        maxLength: 255,
        icon: <DollarOutlined />,
        placeholder: 'Add a subscriptions fee...',
        required: false,
        requiredMessage: 'Please add a subscriptions fee.',
        defaultValue: ''
      },
      {
        type: 'text',
        name: 'redemptionsFee',
        label: 'Redemptions Fee',
        suffix: '%',
        maxLength: 255,
        icon: <DollarOutlined />,
        placeholder: 'Add a redemptions fee...',
        required: false,
        requiredMessage: 'Please add a redemptions fee.',
        defaultValue: ''
      }
    ]
  },
  {
    title: 'Relationships, Providers, and Infrastructure',
    path: 'relationships-providers-infrastructure',
    subtitle: 'Add information about what makes your fund run.',
    showStep: true,
    icon: <BankOutlined />,
    quickDescription: 'Add information about what makes your fund run.',
    fields: [
      {
        type: 'text',
        name: 'bankName',
        label: 'Bank Name',
        maxLength: 255,
        icon: <BankOutlined />,
        placeholder: 'Add a bank name...',
        defaultValue: '',
        requiredMessage: 'Please provide a bank name.',
        required: false
      },
      {
        type: 'text',
        name: 'fundAdministratorName',
        label: 'Fund Administrator Name',
        maxLength: 255,
        icon: <BankOutlined />,
        placeholder: 'Add a fund administrator name...',
        defaultValue: '',
        requiredMessage: 'Please provide a fund administrator name.',
        required: false
      },
      {
        type: 'text',
        name: 'fundAdministratorAddressLine1',
        label: 'Fund Administrator Address 1',
        maxLength: 255,
        icon: <PushpinOutlined />,
        placeholder: 'Add a fund administrator address line 1...',
        defaultValue: '',
        requiredMessage: 'Please provide a fund administrator address line 1.',
        required: false
      },
      {
        type: 'text',
        name: 'fundAdministratorAddressLine2',
        label: 'Fund Administrator Address 2',
        maxLength: 255,
        icon: <PushpinOutlined />,
        placeholder: 'Add a fund administrator address line 2...',
        defaultValue: '',
        requiredMessage: 'Please provide a fund administrator address line 2.',
        required: false
      },
      [
        {
          type: 'text',
          name: 'fundAdministratorAddressCity',
          label: 'Fund Administrator City',
          maxLength: 255,
          icon: <PushpinOutlined />,
          placeholder: 'Add a fund administrator city...',
          defaultValue: '',
          requiredMessage: 'Please provide a fund administrator city.',
          required: false
        },
        {
          type: 'select',
          name: 'fundAdministratorAddressState',
          label: 'Fund Administrator State',
          icon: <PushpinOutlined />,
          placeholder: 'Add a fund administrator state...',
          defaultValue: '',
          requiredMessage: 'Please provide a fund administrator state.',
          required: false,
          options: states
        },
        {
          type: 'text',
          name: 'fundAdministratorAddressZipCode',
          label: 'Fund Administrator Zip Code',
          maxLength: 255,
          icon: <PushpinOutlined />,
          placeholder: 'Add a fund administrator zip code...',
          defaultValue: '',
          requiredMessage: 'Please provide a fund administrator zip code.',
          required: false
        }
      ],
      {
        type: 'text',
        name: 'fundAdministratorEmail',
        label: 'Fund Administrator Email',
        maxLength: 255,
        requiredType: 'email',
        icon: <MailOutlined />,
        placeholder: 'Add a fund administrator email...',
        defaultValue: '',
        requiredMessage: 'Please provide a valid fund administrator email.',
        required: false
      },
      {
        type: 'text',
        name: 'legalCounselName',
        label: 'Legal Counsel Name',
        maxLength: 255,
        icon: <BankOutlined />,
        placeholder: 'Add a legal counsel name...',
        defaultValue: '',
        requiredMessage: 'Please provide a legal counsel name.',
        required: false
      },
      {
        type: 'text',
        name: 'financialAuditorName',
        label: 'Financial Auditor Name',
        maxLength: 255,
        icon: <BankOutlined />,
        placeholder: 'Add a financial auditor name...',
        defaultValue: '',
        requiredMessage: 'Please provide a financial auditor name.',
        required: false
      },
      {
        type: 'text',
        name: 'accountantName',
        label: 'Accountant Name',
        maxLength: 255,
        icon: <BankOutlined />,
        placeholder: 'Add a accountant name...',
        defaultValue: '',
        requiredMessage: 'Please provide a accountant name.',
        required: false
      },
      {
        type: 'text',
        name: 'primeBrokerName',
        label: 'Prime Broker Name',
        maxLength: 255,
        icon: <BankOutlined />,
        placeholder: 'Add a prime broker name...',
        defaultValue: '',
        requiredMessage: 'Please provide a prime broker name.',
        required: false
      },
      {
        type: 'text',
        name: 'complianceName',
        label: 'Compliance Name',
        maxLength: 255,
        icon: <BankOutlined />,
        placeholder: 'Add a compliance name...',
        defaultValue: '',
        requiredMessage: 'Please provide a compliance name.',
        required: false
      },
      {
        type: 'text',
        name: 'custodianNames',
        label: 'Custodian Name(s)',
        maxLength: 255,
        icon: <BankOutlined />,
        placeholder: 'Add a custodian name...',
        defaultValue: '',
        requiredMessage: 'Please provide at least one custodian name.',
        required: false
      },
      {
        type: 'text',
        name: 'pmsSystems',
        label: 'PMS System(s)',
        maxLength: 255,
        icon: <DatabaseOutlined />,
        placeholder: 'Add a PMS system...',
        defaultValue: '',
        requiredMessage: 'Please provide a PSM system.',
        required: false
      },
      {
        type: 'text',
        name: 'oemsSystems',
        label: 'OEMS System(s)',
        maxLength: 255,
        icon: <DatabaseOutlined />,
        placeholder: 'Add an OEMS system...',
        defaultValue: '',
        requiredMessage: 'Please provide an OEMS system.',
        required: false
      },
      {
        type: 'text',
        name: 'thirdPartyDiligenceReports',
        label: '3rd Party Diligence Reports',
        maxLength: 255,
        icon: <FileSearchOutlined />,
        placeholder: 'Add 3rd party diligence reports...',
        defaultValue: '',
        requiredMessage: 'Please provide 3rd party diligence reports.',
        required: false
      },
      {
        type: 'text',
        name: 'otherServiceProviders',
        label: 'Other Service Providers',
        maxLength: 255,
        icon: <DatabaseOutlined />,
        placeholder: 'Add other service providers...',
        defaultValue: '',
        requiredMessage: 'Please provide any other service providers.',
        required: false
      }
    ]
  },
  {
    title: 'Fund Details',
    path: 'fund-details',
    showStep: true,
    quickDescription: 'Add additional details',
    icon: <ProfileOutlined />,
    subtitle: 'Here you can add additional details.',
    fields: [
      {
        type: 'text',
        name: 'legalName',
        label: 'Fund Legal Name',
        maxLength: 255,
        icon: <GlobalOutlined />,
        placeholder: 'Add a legal name...',
        required: false,
        requiredMessage: 'Please add a legal name.',
        defaultValue: ''
      },
      {
        type: 'text',
        name: 'addressLine1',
        icon: <PushpinOutlined />,
        maxLength: 255,
        label: 'Fund Address Line 1',
        placeholder: 'Add an address line 1...',
        required: false,
        requiredMessage: 'Please add an address line 1.',
        defaultValue: ''
      },
      {
        type: 'text',
        name: 'addressLine2',
        icon: <PushpinOutlined />,
        label: 'Fund Address Line 2',
        maxLength: 255,
        placeholder: 'Add an address line 2...',
        required: false,
        requiredMessage: 'Please add an address line 2.',
        defaultValue: ''
      },
      [
        {
          type: 'text',
          name: 'addressCity',
          icon: <PushpinOutlined />,
          maxLength: 255,
          label: 'Fund Address City',
          placeholder: 'Add an address city...',
          required: false,
          requiredMessage: 'Please add an address city.',
          defaultValue: ''
        },
        {
          type: 'select',
          name: 'addressState',
          icon: <PushpinOutlined />,
          label: 'Fund Address State',
          placeholder: 'Add an address state...',
          required: false,
          requiredMessage: 'Please add an address state.',
          defaultValue: '',
          options: states
        },
        {
          type: 'text',
          name: 'addressZipCode',
          icon: <PushpinOutlined />,
          maxLength: 255,
          label: 'Fund Address Zip Code',
          placeholder: 'Add an address zip code...',
          required: false,
          requiredMessage: 'Please add an address zip code.',
          defaultValue: ''
        }
      ],
      {
        type: 'text',
        name: 'taxIdentifier',
        label: 'EIN/TIN',
        icon: <IdcardOutlined />,
        maxLength: 255,
        placeholder: 'Add an EIN/TIN...',
        required: false,
        requiredMessage: 'Please add an EIN/TIN.',
        defaultValue: ''
      },
      {
        type: 'text',
        name: 'legalStructure',
        label: 'Legal Structure',
        icon: <ApartmentOutlined />,
        maxLength: 255,
        placeholder: 'Add a legal structure...',
        required: false,
        requiredMessage: 'Please add a legal structure.',
        defaultValue: ''
      },
      {
        type: 'upload',
        name: 'policiesAndProceduresFile',
        label: 'Policies and Procedures',
        accept: 'application/pdf',
        placeholder: 'Add a policies and procedures file...',
        required: false,
        requiredMessage: 'Please add a policies and procedures file.',
        defaultValue: ''
      },
      {
        type: 'upload',
        name: 'offeringFile',
        label: 'Offering',
        accept: 'application/pdf',
        placeholder: 'Add an offering file...',
        required: false,
        requiredMessage: 'Please add an offering file.',
        defaultValue: ''
      },
      {
        type: 'upload',
        name: 'FAQFile',
        label: 'FAQ',
        accept: 'application/pdf',
        placeholder: 'Add a FAQ file...',
        required: false,
        requiredMessage: 'Please add a FAQ file.',
        defaultValue: ''
      }
    ]
  },
  {
    title: 'Disclaimers',
    path: 'disclaimers',
    showStep: true,
    quickDescription: 'Add disclaimers',
    icon: <ExceptionOutlined />,
    subtitle: 'Here you can add disclaimers.',
    fields: [
      {
        type: 'upload',
        accept: 'application/pdf',
        name: 'disclaimerFile',
        label: 'Disclaimers',
        placeholder: 'Add a disclaimers file...',
        required: false,
        requiredMessage: 'Please add a disclaimers file.',
        defaultValue: ''
      }
    ]
  },
  {
    title: 'Contact Information',
    path: 'contact-information',
    showStep: true,
    quickDescription: 'Add contact information',
    icon: <ContactsOutlined />,
    subtitle: 'Here you can add contact information.',
    fields: [
      {
        type: 'text',
        name: 'contactName',
        label: 'Contact Name',
        maxLength: 255,
        icon: <ContactsOutlined />,
        placeholder: 'Add a contact name...',
        required: false,
        requiredMessage: 'Please add a contact name.',
        defaultValue: ''
      },
      {
        type: 'text',
        name: 'contactEmail',
        label: 'Contact Email',
        maxLength: 255,
        requiredType: 'email',
        icon: <MailOutlined />,
        placeholder: 'Add a contact email...',
        required: false,
        requiredMessage: 'Please provide a valid contact email.',
        defaultValue: ''
      },
      {
        type: 'phone',
        name: 'contactPhoneNumber',
        label: 'Contact Phone Number',
        maxLength: 14,
        len: 14,
        icon: <PhoneOutlined />,
        placeholder: 'Add a contact phone number...',
        required: false,
        requiredMessage: 'Please add a valid phone number.',
        defaultValue: ''
      }
    ]
  },
  {
    title: 'Additional Material',
    path: 'additional-material',
    showStep: true,
    quickDescription: 'Add additional material',
    icon: <SnippetsOutlined />,
    subtitle: 'Here you can add additional material.',
    fields: [
      {
        type: 'textarea',
        name: 'additionalNotes',
        label: 'Additional Notes',
        icon: <SnippetsOutlined />,
        placeholder: 'Add additional notes...',
        required: false,
        requiredMessage: 'Please add additional notes.',
        defaultValue: ''
      },
      {
        type: 'upload',
        name: 'additionalDocumentsFile',
        accept: 'application/pdf',
        label: 'Additional Documents',
        placeholder: 'Add additional documents...',
        required: false,
        requiredMessage: 'Please add additional documents.',
        defaultValue: ''
      }
    ]
  },
  {
    title: 'Review',
    path: 'review',
    showStep: true,
    icon: <AuditOutlined />,
    quickDescription: 'Review and submit your fund',
    fields: [],
    subtitle:
      'Take a look below and make sure everything looks correct before submitting. You can revisit any section and make edits if necessary.'
  }
];

export function buildFieldForType({
  form,
  field,
  fundId,
  setData,
  setLoadingFile,
  onRemoveFile
}: BuildFieldForTypeProps) {
  if (Array.isArray(field)) {
    return (
      <FormRow id="fund_form_row">
        {field.map((f: FundFormSectionField) => {
          return returnFieldComponent({
            form,
            field: f,
            fundId,
            setData,
            setLoadingFile,
            onRemoveFile
          });
        })}
      </FormRow>
    );
  } else {
    return returnFieldComponent({
      form,
      field,
      fundId,
      setData,
      setLoadingFile,
      onRemoveFile
    });
  }
}

function returnFieldComponent({
  form,
  field,
  fundId,
  setData,
  setLoadingFile,
  onRemoveFile
}: ReturnFieldComponentProps) {
  let component = null;
  switch (field.type as FundFormSectionFieldType) {
    case 'phone':
      component = (
        <PhoneInput
          addonBefore={field.addonBefore}
          prefix={field.prefix}
          suffix={field.suffix}
          placeholder={field.placeholder}
          size={field.size}
          maxLength={field.maxLength}
        />
      );
      break;
    case 'url':
      component = (
        <URLInput
          addonBefore={field.addonBefore}
          prefix={field.prefix}
          suffix={field.suffix}
          placeholder={field.placeholder}
          size={field.size}
          maxLength={field.maxLength}
        />
      );
      break;
    case 'text':
      component = (
        <Input
          addonBefore={field.addonBefore}
          prefix={field.prefix}
          suffix={field.suffix}
          placeholder={field.placeholder}
          size={field.size}
          maxLength={field.maxLength}
        />
      );
      break;
    case 'number':
      component = (
        <InputNumber
          style={{ minWidth: 275 }}
          addonBefore={field.addonBefore}
          prefix={field.prefix}
          formatter={field.formatter}
          parser={field.parser}
          suffix={field.suffix}
          placeholder={field.placeholder}
          size={field.size}
          maxLength={field.maxLength}
        />
      );
      break;
    case 'textarea':
      component = <Input.TextArea style={{ minHeight: '25vh' }} placeholder={field.placeholder} />;
      break;
    case 'radio':
      component = (
        <Radio.Group>
          {field.options?.map?.((option) => {
            return (
              <Radio key={`field_${field.name}_radio_${option.value}`} value={option.value}>
                {option.label}
              </Radio>
            );
          }) || null}
        </Radio.Group>
      );
      break;
    case 'checkbox':
      return (
        <Form.Item
          key={`fund_form_${field.name}`}
          labelCol={antdFormConfig.labelCol}
          name={field.name}
          label={field.label}
          help={field.help}
          extra={field.extra}
          valuePropName="checked"
          rules={[
            {
              required: field.required,
              message: field.requiredMessage || DEFAULT_REQUIRED_MESSAGE
            }
          ]}
        >
          <Checkbox />
        </Form.Item>
      );
    case 'select':
      component = (
        <Select
          showSearch
          mode={field.mode}
          placeholder={field.placeholder}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          notFoundContent={field.notFoundContent}
          options={field.options}
        />
      );
      break;
    case 'upload':
      return (
        <FundFormUploader
          key={`fund_form_${field.name}`}
          form={form}
          help={field.help}
          extra={field.extra}
          required={field.required}
          accept={field.accept}
          fundId={fundId}
          allowCrop={field.allowCrop}
          onRemove={onRemoveFile}
          setData={setData}
          allowHideFromListing={field.allowHideFromListing}
          setLoadingFile={setLoadingFile}
          name={field.name}
          label={field.label}
          maxCount={field.maxCount}
        />
      );
    case 'date':
      component = (
        <FundFormDatePicker
          form={form}
          setData={setData}
          name={field.name}
          disabledDate={field.disabledDate}
          placeholder={field.placeholder}
        />
      );
      break;
    default:
      console.warn('Unknown input type detected', field.type);
      break;
  }
  if (field.fields) {
    return (
      <Form.Item
        key={`fund_form_${field.name}`}
        labelCol={antdFormConfig.labelCol}
        label={field.label}
        help={field.help}
        extra={field.extra}
        shouldUpdate={(prevValues, curValues) => prevValues[field.name] !== curValues[field.name]}
        rules={[
          {
            required: field.required,
            type: field.requiredType,
            len: field.len,
            message: field.requiredMessage || DEFAULT_REQUIRED_MESSAGE
          }
        ]}
      >
        {({ getFieldValue }) => {
          const values = getFieldValue(field.name);
          return renderFormListItemsBasedOnType(field, values, form, fundId, setData);
        }}
      </Form.Item>
    );
  } else {
    return (
      <Form.Item
        key={`fund_form_${field.name}`}
        labelCol={antdFormConfig.labelCol}
        name={field.name}
        label={field.label}
        help={field.help}
        extra={field.extra}
        rules={[
          {
            required: field.required,
            type: field.requiredType,
            len: field.len,
            message: field.requiredMessage || DEFAULT_REQUIRED_MESSAGE
          }
        ]}
      >
        {component}
      </Form.Item>
    );
  }
}

export function renderFormListItemsBasedOnType(
  field: FundFormSectionField,
  values: any[],
  form: FormInstance,
  fundId: string,
  setData: (values: FundFormValues) => void
) {
  switch (field.name) {
    case 'managers':
      return (
        <FundFormManagers
          field={field}
          setData={setData}
          form={form}
          data={values}
          fundId={fundId}
        />
      );
    default:
      console.warn('Unable to render list item in form based on field name', field.name);
      return null;
  }
}

export function getFundAttributes() {
  return [
    {
      value: 'On-Chain Execution',
      label: 'On-Chain Execution'
    },
    {
      value: 'MEV',
      label: 'MEV'
    },
    {
      value: 'Systematic Yield (a.k.a. “Yield Farming”)',
      label: 'Systematic Yield (a.k.a. “Yield Farming”)'
    },
    {
      value: 'Directional',
      label: 'Directional'
    },
    {
      value: 'Fully Market Neutral',
      label: 'Fully Market Neutral'
    },
    {
      value: 'Mainly Market Neutral',
      label: 'Mainly Market Neutral'
    },
    {
      value: 'No Centralized Exchange Risk',
      label: 'No Centralized Exchange Risk'
    },
    {
      value: 'Centralized Exchange Execution',
      label: 'Centralized Exchange Execution'
    },
    {
      value: 'Quantitative Trading',
      label: 'Quantitative Trading'
    },
    {
      value: 'Arbitrage',
      label: 'Arbitrage'
    },
    {
      value: 'DeFi',
      label: 'DeFi'
    }
  ];
}

export function getFundTypes() {
  return [
    {
      value: 'Hedge Fund',
      label: 'Hedge Fund'
    },
    {
      value: 'Venture Fund',
      label: 'Venture Fund'
    }
  ];
}
