import { useEffect, useState } from 'react';
import EulithHelmetConsumer from '../components/EulithHelmetConsumer';
import { useSearchParams } from 'react-router-dom';
import ActivateUserForm from '../components/ActivateUser/ActivateUserForm';
import { H1, PageContainer } from '../styles/shared';

const ActivateUserPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [user, setUser] = useState<string>();
  const [salt, setSalt] = useState<string>();
  const [hash, setHash] = useState<string>();

  useEffect(() => {
    const vars = {
      user: searchParams.get('sub') || 'hello',
      salt: searchParams.get('salt') || 'hello',
      hash: searchParams.get('hash') || 'hello'
    };

    setUser(vars.user);
    setSalt(vars.salt);
    setHash(vars.hash);
  }, [searchParams]);

  return (
    <PageContainer>
      <EulithHelmetConsumer>Activate User</EulithHelmetConsumer>
      {user && salt && hash ? (
        <ActivateUserForm user={user} hash={hash} salt={salt} />
      ) : (
        <H1 style={{ textAlign: 'center' }}>Invalid activation link</H1>
      )}
    </PageContainer>
  );
};

export default ActivateUserPage;
