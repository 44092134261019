import React from 'react';
import { Button, Row, Select, Space, message } from 'antd';
import styled from 'styled-components';
import { colors } from '../../styles/shared';
import { CopyOutlined } from '@ant-design/icons';
import { DefaultOptionType } from 'antd/es/select';
import { shortenString } from '../../utils/data';
import MiddleEllipsis from '../MiddleEllipses';
import MonoSpaceSpan from '../MonoSpaceSans';

interface Props {
  label: string;
  enabled?: boolean;
  error?: boolean;
  showCircle?: boolean;
  emptyCopy?: string;
  value?: string | undefined;
  allowCopy?: boolean;
  defaultValue?: any;
  options?: DefaultOptionType[];
  editable?: boolean;
  shorten?: number;
  onChange?: (value: any) => void;
  labelStyle?: React.CSSProperties;
}

const StatusInput: React.FC<Props> = ({
  label,
  enabled,
  error = false,
  value,
  emptyCopy = '',
  defaultValue,
  showCircle = true,
  allowCopy = true,
  options = [],
  editable = true,
  shorten,
  onChange,
  labelStyle = {}
}: Props) => {
  function copyValue() {
    if (value || defaultValue) {
      navigator.clipboard.writeText(value || defaultValue || '');
      message.success('Copied to clipboard!');
    }
  }

  function handleChange(data: any) {
    if (onChange) {
      onChange(data);
    }
  }

  return (
    <Space size="small" direction="vertical">
      <Row align="middle" style={labelStyle}>
        {showCircle ? <StyledDot enabled={!!enabled} /> : null}
        <StyledLabel enabled={!!enabled} error={error}>
          <MiddleEllipsis>
            <span>{editable ? label : value ? label : `${label} ${emptyCopy}`}</span>
            {allowCopy && (value || defaultValue) ? (
              <Button onClick={copyValue} shape="circle" style={{ marginLeft: 10 }}>
                <CopyOutlined style={{ color: colors.grey4 }} />
              </Button>
            ) : null}
          </MiddleEllipsis>
        </StyledLabel>
      </Row>
      {options?.length ? (
        <Row align="middle">
          {editable ? (
            <StyledSelect
              onChange={handleChange}
              options={options}
              popupMatchSelectWidth={false}
              value={value ? (shorten ? shortenString(value, shorten) : value) : emptyCopy}
              defaultValue={defaultValue}
            />
          ) : value ? (
            <StyledValue error={error}>
              <MiddleEllipsis>
                <MonoSpaceSpan>{value}</MonoSpaceSpan>
              </MiddleEllipsis>
            </StyledValue>
          ) : null}
        </Row>
      ) : null}
    </Space>
  );
};

const StyledSelect = styled(Select)`
  color: ${colors.grey4};
`;

const StyledValue = styled.div<{ error: boolean }>`
  height: 30px;
  padding: 5px 0;
  max-width: 200px;
  user-select: none;
  ${({ error }) => {
    if (error) {
      return `color: ${colors.error}`;
    } else {
      return `color: ${colors.grey4}`;
    }
  }}
`;

const StyledDot = styled.div<{ enabled: boolean }>`
  width: 7px;
  height: 7px;
  border-radius: 100px;
  margin-right: 10px;
  ${({ enabled }) => {
    if (enabled) {
      return `background-color: ${colors.success}`;
    } else {
      return `background-color: ${colors.error}`;
    }
  }}
`;

const StyledLabel = styled.div<{ enabled: boolean; error: boolean }>`
  font-weight: bold;
  ${({ enabled, error }) => {
    if (enabled) {
      if (error) {
        return `color: ${colors.error}`;
      } else {
        return `color: ${colors.teal}`;
      }
    } else {
      return `color: ${colors.error}`;
    }
  }}
`;

export default StatusInput;
