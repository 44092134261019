import jwt_decode from 'jwt-decode';
import { TokenClaims, TokenType } from './authTypes';
import BugsnagManager from '../../BugsnagManager';

export function decodeUserToken(token: string): TokenType | null {
  if (token && token !== 'null') {
    try {
      const decoded = jwt_decode(token) as any;
      if (decoded?.privileges?.privileges) {
        decoded.privileges = decoded?.privileges?.privileges;
      }
      return decoded as TokenType;
    } catch (error: any) {
      BugsnagManager.notify(error, {
        context: 'Unable to decode jwt',
        metadata: {
          token
        }
      });
      console.warn(error);
      return null;
    }
  } else {
    return null;
  }
}

export function claimsFromToken(token: string): TokenClaims | undefined {
  try {
    const claims: TokenClaims = jwt_decode(token);
    return claims;
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'Unable to retrieve claims from token',
      metadata: {
        token
      }
    });
    return undefined;
  }
}

export function isTokenExpired(token: string) {
  const exp = claimsFromToken(token)?.exp ?? 0;
  const expired = Date.now() >= exp * 1000;
  return expired;
}
