import { Web3Modal } from '@web3modal/standalone';
import { createSessionProposal, WalletConnectWallet } from '../features/wallet/walletconnect';
import WalletInfoOrConnect from '../components/Wallet/WalletInfoOrConnect';
import SessionsList from '../components/Wallet/SessionsList';
import EulithCard from '../components/EulithCard';
import { Button, Descriptions, Form, Input, Modal, Row, Space, Spin } from 'antd';
import { LoadingOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import { antdFormConfig, Text } from '../styles/shared';
import { useAppSelector } from '../hooks/redux';
import { selectAccessToken } from '../features/auth/authSlice';
import {
  selectWalletInitialized,
  selectWalletInstance,
  selectWalletProposal
} from '../features/wallet/walletSlice';
import { useEulithWallet } from '../features/wallet/useEulithWallet';
import StatusIndicator from '../components/StatusIndicator';
import { selectSelectedWalletContract } from '../features/order/orderSlice';
import { ConnectWallet, useChain, useChainId, useConnectionStatus } from '@thirdweb-dev/react';
import { WalletConnectButton } from '../components/Wallet/WalletConnectButton';
import { shortenString } from '../utils/data';
import { useState } from 'react';
import ContractStatus from '../components/ContractStatus';
import { showError } from '../features/wallet/error';
import EulithHelmetConsumer from '../components/EulithHelmetConsumer';
import eulithSingleton from '../features/eulith/EulithSingleton';
import { SupportedWallets } from '../features/wallet/walletTypes';
import { selectContractsData } from '../features/eulith/eulithService';
import { useNavigate } from 'react-router-dom';
import EmptyState from '../components/EmptyState/EmptyState';
import { ARMOR_TABS } from '../components/Layouts/shared';
import styled from 'styled-components';
import { calculateChainIconUrl } from '../utils/networks';
import BugsnagManager from '../BugsnagManager';

const web3Modal = new Web3Modal({
  projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID || '',
  themeMode: 'dark',
  walletConnectVersion: 2
});

interface FormValues {
  relayUri: string;
}

export default function WalletPage() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const selectedContract = useAppSelector(selectSelectedWalletContract);
  const accessToken = useAppSelector(selectAccessToken);
  const walletIsInitialized = useAppSelector(selectWalletInitialized);
  const walletInstance = useAppSelector(selectWalletInstance);
  const walletProposal = useAppSelector(selectWalletProposal);
  const connectionStatus = useConnectionStatus();
  const contracts = useAppSelector(selectContractsData);
  const navigate = useNavigate();
  const chainId = useChainId();
  const {
    connectToMetaMask,
    approveDappConnection,
    rejectDappConnection,
    connectDapp,
    disconnectWallet,
    // forgetPairing,
    disconnectSession,
    onWalletConnected
  } = useEulithWallet();
  const chain = useChain();

  const walletClients = eulithSingleton.walletClients;

  function navigateToDeployArmor() {
    navigate(ARMOR_TABS.OVERVIEW);
  }

  function closeAddModal() {
    setAddModalVisible(false);
  }

  const onPressDappApprove = async () => {
    await approveDappConnection();
  };

  const onPressDappReject = async () => {
    await rejectDappConnection();
  };

  const onSubmitWalletConnectURI = async (uri: string) => {
    await connectDapp(uri);
  };

  const onDisconnectWallet = async () => {
    await disconnectWallet();
  };

  const onPressForgetSession = async (topic: string) => {
    await disconnectSession(topic);
  };

  // const onPressForgetPairing = async (topic: string) => {
  //   await forgetPairing(topic);
  // };

  const onClickMetaMask = async () => {
    if (accessToken) {
      try {
        await connectToMetaMask();
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Unable to connect to metamask',
          metadata: {
            accessToken
          }
        });
        showError('Unable to connect to MetaMask.', { error });
      }
    } else {
      showError('You are not authenticated.');
    }
  };

  const onClickWalletConnect = async () => {
    if (!accessToken) {
      showError('You are not authenticated.');
      return;
    }
    if (!walletClients) {
      showError('Eulith services have not yet been initialized.');
      return;
    }
    const { uri, approval, standaloneChains } = await createSessionProposal(walletClients);

    if (!uri) {
      showError('WalletConnect did not return a URI.');
      return;
    }

    try {
      await web3Modal.openModal({ uri, standaloneChains });
    } catch (error: any) {
      BugsnagManager.notify(error, {
        context: 'Unable to open wallet connect',
        metadata: {
          uri,
          standaloneChains
        }
      });
      showError('Unable to open WalletConnect.', { error });
    }
    let session;
    try {
      session = await approval();
    } catch (error: any) {
      BugsnagManager.notify(error, {
        context: 'WalletConnectModal approval returned an error',
        metadata: {
          uri,
          standaloneChains
        }
      });
      showError(
        'WalletConnectModal approval returned an error' +
          (error?.message ? ` (${error.message}).` : '.')
      );
      return;
    } finally {
      web3Modal.closeModal();
    }

    const wallet = new WalletConnectWallet(session, walletClients);
    await onWalletConnected(wallet, 'WalletConnect');
  };

  async function onConnect(values: FormValues) {
    setLoading(true);
    try {
      await onSubmitWalletConnectURI(values.relayUri);
      setAddModalVisible(false);
      setLoading(false);
      form.resetFields();
    } catch (error: any) {
      BugsnagManager.notify(error, {
        context: 'Error occurred while connecting to wallet',
        metadata: {
          values
        }
      });
      showError(error?.message || 'Error occurred while connecting to wallet.');
      setLoading(false);
    }
  }

  if (contracts?.length === 0) {
    return (
      <Row
        gutter={[16, 16]}
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <EmptyState
          image={<SafetyCertificateOutlined style={{ fontSize: 50, color: '#FFFFFF40' }} />}
          imageStyle={{ height: 60 }}
          description="You must deploy an Armor instance on the currently selected network before using Eulith Wallet."
        >
          <Button type="primary" size="large" onClick={navigateToDeployArmor}>
            Deploy Armor
          </Button>
        </EmptyState>
      </Row>
    );
  }

  if (!(walletIsInitialized && connectionStatus !== 'disconnected')) {
    return (
      <Row
        gutter={[16, 16]}
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Text>
          {connectionStatus !== 'disconnected' ? 'Initializing...' : 'Please connect a wallet'}
        </Text>
        {connectionStatus !== 'disconnected' ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        ) : (
          <ConnectWallet theme="dark" />
        )}
      </Row>
    );
  }

  return (
    <>
      <EulithHelmetConsumer>Wallet</EulithHelmetConsumer>
      <EulithCard
        title="Eulith Wallet"
        fullHeight
        style={{ height: '100%' }}
        bodyStyle={{ height: 'calc(100% - 80px)' }}
        extra={
          <Space size="large">
            {walletInstance ? (
              <WalletConnectButton
                size="sm"
                title={walletInstance.name as SupportedWallets}
                subtitle={shortenString(walletInstance.address, 30)}
                onDisconnect={onDisconnectWallet}
              />
            ) : null}
            <StatusIndicator
              key="ace_status"
              label="ACE"
              enabled={!!selectedContract?.isEnabled}
              error={!selectedContract?.isEnabled}
              value={
                selectedContract?.isEnabled
                  ? 'Connected'
                  : selectedContract?.hasAce
                  ? 'Disconnected'
                  : 'N/A'
              }
              redErrorStyle={selectedContract?.hasAce}
            />
            {chain ? (
              <Row align="middle" justify="end" key="network_status">
                <StatusIndicator
                  label="Network"
                  enabled
                  error={false}
                  value={chain?.name || '?'}
                  redErrorStyle={false}
                />
                {chain?.icon?.url ? (
                  <StyledNetworkImage
                    src={calculateChainIconUrl(chain?.icon?.url || '', chainId)}
                  />
                ) : null}
              </Row>
            ) : null}
          </Space>
        }
      >
        {walletInstance ? (
          <Space direction="vertical" style={{ width: '100%', marginBottom: 20 }}>
            <ContractStatus contractType="wallet" />
            <SessionsList
              setAddModalVisible={setAddModalVisible}
              onPressForget={onPressForgetSession}
            />
            <Modal
              open={addModalVisible}
              title="Connect DApp to Eulith Wallet"
              onCancel={closeAddModal}
              onOk={form.submit}
              okText="Connect"
              okButtonProps={{
                loading
              }}
              cancelButtonProps={{
                loading
              }}
            >
              <Form
                form={form}
                onFinish={onConnect}
                style={{ width: '100%' }}
                layout="vertical"
                requiredMark={antdFormConfig.requiredMark}
                scrollToFirstError
                size={antdFormConfig.size}
              >
                <Form.Item
                  label="Relay URL"
                  name="relayUri"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a relay url.'
                    }
                  ]}
                >
                  <Input style={{ width: '100%' }} placeholder="e.g. wc:a281567bb3e4..." />
                </Form.Item>
              </Form>
            </Modal>
            <Modal
              open={!!walletProposal}
              onOk={onPressDappApprove}
              onCancel={onPressDappReject}
              title="Approve DApp connection?"
              okText="Approve"
              cancelButtonProps={{
                danger: true
              }}
              cancelText="Reject"
              closeIcon={<div />}
              closable={false}
              maskClosable={false}
            >
              <Descriptions
                column={1}
                title={
                  walletProposal?.params?.proposer?.metadata?.icons?.[0] ? (
                    <span style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                      <img
                        src={walletProposal.params.proposer.metadata.icons[0]}
                        style={{ width: 30, height: 30, marginRight: 10 }}
                        onError={(e) => (e.currentTarget.src = '/dapp.png')}
                      />
                      <div style={{ fontSize: 20 }}>
                        {walletProposal?.params?.proposer?.metadata?.name}
                      </div>
                    </span>
                  ) : null
                }
                items={[
                  {
                    key: 'description',
                    label: 'Description',
                    children: walletProposal?.params?.proposer?.metadata?.description || 'Unknown'
                  },
                  {
                    key: 'url',
                    label: 'URL',
                    children: walletProposal?.params?.proposer?.metadata?.url ? (
                      <a href={walletProposal.params.proposer.metadata.url}>
                        {walletProposal.params.proposer.metadata.url.split('://')[1]}
                      </a>
                    ) : (
                      'Unknown'
                    )
                  },
                  {
                    key: 'topic',
                    label: 'Topic',
                    children: walletProposal?.params?.pairingTopic || 'Unknown'
                  }
                ]}
              />
            </Modal>
          </Space>
        ) : (
          <WalletInfoOrConnect
            onClickMetaMask={onClickMetaMask}
            onClickWalletConnect={onClickWalletConnect}
          />
        )}
      </EulithCard>
    </>
  );
}

const StyledNetworkImage = styled.img`
  user-select: none;
  margin-left: 10px;
  width: 30px;
  height: 30px;
`;
