import { QuestionCircleOutlined } from '@ant-design/icons';
import { Image } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { selectTokenBalanceData } from '../../features/tokens/tokensService';
import { useAppSelector } from '../../hooks/redux';
import { useIsMismatched } from '../../hooks/useMismatch';
import EulithCard from '../EulithCard';
import EulithTable from '../EulithTable';

const Portfolio: React.FC = () => {
  const tokens = useAppSelector(selectTokenBalanceData);
  const isMismatched = useIsMismatched();

  const columns: ColumnsType<any> = [
    {
      title: 'Icon',
      dataIndex: 'logo',
      render: (logo?: string) =>
        logo ? (
          <Image src={logo} width={30} height={30} preview={false} />
        ) : (
          <QuestionCircleOutlined style={{ fontSize: 30, color: 'rgba(255,255,255,0.3)' }} />
        )
    },
    {
      title: 'Token',
      dataIndex: 'symbol'
    },
    {
      title: 'Balance',
      dataIndex: 'tokenBalance'
    }
  ];

  return (
    <EulithCard bodyStyle={{ padding: 10 }} title="Safe Balances" isDisabled={isMismatched}>
      <EulithTable
        rowKey="contractAddress"
        scroll={{ x: 600, y: 600 }}
        dataSource={tokens}
        columns={columns}
        pagination={false}
      />
    </EulithCard>
  );
};

export default Portfolio;
