import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { authApi } from './features/auth/authService';
import { eulithApi } from './features/eulith/eulithService';
import { fundsApi } from './features/funds/fundsService';
import { statusApi } from './features/status/statusService';
import { tokensApi } from './features/tokens/tokensService';
import { whitelistsApi } from './features/whitelist/whitelistService';
import { orderApi } from './features/order/orderService';
import authReducer from './features/auth/authSlice';
import eulithReducer, { initialState as EulithInitialState } from './features/eulith/eulithSlice';
import orderReducer, { initialState as OrderInitialState } from './features/order/orderSlice';
import settingsReducer from './features/settings/settingsSlice';
import tokensReducer from './features/tokens/tokensSlice';
import walletReducer, { initialState as WalletInitialState } from './features/wallet/walletSlice';
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  createTransform
  // FLUSH,
  // REHYDRATE,
  // PAUSE,
  // PERSIST,
  // PURGE,
  // REGISTER
} from 'redux-persist';
import { WalletState } from './features/wallet/walletTypes';
import { OrderState } from './features/order/orderTypes';
import { EulithState } from './features/eulith/eulithTypes';
import { MetaMaskWallet } from './features/wallet/metamask';
import eulithSingleton from './features/eulith/EulithSingleton';
import pjson from '../package.json';

const pjsonVersion = pjson?.version || -1;
const persistVersion = parseInt(pjsonVersion.toString().replace(/\./g, ''));

console.log(`App version: ${persistVersion}`);

const orderTransform = createTransform(
  (inboundState: OrderState) => {
    return {
      ...OrderInitialState,
      selectedTradingContract: inboundState.selectedTradingContract,
      selectedWhitelistContract: inboundState.selectedWhitelistContract,
      selectedWalletContract: inboundState.selectedWalletContract
    };
  },
  (outboundState: OrderState) => outboundState,
  { whitelist: ['order'] }
);

const eulithTransform = createTransform(
  () => EulithInitialState,
  (outboundState: EulithState) => outboundState,
  { whitelist: ['eulith'] }
);

const walletTransform = createTransform(
  (inboundState: WalletState) => {
    return {
      ...inboundState,
      initialized: WalletInitialState.initialized,
      initializing: WalletInitialState.initializing,
      proposal: WalletInitialState.proposal,
      sessions: WalletInitialState.sessions,
      pairings: WalletInitialState.pairings,
      tabNotificationMessage: WalletInitialState.tabNotificationMessage
    };
  },
  (outboundState: WalletState) => {
    if (outboundState.wallet?.type === 'MetaMask' && outboundState.wallet?.address) {
      eulithSingleton.wallet = new MetaMaskWallet(outboundState.wallet.address);
    }
    return { ...outboundState };
  },
  { whitelist: ['wallet'] }
);

function handleStateMigration(state: any) {
  if (state && state?._persist?.version) {
    if (state?._persist?.version !== persistVersion) {
      console.log(`Migrating from v${state?._persist?.version} to v${persistVersion}`);
      return Promise.resolve(undefined);
    }
  }
  return Promise.resolve(state);
}

export const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'eulith', 'order', 'settings', 'tokens', 'wallet'],
  transforms: [eulithTransform, walletTransform, orderTransform],
  version: persistVersion,
  migrate: handleStateMigration
};

const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [eulithApi.reducerPath]: eulithApi.reducer,
  [statusApi.reducerPath]: statusApi.reducer,
  [fundsApi.reducerPath]: fundsApi.reducer,
  [tokensApi.reducerPath]: tokensApi.reducer,
  [whitelistsApi.reducerPath]: whitelistsApi.reducer,
  [orderApi.reducerPath]: orderApi.reducer,
  auth: authReducer,
  eulith: eulithReducer,
  order: orderReducer,
  settings: settingsReducer,
  tokens: tokensReducer,
  wallet: walletReducer
});

const persistedReducer = persistReducer<ReturnType<typeof rootReducer>>(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      // }
    }).concat(
      fundsApi.middleware,
      authApi.middleware,
      eulithApi.middleware,
      statusApi.middleware,
      tokensApi.middleware,
      whitelistsApi.middleware,
      orderApi.middleware
    )
});

export const persistor = persistStore(store);
// persistor.purge();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
