// Copied from @metamask/create-snap

import type { MetaMaskInpageProvider } from '@metamask/providers';
import BugsnagManager from '../../BugsnagManager';

export const EULITH_NPM_SNAP_ID = 'npm:defi-armor-snap';

export function eulithSnapId() {
  return EULITH_NPM_SNAP_ID;
}

export type Snap = {
  permissionName: string;
  id: string;
  version: string;
  initialPermissions: Record<string, unknown>;
};

export type GetSnapsResponse = Record<string, Snap>;

/**
 * Tries to detect if one of the injected providers is MetaMask and checks if snaps is available in that MetaMask version.
 *
 * @returns True if the MetaMask version supports Snaps, false otherwise.
 */
export const detectSnaps = async () => {
  if (window.ethereum?.detected) {
    for (const provider of window.ethereum.detected) {
      try {
        // Detect snaps support
        await getSnaps(provider);

        // enforces MetaMask as provider
        if (window.ethereum.setProvider) {
          window.ethereum.setProvider(provider);
        }

        return true;
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Error in detectSnaps for MetaMask 1'
        });
        console.warn('Error in detectSnaps for MetaMask:', error);
      }
    }
  }

  if (window.ethereum?.providers) {
    for (const provider of window.ethereum.providers) {
      try {
        // Detect snaps support
        await getSnaps(provider);

        window.ethereum = provider;

        return true;
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Error in detectSnaps for MetaMask 2'
        });
        console.warn('Error in detectSnaps for MetaMask:', error);
      }
    }
  }

  try {
    await getSnaps();

    return true;
  } catch {
    return false;
  }
};

/**
 * Detect if the wallet injecting the ethereum object is MetaMask Flask.
 *
 * @returns True if the MetaMask version is Flask, false otherwise.
 */
export const isFlask = async () => {
  const provider = window.ethereum;

  try {
    const clientVersion = await provider?.request({
      method: 'web3_clientVersion'
    });

    const isFlaskDetected = (clientVersion as string[])?.includes('flask');

    return Boolean(provider && isFlaskDetected);
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'Error in isFlask for MetaMask'
    });
    console.warn('Error in isFlask for MetaMask:', error);
    return false;
  }
};

/**
 * Get the installed snaps in MetaMask.
 *
 * @param provider - The MetaMask inpage provider.
 * @returns The snaps installed in MetaMask.
 */
export const getSnaps = async (provider?: MetaMaskInpageProvider): Promise<GetSnapsResponse> =>
  (await (provider ?? window.ethereum).request({
    method: 'wallet_getSnaps'
  })) as unknown as GetSnapsResponse;

/**
 * Connect a snap to MetaMask.
 *
 * @param snapId - The ID of the snap.
 * @param params - The params to pass with the snap to connect.
 */
export const connectSnap = async (
  snapId: string,
  params: Record<'version' | string, unknown> = {}
) => {
  await window.ethereum.request({
    method: 'wallet_requestSnaps',
    params: {
      [snapId]: params
    }
  });
};

/**
 * Get the snap from MetaMask.
 *
 * @param version - The version of the snap to install (optional).
 * @returns The snap object returned by the extension.
 */
export const getSnap = async (version?: string): Promise<Snap | undefined> => {
  try {
    const snaps = await getSnaps();

    return Object.values(snaps).find(
      (snap) => snap.id === eulithSnapId() && (!version || snap.version === version)
    );
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'Error in getSnap for MetaMask 3'
    });
    console.warn('Error in getSnap for MetaMask:', error);
    return undefined;
  }
};
