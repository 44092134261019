import React, { useEffect } from 'react';
import EulithHelmetConsumer from '../components/EulithHelmetConsumer';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Image, Space, Tag, message } from 'antd';
import EulithTable from '../components/EulithTable';
import { DateTime } from 'luxon';
import { CalendarOutlined } from '@ant-design/icons';
import EmptyState from '../components/EmptyState/EmptyState';
import dayjs from 'dayjs';
import { getFundAttributes } from '../utils/funds';
import { selectCanCreateFunds } from '../features/auth/authSlice';
import { useAppSelector } from '../hooks/redux';
import type { FundFormValues } from '../features/funds/fundsTypes';
import { selectAllFundsData, useLazyGetFundsQuery } from '../features/funds/fundsService';
import EulithCard from '../components/EulithCard';
import { dollarFormatter } from '../utils/data';
import BugsnagManager from '../BugsnagManager';

function defaultSorter(a: any, b: any, property: string) {
  if (property) {
    if (a[property] < b[property]) {
      return -1;
    }
    if (a[property] > b[property]) {
      return 1;
    }
  } else {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
  }
  return 0;
}

const FundsListPage: React.FC = () => {
  const navigate = useNavigate();
  const canCreateFunds = useAppSelector(selectCanCreateFunds);
  const [getFunds, { isLoading }] = useLazyGetFundsQuery();
  const funds = useAppSelector(selectAllFundsData);

  useEffect(() => {
    if (canCreateFunds) {
      fetchData();
    } else {
      navigate('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchData() {
    try {
      await getFunds().unwrap();
    } catch (error: any) {
      BugsnagManager.notify(error, {
        context: 'Unable to fetch funds in funds list page'
      });
      message.error('Unable to fetch funds. Please try again later.');
      console.warn(error);
    }
  }

  function navigateToPreview(fund: FundFormValues) {
    window.open(`/funds/public/${fund.id}`, '_blank');
  }

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      uid: 'title',
      sorter: (a: any, b: any) => {
        return defaultSorter(a, b, 'name');
      },
      render: (value: string, record: FundFormValues) => {
        return (
          <Space>
            <Image
              src={record.logoFile?.[0]?.url}
              preview={false}
              width={50}
              height={50}
              style={{ borderRadius: 4, objectFit: 'cover' }}
            />
            {value}
          </Space>
        );
      }
    },
    {
      title: 'Launch Date',
      dataIndex: 'launchDate',
      uid: 'launchDate',
      sorter: (a: any, b: any) => {
        return defaultSorter(a, b, 'launchDate');
      },
      render: (value: string | null) => {
        if (value) {
          return (
            <Space>
              <CalendarOutlined />
              {dayjs(value).format('MMMM, YYYY')}
            </Space>
          );
        } else {
          return '-';
        }
      }
    },
    {
      title: 'Current AUM',
      dataIndex: 'currentAUM',
      uid: 'currentAUM',
      sorter: (a: any, b: any) => {
        return defaultSorter(a, b, 'currentAUM');
      },
      render: (text: string) => (text ? dollarFormatter(parseFloat(text)) : '-')
    },
    {
      title: 'Management Fee',
      dataIndex: 'managementFee',
      uid: 'managementFee',
      sorter: (a: any, b: any) => {
        return defaultSorter(a, b, 'managementFee');
      },
      render: (text: string) => (text ? `${text}%` : '-')
    },
    {
      title: 'Performance Fee',
      dataIndex: 'performanceFee',
      uid: 'performanceFee',
      sorter: (a: any, b: any) => {
        return defaultSorter(a, b, 'performanceFee');
      },
      render: (text: string) => (text ? `${text}%` : '-')
    },
    {
      title: 'Attributes',
      dataIndex: 'fundAttributes',
      uid: 'fundAttributes',
      filters: getFundAttributes().map((attribute) => {
        return {
          text: attribute.label,
          value: attribute.label
        };
      }),
      onFilter: (value: any, record: any) => {
        return !!record.fundAttributes?.includes?.(value);
      },
      render: (value: any, record: any) => {
        if (value?.length) {
          return (
            <Space wrap>
              {value.map((tag: string) => {
                return <Tag key={`record_${record.id}_${tag}`}>{tag}</Tag>;
              })}
            </Space>
          );
        } else {
          return '-';
        }
      }
    },
    {
      title: 'Last Updated',
      dataIndex: 'updatedAt',
      uid: 'updatedAt',
      sorter: (a: any, b: any) => {
        return defaultSorter(a, b, 'updatedAt');
      },
      render: (text: string) =>
        text ? DateTime.fromISO(text).toLocaleString(DateTime.DATE_MED) : ''
    }
  ];

  function onRow(record: FundFormValues) {
    return {
      onClick: () => {
        if (record) {
          navigateToPreview(record);
        }
      }
    };
  }

  const filteredFunds =
    funds?.filter?.((datum: FundFormValues) => datum.published && !datum.isDraft) || [];

  if (!canCreateFunds) {
    return null;
  }
  return (
    <>
      <EulithHelmetConsumer>Funds List</EulithHelmetConsumer>
      <Container>
        <EulithCard title="All Funds" bodyStyle={{ padding: 10 }} style={{ marginBottom: 20 }}>
          {filteredFunds.length ? (
            <EulithTable
              rowKey="id"
              onRow={onRow}
              rowClassName="cursor-pointer"
              loading={isLoading}
              columns={columns}
              dataSource={filteredFunds}
              scroll={{
                x: 600
              }}
            />
          ) : (
            <EmptyState description="No funds found" />
          )}
        </EulithCard>
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export default FundsListPage;
