import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import type { EulithState } from './eulithTypes';
import type { PayloadAction } from '@reduxjs/toolkit';

export const initialState: EulithState = {
  initializing: true,
  initialized: false
};

export const eulithSlice = createSlice({
  name: 'eulith',
  initialState,
  reducers: {
    setEulithClientInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
    },
    setEulithClientInitializing: (state, action: PayloadAction<boolean>) => {
      state.initializing = action.payload;
    }
  }
});

export const { setEulithClientInitialized, setEulithClientInitializing } = eulithSlice.actions;

export const selectEulithClientInitialized = (state: RootState) => state.eulith.initialized;
export const selectEulithClientInitializing = (state: RootState) => state.eulith.initializing;

export default eulithSlice.reducer;
