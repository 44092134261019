import { Input, InputProps } from 'antd';
import BugsnagManager from '../../BugsnagManager';

function URLInput(props: InputProps) {
  const formatUrl = (value: any): string => {
    if (!value) {
      return value;
    }
    try {
      const formattedValue = value.replace('https://', '').replace('http://', '').toLowerCase();
      return formattedValue;
    } catch (error: any) {
      BugsnagManager.notify(error, {
        context: 'Unable to format url',
        metadata: {
          value
        }
      });
      return value;
    }
  };

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const formattedValue = formatUrl(value);
    e.target.value = formattedValue;
    props.onChange?.(e);
  };

  return <Input {...props} value={formatUrl(props.value)} onChange={handleUrlChange} />;
}

export default URLInput;
