import logoBlack from './images/logo_black.png';
import logoWhite from './images/logo_solid_white.png';
import logo from './images/logo_white.png';
import NYC from './images/nyc.jpg';

export default {
  logo,
  logoWhite,
  logoBlack,
  NYC
};
