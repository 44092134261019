import styled from 'styled-components';

const StepsTitleInputContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;
`;

export default StepsTitleInputContainer;
