import React from 'react';
import { Row, Space } from 'antd';
import styled from 'styled-components';
import { colors } from '../../styles/shared';

interface Props {
  label: string;
  enabled: boolean;
  error: boolean;
  value?: string;
  labelStyle?: React.CSSProperties;
  redErrorStyle?: boolean;
}

const StatusIndicator: React.FC<Props> = ({
  label,
  enabled,
  error = false,
  value,
  labelStyle,
  redErrorStyle = true
}: Props) => {
  return (
    <Space size="small" direction="vertical">
      <StyledRow align="middle" justify="end">
        <StyledLabel
          enabled={enabled}
          error={error}
          style={labelStyle}
          redErrorStyle={redErrorStyle}
        >
          {label}
        </StyledLabel>
      </StyledRow>
      <Row align="middle" justify="end">
        <StyledValue enabled={enabled} error={error} redErrorStyle={redErrorStyle}>
          {value}
        </StyledValue>
      </Row>
    </Space>
  );
};

const StyledValue = styled.div<{ enabled: boolean; error: boolean; redErrorStyle: boolean }>`
  user-select: none;
  font-weight: bold;
  font-size: 16px;
  ${({ error, redErrorStyle }) => {
    if (error) {
      if (redErrorStyle) {
        return `color: ${colors.error}`;
      } else {
        return `color: ${colors.grey4}`;
      }
    } else {
      return `color: ${colors.success}`;
    }
  }}
`;

const StyledRow = styled(Row)`
  margin-bottom: -10px;
`;

const StyledLabel = styled.div<{ enabled: boolean; error: boolean; redErrorStyle: boolean }>`
  font-weight: bold;
  font-size: 14px;

  ${({ enabled, error, redErrorStyle }) => {
    if (enabled) {
      if (error) {
        return `color: ${colors.error}`;
      } else {
        return `color: ${colors.teal}`;
      }
    } else {
      if (redErrorStyle) {
        return `color: ${colors.error}`;
      } else {
        return `color: ${colors.grey4}`;
      }
    }
  }}
`;

export default StatusIndicator;
