import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Collapse, Popconfirm, Space } from 'antd';
import { ManagerType } from '../../features/funds/fundsTypes';

const { Panel } = Collapse;

interface ManagerListItemProps {
  data: ManagerType;
  editManager?: (values: ManagerType) => void;
  deleteManager?: (values: ManagerType) => void;
}

function ManagerListItem({ data, editManager, deleteManager }: ManagerListItemProps) {
  function stopPropagation(e: any) {
    e?.stopPropagation?.();
  }

  return (
    <Collapse size="small" key={`fund_form_manager_${data.id}`}>
      <Panel
        key={`fund_form_manager_panel_${data.id}`}
        header={`${data.firstName} ${data.lastName}, ${data.title}`}
        extra={
          editManager && deleteManager ? (
            <Space>
              <EditOutlined
                onClick={(e: any) => {
                  e?.stopPropagation?.();
                  editManager(data);
                }}
              />
              <Popconfirm
                title="Delete this manager?"
                description="They will be permanently removed from this fund."
                onConfirm={() => {
                  deleteManager(data);
                }}
                okText="Delete"
                okButtonProps={{
                  danger: true
                }}
              >
                <DeleteOutlined style={{ color: '#DC4546' }} onClick={stopPropagation} />
              </Popconfirm>
            </Space>
          ) : null
        }
        style={{ marginBottom: 20 }}
      >
        {data.summary || 'No summary provided'}
      </Panel>
    </Collapse>
  );
}

export default ManagerListItem;
