import { useChainId, useConnectionStatus } from '@thirdweb-dev/react';
import { Col, Modal, Row, Space } from 'antd';
import { useCallback } from 'react';
import styled from 'styled-components';
import { selectContractsData, selectPingData } from '../../features/eulith/eulithService';
import {
  selectSelectedTradingContract,
  selectSelectedWalletContract,
  selectSelectedWhitelistContract,
  setSelectedTradingContract,
  setSelectedWalletContract,
  setSelectedWhitelistContract
} from '../../features/order/orderSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useIsMismatched } from '../../hooks/useMismatch';
import EulithCard from '../EulithCard';
import StatusIndicator from '../StatusIndicator';
import StatusInput from '../StatusInput';
import { selectWalletSessions } from '../../features/wallet/walletSlice';
import { useEulithWallet } from '../../features/wallet/useEulithWallet';
import { DecoratedContract } from '../../features/eulith/eulithTypes';

interface Props {
  contractType: 'trading' | 'wallet' | 'whitelist';
}

function ContractStatus({ contractType }: Props) {
  const dispatch = useAppDispatch();
  const connectionStatus = useConnectionStatus();
  const { disconnectAllSessions } = useEulithWallet();
  const sessions = useAppSelector(selectWalletSessions);
  const contracts = useAppSelector(selectContractsData);
  const chainId = useChainId();

  const selectorMap: { [key: string]: any } = {
    trading: selectSelectedTradingContract,
    wallet: selectSelectedWalletContract,
    whitelist: selectSelectedWhitelistContract
  };

  const selectedContract: DecoratedContract = useAppSelector(selectorMap[contractType]);
  const pingData = useAppSelector(selectPingData);
  const hasAce = !!selectedContract?.hasAce;
  const selectedContractAce = pingData[selectedContract?.tradingKeyAddress || ''];
  const isMismatched = useIsMismatched();
  const currentActiveWhitelist = selectedContract?.whitelist;

  const handleContractChange = useCallback(
    (contract: DecoratedContract) => {
      switch (contractType) {
        case 'trading':
          dispatch(setSelectedTradingContract(contract));
          break;
        case 'wallet':
          dispatch(setSelectedWalletContract(contract));
          break;
        case 'whitelist':
          dispatch(setSelectedWhitelistContract(contract));
          break;
        default:
          break;
      }
    },
    [contractType, dispatch]
  );

  const handleSelectedContractChange = useCallback(
    (contractAddress: string) => {
      const contract = contracts.find((contract) => {
        return contract.contractAddress === contractAddress;
      });
      if (contract) {
        if (sessions?.length && contractType === 'wallet') {
          Modal.confirm({
            title: 'Warning',
            content:
              'This will terminate all existing DApp connections, would you like to continue?',
            okText: 'Confirm',
            cancelText: 'Cancel',
            maskClosable: true,
            onOk: () => {
              handleContractChange(contract);
              disconnectAllSessions();
            }
          });
        } else {
          handleContractChange(contract);
        }
      } else {
        console.warn('Could not find this contract', contractAddress);
      }
    },
    [handleContractChange, contracts, sessions, disconnectAllSessions, contractType]
  );

  const contractOptions = contracts
    ?.filter((contract) => {
      if (contractType === 'wallet') {
        return contract.chainId === chainId;
      } else {
        return true;
      }
    })
    .map?.((contract, index) => {
      return {
        label: `${contract?.name || `Untitled Account ${index + 1}`}${
          contract.isEnabled ? '' : ' (inactive)'
        }`,
        value: contract?.contractAddress,
        disabled: !contract.isEnabled
      };
    });

  if (!contractOptions?.length) {
    return null;
  }

  return (
    <BtnGroups>
      <Row align="middle" justify="space-between" gutter={[10, 10]}>
        {contracts?.length ? (
          <Col xs={24} xl={16}>
            <Space size="middle" wrap>
              <StatusInput
                label="Account"
                enabled={!!contractOptions?.length}
                error={!contractOptions?.length}
                options={contractOptions}
                allowCopy={false}
                showCircle={false}
                onChange={handleSelectedContractChange}
                value={selectedContract?.contractAddress}
              />
              <StatusInput
                label="Safe Address"
                enabled={!!contractOptions?.length}
                error={!contractOptions?.length}
                editable={false}
                showCircle={false}
                allowCopy={!!selectedContract && !!contractOptions?.length}
                options={
                  selectedContract && contractOptions?.length
                    ? [
                        {
                          label: selectedContract.safeAddress,
                          value: selectedContract.contractAddress
                        }
                      ]
                    : []
                }
                value={selectedContract?.safeAddress}
                emptyCopy="-"
              />
              <StatusInput
                label="Trading Key Address"
                enabled={!!contractOptions?.length}
                error={!contractOptions?.length}
                editable={false}
                showCircle={false}
                allowCopy={!!selectedContract && !!contractOptions?.length}
                options={
                  selectedContract && contractOptions?.length
                    ? [
                        {
                          label: selectedContract.tradingKeyAddress,
                          value: selectedContract.contractAddress
                        }
                      ]
                    : []
                }
                value={selectedContract?.tradingKeyAddress}
                emptyCopy="-"
              />
              {hasAce ? (
                <StatusInput
                  label="Wallet"
                  enabled={!isMismatched && connectionStatus === 'connected'}
                  error={isMismatched || connectionStatus !== 'connected'}
                  editable={false}
                  options={
                    selectedContractAce?.ui_address
                      ? [
                          {
                            label: selectedContractAce.ui_address,
                            value: selectedContractAce.ui_address
                          }
                        ]
                      : []
                  }
                  value={selectedContractAce?.ui_address}
                  emptyCopy="Not Found"
                />
              ) : null}
            </Space>
          </Col>
        ) : null}
        <Col xs={24} xl={8}>
          <Row align="middle" justify={{ xs: 'start', md: 'start', xl: 'end' }}>
            <Space size="large" wrap>
              <StatusIndicator
                label="Whitelist"
                enabled={!!currentActiveWhitelist}
                error={!currentActiveWhitelist}
                value={currentActiveWhitelist ? 'Active' : 'Inactive'}
              />
              <StatusIndicator
                label="ACE"
                enabled={!!selectedContractAce}
                error={!selectedContractAce}
                value={selectedContractAce ? 'Connected' : hasAce ? 'Disconnected' : 'N/A'}
                redErrorStyle={hasAce}
              />
              <StatusIndicator
                label="DeFi Armor"
                enabled={!!contractOptions?.length}
                error={!contractOptions?.length}
                value={contractOptions?.length ? 'Active' : 'Inactive'}
              />
            </Space>
          </Row>
        </Col>
      </Row>
    </BtnGroups>
  );
}

const BtnGroups = styled(EulithCard)`
  width: 100%;
  margin-bottom: 10px;

  .ant-card-body {
    padding: 10px 20px;
  }
`;

export default ContractStatus;
