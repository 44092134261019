import styled from 'styled-components';

const FundsSectionContainer = styled.div<{ omitPadding?: boolean }>`
  width: 100%;
  height: 100%;
  padding: ${({ omitPadding }) => {
    if (omitPadding) {
      return '0 20px';
    } else {
      return '20px';
    }
  }};
  display: flex;
  flex-direction: column;
`;

export default FundsSectionContainer;
