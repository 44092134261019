import { Outlet } from 'react-router-dom';
import usePageView from '../../hooks/usePageView';
import { ConfigProvider, Layout, theme } from 'antd';
import LoggedInHeader from '../Header/LoggedInHeader';
import { Content } from 'antd/es/layout/layout';

const FundsPreviewLayout: React.FC = () => {
  usePageView();
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.defaultAlgorithm
      }}
    >
      <Layout style={{ height: '100%' }}>
        <LoggedInHeader />
        <Content
          style={{
            overflowY: 'auto'
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </ConfigProvider>
  );
};

export default FundsPreviewLayout;
