import Bugsnag, { BreadcrumbType, NotifiableError } from '@bugsnag/js';

interface NotifyOptions {
  unhandled?: boolean;
  overrideGroupingHash?: boolean;
  groupingHash?: string;
  context?: string;
  metadata?: any;
}
class BugsnagManager {
  leaveBreadcrumb = (message: string, metadata?: { [key: string]: any }, type?: BreadcrumbType) => {
    Bugsnag.leaveBreadcrumb(message, metadata, type);
  };

  setUser = (id?: string, email?: string, name?: string) => {
    Bugsnag.setUser(id, email, name);
  };

  notify = (e: NotifiableError, options: NotifyOptions = {}) => {
    const {
      unhandled = false,
      overrideGroupingHash = true,
      groupingHash,
      context,
      metadata = null
    } = options;

    if (typeof e === 'string') {
      e = new Error(e);
    }

    Bugsnag.notify(e, function (event) {
      event.context = context;
      event.unhandled = unhandled;
      if (overrideGroupingHash) {
        event.groupingHash = groupingHash || context;
      }
      if (metadata) {
        for (const key in metadata) {
          event.addMetadata(key, metadata[key]);
        }
      }
    });
  };
}

export default new BugsnagManager();
