import {
  BaseQueryFn,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery
} from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store';
import { createSelector } from '@reduxjs/toolkit';
import { Alchemy } from 'alchemy-sdk';
import { GetTokensRequest, MergedTokenInfo } from './tokenTypes';
import { hexToDollarAmount } from '../../utils/currency';
import { chainIdToAlchemyNetwork } from '../../utils/networks';
import { selectSelectedTradingContract } from '../order/orderSlice';
import BugsnagManager from '../../BugsnagManager';

// https://docs.alchemy.com/reference/alchemy-gettokenbalances
const getTokensQuery: BaseQueryFn<
  GetTokensRequest,
  MergedTokenInfo[],
  FetchBaseQueryError
> = async ({ chainId, address, contractAddresses }) => {
  const settings = {
    apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
    network: chainIdToAlchemyNetwork[chainId]
  };
  try {
    const alchemy = new Alchemy(settings);
    const result = await alchemy.core.getTokenBalances(address, contractAddresses);
    const promises = result.tokenBalances.map(async (token) => {
      return await alchemy.core.getTokenMetadata(token.contractAddress);
    });
    const resultsMetadata = await Promise.all(promises);
    const mergedList: MergedTokenInfo[] = result.tokenBalances.map((token, index) => {
      const mergedToken: MergedTokenInfo = {
        ...token,
        ...resultsMetadata[index]
      };
      if (mergedToken.tokenBalance) {
        mergedToken.tokenBalance = hexToDollarAmount(
          mergedToken.tokenBalance,
          mergedToken.decimals
        );
      }
      return mergedToken;
    });
    return { data: mergedList };
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'Error in fetch tokens',
      metadata: {
        chainId,
        address,
        contractAddresses
      }
    });
    console.warn('Unable to fetch tokens', error);
    return { error: error as FetchBaseQueryError };
  }
};

export const tokensApi = createApi({
  reducerPath: 'tokensApi',
  tagTypes: ['Tokens'],
  baseQuery: fetchBaseQuery({}),
  endpoints: (builder) => ({
    getTokens: builder.query<MergedTokenInfo[], GetTokensRequest>({
      queryFn: getTokensQuery,
      providesTags: ['Tokens']
    })
  })
});

export const { useGetTokensQuery, useLazyGetTokensQuery } = tokensApi;

export const selectTokenBalanceData = createSelector(
  (state: RootState) => {
    const selectedTradingContract = selectSelectedTradingContract(state);
    if (state.wallet.address && state.wallet.chainId) {
      const result = tokensApi.endpoints.getTokens.select({
        chainId: state.wallet.chainId,
        address: selectedTradingContract?.safeAddress || ''
      })(state);
      return result.data || [];
    } else {
      return [];
    }
  },
  (result) => result
);
