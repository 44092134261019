import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import * as Eulith from 'eulith-web3js';
import { StyledInternalLink, Text, antdFormConfig } from '../../styles/shared';
import { Button, Form, Input, Modal, message } from 'antd';
import { setAccessToken } from '../../features/auth/authSlice';
import { useAppDispatch } from '../../hooks/redux';
import {
  useLoginMutation,
  useUseLoginLegacyMutationMutation
} from '../../features/auth/authService';
import { useNavigate } from 'react-router-dom';
import EulithCard from '../EulithCard';
import { LockOutlined } from '@ant-design/icons';
import { LoginLegacyRequest } from '../../features/auth/authTypes';
import BugsnagManager from '../../BugsnagManager';

interface OTPValues {
  code: string;
}

const NUM_SECS_IN_DAY = 86400;

const LoginForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [login, { isLoading }] = useLoginMutation();
  const [loginLegacy] = useUseLoginLegacyMutationMutation();

  const handleAuthenticationError = useCallback(
    (sub: string, e: any) => {
      if (e?.status === 401) {
        setLoading(false);
        message.warning('Incorrect username or password.');
      } else if (e?.status === 422) {
        setLoading(false);
        message.warning('You have already transitioned your account to use one-time passwords.');
      } else if (e?.status === 301) {
        navigate('/reauth' + window.location.search, {
          state: {
            sub,
            salt: e?.data?.salt,
            activation_hash: e?.data?.activation_hash,
            totp_url: e?.data?.totp_url
          }
        });
      } else {
        setLoading(false);
        console.log(e);
        message.error('Failed to authenticate.');
      }
    },
    [navigate]
  );

  const onSubmit = useCallback(
    async (data: LoginLegacyRequest & OTPValues) => {
      Modal.destroyAll();
      try {
        const expiresIn = 1 * NUM_SECS_IN_DAY;
        const generateKeyPairResponse = await Eulith.Auth.generateKeyPair(data.sub, data.password);
        const generateLoginResponse = generateKeyPairResponse.generate_login(
          data.code,
          data.sub,
          BigInt(expiresIn)
        );
        const response = await login({
          otp: generateLoginResponse.otp,
          sub: generateLoginResponse.sub,
          signature: generateLoginResponse.signature,
          now: generateLoginResponse.now,
          expire_in_secs: expiresIn
        }).unwrap();
        if (response.token) {
          message.success('Successfully authorized!');
          dispatch(setAccessToken(response.token));
          setTimeout(() => {
            const urlParams = new URLSearchParams(window.location.search);
            const redirect = urlParams.get('redirect') || '';
            if (redirect) {
              window.location.replace(redirect);
            } else {
              navigate('/home');
            }
          }, 1000);
        } else {
          message.error('Failed to authenticate.');
        }
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Unable to authenticate in LoginForm'
        });
        handleAuthenticationError(data.sub, error);
      }
    },
    [dispatch, navigate, login, handleAuthenticationError]
  );

  const handleFirstTimeAuthenticator = useCallback(
    async (loginFormData: LoginLegacyRequest) => {
      Modal.destroyAll();
      try {
        await loginLegacy({
          password: loginFormData.password,
          sub: loginFormData.sub
        }).unwrap();
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Unable to handle first time authenticator in LoginForm'
        });
        handleAuthenticationError(loginFormData.sub, error);
      }
    },
    [loginLegacy, handleAuthenticationError]
  );

  const requestCode = useCallback(
    (loginFormData: LoginLegacyRequest) => {
      setLoading(true);
      Modal.info({
        title: 'Please enter your one-time password',
        icon: <LockOutlined />,
        closeIcon: <div />,
        footer: null,
        onOk: () => {
          setLoading(false);
        },
        onCancel: () => {
          setLoading(false);
        },
        maskClosable: true,
        closable: true,
        content: (
          <div style={{ paddingRight: 30 }}>
            <div style={{ paddingBottom: 15 }}>
              Click <a onClick={() => handleFirstTimeAuthenticator(loginFormData)}>here</a> if you
              haven&apos;t set up an authenticator app for Eulith yet.
            </div>
            <Form
              onFinish={(data: OTPValues) => {
                setLoading(false);
                onSubmit({
                  sub: loginFormData.sub,
                  password: loginFormData.password,
                  code: data.code
                });
              }}
            >
              <Form.Item
                name="code"
                labelCol={antdFormConfig.labelCol}
                rules={[
                  {
                    required: true,
                    message: 'Please enter a code.'
                  }
                ]}
              >
                <Input
                  ref={(input) => input && input.focus()}
                  size="large"
                  placeholder="Enter code from authenticator app"
                />
              </Form.Item>
              <Button
                htmlType="submit"
                block
                size="large"
                type="primary"
                style={{ width: '100%' }}
                loading={isLoading}
                disabled={isLoading}
              >
                Submit
              </Button>
            </Form>
          </div>
        )
      });
    },
    [handleFirstTimeAuthenticator, isLoading, onSubmit]
  );

  return (
    <Container>
      <EulithCard title="Sign In">
        <Form
          requiredMark="optional"
          scrollToFirstError
          wrapperCol={{ span: 24 }}
          size={antdFormConfig.size}
          onFinish={requestCode}
        >
          <Form.Item
            labelCol={antdFormConfig.labelCol}
            name="sub"
            label="Username"
            rules={[
              {
                required: true,
                message: 'Please enter your username.'
              }
            ]}
          >
            <Input disabled={loading || isLoading} />
          </Form.Item>
          <Form.Item
            labelCol={antdFormConfig.labelCol}
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: 'Please enter your password'
              }
            ]}
          >
            <Input.Password disabled={loading || isLoading} />
          </Form.Item>
          <Button
            htmlType="submit"
            block
            size="large"
            type="primary"
            style={{ width: '100%' }}
            disabled={loading || isLoading}
            loading={loading || isLoading}
          >
            {loading ? null : 'Sign In'}
          </Button>
        </Form>
        <Text style={{ marginTop: 24 }}>
          Not a member?{' '}
          <StyledInternalLink to="/signup">
            <b>Register now.</b>
          </StyledInternalLink>
        </Text>
      </EulithCard>
    </Container>
  );
};

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 160px;
`;

export default LoginForm;
