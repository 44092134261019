import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { OrderFormField, OrderState } from './orderTypes';
import { eulithApi } from '../eulith/eulithService';
import { orderApi } from './orderService';
import { DecoratedContract } from '../eulith/eulithTypes';

export const initialState: OrderState = {
  form: {
    parentOrder: 'Immediate Swap',
    marketFrom: '',
    marketTo: '',
    maximumSlippage: 0.1,
    amount: 1,
    router: '',
    marketType: 'sell-buy'
  },
  quote: null,
  selectedTradingContract: undefined,
  selectedWhitelistContract: undefined,
  selectedWalletContract: undefined
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setField: (state, action: PayloadAction<OrderFormField>) => {
      for (const [field, value] of Object.entries(action.payload)) {
        state.form[field] = value;
      }
    },
    setSelectedTradingContract: (state, action: PayloadAction<DecoratedContract | undefined>) => {
      state.selectedTradingContract = action.payload;
    },
    setSelectedWhitelistContract: (state, action: PayloadAction<DecoratedContract | undefined>) => {
      state.selectedWhitelistContract = action.payload;
    },
    setSelectedWalletContract: (state, action: PayloadAction<DecoratedContract | undefined>) => {
      state.selectedWalletContract = action.payload;
    }
  },
  extraReducers: (builder) => {
    // auto-select first contract
    builder.addMatcher(
      eulithApi.endpoints.getContracts.matchFulfilled,
      (state, action: PayloadAction<DecoratedContract[]>) => {
        const contracts = action.payload || [];
        if (!state.selectedTradingContract) {
          state.selectedTradingContract = contracts[0];
        } else {
          state.selectedTradingContract = contracts.find((c) => {
            return c.contractAddress === state.selectedTradingContract?.contractAddress;
          });
        }
        if (!state.selectedWhitelistContract) {
          state.selectedWhitelistContract = contracts[0];
        } else {
          state.selectedWhitelistContract = contracts.find((c) => {
            return c.contractAddress === state.selectedWhitelistContract?.contractAddress;
          });
        }
        if (!state.selectedWalletContract) {
          state.selectedWalletContract = contracts[0];
        } else {
          state.selectedWalletContract = contracts.find((c) => {
            return c.contractAddress === state.selectedWalletContract?.contractAddress;
          });
        }
      }
    );
    builder.addMatcher(
      orderApi.endpoints.fetchQuote.matchFulfilled,
      (state, action: PayloadAction<number>) => {
        state.quote = {
          price: `${action.payload}`
        };
      }
    );
    builder.addMatcher(orderApi.endpoints.executeOrderForAce.matchFulfilled, (state) => {
      state.quote = initialState.quote;
      state.form = initialState.form;
    });
    builder.addMatcher(orderApi.endpoints.executeOrderWithoutAce.matchFulfilled, (state) => {
      state.quote = initialState.quote;
      state.form = initialState.form;
    });
  }
});

export const {
  setField,
  setSelectedTradingContract,
  setSelectedWhitelistContract,
  setSelectedWalletContract
} = orderSlice.actions;

export const selectOrderForm = (state: RootState) => state.order.form;
export const selectQuote = (state: RootState) => state.order.quote;
export const selectSelectedTradingContract = (state: RootState) =>
  state.order.selectedTradingContract;
export const selectSelectedWhitelistContract = (state: RootState) =>
  state.order.selectedWhitelistContract;
export const selectSelectedWalletContract = (state: RootState) =>
  state.order.selectedWalletContract;

export default orderSlice.reducer;
