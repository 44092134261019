import { Row, Spin, message } from 'antd';
import React, { useEffect, useState } from 'react';
import EulithHelmetConsumer from '../components/EulithHelmetConsumer';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import backgroundImage from '../assets/images/rectangle_fade.svg';
import { useLazyGetFundQuery } from '../features/funds/fundsService';
import type { FundFormValues } from '../features/funds/fundsTypes';
import '../styles/funds-form.css';
import FundsPreview from './FundsPreview';
import { Text } from '../styles/shared';
import { LoadingOutlined } from '@ant-design/icons';
import BugsnagManager from '../BugsnagManager';

interface FundsPreviewPageProps {
  id?: string;
}

const FundsPreviewPage: React.FC<FundsPreviewPageProps> = ({ id }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<FundFormValues>();
  const params = useParams();
  const fundId = params.id || id;

  const [getFund] = useLazyGetFundQuery();

  const handleGetFund = React.useCallback(async () => {
    try {
      if (fundId) {
        const response = await getFund(fundId).unwrap();
        if (response?.isDraft) {
          message.warning('Could not find this fund.');
          navigate(-1);
        } else {
          setData(response);
          setTimeout(() => {
            setLoading(false);
          }, 750);
        }
      } else {
        message.warning('Could not find this fund.');
        navigate(-1);
      }
    } catch (error: any) {
      BugsnagManager.notify(error, {
        context: 'Unable to find fund in fund preview page',
        metadata: {
          fundId
        }
      });
      console.warn(error);
      message.warning('Could not find this fund.');
      navigate(-1);
    }
  }, [fundId, getFund, navigate]);

  useEffect(() => {
    if (fundId) {
      handleGetFund();
    } else {
      message.warning('Could not find this fund.');
      navigate(-1);
    }
  }, [fundId, handleGetFund, navigate]);

  return (
    <Container>
      <EulithHelmetConsumer>{data ? data.title : 'Fund Overview'}</EulithHelmetConsumer>
      <Body>
        {data ? (
          <FundsPreviewContainer>
            <FundsPreview isPreview={false} data={data} loading={loading} />
          </FundsPreviewContainer>
        ) : (
          <div style={{ height: '100%', position: 'relative' }}>
            <Row
              gutter={[16, 16]}
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Text style={{ color: '#000' }}>Loading...</Text>
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            </Row>
          </div>
        )}
      </Body>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const Body = styled.div`
  @media (max-width: 576px) {
    padding: 20px;
  }
  overflow: auto;
  height: 100%;
  width: 100%;
`;

const FundsPreviewContainer = styled.div`
  margin: auto;
  max-width: 1200px;
  padding: 20px;
`;

export default FundsPreviewPage;
