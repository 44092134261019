import { Card, CardProps } from 'antd';
import styled from 'styled-components';
import { colors } from '../../styles/shared';
import React from 'react';
import { useAppSelector } from '../../hooks/redux';
import { selectIsDarkMode } from '../../features/settings/settingsSlice';

interface Props extends CardProps {
  isDisabled?: boolean;
  fullHeight?: boolean;
  containerStyle?: React.CSSProperties;
  smallHeader?: boolean;
}

const EulithCard: React.FC<Props> = ({
  children,
  isDisabled = false,
  title,
  style,
  extra,
  fullHeight,
  containerStyle,
  smallHeader = false,
  ...rest
}) => {
  const isDarkMode = useAppSelector(selectIsDarkMode);
  return (
    <StyledContainer isDisabled={isDisabled} fullHeight={fullHeight} style={containerStyle}>
      <StyledCard
        title={title}
        smallHeader={smallHeader}
        style={style}
        extra={extra}
        theme={isDarkMode ? 'dark' : 'light'}
        {...rest}
      >
        {children}
      </StyledCard>
    </StyledContainer>
  );
};

const StyledCard = styled(Card)<{ theme: 'dark' | 'light'; smallHeader: boolean }>`
  overflow: auto;
  background-color: ${({ theme }) => (theme === 'light' ? colors.white : colors.blue2)};
  .ant-card-hoverable:hover {
    border-color: ${colors.teal} !important;
  }
  .ant-card-head {
    min-height: ${({ smallHeader }) => {
      if (smallHeader) {
        return ``;
      } else {
        return 'unset !important';
      }
    }};
    padding: ${({ smallHeader }) => {
      if (smallHeader) {
        return ``;
      } else {
        return '24px 24px !important';
      }
    }};
  }
  .ant-card-head-title {
    padding: ${({ smallHeader }) => {
      if (smallHeader) {
        return ``;
      } else {
        return '0';
      }
    }};
    margin: ${({ smallHeader }) => {
      if (smallHeader) {
        return ``;
      } else {
        return '0';
      }
    }};
    font-weight: ${({ smallHeader }) => {
      if (smallHeader) {
        return ``;
      } else {
        return '600';
      }
    }};
    font-size: ${({ smallHeader }) => {
      if (smallHeader) {
        return ``;
      } else {
        return '24px';
      }
    }};
  }
`;

const StyledContainer = styled.div<{ isDisabled?: boolean; fullHeight?: boolean }>`
  ${({ isDisabled, fullHeight }) => {
    if (isDisabled) {
      return `
        height: ${fullHeight ? '100%' : 'unset'};
        opacity: 0.3 !important;
        cursor: not-allowed !important;
        pointer-events: none !important;
        user-select: none !important;
        overflow: hidden;
        `;
    } else {
      return `
        height: ${fullHeight ? '100%' : 'unset'};
        overflow: hidden;
      `;
    }
  }}
`;

export default EulithCard;
