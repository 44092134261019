import EulithHelmetConsumer from '../components/EulithHelmetConsumer';
import StatusSection from '../components/Status/StatusSection';
import { useGetStatusQuery } from '../features/status/statusService';

const StatusPage: React.FC = () => {
  const { data: status, isLoading } = useGetStatusQuery();

  return (
    <>
      <EulithHelmetConsumer>Status</EulithHelmetConsumer>
      <StatusSection providers={status?.providers || []} loading={isLoading} />
    </>
  );
};

export default StatusPage;
