import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { SettingsState } from './settingsTypes';
import { colors } from '../../styles/shared';

const initialState: SettingsState = {
  isDarkMode: true,
  inlineCollapsed: undefined,
  theme: {
    token: {
      fontFamily: 'Inter',
      colorPrimary: colors.teal
    }
  }
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.isDarkMode = !state.isDarkMode;
    },
    toggleInlineCollapsed: (state) => {
      state.inlineCollapsed = !state.inlineCollapsed;
    }
  }
});

export const { toggleTheme, toggleInlineCollapsed } = settingsSlice.actions;

export const selectTheme = (state: RootState) => state.settings.theme;
export const selectIsDarkMode = (state: RootState) => state.settings.isDarkMode;
export const selectInlineCollapsed = (state: RootState) => state.settings.inlineCollapsed;

export default settingsSlice.reducer;
