import { createSlice } from '@reduxjs/toolkit';
import type { TokensState } from './tokenTypes';

const initialState: TokensState = {
  data: null
};

export const tokensSlice = createSlice({
  name: 'tokens',
  initialState,
  reducers: {}
});

export default tokensSlice.reducer;
