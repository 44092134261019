import React from 'react';
import EulithHelmetConsumer from '../components/EulithHelmetConsumer';
import ContactUsForm from '../components/Contact/ContactUsForm';

// we could easily just have this be replaced by a typeform until further notice
const ContactUsPage: React.FC = () => {
  return (
    <>
      <EulithHelmetConsumer>Contact Us</EulithHelmetConsumer>
      <ContactUsForm />
    </>
  );
};

export default ContactUsPage;
