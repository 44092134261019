import React from 'react';
import EulithHelmetConsumer from '../components/EulithHelmetConsumer';
import ReauthorizeForm from '../components/ReauthorizeForm';

const ReauthPage: React.FC = () => {
  return (
    <>
      <EulithHelmetConsumer>Reauthorize</EulithHelmetConsumer>
      <ReauthorizeForm />
    </>
  );
};

export default ReauthPage;
