import React, { useCallback, useState } from 'react';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Text, antdFormConfig } from '../../styles/shared';
import Flex from '../Flex';
import { Button, Form, Input, Modal, message } from 'antd';
import {
  useGenerateNewTotpMutation,
  useReauthorizeUserMutation
} from '../../features/auth/authService';
import { NewActivateUserRequest } from '../../features/auth/authTypes';
import * as Eulith from 'eulith-web3js';
import EulithCard from '../EulithCard';
import { LockOutlined } from '@ant-design/icons';
import { QRCodeSVG } from 'qrcode.react';
import { setAccessToken } from '../../features/auth/authSlice';
import { useAppDispatch } from '../../hooks/redux';
import BugsnagManager from '../../BugsnagManager';

interface Props {
  user: string;
  salt: string;
  hash: string;
}

interface OTPValues {
  code: string;
}

interface FormValues {
  password: string;
  confirmPassword: string;
}

const ActivateUserForm: React.FC<Props> = ({ user, salt, hash }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [showConfetti, setShowConfetti] = useState<boolean>(false);
  const [reauthorizeUser] = useReauthorizeUserMutation();
  const [generateNewTotp] = useGenerateNewTotpMutation();

  const displayConfetti = () => {
    if (showConfetti) {
      return (
        <ConfettiContainer>
          <Confetti
            gravity={0.7}
            friction={0.99}
            recycle={false}
            width={window.innerWidth}
            height={window.innerHeight}
          />
        </ConfettiContainer>
      );
    } else {
      return null;
    }
  };

  const activate = useCallback(
    async (data: FormValues & OTPValues) => {
      const { password } = data;
      try {
        Modal.destroyAll();
        const generateKeyPairResponse = await Eulith.Auth.generateKeyPair(user, password);
        const signHashResponse = generateKeyPairResponse.sign_hash(hash);
        const requestData = {
          user,
          salt,
          activation_hash: hash,
          totp_code: data.code,
          pub_key: generateKeyPairResponse.public_key,
          activation_hash_signature: signHashResponse
        } as NewActivateUserRequest;
        const response = await reauthorizeUser(requestData).unwrap();
        setShowConfetti(true);
        if (response?.token) {
          dispatch(setAccessToken(response.token));
          message.success('Welcome to Eulith!');
          setTimeout(() => {
            setShowConfetti(false);
            navigate('/home');
          }, 3000);
        } else {
          message.success('Welcome to Eulith! You may now log in.');
          setTimeout(() => {
            setShowConfetti(false);
            navigate('/login');
          }, 3000);
        }
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Failed to activate user'
        });
        console.warn(error);
        message.error('Failed to activate user. Please contact us if this problem persists.');
      }
    },
    [user, hash, salt, reauthorizeUser, dispatch, navigate]
  );

  const handleNext = useCallback(
    async (data: FormValues) => {
      try {
        setLoading(true);
        const response = await generateNewTotp({
          user,
          salt,
          hash
        }).unwrap();
        Modal.info({
          title: 'Scan QR code to continue in an authenticator app of choice.',
          icon: <LockOutlined />,
          closeIcon: <div />,
          footer: null,
          closable: false,
          maskClosable: false,
          content: (
            <div style={{ paddingRight: 30, paddingTop: 20 }}>
              <QRCodeSVG
                style={{ width: '100%', height: '100%' }}
                includeMargin={false}
                // TODO: correct this api response property to be totp_url
                //@ts-ignore
                value={response?.import_url}
              />
              <Form
                onFinish={(values: OTPValues) => {
                  activate({
                    ...values,
                    ...data
                  });
                }}
                style={{ marginTop: 20 }}
              >
                <Form.Item
                  name="code"
                  labelCol={antdFormConfig.labelCol}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a code.'
                    }
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter code from authenticator app"
                    ref={(input) => input && input.focus()}
                  />
                </Form.Item>
                <Button
                  htmlType="submit"
                  block
                  size="large"
                  type="primary"
                  style={{ width: '100%' }}
                >
                  Submit
                </Button>
              </Form>
            </div>
          )
        });
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Failed to activate user account'
        });
        console.warn(error);
        message.error(
          'Unable to activate your account. Please contact us if this problem persists.'
        );
      }
    },
    [generateNewTotp, hash, activate, salt, user]
  );

  return (
    <div>
      {displayConfetti()}
      <Flex
        flex="1 1 auto"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        style={{ paddingBottom: 80 }}
      >
        <EulithCard title="Activate Your Account">
          <Text>Please set a password to activate your account.</Text>
          <Form
            layout="vertical"
            requiredMark={antdFormConfig.requiredMark}
            scrollToFirstError
            size={antdFormConfig.size}
            onFinish={handleNext}
            style={{ width: '100%' }}
          >
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please enter your password.'
                }
              ]}
            >
              <Input placeholder="Password" type="password" />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password.'
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('The passwords that you entered do not match.')
                    );
                  }
                })
              ]}
            >
              <Input placeholder="Confirm Password" type="password" />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form>
        </EulithCard>
      </Flex>
    </div>
  );
};

const ConfettiContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
`;

export default ActivateUserForm;
