import { useAddress } from '@thirdweb-dev/react';
import { selectSelectedTradingContract } from '../features/order/orderSlice';
import { useAppSelector } from './redux';
import { selectPingData } from '../features/eulith/eulithService';

export const useIsMismatched = () => {
  const connectedWalletAddress = useAddress();
  const selectedTradingContract = useAppSelector(selectSelectedTradingContract);
  const pingData = useAppSelector(selectPingData);
  const selectedTradingContractAce = pingData[selectedTradingContract?.tradingKeyAddress || ''];
  const hasAce = selectedTradingContract?.hasAce;

  let isMismatched;

  if (hasAce) {
    // If we have an ACE, the connected wallet address must match the uiWalletAddress
    // So if they don't exist, or they don't match, we're in trouble.
    if (selectedTradingContractAce?.ui_address && connectedWalletAddress) {
      isMismatched =
        selectedTradingContractAce.ui_address.toLowerCase() !==
        connectedWalletAddress.toLowerCase();
    } else {
      isMismatched = true;
    }
  } else {
    // If we don't have an ACE, the connected wallet address must match the trading key address
    // (and if either address doesn't exist, we're in trouble)
    if (selectedTradingContract?.tradingKeyAddress && connectedWalletAddress) {
      isMismatched =
        selectedTradingContract.tradingKeyAddress.toLowerCase() !==
        connectedWalletAddress.toLowerCase();
    } else {
      isMismatched = true;
    }
  }

  return isMismatched;
};
