import React, { FC, useCallback, useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ConfigProvider, theme } from 'antd';
import { ThirdwebProvider } from '@thirdweb-dev/react';
import { useAppSelector } from '../hooks/redux';
import { selectIsDarkMode, selectTheme } from '../features/settings/settingsSlice';
import { getChainByChainIdAsync } from '@thirdweb-dev/chains';
import { SUPPORTED_CHAINS } from '../features/wallet/constants';
import { defaultChains } from '@thirdweb-dev/chains';
import BugsnagManager from '../BugsnagManager';

const AppProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const currentTheme = useAppSelector(selectTheme);
  const isDarkMode = useAppSelector(selectIsDarkMode);
  const [supportedChains, setSupportedChains] = useState<any[]>(defaultChains);

  const fetchChains = useCallback(async () => {
    const allChainsPromises = await new Set(
      [...SUPPORTED_CHAINS].map(async (chainId) => {
        return getChainByChainIdAsync(chainId).catch((error) => {
          console.warn(`Could not find chain id ${chainId}`);
          BugsnagManager.notify(error, {
            context: `Could not find chain id ${chainId}`
          });
        });
      })
    );
    const chains = (await Promise.all(allChainsPromises)).filter((x) => x);
    setSupportedChains(chains);
  }, []);

  useEffect(() => {
    fetchChains();
  }, [fetchChains]);

  return (
    // TODO: replace with Eulith API key (this key currently belongs to Drew)
    <ThirdwebProvider supportedChains={supportedChains} clientId="5e66730a25c5dfb37dfce0177d54857f">
      <ConfigProvider
        theme={{
          ...currentTheme,
          algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm
        }}
      >
        <HelmetProvider>{children}</HelmetProvider>
      </ConfigProvider>
    </ThirdwebProvider>
  );
};

export default AppProvider;
