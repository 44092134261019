import { CheckCircleFilled, LoadingOutlined, MailFilled } from '@ant-design/icons';
import { Col, Image, Row, Spin, Steps, message } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import EulithHelmetConsumer from '../components/EulithHelmetConsumer';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import styled from 'styled-components';
import fallbackImage from '../assets/fallbackImage';
import FundsFormOverview from '../components/FundsForm/FundsFormOverview';
import FundsFormReview from '../components/FundsForm/FundsFormReview';
import FundsFormSection from '../components/FundsForm/FundsFormSection';
import { selectCanCreateFunds } from '../features/auth/authSlice';
import { useCreateFundMutation, useLazyGetFundQuery } from '../features/funds/fundsService';
import { FundFormValues } from '../features/funds/fundsTypes';
import { selectIsDarkMode } from '../features/settings/settingsSlice';
import { useAppSelector } from '../hooks/redux';
import { H2, Text, colors } from '../styles/shared';
import { buildFundFormConfig } from '../utils/funds';
import LeftColumnContainer from '../components/LeftColumnContainer';
import StepsTitleInputContainer from '../components/StepsTitleInputContainer';
import StepsContainer from '../components/StepsContainer';
import StepsFooterContainer from '../components/StepsFooterContainer';
import CenterAlignedAnchor from '../components/CenterAlignedAnchor';
import BugsnagManager from '../BugsnagManager';
import '../styles/funds-form.css';

interface Props {
  editing?: boolean;
  initialValues?: FundFormValues;
}

const DEFAULT_FORM_VALUES: FundFormValues = {
  offeredToUSInvestors: true
};

const FundsCreateEditPage: React.FC<Props> = ({ editing = false }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState<FundFormValues>(DEFAULT_FORM_VALUES);
  const canCreateFunds = useAppSelector(selectCanCreateFunds);
  const isDarkMode = useAppSelector(selectIsDarkMode);
  const [getFund] = useLazyGetFundQuery();
  const [createFund] = useCreateFundMutation();

  const id = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('id') || '';
  }, []);

  const [loading, setLoading] = useState(!!id);

  const fundFormConfig = buildFundFormConfig({
    title: data?.title || ''
  });

  const currentStep =
    fundFormConfig.findIndex((section) => {
      return location.pathname.includes(section.path);
    }) - 1;

  useEffect(() => {
    if (!loading) {
      const step = fundFormConfig.find((section) => {
        return location.pathname.includes(section.path);
      });
      if (step) {
        const container = document.getElementById('steps-scrollable-container');
        const element = document.getElementById(`section_${step.path}`);
        if (element && container) {
          const containerTop = container.getBoundingClientRect().top;
          const containerHeight = container.getBoundingClientRect().height - containerTop;
          const offsetY = container.scrollTop;
          const y =
            element.getBoundingClientRect().top - containerTop + offsetY - containerHeight * 0.5;
          container.scroll({
            top: y,
            behavior: 'smooth'
          });
        }
      }
    }
  }, [fundFormConfig, location.pathname, loading]);

  const returnUrlForDraftProgress = useCallback(
    (fund: FundFormValues) => {
      const completedStage = fund?.completedStage;
      const step = completedStage
        ? fundFormConfig.findIndex((section) => {
            return section.path.includes(completedStage);
          })
        : -1;
      return `${fundFormConfig[step + 1]?.path}?id=${fund.id}`;
    },
    [fundFormConfig]
  );

  const handleGetFund = useCallback(async () => {
    if (id) {
      try {
        const response = await getFund(id).unwrap();
        if (response?.id) {
          setData({
            ...response,
            ...DEFAULT_FORM_VALUES
          });
          navigate(returnUrlForDraftProgress(response));
          setTimeout(() => {
            setLoading(false);
          }, 750);
        } else {
          console.warn('Data did not exist');
          navigate('/funds/manage');
          message.error('Unable to fetch data for this fund. Please try again later.');
        }
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Unable to fetch data for fund',
          metadata: {
            id
          }
        });
        console.warn(error);
        navigate('/funds/manage');
        message.error('Unable to fetch data for this fund. Please try again later.');
      }
    } else if (!editing) {
      try {
        const valuesToSend: FundFormValues = {
          isDraft: true
        };
        const response = await createFund(valuesToSend).unwrap();
        if (response?.id) {
          setData({
            ...response,
            ...DEFAULT_FORM_VALUES
          });
          navigate(returnUrlForDraftProgress(response), { replace: true });
          setTimeout(() => {
            setLoading(false);
          }, 750);
        } else {
          console.warn('Malformed response');
          navigate('/funds/manage');
          message.error('Unable to create a new fund right now. Please try again later.');
        }
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Unable to create new fund'
        });
        console.warn(error);
        navigate('/funds/manage');
        message.error('Unable to create a new fund right now. Please try again later.');
      }
    }
  }, [createFund, id, editing, getFund, navigate, returnUrlForDraftProgress]);

  useEffect(() => {
    if (canCreateFunds) {
      handleGetFund();
    } else {
      navigate('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stepItems = fundFormConfig
    .filter(({ showStep }) => !!showStep)
    .map((section, index) => {
      const indexOfLastCompletedStage = fundFormConfig.findIndex((field) => {
        return field.path === data?.completedStage;
      });
      const isSectionEnabled = data?.isDraft ? indexOfLastCompletedStage >= index : true;
      const isSectionCompleted = data?.isDraft ? indexOfLastCompletedStage > index : true;
      return {
        title: section.title,
        id: `section_${section.path}`,
        icon: isSectionCompleted ? <CheckCircleFilled /> : section.icon,
        description: section.quickDescription,
        onClick: isSectionEnabled ? () => navigate(`${section.path}?id=${id}`) : undefined,
        disabled: !isSectionEnabled,
        className: isSectionEnabled ? 'ant-enabled-step' : 'ant-disabled-step'
      };
    });

  function handleSetData(values: FundFormValues) {
    setData((prevData: FundFormValues) => {
      const title = values.title || prevData.title;
      const newData = {
        ...prevData,
        ...values,
        title
      };
      return newData;
    });
  }

  function navigateToOverview() {
    navigate(`/funds/${editing ? 'edit' : 'create'}/${fundFormConfig[0].path}?id=${id}`);
  }

  function returnLatestCurrentStage(path: string) {
    const indexOfLastCompletedStage = fundFormConfig.findIndex((section) => {
      return section.path === data.completedStage;
    });
    const indexOfCurrentCompletedStage = fundFormConfig.findIndex((section) => {
      return section.path === path;
    });
    if (indexOfLastCompletedStage === -1) {
      return path;
    } else {
      if (indexOfCurrentCompletedStage >= indexOfLastCompletedStage) {
        return path;
      } else {
        return data.completedStage || '';
      }
    }
  }

  if (!canCreateFunds) {
    return null;
  }

  if (loading) {
    return (
      <>
        <EulithHelmetConsumer>{editing ? 'Edit Fund' : 'Create Fund'}</EulithHelmetConsumer>
        <Container>
          <Row
            gutter={[16, 16]}
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Text>Loading...</Text>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          </Row>
        </Container>
      </>
    );
  }

  return (
    <>
      <EulithHelmetConsumer>{editing ? 'Edit Fund' : 'Create Fund'}</EulithHelmetConsumer>
      <Container>
        <Row
          gutter={[16, 16]}
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Col xs={0} sm={0} md={10} lg={8} xl={6} xxl={4} style={{ height: '100%' }}>
            <LeftColumnContainer theme={isDarkMode ? 'dark' : 'light'}>
              <StepsTitleInputContainer onClick={navigateToOverview}>
                <Image
                  src={data.logoFile?.[0]?.url}
                  width={40}
                  height={40}
                  preview={false}
                  style={{ objectFit: 'cover', minWidth: 40, borderRadius: 4 }}
                  wrapperStyle={{ marginRight: 15 }}
                  fallback={fallbackImage}
                />
                <H2 style={{ cursor: data?.title ? 'pointer' : 'default' }}>
                  {data?.title || 'Untitled Fund'}
                </H2>
              </StepsTitleInputContainer>
              <StepsContainer id="steps-scrollable-container">
                <Steps direction="vertical" current={currentStep} items={stepItems} />
              </StepsContainer>
              <StepsFooterContainer>
                <p style={{ color: colors.placeholder }}>© Eulith {new Date().getFullYear()}</p>
                <CenterAlignedAnchor href="mailto:help@eulith.com">
                  <MailFilled
                    style={{
                      color: colors.placeholder,
                      fontSize: 12,
                      marginRight: 7
                    }}
                  />
                  <span style={{ color: colors.placeholder }}>help@eulith.com</span>
                </CenterAlignedAnchor>
              </StepsFooterContainer>
            </LeftColumnContainer>
          </Col>
          <Col xs={24} sm={24} md={14} lg={16} xl={18} xxl={20} style={{ height: '100%' }}>
            <SwitchTransition>
              <CSSTransition key={location.pathname} classNames="fade" timeout={300} unmountOnExit>
                <Routes location={location}>
                  {fundFormConfig.map((section, index) => {
                    if (index === 0) {
                      return (
                        <Route
                          key={section.title}
                          path={section.path}
                          element={
                            <FundsFormOverview
                              editing={editing}
                              initialValues={data}
                              returnLatestCurrentStage={returnLatestCurrentStage}
                              navigateTo={fundFormConfig[index + 1]?.path || ''}
                              setData={handleSetData}
                              section={section}
                            />
                          }
                        />
                      );
                    } else if (index === fundFormConfig.length - 1) {
                      return (
                        <Route
                          key={section.title}
                          path={section.path}
                          element={
                            <FundsFormReview
                              navigateBack={fundFormConfig[index - 1]?.path || ''}
                              progressCount={`${index + 1}/${fundFormConfig.length}`}
                              id={data?.id}
                              editing={editing}
                              section={section}
                              initialValues={data}
                            />
                          }
                        />
                      );
                    } else {
                      return (
                        <Route
                          key={section.title}
                          path={section.path}
                          element={
                            <FundsFormSection
                              editing={editing}
                              initialValues={data}
                              setData={handleSetData}
                              section={section}
                              returnLatestCurrentStage={returnLatestCurrentStage}
                              navigateTo={fundFormConfig[index + 1]?.path || ''}
                              navigateBack={fundFormConfig[index - 1]?.path || ''}
                              progressCount={`${index + 1}/${fundFormConfig.length}`}
                            />
                          }
                        />
                      );
                    }
                  })}
                </Routes>
              </CSSTransition>
            </SwitchTransition>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export default FundsCreateEditPage;
