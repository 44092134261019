import { SupportedWallets, Wallet } from './walletTypes';

export class MetaMaskWallet extends Wallet {
  private readonly address: string;

  constructor(address: string) {
    super();
    this.address = address;
  }

  getName(): SupportedWallets {
    return 'MetaMask';
  }

  getAddress(): string {
    return this.address;
  }

  async chainId(): Promise<number> {
    return parseInt(await window.ethereum.request({ method: 'eth_chainId' }), 16);
  }

  async signTypedData(typedData: any): Promise<string> {
    return await window.ethereum.request({
      method: 'eth_signTypedData_v4',
      params: [this.getAddress(), typedData]
    });
  }

  async ethRpcRequest(method: string, params: any[]): Promise<any> {
    return await window.ethereum.request({
      method,
      params
    });
  }

  async disconnect(): Promise<void> {
    // MetaMask doesn't have a concept of disconnecting.
    //
    // https://github.com/MetaMask/metamask-extension/issues/8990
  }
}

export async function connect(): Promise<Wallet | null> {
  if (!ensure()) {
    return null;
  }

  return getConnection();
}

export async function checkConnection(): Promise<Wallet | null> {
  if (!ensure()) {
    return null;
  }

  return window.ethereum.isConnected() ? getConnection() : null;
}

async function getConnection(): Promise<Wallet | null> {
  // TODO(ian): check chain ID (https://docs.metamask.io/wallet/how-to/connect/detect-network/)
  // TODO(ian): error handling
  const connectedAddresses = await window.ethereum.request({ method: 'eth_requestAccounts' });

  if (connectedAddresses.length === 0) {
    console.warn('Tried to connect to MetaMask, but no connected accounts found.');
    return null;
  }

  // MetaMask docs recommend reloading the page when chain or accounts change.
  //
  // https://docs.metamask.io/wallet/how-to/connect/detect-network/
  // TODO(ian): how do I notify the dapp?
  window.ethereum.on('chainChanged', (chainId: number) => {
    window.location.reload();
  });

  window.ethereum.on('accountsChanged', (accounts: string[]) => {
    window.location.reload();
  });

  // TODO(ian): handle multiple accounts
  return new MetaMaskWallet(connectedAddresses[0]);
}

function ensure(): boolean {
  return window.ethereum && window.ethereum.isMetaMask && !window.ethereum.isEnkrypt;
}
