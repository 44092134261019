import React from 'react';

interface FormRowProps {
  children?: string | boolean | JSX.Element | JSX.Element[] | null;
  id: string;
}

export default function FormRow({ children, id }: FormRowProps) {
  const count = React.Children.count(children);
  return (
    <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
      {React.Children.map(children, (child, index) => {
        return (
          <React.Fragment key={`form_row_${id}_${index}`}>
            <div style={{ flexGrow: 1 }}>{child}</div>
            {index === count - 1 ? null : <div style={{ width: 15 }} />}
          </React.Fragment>
        );
      })}
    </div>
  );
}
