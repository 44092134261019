import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { sendInquiry } from '../../utils/slack';
import { antdFormConfig } from '../../styles/shared';
import { Button, Form, Input, Row } from 'antd';
import FormRow from '../FormRow';
import EulithCard from '../EulithCard';
import FundFormBackButton from '../FundsForm/FundFormBackButton';
import BugsnagManager from '../../BugsnagManager';
import MetaMaskHero from '../MetaMaskHero';
interface FormValues {
  firstName: string;
  lastName: string;
  username?: string;
  email: string;
}

const SignUpForm: React.FC = () => {
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const isMetaMaskSnapSignUp = window.location.pathname === '/metamask-signup';

  const onSubmit = useCallback(
    async (data: FormValues) => {
      const { firstName, lastName, username, email } = data;
      setSubmitting(true);
      try {
        await sendInquiry({ firstName, lastName, username, email, isMetaMaskSnapSignUp });
        setSubmitting(false);
        navigate('/thank-you');
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Failed to send contact inquiry in sign up form'
        });
        console.log(error);
        setSubmitting(false);
        navigate('/thank-you');
      }
    },
    [navigate, isMetaMaskSnapSignUp]
  );

  return (
    <Container>
      <EulithCard
        title={
          <Row>
            {isMetaMaskSnapSignUp ? null : (
              <FundFormBackButton style={{ marginRight: 5 }} to="/" backTitle={null} />
            )}
            <div className="ant-card-head-title" style={{ fontSize: 16 }}>
              Create Your Account
            </div>
          </Row>
        }
      >
        <Form
          onFinish={onSubmit}
          style={{ width: '100%' }}
          layout="vertical"
          requiredMark={antdFormConfig.requiredMark}
          scrollToFirstError
          size={antdFormConfig.size}
        >
          {isMetaMaskSnapSignUp ? (
            <div>
              <MetaMaskHero />
              <p>
                In order to use{' '}
                <a href="https://metamask.io/snaps/" target="_blank" rel="noreferrer">
                  MetaMask Snaps
                </a>
                , you will need to first register as a Eulith user. Once approved, you will receive
                a link via email that will guide you through your Snaps setup and usage.
              </p>
            </div>
          ) : null}
          <FormRow id="sign_up_form_row">
            <Form.Item
              label="First name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: 'Please enter first name.'
                }
              ]}
            >
              <Input placeholder="First name" disabled={submitting} />
            </Form.Item>
            <Form.Item
              label="Last name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: 'Please enter your last name.'
                }
              ]}
            >
              <Input placeholder="Last name" disabled={submitting} />
            </Form.Item>
          </FormRow>
          <Form.Item
            label="Desired Username"
            name="username"
            rules={[
              {
                required: true,
                message: 'Please enter a desired username.'
              }
            ]}
          >
            <Input placeholder="Username" disabled={submitting} />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please enter your email.'
              }
            ]}
          >
            <Input placeholder="Email" disabled={submitting} />
          </Form.Item>
          <Button type="primary" htmlType="submit" disabled={submitting}>
            Sign Up
          </Button>
        </Form>
      </EulithCard>
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  width: 100%;
  max-width: 600px;
`;

export default SignUpForm;
