import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Collapse, Popconfirm, Space } from 'antd';
import * as Eulith from 'eulith-web3js';
import MiddleEllipses from '../../../../../MiddleEllipses';
import MonoSpaceSpan from '../../../../../MonoSpaceSans';
import { chainIdToNetworkLabel } from '../../../../../../utils/networks';

const { Panel } = Collapse;

interface WhitelistAddressListItemProps {
  data: Eulith.WhitelistsV2.AddressOnChain;
  editAddress?: (address: Eulith.WhitelistsV2.AddressOnChain) => void;
  deleteAddress?: (address: Eulith.WhitelistsV2.AddressOnChain) => void;
}

function WhitelistAddressListItem({
  data,
  editAddress,
  deleteAddress
}: WhitelistAddressListItemProps) {
  function stopPropagation(e: any) {
    e?.stopPropagation?.();
  }
  return (
    <Collapse size="small">
      <Panel
        showArrow={false}
        key={`whitelist_address_panel_${data.address}`}
        header={
          <div>
            <MiddleEllipses width="95%">
              {data.address ? <MonoSpaceSpan>{data.address}</MonoSpaceSpan> : 'No address provided'}
            </MiddleEllipses>
            {chainIdToNetworkLabel(data.chainId)}
          </div>
        }
        extra={
          <Space>
            {editAddress ? (
              <EditOutlined
                onClick={(e: any) => {
                  e?.stopPropagation?.();
                  editAddress(data);
                }}
              />
            ) : null}
            {deleteAddress ? (
              <Popconfirm
                title="Delete this address?"
                description="They will be removed from this whitelist."
                onConfirm={(e: any) => {
                  e?.stopPropagation?.();
                  deleteAddress(data);
                }}
                okText="Delete"
                okButtonProps={{
                  danger: true
                }}
              >
                <DeleteOutlined style={{ color: '#DC4546' }} onClick={stopPropagation} />
              </Popconfirm>
            ) : null}
          </Space>
        }
        style={{ marginBottom: 20 }}
        collapsible="icon"
      />
    </Collapse>
  );
}

export default WhitelistAddressListItem;
