import styled from 'styled-components';
import { METAMASK_IMAGE, WALLET_CONNECT_IMAGE } from '../../features/wallet/constants';
import CloseButton from '../CloseButton';
import { SupportedWallets } from '../../features/wallet/walletTypes';

interface Props {
  onClick?: () => void;
  onDisconnect?: () => void;
  title: SupportedWallets;
  subtitle?: string;
  size?: 'sm' | 'lg';
}

function returnWalletImageForType(name: SupportedWallets) {
  switch (name) {
    case 'MetaMask':
      return METAMASK_IMAGE;
    case 'WalletConnect':
      return WALLET_CONNECT_IMAGE;
    default:
      return '';
  }
}

export function WalletConnectButton({
  onClick,
  title,
  subtitle,
  size = 'lg',
  onDisconnect
}: Props) {
  const imageSize = size === 'lg' ? 48 : 30;
  return (
    <StyledButton onClick={onClick}>
      <img
        width={imageSize}
        height={imageSize}
        src={returnWalletImageForType(title)}
        alt=""
        draggable="false"
        style={{ height: imageSize, width: imageSize, userSelect: 'none' }}
      />
      <div
        className="css-1b5osxr"
        style={{ flex: '1 1 0%', display: 'flex', flexDirection: 'column', gap: 6 }}
      >
        <StyledTitle>{title}</StyledTitle>
        {subtitle ? <StyledSubtitle>{subtitle}</StyledSubtitle> : null}
      </div>
      {onDisconnect ? <CloseButton tooltip="Disconnect Wallet" onClick={onDisconnect} /> : null}
    </StyledButton>
  );
}

const StyledButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  color: hsl(247, 3.4%, 50.7%);
  position: relative;
  border-radius: 8px;
  padding: 8px 8px;
  transition: background-color 200ms ease, transform 200ms ease;
  &:hover {
    background-color: hsl(230deg 11.63% 12%);
    transform: scale(1.01);
  }
`;

const StyledTitle = styled.span`
  font-size: 16px;
  color: hsl(256, 6%, 93.2%);
  margin: 0;
  display: block;
  font-weight: 600;
  line-height: 1;
  text-wrap: inherit;
`;

const StyledSubtitle = styled.span`
  font-size: 14px;
  color: hsl(256, 6%, 93.2%);
  margin: 0;
  display: block;
  line-height: 1;
  text-wrap: inherit;
`;
