import { Outlet } from 'react-router-dom';
import Footer from '../Footer';
import { Main, StyledContainer } from './shared';
import usePageView from '../../hooks/usePageView';
import LoggedInHeader from '../Header/LoggedInHeader';
import { useAppSelector } from '../../hooks/redux';
import { selectAuthenticated } from '../../features/auth/authSlice';
import { useEffect } from 'react';

type Props = {
  title?: string;
};

const LoggedOutLayout: React.FC<Props> = () => {
  usePageView();
  const authenticated = useAppSelector(selectAuthenticated);
  useEffect(() => {
    if (authenticated) {
      const urlParams = new URLSearchParams(window.location.search);
      const redirect = urlParams.get('redirect') || '';
      if (redirect) {
        window.location.replace(redirect);
      } else {
        if (window.location.pathname === '/metamask-signup') {
          window.location.replace('/metamask');
        } else {
          window.location.replace('/home');
        }
      }
    }
  }, [authenticated]);
  if (authenticated) {
    return null;
  }
  return (
    <StyledContainer>
      <LoggedInHeader />
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </StyledContainer>
  );
};

export default LoggedOutLayout;
