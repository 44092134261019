import React, { useEffect } from 'react';
import EulithHelmetConsumer from '../components/EulithHelmetConsumer';
import styled from 'styled-components';
import { H1, sizes } from '../styles/shared';
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown, Modal, Space, Statistic, Tag, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useAppSelector } from '../hooks/redux';
import type { FundFormValues } from '../features/funds/fundsTypes';
import type { MenuProps } from 'antd';
import {
  selectUserFundsData,
  useDeleteFundMutation,
  useEditFundMutation,
  useLazyGetUserFundsQuery
} from '../features/funds/fundsService';
import EulithCard from '../components/EulithCard';
import { selectCanCreateFunds } from '../features/auth/authSlice';
import BugsnagManager from '../BugsnagManager';

const FundsManagePage: React.FC = () => {
  const navigate = useNavigate();
  const [getUserFunds, { isLoading: getUserFundsLoading }] = useLazyGetUserFundsQuery();
  const funds = useAppSelector(selectUserFundsData);
  const [editFund, { isLoading: editFundLoading }] = useEditFundMutation();
  const [deleteFund] = useDeleteFundMutation();
  const canCreateFunds = useAppSelector(selectCanCreateFunds);

  const loading = getUserFundsLoading || editFundLoading;

  useEffect(() => {
    if (canCreateFunds) {
      fetchData();
    } else {
      navigate('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchData() {
    try {
      await getUserFunds().unwrap();
    } catch (error: any) {
      BugsnagManager.notify(error, {
        context: 'Unable to fetch funds submissions'
      });
      message.error('Unable to fetch your submissions. Please try again later.');
      console.warn(error);
    }
  }

  async function toggleFundPublishedStatus(fund: FundFormValues) {
    return new Promise(async (resolve, reject) => {
      try {
        const valuesToSend = { ...fund, published: !fund.published };
        const response = await editFund(valuesToSend).unwrap();
        fetchData();
        resolve(response);
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Unable to change fund published status',
          metadata: {
            fund
          }
        });
        message.error('Unable to change published status. Please try again later.');
        console.warn(error);
        reject(error);
      }
    });
  }

  function navigateToAddFund() {
    navigate('/funds/create');
  }

  function navigateToPreview(fund: FundFormValues) {
    window.open(`/funds/public/${fund.id}`, '_blank');
  }

  function navigateToEditDraft(fund: FundFormValues) {
    // TODO: these url paths should pull from fund form config
    // rather than being duplicated here.
    navigate(`/funds/edit/start?id=${fund.id}`);
  }

  async function handleDeleteFund(fund: FundFormValues) {
    try {
      await deleteFund(fund);
      message.success(fund.isDraft ? 'Draft deleted!' : 'Fund deleted!');
      fetchData();
    } catch (error: any) {
      BugsnagManager.notify(error, {
        context: 'Unable to delete fund',
        metadata: {
          fund
        }
      });
      console.warn(error);
      message.error(
        `Unable to delete this ${fund.isDraft ? 'draft' : 'fund'}. Please try again later.`
      );
    }
  }

  function createItems(fund: FundFormValues): MenuProps['items'] {
    return [
      {
        key: `fund_${fund.id}_edit`,
        label: 'Edit',
        onClick: () => {
          navigateToEditDraft(fund);
        }
      },
      {
        type: 'divider'
      },
      {
        key: `fund_${fund.id}_publish`,
        label: fund.published ? 'Unpublish' : 'Publish',
        onClick: () => {
          Modal.warning({
            title: fund.published ? 'Unpublish this fund?' : 'Publish this fund?',
            content: fund.published
              ? 'Your fund will be hidden from public view, and will not be accessible by allocators. You can re-publish your fund at any time.'
              : 'Your fund will be visible to the public and by allocators. You can unpublish your fund at any time.',
            okText: fund.published ? 'Unpublish' : 'Publish',
            cancelText: 'Cancel',
            maskClosable: true,
            okButtonProps: {
              loading: editFundLoading,
              danger: fund.published
            },
            okCancel: true,
            onOk: () => {
              return toggleFundPublishedStatus(fund);
            }
          });
        }
      },
      {
        key: `fund_${fund.id}_delete`,
        danger: true,
        label: 'Delete',
        onClick: () => {
          Modal.warning({
            title: 'Delete this fund?',
            content: 'Your fund will be permanently deleted. This action cannot be undone.',
            okText: 'Delete',
            cancelText: 'Cancel',
            maskClosable: true,
            okButtonProps: {
              danger: true
            },
            okCancel: true,
            onOk: () => {
              handleDeleteFund(fund);
            }
          });
        }
      }
    ];
  }

  if (!canCreateFunds) {
    return null;
  }
  return (
    <>
      <EulithHelmetConsumer>Funds</EulithHelmetConsumer>
      <Container>
        <EulithCard
          title="My Funds"
          style={{ margin: 'auto' }}
          loading={loading}
          containerStyle={{
            paddingBottom: 20
          }}
          extra={
            funds?.length ? (
              <Button type="primary" onClick={navigateToAddFund}>
                Add Fund
              </Button>
            ) : null
          }
        >
          {funds?.length ? (
            funds.map((fund: FundFormValues, index) => {
              return (
                <EulithCard
                  key={`fund_${fund.id}`}
                  style={{ marginBottom: index === funds.length - 1 ? 0 : 25 }}
                  type="inner"
                  title={
                    <Space>
                      {fund.title || 'Untitled'}
                      <Tag color={fund.isDraft ? 'orange' : fund.published ? 'green' : 'orange'}>
                        {fund.isDraft ? 'INCOMPLETE' : fund.published ? 'COMPLETED' : 'UNPUBLISHED'}
                      </Tag>
                    </Space>
                  }
                  extra={
                    <Space>
                      {fund.isDraft ? (
                        <Button
                          danger
                          onClick={() => {
                            handleDeleteFund(fund);
                          }}
                        >
                          Discard
                        </Button>
                      ) : (
                        <Dropdown menu={{ items: createItems(fund) }}>
                          <a onClick={(e) => e.preventDefault()} style={{ marginRight: 10 }}>
                            <Space>
                              Actions
                              <DownOutlined />
                            </Space>
                          </a>
                        </Dropdown>
                      )}
                      <Button
                        onClick={() => {
                          if (fund.isDraft) {
                            navigateToEditDraft(fund);
                          } else {
                            navigateToPreview(fund);
                          }
                        }}
                      >
                        {fund.isDraft ? 'Continue' : 'View'}
                      </Button>
                    </Space>
                  }
                >
                  <Statistic
                    title="Summary"
                    valueStyle={{ fontSize: sizes.default }}
                    value={fund.summary || 'No summary provided'}
                  />
                </EulithCard>
              );
            })
          ) : (
            <FundsListEmptyContainer>
              <H1>Add Your First Fund</H1>
              <p>We&apos;re excited to put your profile in front of allocators.</p>
              <Button type="primary" onClick={navigateToAddFund} style={{ marginTop: 20 }}>
                Add Fund
              </Button>
            </FundsListEmptyContainer>
          )}
        </EulithCard>
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const FundsListEmptyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
`;

export default FundsManagePage;
