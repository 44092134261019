import dayjs from 'dayjs';

/* eslint-disable @typescript-eslint/ban-ts-comment */
export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const roundToTwo = (num: number) => {
  // @ts-ignore
  return +(Math.round(num + 'e+2') + 'e-2');
};

export const everyNth = (arr: any[], nth: number) =>
  arr.filter((_, index) => {
    return index % nth === 0;
  });

export const subtractHours = (date: Date, hours: number) => {
  date.setHours(date.getHours() - hours);

  return date;
};

export const formatAMPM = (date: Date) => {
  let hours = date.getHours();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const strTime = hours + ' ' + ampm;
  return strTime;
};

export function hexToDollarAmount(
  hexValue: string,
  decimals: number | null,
  exchangeRate?: number
): string {
  // Convert the hexadecimal value to its decimal representation
  const decimalValue = BigInt(hexValue);

  // Convert to a string and remove extra zeros from the end
  const scaledValue = decimalValue.toString().slice(0, -(decimals || 0)) || '0';

  // Parse the scaled value as a regular number and multiply by the exchange rate
  const dollarAmount = parseInt(scaledValue, 10) * (exchangeRate || 1);

  return dollarAmount.toFixed(2);
}

export function disabledDateAfter(current: any) {
  const today = dayjs().startOf('day');
  return current ? current.isAfter(today) : undefined;
}

export function disabledDateBefore(current: any) {
  const today = dayjs().startOf('day');
  return current ? current.isBefore(today) : undefined;
}

export function dollarParser(value = '') {
  if (value) {
    return value.replace(/\$\s?|(,*)/g, '');
  } else {
    return value;
  }
}
