import React, { CSSProperties, ReactElement, useCallback, useState } from 'react';
import { Button, Card, Collapse, Form, Image, Input, List, Row, Space, Tag, message } from 'antd';
import { H1, Text, antdFormConfig, colors } from '../styles/shared';
import { ArrowRightOutlined, DownloadOutlined, SendOutlined } from '@ant-design/icons';
import fallbackImage from '../assets/fallbackImage';
import { buildFundFormConfig } from '../utils/funds';
import ManagerListItem from '../components/ManagerListItem';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { RuleType } from 'rc-field-form/lib/interface';
import { slackHookUrl } from '../utils/slack';
import type {
  ContactFundRequest,
  FundFieldName,
  FundFormSectionField,
  FundFormValues,
  ManagerFieldName,
  UploadFieldName
} from '../features/funds/fundsTypes';
import { useLazyContactFundQuery } from '../features/funds/fundsService';
import { DateTime } from 'luxon';
import '../styles/funds-form.css';
import { formatUrl } from '../utils/data';
import BugsnagManager from '../BugsnagManager';

const { Panel } = Collapse;

interface FundsPreviewProps {
  data: FundFormValues;
  loading?: boolean;
  isPreview: boolean;
}

interface PropertyStatusProps {
  title: string;
  enabled: boolean;
  style?: CSSProperties;
}

interface ContactUsFormConfigType {
  name: string;
  label: string;
  requiredMessage?: string | ReactElement;
  required?: boolean;
  requiredType?: RuleType;
  placeholder?: string;
}

const contactUsFormConfig: ContactUsFormConfigType[] = [
  {
    name: 'firstName',
    label: 'First Name',
    requiredMessage: 'Please provide a first name',
    required: true,
    requiredType: 'string',
    placeholder: 'John'
  },
  {
    name: 'lastName',
    label: 'Last Name',
    requiredMessage: 'Please provide a last name',
    required: true,
    requiredType: 'string',
    placeholder: 'Smith'
  },
  {
    name: 'email',
    label: 'Email',
    requiredMessage: 'Please provide a email',
    required: true,
    requiredType: 'email',
    placeholder: 'john@sample.com'
  },
  {
    name: 'firm',
    label: 'Firm',
    required: false,
    requiredType: 'string',
    requiredMessage: 'Please provide a firm',
    placeholder: 'Sample Firm'
  }
];

function PropertyStatus({ title, enabled, style }: PropertyStatusProps) {
  return (
    <Button type="text">
      <Space direction="horizontal" style={style}>
        <div
          style={{
            width: 10,
            height: 10,
            borderRadius: 100,
            backgroundColor: enabled ? 'green' : 'orange'
          }}
        />
        <div>{title}</div>
      </Space>
    </Button>
  );
}

const FundsPreview: React.FC<FundsPreviewProps> = ({ data, loading, isPreview }) => {
  const [contactUsForm] = Form.useForm();
  const [feedbackForm] = Form.useForm();
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [contactFund, { isLoading }] = useLazyContactFundQuery();

  const isAdmin = false;

  const fundFormConfig = buildFundFormConfig({
    title: data?.title || ''
  });

  async function handleShare() {
    const url = window.location.href;
    try {
      navigator
        .share({
          title: data?.title,
          url
        })
        .then(() => {
          message.success('Thanks for sharing!');
        })
        .catch((error: any) => {
          BugsnagManager.notify(error, {
            context: 'Unable to share fund',
            metadata: {
              url
            }
          });
          console.warn(error);
          message.error('Unable to share. Please try again later.');
        });
    } catch (error) {
      try {
        await navigator.clipboard.writeText(url);
        message.success('Copied to clipboard!');
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Unable to copy fund url to clipboard',
          metadata: {
            url
          }
        });
        console.warn(error);
        message.error('Unable to copy to clipboard.');
      }
    }
  }

  async function handleNextSteps(values: ContactFundRequest) {
    try {
      if (data?.id) {
        await contactFund({
          ...values,
          id: data.id
        }).unwrap();
        contactUsForm.resetFields();
        message.success('Your message has been sent!');
      } else {
        console.warn('Unable to contact fund because no id was provided');
      }
    } catch (error: any) {
      BugsnagManager.notify(error, {
        context: 'Unable to send fund message',
        metadata: {
          values,
          data
        }
      });
      console.warn(error);
      message.error("We couldn't send your message. Please try again later.");
    }
  }

  async function handleFeedback(values: any) {
    setFeedbackLoading(true);
    const data = {
      text: 'New Feedback',
      blocks: [
        {
          type: 'header',
          text: {
            type: 'plain_text',
            text: 'Feedback',
            emoji: false
          }
        },
        {
          type: 'section',
          fields: [
            {
              type: 'mrkdwn',
              text: values.feedback
            }
          ]
        }
      ]
    };

    const raw = JSON.stringify(data);

    const requestOptions = {
      method: 'POST',
      body: raw
    };

    try {
      await fetch(slackHookUrl, requestOptions);
      feedbackForm.resetFields();
      message.success('Your feedback has been sent!');
      setFeedbackLoading(false);
    } catch (error: any) {
      BugsnagManager.notify(error, {
        context: 'Unable to send fund feedback',
        metadata: {
          values,
          data
        }
      });
      console.warn(error);
      message.error("We couldn't send your feedback. Please try again later.");
      setFeedbackLoading(false);
    }
  }

  const renderCardTitle = useCallback(() => {
    return (
      <div style={{ width: '100%' }}>
        <Space
          style={{ overflowY: 'auto', width: '100%', paddingRight: 100 }}
          className="hide-scrollbar"
        >
          {isAdmin ? (
            <>
              <PropertyStatus title="Eulith OEMS" enabled={!!data?.usesEulithOEMS} />
              <PropertyStatus title="Eulith ODD" enabled={!!data?.usesEulithODD} />
            </>
          ) : null}
          {data.files
            ?.filter?.((file) => !file.hide)
            ?.map((file) => {
              return (
                <Button
                  key={`fund_file_${file.id}`}
                  type="text"
                  target="_blank"
                  rel="noreferrer"
                  href={file.url}
                  download={file.name}
                  style={{ color: colors.teal }}
                  icon={<DownloadOutlined />}
                >
                  Download {file.label}
                </Button>
              );
            })}
        </Space>
      </div>
    );
  }, [isAdmin, data?.usesEulithOEMS, data?.usesEulithODD, data.files]);

  const sections = fundFormConfig
    .filter(({ showStep, title }) => {
      return (
        !!showStep &&
        !title.toLowerCase().includes('review') &&
        !title.toLowerCase().includes('contact')
      );
    })
    .map((section) => {
      return {
        ...section,
        fields: section.fields.flat().filter((field: FundFormSectionField) => {
          switch (field.type) {
            case 'phone':
            case 'url':
            case 'text':
            case 'number':
            case 'date':
            case 'textarea':
              if (field.fields) {
                return !!data[field.name]?.length;
              } else {
                return !!data[field.name];
              }
            case 'select':
              return !!data[field.name]?.length;
            case 'upload':
              return false;
            default:
              return false;
          }
        })
      };
    });

  if (!data) {
    return <Card title="Overview" loading />;
  }

  return (
    <div>
      <Image
        src={data?.logoFile?.[0]?.url}
        preview={false}
        fallback={fallbackImage}
        width={200}
        height={200}
        style={{ objectFit: 'cover', borderRadius: 4 }}
      />
      <H1
        style={{
          fontSize: '4rem',
          margin: '25px 0',
          color: isPreview ? colors.white : 'inherit'
        }}
      >
        {data.title}
      </H1>
      {data?.updatedAt ? (
        <p style={{ color: colors.grey4, textAlign: 'end', fontWeight: 600, marginTop: 0 }}>
          Last Updated: {DateTime.fromISO(data?.updatedAt).toLocaleString(DateTime.DATE_MED)}
        </p>
      ) : null}
      <Card
        loading={loading}
        title={renderCardTitle()}
        extra={
          <CustomButton
            type="primary"
            icon={<SendOutlined />}
            onClick={handleShare}
            disabled={isPreview}
          >
            Share Fund
          </CustomButton>
        }
      >
        <Collapse
          defaultActiveKey={
            sections?.[0]?.path ? [sections?.[0]?.path, 'next-steps'] : ['next-steps']
          }
        >
          {sections.map((section) => {
            if (!section.fields?.length) {
              return null;
            }
            return (
              <Panel
                key={section.path}
                style={isPreview ? undefined : { backgroundColor: '#E0E9F0' }}
                header={
                  <Row>
                    <Space align="start">
                      {section.icon}
                      <div>{section.title}</div>
                    </Space>
                  </Row>
                }
              >
                <List
                  itemLayout="horizontal"
                  dataSource={section.fields}
                  renderItem={(field: FundFormSectionField) => {
                    switch (field.type) {
                      case 'phone':
                      case 'url':
                      case 'textarea':
                      case 'date':
                      case 'text':
                      case 'number':
                        if (field.fields && field.name === 'managers' && data.managers) {
                          return (
                            <List.Item>
                              <List.Item.Meta
                                avatar={field.icon}
                                title={field.label}
                                description={
                                  <div style={{ marginTop: 15 }}>
                                    {data.managers.map((manager) => {
                                      return (
                                        <ManagerListItem
                                          key={`fund_manager_${manager.id}`}
                                          data={manager}
                                        />
                                      );
                                    })}
                                  </div>
                                }
                              />
                            </List.Item>
                          );
                        } else {
                          let description: string | React.ReactElement = `${field.prefix || ''}${
                            data[field.name]
                          }${field.suffix || ''}`;
                          if (field.formatter) {
                            description = field.formatter(description, {
                              userTyping: false,
                              input: description
                            });
                          }
                          switch (
                            field.name as UploadFieldName | FundFieldName | ManagerFieldName
                          ) {
                            case 'closingDate':
                            case 'launchDate':
                              description = dayjs(data[field.name]).format('MMMM, YYYY');
                              break;
                            case 'website':
                              description = (
                                <a
                                  href={formatUrl(data[field.name])}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {`${field.prefix || ''}${data[field.name]}${field.suffix || ''}`}
                                </a>
                              );
                              break;
                            case 'fundAdministratorEmail':
                            case 'contactEmail':
                              description = (
                                <a
                                  href={`mailto:${data[field.name]}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {`${field.prefix || ''}${data[field.name]}${field.suffix || ''}`}
                                </a>
                              );
                              break;
                            case 'contactPhoneNumber':
                              description = (
                                <a
                                  href={`tel:${data[field.name]}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {`${field.prefix || ''}${data[field.name]}${field.suffix || ''}`}
                                </a>
                              );
                              break;
                          }
                          return (
                            <List.Item>
                              <List.Item.Meta
                                avatar={field.icon}
                                title={field.label}
                                description={description}
                              />
                            </List.Item>
                          );
                        }
                      case 'select':
                        // eslint-disable-next-line no-case-declarations
                        const attributes = Array.isArray(data[field.name])
                          ? data[field.name]
                          : [data[field.name]];
                        return (
                          <List.Item>
                            <List.Item.Meta
                              avatar={field.icon}
                              title={field.label}
                              description={
                                <Row>
                                  <Space wrap>
                                    {attributes.map((attribute: string) => {
                                      return (
                                        <Tag key={`fund_attribute_${attribute}`}>{attribute}</Tag>
                                      );
                                    })}
                                  </Space>
                                </Row>
                              }
                            />
                          </List.Item>
                        );
                      default:
                        console.warn(
                          'Unable to render field type for section',
                          section.title,
                          field.type
                        );
                    }
                  }}
                />
              </Panel>
            );
          })}
          {data?.managers?.length ? (
            <Panel
              key="next-steps"
              showArrow={false}
              style={isPreview ? undefined : { backgroundColor: '#E0E9F0' }}
              header={
                <Row>
                  <Space align="start">
                    <ArrowRightOutlined />
                    <div>Next Steps</div>
                  </Space>
                </Row>
              }
            >
              <Text style={{ color: isPreview ? colors.white : 'inherit' }}>
                We&apos;d love to hear from you. Please submit your name and email and we&apos;ll
                send you an email with more information shortly.
              </Text>
              <Form
                form={contactUsForm}
                layout="vertical"
                requiredMark={antdFormConfig.requiredMark}
                scrollToFirstError
                onFinish={handleNextSteps}
                size={antdFormConfig.size}
              >
                {contactUsFormConfig.map((field) => {
                  return (
                    <Form.Item
                      key={`contact_us_form_${field.name}`}
                      labelCol={antdFormConfig.labelCol}
                      name={field.name}
                      label={field.label}
                      rules={[
                        {
                          required: field.required,
                          type: field.requiredType || 'string',
                          message: field.requiredMessage
                        }
                      ]}
                    >
                      <Input placeholder={field.placeholder} disabled={isPreview || isLoading} />
                    </Form.Item>
                  );
                })}
                <CustomButton
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  icon={<SendOutlined />}
                  disabled={isPreview}
                >
                  Contact Manager
                </CustomButton>
              </Form>
            </Panel>
          ) : null}
        </Collapse>
        {isPreview ? null : (
          <Card title="Critical Feedback & Change Requests" style={{ marginTop: 20 }}>
            <Form
              form={feedbackForm}
              layout="vertical"
              requiredMark={antdFormConfig.requiredMark}
              scrollToFirstError
              onFinish={handleFeedback}
              size={antdFormConfig.size}
            >
              <Form.Item
                key={`feedback_form_field`}
                labelCol={antdFormConfig.labelCol}
                name="feedback"
                rules={[
                  {
                    required: true,
                    message: 'Please add your feedback.'
                  }
                ]}
              >
                <Input.TextArea
                  style={{ minHeight: 200 }}
                  disabled={feedbackLoading}
                  placeholder="Please tell us how we can improve our services. The more detail the better."
                />
              </Form.Item>
              <CustomButton
                type="primary"
                htmlType="submit"
                loading={feedbackLoading}
                icon={<SendOutlined />}
                disabled={isPreview}
              >
                Submit
              </CustomButton>
            </Form>
          </Card>
        )}
      </Card>
    </div>
  );
};

const CustomButton = styled(Button)`
  && {
    background-color: ${colors.teal};
    border-color: ${colors.teal};
    &:hover {
      background-color: rgba(78, 156, 159, 0.8);
      border-color: rgba(78, 156, 159, 0.8);
    }
  }
`;

export default FundsPreview;
