import {
  BaseQueryFn,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery
} from '@reduxjs/toolkit/query/react';
import * as Eulith from 'eulith-web3js';
import eulithSingleton from '../eulith/EulithSingleton';
import { OrderRequest } from './orderTypes';
import { TransactionConfig } from 'web3-core';
import BugsnagManager from '../../BugsnagManager';

function isSymbol(symbol: string): symbol is Eulith.Tokens.Symbols {
  return Object.values(Eulith.Tokens.Symbols).includes(symbol as Eulith.Tokens.Symbols);
}

const executeOrderMutationForAce: BaseQueryFn<void, string, FetchBaseQueryError> = async () => {
  try {
    if (!eulithSingleton.atomicTx) {
      const error = new Error('Eulith atomic tx has not been initialized');
      console.warn('Eulith atomic tx has not been initialized');
      //@ts-ignore
      return { error: error as FetchBaseQueryError };
    }
    const result = await eulithSingleton.atomicTx.commitForAce();
    return { data: result };
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'Unable to execute order for ace in query'
    });
    console.warn('Unable to execute order', error);
    return { error: error as FetchBaseQueryError };
  }
};

const executeOrderWithoutAceMutation: BaseQueryFn<
  void,
  TransactionConfig,
  FetchBaseQueryError
> = async () => {
  try {
    if (!eulithSingleton.atomicTx) {
      const error = new Error('Eulith atomic tx has not been initialized');
      console.warn('Eulith atomic tx has not been initialized');
      //@ts-ignore
      return { error: error as FetchBaseQueryError };
    }
    const result = await eulithSingleton.atomicTx.commit();
    return { data: result };
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'Unable to execute order without ace in query'
    });
    console.warn('Unable to execute order', error);
    return { error: error as FetchBaseQueryError };
  }
};

const fetchQuoteQuery: BaseQueryFn<OrderRequest, number, FetchBaseQueryError> = async ({
  sellToken,
  buyToken,
  recipient,
  routeThrough,
  slippageTolerance,
  liquiditySource,
  sellAmount,
  safeAddress,
  tradingKeyAddress,
  connectedWalletAddress
}) => {
  try {
    const provider = eulithSingleton.provider;
    const web3 = eulithSingleton.web3;

    if (!provider) {
      const error = new Error('Eulith instance has not been initialized');
      console.warn('Eulith instance has not been initialized');
      //@ts-ignore
      return { error: error as FetchBaseQueryError };
    }
    if (!web3) {
      const error = new Error('Eulith web3 instance has not been initialized');
      console.warn('Eulith web3 instance has not been initialized');
      //@ts-ignore
      return { error: error as FetchBaseQueryError };
    }
    if (!isSymbol(sellToken)) {
      throw new Error('Invalid sell token');
    }

    if (!isSymbol(buyToken)) {
      throw new Error('Invalid buy token');
    }
    const sellTokenContract = await Eulith.Tokens.getTokenContract({
      provider,
      symbol: sellToken
    });
    const buyTokenContract = await Eulith.Tokens.getTokenContract({
      provider,
      symbol: buyToken
    });
    const params: Eulith.Swaps.Request = new Eulith.Swaps.Request({
      sellToken: sellTokenContract,
      buyToken: buyTokenContract,
      sellAmount: parseFloat(`${sellAmount}`),
      recipient,
      routeThrough,
      slippageTolerance: parseFloat(`${(slippageTolerance || 1) / 100}`),
      liquiditySource
    });
    eulithSingleton.setAtomicTx(safeAddress, connectedWalletAddress, tradingKeyAddress);
    const result = await web3.eulithSwapQuote(params);
    await eulithSingleton.atomicTx?.addTransactions(result[1]);
    return { data: result[0] };
  } catch (error: any) {
    BugsnagManager.notify(error, {
      context: 'Unable to fetch quote in query',
      metadata: {
        sellToken,
        buyToken,
        recipient,
        routeThrough,
        slippageTolerance,
        liquiditySource,
        sellAmount,
        safeAddress,
        tradingKeyAddress,
        connectedWalletAddress
      }
    });
    console.warn('Unable to fetch quote', error);
    return { error: error as FetchBaseQueryError };
  }
};

export const orderApi = createApi({
  reducerPath: 'orderApi',
  tagTypes: ['Order'],
  baseQuery: fetchBaseQuery({}),
  endpoints: (builder) => ({
    executeOrderForAce: builder.mutation<string, void>({
      queryFn: executeOrderMutationForAce
    }),
    executeOrderWithoutAce: builder.mutation<TransactionConfig, void>({
      queryFn: executeOrderWithoutAceMutation
    }),
    fetchQuote: builder.query<number, OrderRequest>({
      queryFn: fetchQuoteQuery,
      providesTags: ['Order']
    })
  })
});

export const {
  useExecuteOrderWithoutAceMutation,
  useExecuteOrderForAceMutation,
  useFetchQuoteQuery,
  useLazyFetchQuoteQuery
} = orderApi;
