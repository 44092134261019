import * as Eulith from 'eulith-web3js';
import { useState } from 'react';
import { useAddress } from '@thirdweb-dev/react';
import eulithSingleton from '../features/eulith/EulithSingleton';
import { createSignerForSafeTransaction, createTransactionSigner } from '../utils/signing';
import BugsnagManager from '../BugsnagManager';

export const useArmor = () => {
  const signingAddress = useAddress();

  const [armorLoading, setArmorLoading] = useState(false);

  async function createArmor({
    name,
    description,
    tradingKeyAddress,
    existingSafeAddress,
    hasAce = false
  }: {
    name: string;
    description: string;
    tradingKeyAddress: string;
    existingSafeAddress?: string;
    hasAce: boolean;
  }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (!signingAddress) {
          console.warn('Signing address was not passed to enableArmor');
          return reject(new Error('Signing address was not passed to enableArmor'));
        }
        if (eulithSingleton.provider) {
          setArmorLoading(true);

          console.log('Creating signer');
          const setupSigner = createTransactionSigner(signingAddress);

          console.log('Creating armor agent');
          Eulith.OnChainAgents.createArmorAgent({
            provider: eulithSingleton.provider,
            tradingKeyAddress,
            setupSigner,
            safeAddress: existingSafeAddress || undefined,
            hasAce,
            name,
            description
          })
            .then((response: Eulith.OnChainAgents.IArmorAgent) => {
              console.log('Successfully created armor agent');
              setArmorLoading(false);
              resolve(response);
            })
            .catch((error: any) => {
              BugsnagManager.notify(error, {
                context: 'Failed to create armor agent',
                metadata: {
                  provider: eulithSingleton.provider,
                  authorizedAddress: tradingKeyAddress,
                  setupSigner,
                  safeAddress: existingSafeAddress || undefined,
                  hasAce: false,
                  name,
                  description
                }
              });
              console.error('Failed to create armor agent', error);
              setArmorLoading(false);
              reject(error);
            });
        } else {
          setArmorLoading(false);
          console.warn('Provider is not set yet');
          reject(new Error('Provider is not set yet'));
        }
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Unknown error when create armor agent',
          metadata: {
            provider: eulithSingleton.provider,
            authorizedAddress: tradingKeyAddress,
            safeAddress: existingSafeAddress || undefined,
            hasAce: false,
            name,
            description
          }
        });
        console.warn(error);
        setArmorLoading(false);
        reject(error);
      }
    });
  }

  async function authorizeArmorForOwner(ownerAddress: string, authorizedAddress: string) {
    return new Promise(async (resolve, reject) => {
      try {
        if (eulithSingleton.provider) {
          setArmorLoading(true);

          const agent = await Eulith.OnChainAgents.getArmorAgent({
            provider: eulithSingleton.provider,
            tradingKeyAddress: ownerAddress
          });

          const authorizingOwner = createSignerForSafeTransaction(authorizedAddress);

          agent
            .authorizeForOwner(eulithSingleton.provider, authorizingOwner)
            .then(() => {
              console.log('Successfully authorized armor for owner', authorizingOwner);
              setArmorLoading(false);
              resolve(authorizingOwner);
            })
            .catch((error) => {
              BugsnagManager.notify(error, {
                context: 'Unable to authorize armor for owner',
                metadata: {
                  ownerAddress,
                  authorizedAddress,
                  agent
                }
              });
              console.warn(error);
              setArmorLoading(false);
              reject(error);
            });
        } else {
          setArmorLoading(false);
          reject(new Error('Provider is not set yet'));
        }
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Unknown error when authorizing armor for owner',
          metadata: {
            ownerAddress,
            authorizedAddress
          }
        });
        console.warn(error);
        setArmorLoading(false);
        reject(error);
      }
    });
  }

  async function enableArmor(
    tradingKeyAddress: string,
    approvalThreshold: number,
    owners: string[],
    existingSafeAddress?: string
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        if (!signingAddress) {
          return reject(new Error('Signing address was not passed to enableArmor'));
        }
        if (eulithSingleton.provider) {
          setArmorLoading(true);

          const agent: Eulith.OnChainAgents.IArmorAgent = await Eulith.OnChainAgents.getArmorAgent({
            provider: eulithSingleton.provider,
            tradingKeyAddress
          });

          const txSigner = createTransactionSigner(signingAddress);

          const safeParams: Eulith.OnChainAgents.EnableArmorSafeParams = {
            newSafe: { owners, approvalThreshold },
            authorizedTradingAddress: tradingKeyAddress,
            safeAddress: existingSafeAddress || undefined
          };

          agent
            .enableArmor(eulithSingleton.provider, txSigner, safeParams)
            .then(() => {
              console.log('Successfully enabled armor', { owners, approvalThreshold });
              setArmorLoading(false);
              resolve({ owners, approvalThreshold });
            })
            .catch((error) => {
              console.warn(error);
              setArmorLoading(false);
              reject(error);
            });
        } else {
          setArmorLoading(false);
          reject(new Error('Provider is not set yet'));
        }
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Unknown error when enabling armor',
          metadata: {
            tradingKeyAddress,
            approvalThreshold,
            owners,
            existingSafeAddress
          }
        });
        console.warn(error);
        setArmorLoading(false);
        reject(error);
      }
    });
  }

  return {
    createArmor,
    authorizeArmorForOwner,
    enableArmor,
    armorLoading,
    setArmorLoading
  };
};
