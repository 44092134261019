import styled from 'styled-components';
import { padding } from '../../styles/shared';

const Footer = () => <FooterContainer />;

const FooterContainer = styled.footer`
  padding: 8px ${padding}px;
  background: linear-gradient(45deg, #0585ffd6, #4cbdc3ed);
`;

export default Footer;
