import { EditOutlined } from '@ant-design/icons';
import { Button, Modal, Space, message } from 'antd';
import { useMemo } from 'react';
import EulithHelmetConsumer from '../EulithHelmetConsumer';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useEditFundMutation } from '../../features/funds/fundsService';
import { FundsFormReviewProps } from '../../features/funds/fundsTypes';
import FundsPreview from '../../pages/FundsPreview';
import { H1, Text, colors } from '../../styles/shared';
import EulithCard from '../EulithCard';
import FundFormBackButton from './FundFormBackButton';
import BugsnagManager from '../../BugsnagManager';

const FundsFormReview: React.FC<FundsFormReviewProps> = ({
  id,
  progressCount,
  editing,
  section,
  initialValues = {},
  navigateBack
}) => {
  const navigate = useNavigate();
  const [editFund, { isLoading }] = useEditFundMutation();

  const fundId = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('id') || '';
  }, []);

  async function handleSubmit() {
    if (initialValues?.isDraft) {
      const valuesToSend = {
        ...initialValues,
        id: fundId || id,
        published: true,
        completedStage: null,
        isDraft: false
      };

      try {
        await editFund(valuesToSend).unwrap();
        setTimeout(() => {
          Modal.success({
            centered: true,
            content: (
              <div>
                <div>
                  Congratulations! Your fund has been submitted. Thank you for working with us.
                </div>
              </div>
            )
          });
          navigate('/funds/manage');
        }, 750);
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Unable to process fund submission'
        });
        message.error('Unable to process your submission. Please try again later.');
        console.warn(error);
      }
    } else {
      message.success('Your fund has been updated!');
      navigate('/funds/manage');
    }
  }

  return (
    <>
      <EulithHelmetConsumer>{section.title}</EulithHelmetConsumer>
      <EulithCard
        fullHeight
        bodyStyle={{ height: 'calc(100% - 80px)', padding: 0, overflow: 'auto' }}
        style={{ height: '100%', overflow: 'hidden' }}
        title={
          <FundFormBackButton
            to={`/funds/${editing ? 'edit' : 'create'}/${navigateBack}?id=${
              fundId || initialValues.id
            }`}
          />
        }
        extra={
          <Space>
            <Button
              loading={isLoading}
              type={editing ? 'default' : 'text'}
              onClick={() => navigate('/funds/manage')}
            >
              Finish Later
            </Button>
            <Button
              loading={isLoading}
              type="primary"
              onClick={handleSubmit}
              icon={initialValues?.isDraft ? <EditOutlined /> : <EditOutlined />}
            >
              {initialValues?.isDraft ? 'Submit' : 'Save'}
            </Button>
          </Space>
        }
      >
        <FundsFormReviewContainer>
          <Text style={{ color: colors.borderGrey }}>{progressCount}</Text>
          <H1>{section.title}</H1>
          <Text>{section.subtitle}</Text>
          <FundsPreviewContainer>
            <FundsPreview isPreview data={initialValues} />
          </FundsPreviewContainer>
        </FundsFormReviewContainer>
      </EulithCard>
    </>
  );
};

const FundsFormReviewContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const FundsPreviewContainer = styled.div`
  padding: 20px 0;
`;

export default FundsFormReview;
