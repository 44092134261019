import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store';
import { endpointHost } from '../../api/common';
import { GetStatusResponse } from './statusTypes';
import { createSelector } from '@reduxjs/toolkit';

export const statusApi = createApi({
  reducerPath: 'statusApi',
  baseQuery: fetchBaseQuery({
    baseUrl: endpointHost,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.accessToken;
      if (token) {
        if (!headers.has('Authorization') && token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
      }
      if (!headers.has('Content-Type')) {
        headers.set('Content-Type', 'application/json');
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getStatus: builder.query<GetStatusResponse, void>({
      query: () => ({
        url: '/v0/status',
        method: 'GET'
      })
    })
  })
});

export const { useLazyGetStatusQuery, useGetStatusQuery } = statusApi;

const selectStatusResults = statusApi.endpoints.getStatus.select();
export const selectStatusData = createSelector(selectStatusResults, (result) => result.data);
