import { useAddress } from '@thirdweb-dev/react';
import { Col, Form, Modal, Row, message } from 'antd';
import * as Eulith from 'eulith-web3js';
import React, { useCallback, useRef, useState } from 'react';
import Confetti from 'react-confetti';
import EulithHelmetConsumer from '../components/EulithHelmetConsumer';
import styled from 'styled-components';
import OrderForm from '../components/Trading/OrderForm';
import OrderSummary from '../components/Trading/OrderSummary';
import Portfolio from '../components/Trading/Portfolio';
import eulithSingleton from '../features/eulith/EulithSingleton';
import { useLazyFetchQuoteQuery } from '../features/order/orderService';
import {
  selectOrderForm,
  setField,
  selectSelectedTradingContract
} from '../features/order/orderSlice';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { chainIdToScannerUrl } from '../utils/networks';
import BugsnagManager from '../BugsnagManager';

const TradingHomePage: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const order = useAppSelector(selectOrderForm);
  const [fetchQuote, { isFetching }] = useLazyFetchQuoteQuery();
  const address = useAddress();
  const fieldBlurTimeoutRef = useRef(0);
  const [showConfetti, setShowConfetti] = useState(false);
  const [transactionHash, setTransactionHash] = useState<null | {
    hash: string;
    url: string;
    scannerName: string;
  }>(null);
  const selectedTradingContract = useAppSelector(selectSelectedTradingContract);

  const handleFetchQuote = useCallback(async () => {
    clearTimeout(fieldBlurTimeoutRef.current);
    if (address) {
      const provider = eulithSingleton.provider;
      if (!provider) {
        message.error('Eulith instance not yet initialized.');
        return;
      }
      if (!selectedTradingContract) {
        message.error('Please select an account before fetching a quote.');
        return;
      }

      try {
        await fetchQuote({
          sellToken: order.marketTo,
          buyToken: order.marketFrom,
          recipient: selectedTradingContract.safeAddress,
          routeThrough: order.router as Eulith.Swaps.Provider,
          slippageTolerance: order.maximumSlippage,
          sellAmount: order.amount,
          connectedWalletAddress: address,
          tradingKeyAddress: selectedTradingContract.tradingKeyAddress,
          safeAddress: selectedTradingContract.safeAddress || ''
        }).unwrap();
      } catch (error: any) {
        BugsnagManager.notify(error, {
          context: 'Unable to fetch quote in trading home page',
          metadata: {
            sellToken: order.marketTo,
            buyToken: order.marketFrom,
            recipient: selectedTradingContract.safeAddress,
            routeThrough: order.router as Eulith.Swaps.Provider,
            slippageTolerance: order.maximumSlippage,
            sellAmount: order.amount,
            connectedWalletAddress: address,
            tradingKeyAddress: selectedTradingContract.tradingKeyAddress,
            safeAddress: selectedTradingContract.safeAddress || ''
          }
        });
        console.warn(error);
        message.error(error?.message || 'Unable to fetch quote.');
      }
    } else {
      message.error('Please select a contract first');
    }
  }, [
    address,
    selectedTradingContract,
    fetchQuote,
    order?.marketTo,
    order?.marketFrom,
    order?.router,
    order?.maximumSlippage,
    order?.amount
  ]);

  function handleFieldChange(changed: any) {
    clearTimeout(fieldBlurTimeoutRef.current);
    dispatch(setField(changed));
    fieldBlurTimeoutRef.current = window.setTimeout(form.submit, 1000);
  }

  const displayConfetti = () => {
    if (showConfetti) {
      return (
        <ConfettiContainer>
          <Confetti
            gravity={0.7}
            recycle={false}
            friction={0.99}
            width={window.innerWidth}
            height={window.innerHeight}
          />
        </ConfettiContainer>
      );
    } else {
      return null;
    }
  };

  function handleSuccessfulTrade(hash: string) {
    setShowConfetti(true);
    const chainId = selectedTradingContract?.chainId;
    let url = '';
    let scannerName = '';
    if (chainId && chainIdToScannerUrl[chainId]) {
      url = `${chainIdToScannerUrl[chainId].url}/tx/${hash}`;
      scannerName = chainIdToScannerUrl[chainId].name;
    } else {
      url = `${chainIdToScannerUrl[1].url}/tx/${hash}`;
      scannerName = chainIdToScannerUrl[1].name;
    }
    setTransactionHash({ hash, url, scannerName });
  }

  return (
    <>
      <EulithHelmetConsumer>Trading</EulithHelmetConsumer>
      <Modal
        cancelText="Close"
        onCancel={() => {
          setTransactionHash(null);
          setShowConfetti(false);
        }}
        okText={
          transactionHash?.scannerName
            ? `View on ${transactionHash.scannerName}`
            : 'View Transaction'
        }
        closable={false}
        onOk={() => {
          if (transactionHash?.url) {
            window.open(transactionHash.url, '_blank');
          }
        }}
        open={!!transactionHash}
        title="Congratulations!"
      >
        <div>
          <p>Your trade has been successfully executed.</p>
        </div>
      </Modal>
      {displayConfetti()}
      <Row gutter={[10, 10]}>
        <Col md={24} xl={12}>
          <OrderForm
            form={form}
            fetchQuote={handleFetchQuote}
            loadingQuote={isFetching}
            handleFieldChange={handleFieldChange}
          />
          <OrderSummary onFinish={handleSuccessfulTrade} loadingQuote={isFetching} />
        </Col>
        <Col md={24} xl={12}>
          <Portfolio />
        </Col>
      </Row>
    </>
  );
};

const ConfettiContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
`;

export default TradingHomePage;
